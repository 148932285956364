
// PaymentMethodFormStripe
// Used for adding a new payment method for the user.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import {  DEFAULT_COUNTRY_CODE } from '@/Site';

export default class PaymentMethodForm extends Form {

    // Constructor
    constructor(model, id = null) {
        // Pass our model to the base class
        super(Object.assign(model ?? {},
            {
                id: id,
                name: '',
                isExpired: false,
                isDefault: false,
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                province: '',
                country: DEFAULT_COUNTRY_CODE,
            }));

        // Set required fields
        this.validator.fields.name.isRequired = true;
        this.validator.fields.addressLine1.isRequired = true;
        this.validator.fields.city.isRequired = true;
        this.validator.fields.state.isRequired = true;

        // Set maximum lengths
        // TODO: Expose these numbers from the backend somehow
        this.validator.fields.name.maxLength = 100;
        this.validator.fields.addressLine1.maxLength = 100;
        this.validator.fields.addressLine2.maxLength = 100;
        this.validator.fields.city.maxLength = 50;
        this.validator.fields.state.maxLength = 2;
        this.validator.fields.province.maxLength = 50;
        this.validator.fields.country.maxLength = 2;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    reset() {
        this.model.name = '';
        this.model.isExpired = false;
        this.model.isDefault = false;
        this.model.addressLine1 = '';
        this.model.addressLine2 = '';
        this.model.city = '';
        this.model.state = '';
        this.model.province = '';
        this.model.country = DEFAULT_COUNTRY_CODE;
    }

    /**
     * Call the form to reset for a new payment info.
     */
    create() {
        this.model.id = null;
        this.reset();
    }

    onValidate() {
        super.onValidate();
    }
}