<script>

    // PrintOnDemandStepper
    // 3 step stepper
    // Constants:
    // - stepCounter - is used to determine the current step you are on.

    // Constants
    const constants = {
        stepCounter: {
            ONE: 1,
            TWO: 2,
            THREE: 3
        }
    };

    // Exports
    export const stepCounter = constants.stepCounter;

</script>

<script setup>

    
    import { defineProps, computed } from 'vue';

    // Props ----
    const props = defineProps({
        currentStep: {
            type: Number,
            required: true
        }
    });

    // Constants
    const stepStatus = {
        CURRENT: 'current',
        COMPLETED: 'completed'
    }

    const steps = {
        STEP1: {
            step1: stepStatus.CURRENT
        },
        STEP2: {
            step1: stepStatus.COMPLETED,
            step2: stepStatus.CURRENT
        },
        STEP3: {
            step1: stepStatus.COMPLETED,
            step2: stepStatus.COMPLETED,
            step3: stepStatus.CURRENT
        }
    }

    // Computed ----
    // Step determines which classes to output based on the currentStep prop.
    const step = computed(() => {

        switch (props.currentStep) {
            case 1:
                return steps.STEP1;
            case 2:
                return steps.STEP2;
            case 3:
                return steps.STEP3;
            default:
                return {}
        }
    });
</script>

<template>

    <div class="print-on-demand-stepper">

        <div class="row">

            <div class="col">

                <div class="d-flex justify-content-evenly step-container">

                    <div class="step" :class="step.step1">

                        <div class="step-number">
                            <span v-if="step.step1 == stepStatus.COMPLETED">
                                <i class="bi bi-check-lg"></i>
                            </span>
                            <span v-else>
                                1
                            </span>
                        </div>

                        <div class="step-name">
                            Personalize
                        </div>

                    </div>

                    <div class="step" :class="step.step2">

                        <div class="step-number">
                            <span v-if="step.step2 == stepStatus.COMPLETED">
                                <i class="bi bi-check-lg"></i>
                            </span>
                            <span v-else>
                                2
                            </span>
                        </div>

                        <div class="step-name">
                            Select Mail List
                        </div>

                    </div>

                    <div class="step" :class="step.step3">

                        <div class="step-number">
                            <span v-if="step.step3 == stepStatus.COMPLETED">
                                <i class="bi bi-check-lg"></i>
                            </span>
                            <span v-else>
                                3
                            </span>
                        </div>

                        <div class="step-name">
                            Review
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<style lang="scss">
    .print-on-demand-stepper {

        .step-container {
            position: relative;

            &:before {
                border-top: 2px solid $gray-2;
                content: "";
                margin: 0 auto;
                position: absolute;
                top: calc(50% - 15px);
                left: 0;
                right: 0;
                bottom: 0;
                width: 55%;
                z-index: -1;
            }
        }

        .step {
            text-align: center;
        }
        // TODO: add the variables for success alert
        .current .step-number {
            color: #008040;
            border: 2px solid #008040;
        }
        // TODO: add the variables for success alert
        .completed .step-number {
            color: $gray-0;
            background: #008040;
        }

        .step-number {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 1px solid $gray-2;
            text-align: center;
            line-height: 60px;
            font-weight: bold;
            font-size: 1.875rem;
            color: $gray-2;
            display: inline-block;
            background: $gray-0;
        }

        .step-name {
            font-size: 1.375rem;
            color: $gray-2;
        }

        .current .step-name {
            color: $gray-3;
            font-weight: bold;
        }
    }
</style>