<script setup>

    // CallToActionImage
    // Call to action image on the sidebar. Takes an image, link and button text as props.
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        imageUrl: {
            type: String,
            required: true
        },
        linkUrl: {
            type: [String, Object],
            required: false
        },
        buttonText: {
            type: String,
            required: false
        }
    });

</script>

<template>

    <div class="call-to-action-image text-center">

        <img :src="props.imageUrl" class="img-fluid" :alt="props.buttonText"/>

        <div class="button-container">
            <router-link class="btn btn-primary" :to="props.linkUrl" :title="props.buttonText">{{buttonText}}</router-link>
        </div>

    </div>

</template>

<style lang="scss">
    .call-to-action-image {
        .button-container{
            padding:20px 10px;
            background: $color-1;
        }
    }
</style>