<script setup>

    // UserImageLibrary
    // This is a collection of all of the users images in their personal library

    /*
     * This component has an emit that will send the ID of the selected image to the parent if you add @selectImage
    */

    // Imports
    import { ref, defineEmits, defineProps } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';

    // Components
    import ImageDropZone from '@/site/areas/image-library/ImageDropZone';
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';
    import ToolTip from '@/site/components/ToolTip';

    // Constants
    const constants = {
        DEFAULT_PAGE_SIZE: 12
    }

    // Props ----
    const props = defineProps({
        showSelectButton: {
            type: Boolean,
            required: false,
            default: true
        },
        showEditMenu: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State
    const search = new Search('images-for-user', 'detail', 'image-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);

    const date = new Date();
    const today = date.toISOString("en-US");
    const years = new Date(2000, 0, 1).toISOString("en-US");

    const selectedImageId = ref('');

    const showOverlay = ref(false);

    const showSearch = ref(true);

    const imageIdToDelete = ref('');

    const renameId = ref('');

    const newImageName = ref('');

    let startDate = ref(years);

    let endDate = ref(today);

    // Methods
    function cancelDelete() {
        imageIdToDelete.value = '';
        showOverlay.value = false;
    }

    function cancelRename() {
        renameId.value = '';
    }

    async function confirmDelete() {
        // Delete it from the DB
        await site.imageLibrary.deleteUsersImages(imageIdToDelete.value);

        // Get the images fresh from the server after renaming 
        refreshImages();

        // Reset the variables
        imageIdToDelete.value = '';
        showOverlay.value = false;
    }

    function deleteImage(id) {
        imageIdToDelete.value = id;
        showOverlay.value = true;
    }

    function refreshImages() {
        search.refresh();
    }

    function renameImage(id, name) {
        renameId.value = id;
        newImageName.value = name;
    }

    async function saveRename() {

        await site.imageLibrary.postRenameImage(renameId.value, newImageName.value);
        
        // Get the images fresh from the server after renaming 
        refreshImages();

        // Reset Values after rename 
        renameId.value = '';
        newImageName.value = '';
    }

    function selectImage(id) {
        selectedImageId.value = id;
    }

    // Emits
    const emits = defineEmits(['selectImage']);

    const emitSelectedImage = () => {
        emits('selectImage', selectedImageId.value)
        selectedImageId.value = '';
    }

</script>

<template>

    <div class="user-image-library">

        <ConfirmationOverlay v-if="showOverlay">
            <template v-slot:headline>

                <h2 class="confirm">Are you sure you want to delete this image?</h2>

            </template>
            <template v-slot:buttons>

                <button class="btn btn-outline-primary me-5" @click="cancelDelete">Cancel</button>
                <button class="btn btn-outline-primary ms-5" @click="confirmDelete">Delete</button>

            </template>
        </ConfirmationOverlay>

        <SearchContainer :search="search" default-view="grid" :deep-link="false" v-if="showSearch">

            <div class="row mt-3">

                <div class="col text-end" v-if="props.showSelectButton">

                    <button class="btn btn-primary" @click="emitSelectedImage">Select</button>

                </div>

            </div>



            <div class="row mb-3">

                <div class="col">
                    <label class="fw-bold">Date Range Filter</label>
                    <DateRangeFilter filter-id="image-date-range" :initial-start-date="startDate" :initial-end-date="endDate" v-if="showSearch" />
                </div>

                <div class="col d-flex flex-column">
                    <div>
                        <label class="fw-bold">Search Image Library</label>
                        <TextBoxFilter filter-id="image-keyword" placeholder="Search Image Library" />
                    </div>
                    <div class="text-end mt-auto"><PageSelector /></div>
                </div>

            </div>

            <div class="row">
                <div class="col pt-3">
                    <ToolTip placement="right-end">
                        <p>Image Size Recommendations <span><i class="bi bi-question-circle-fill"></i></span></p>
                        <template #content>
                            <p class="fs-5">
                                <span class="text-secondary fw-bold">Logo Upload: </span>
                                In order to provide a clear, crisp image of your logo and picture, it is important to upload images that are high resolution.
                                For photos, a head shot (show head and shoulders) with a solid color background works best and is the most professional.
                                Most digital cameras will produce an image that will meet these quality requirements.
                                <br /> <br />
                                For the best results, use a high resolution image of <span class="fw-bolder">300 DPI (dots per inch) minimum.</span>
                                The higher the DPI, the clearer the photo or logo will be once it is printed.
                            </p>
                            <div>
                                <p class="text-secondary fw-bold fs-5 mb-0">Acceptable file formats: </p>
                                <p class="fs-5 mb-0"><span class="fw-bold">Agent photo: </span> JPG, JPEG, TIF and TIFF</p>
                                <p class="fs-5 mb-4"><span class="fw-bold">Agency logo: </span> Vector image, EPS, JPEG, TIF and TIFF</p>
                                <p class="fs-5"><span class="fw-bold">Need help? </span> Call 1-800-845-9860 and ask the operator for image upload support.</p>
                            </div>
                        </template>
                    </ToolTip>

                    <br />

                    <ImageDropZone @refreshImages="refreshImages" />

                </div>

                <div class="col-12 text-center mb-3 mt-3">

                    <SearchGrid >

                        <!-- Template for each item in a list view -->
                        <template v-slot:grid="image">

                            <img :src="image.thumbnailUrl" @click="selectImage(image.id)" :alt="image.name" :class="{selected: selectedImageId == image.id}" class="img-fluid userImage w-100" />

                            <div class="row mt-2">

                                <div class="col-10 text-start" v-if="renameId == image.id">

                                    <input type="text" v-model="newImageName" class="form-control" />

                                </div>

                                <div class="col-10 text-start break-word" v-else>

                                    {{image.name}}

                                </div>

                                <div class="col-2 text-end" v-if="showEditMenu">

                                    <div class="btn-group dropstart">

                                        <i class="bi bi-three-dots-vertical dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></i>

                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" :href="image.originalUrl" target="_blank">Download</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0)" @click="renameImage(image.id, image.name)">Rename</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0)" @click="deleteImage(image.id)">Delete</a></li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                            <div class="row mt-2" v-if="renameId == image.id">

                                <div class="col-6">

                                    <button type="button" @click="cancelRename" class="btn btn-outline-primary btn-sm">Cancel</button>

                                </div>

                                <div class="col-6 text-end">

                                    <button type="button" @click="saveRename" class="btn btn-primary btn-sm">Save</button>

                                </div>

                            </div>

                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            No products were found that match your criteria.
                        </template>

                    </SearchGrid>

                </div>

            </div>

            <div class="row">
                <div class="col text-end fit-content"><PageSelector /></div>
            </div>


        </SearchContainer>

    </div>

</template>

<style lang="scss">

    .user-image-library {
        .dropZone {
            border: 3px dashed $color-1;
        }

        .userImage {
            border: 1px solid $gray-2;
        }

        .selected {
            border: 3px solid $color-1;
        }

        .dropdown-menu {
            padding: 0;
            border: 1px solid $neutral-md-dk;
        }

        li a.dropdown-item {
            padding: 0 .5rem;
            color: $color-2-dk;
            font-size: 0.875rem;
            border-bottom: 1px solid $neutral-md-dk;
        }

        li:last-child a.dropdown-item {
            border-bottom: none;
        }

        h2.confirm {
            color: $gray-0;
        }

        .dp__input.form-control {
            padding: 0.375rem 2rem !important;
            font-size: 1.125rem !important;
            line-height: 1.5;
        }

        .popper {
            width: 40%;
        }

        .break-word {
            overflow-wrap: break-word;
        }
    }
</style>