<script setup>

    // AnnualAutoSelectChannelPage
    // Annual Auto how to select the channel to market too

    // Constants 
    const channels = {
        DIRECT_MAIL: 'direct mail',
        EMAIL: 'email',
        MULTI_CHANNEL_CAMPAIGN: 'multi-channel compaign'

    }

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ToolTip from '@/site/components/ToolTip';

    // Methods
    function selectChannel(selectedChannel) {
        // TODO: add the functionality to include Express Ordering
        console.log("button clicked was: ", selectedChannel);
    }

</script>

<template>
    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar/>

            <div class="root-page-container annual-auto-select-channel">

                <div class="row mt-5">

                    <div class="col-sm-8 offset-sm-2 text-center">

                        <h1>
                            How would you like to notify your 6-month auto policyholders of conversion to a 12-month policy?
                        </h1>

                    </div>

                </div>

                <div class="row">

                    <div class="col text-center">

                        <div class="pro-tip">
                            <ToolTip>
                                <p class="mb-3 tip-text">
                                    Pro tip on how these different marketing channels work together.
                                    <i class="bi bi-chevron-right"></i>
                                </p>
                                <template #content>
                                    <div class="text-start">
                                        <h4 class="fw-bold">Pro Tip</h4>
                                        <div class="d-flex gap-2">
                                            <img src="/images/icons/boost-conversions.png" alt="Boost conversions" class="img-fluid" />
                                            <p class="fs-5 mb-0">
                                                <span class="fw-bold">Boost conversions: </span>
                                                Studies have shown that using email and direct mail together can increase your conversion rate by up to 35% from using either method individually.
                                            </p>
                                        </div>
                                        <p class="text-end fs-6 mb-0">SOURCE: Marketing Insider Group</p>
                                    </div>
                                </template>
                            </ToolTip>
                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <div class="d-flex justify-content-center">

                            <div class="text-center me-5">

                                <img src="/images/select-channel-direct-mail.png" class="img-fluid" alt="direct mail" />

                                <div>
                                    <button class="btn btn-primary" @click="selectChannel(channels.DIRECT_MAIL)">Direct Mail</button>
                                </div>

                            </div>

                            <div class="text-center me-5">

                                <img src="/images/select-channel-email.png" class="img-fluid" alt="email" />

                                <div>
                                    <button class="btn btn-primary" @click="selectChannel(channels.EMAIL)">Email</button>
                                </div>

                            </div>

                            <div class="text-center">

                                <img src="/images/select-channel-mutli-channel-campaign.png" class="img-fluid" alt="multi-channel campaign" />

                                <div>
                                    <button class="btn btn-primary" @click="selectChannel(channels.MULTI_CHANNEL_CAMPAIGN)">Multi-Channel (Direct Mail & Email)</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <!-- TODO: make this go to the previous page-->
                        <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>
</template>

<style lang="scss" scoped>
    .annual-auto-select-channel {
        .pro-tip > div {
            position: relative;
        }

        .pro-tip {
            img {
                width: 95px;
                height: 95px;
            }

            .tip-text {
                color: $color-2-dk;
                cursor: pointer;
                line-height: 27px;
                i {
                    font-size: 12px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>