<script setup>
    // PersonalizeStep4Page
    // Step 4 of mail drops the review and check out or add to cart

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    // TODO bring this back in when we are using a PDF
    //import PdfViewer from '@/site/components/PdfViewer';

    // Imports
    import { reactive, computed, onMounted, ref } from 'vue';
    import { site } from '@/Site';
    import { useRouter, useRoute } from 'vue-router';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { ProgramType, getTabName } from '@/constants'
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';

    const router = useRouter();
    const route = useRoute();

    // State
    const detail = reactive({ product: {} });
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const showInputTargetCount = ref(false);
    const customQuantity = ref(0);
    const addingToCart = ref(false);

    // Methods
    async function addToCart() {

        addingToCart.value = true;
        await site.personalizationInfo.setSaveForLater(false);
        const response = await site.personalizationInfo.addToCart(orderDetails.value.productId, orderDetails.value.targetCount);

        if (response.succeeded) {
            site.toast.success(detail.product.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }
        addingToCart.value = false;
    }


    async function checkout() {
        if (!site.errorHandler.canStartOrder(orderDetails.value.productId)) {
            return false;
        }
        await addToCart();

        router.push({
            name: 'CartPage'
        });
    }

    function previewPdf() {
        site.personalizationInfo.openPdfPreview(orderDetails.value.pageFlexDocumentId);
    }

    // Computed
    const orderDetails = computed(() => {
        return site.personalizationInfo.orderFlowDetails;
    });

    const canAddToCart = computed(() => {
        return !addingToCart.value
    });
    const userInfo = computed(() => {
        return site.user;
    })

    const toPersonalizationPage = computed(() => {
        const parentId = orderDetails.value.parentId ? orderDetails.value.parentId : orderDetails.value.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderDetails.value.id}/${parentId}/${orderDetails.value.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    const showDropDate = computed(() => {

        return orderDetails.value.dropDetails
            && orderDetails.value.dropDetails.dropType != 'NONE'
            && orderDetails.value.dropDetails.mailingDetails
            && orderDetails.value.dropDetails.mailingDetails.length > 0
    });

    const pdfPreviewUrl = computed(() => {

        if (Util.isNotEmpty(orderFlowDetails.pageFlexDocumentId) && Util.isNotEmpty(userInfo.value.userName)) {
            const userName = userInfo.value.userName.replace(".Test@test.com", '');
            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;
            return `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${orderFlowDetails.pageFlexDocumentId}&UserName=${userName}`;
        }
        else
            return '';

    });


    const tabName = computed(() => {
        return getTabName(orderFlowDetails.programType);
    });

    const isLoaded = computed(() => {
        return detail.product && detail.product.itemNumber && detail.product.itemNumber.length > 0;
    });

    const mailingDetails = computed(() => {
        return site.personalizationInfo.orderFlowDetails.dropDetails.mailingDetails;
    });


    const orderTotal = computed(() => {
        let total = 0;
        for (let i = 0; i < orderDetails.value.dropDetails.mailingDetails.length; i++) {
            total += site.personalizationInfo.orderFlowDetails.dropDetails.mailingDetails[i].price;
        }
        return total;
    });


    const displayElements = computed(() => {
        let displayState = {
            showPersonalization: true,
            showFrequency: true,
            showMailings: true,
            showPriceSummary: true,
            showEditTargetCount: false,
            showRenewalText: false,
        };
        if (orderDetails.value.programType == ProgramType.WINBACK) {
            displayState.showPriceSummary = true;
            displayState.showMailings = false
        }
        return displayState;
    })

    const previous = computed(() => {
        if (orderDetails.value.programType == ProgramType.WINBACK) {
            return `/personalization/step-3-win-back/${orderDetails.value.id}`;
        }
        return `/personalization/step-3-drop-date/${orderDetails.value.id}`;
    })

    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    // functions
    const updateTargetCount = () => {

        orderFlowDetails.targetCount = customQuantity;
        showInputTargetCount.value = false;
    }

    async function saveForLater() {
        if (site.errorHandler.handleSaveForLaterRequest()) {
            router.push({
                name: 'Home',
            });
        }
    }


    // Lifecycle ----
    onMounted(async () => {
        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            await site.personalizationInfo.getOrderState(orderStateId);
        }
        if (orderFlowDetails && orderFlowDetails.productId && orderFlowDetails.productId.length > 0) {
            Object.assign(detail.product, await site.products.getProductDetail(orderFlowDetails.productId));
        }
    });



</script>
<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />
            <div class="root-page-container personalize-step-4" v-if="isLoaded">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.FOUR" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>
                                <h1>Review & Add to Cart</h1>
                            </div>

                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>

                        </div>

                    </div>

                </div>


                <div class="row mt-5">

                    <div class="col-sm-6">

                        <div class="mb-3">

                            <div class="fw-bold">Item</div>
                            <div class="text-color-2">
                                {{detail.product.name}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Item ID</div>
                            <div class="text-color-2">
                                {{detail.product.itemNumber}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Personalization <router-link :to="toPersonalizationPage" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>

                        </div>

                        <div class="mb-3" v-if="displayElements.showFrquency">

                            <div class="fw-bold">Frequency <router-link :to="`/personalization/step-3-drop-date/${orderDetails.id}`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>
                            <div class="text-color-2 text-capitalize">
                                {{orderDetails.dropDetails.frequency}}
                            </div>

                        </div>

                        <div class="mb-3" v-if="displayElements.showMailings">

                            <div class="fw-bold">Number of Mailings <router-link :to="`/personalization/step-3-drop-date/${orderDetails.id}`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>
                            <div class="text-color-2">
                                {{orderDetails.dropDetails.numberOfMailings}}
                            </div>

                        </div>

                        <div class="mb-3" v-if="showDropDate">

                            <div class="fw-bold">Drop Start Date <router-link :to="`/personalization/step-3-drop-date/${orderDetails.id}`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>
                            <div class="text-color-2">
                                {{site.formatDisplayDate(orderDetails.dropDetails.mailingDetails[0].mailDate)}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">
                                Total Order Count
                                <span @click="showInputTargetCount = !showInputTargetCount" v-if="displayElements.showEditTargetCount" class="btn btn-outline-primary btn-sm">
                                    <i class="bi bi-pencil-fill "></i> Edit
                                </span>
                            </div>

                            <div class="text-color-2">
                                {{orderDetails.targetCount}}  x (<UomLabel :item="detail.product" />)
                            </div>

                            <div v-if="showInputTargetCount">
                                <input v-model="customQuantity" type="number" />
                                <span @click="updateTargetCount" class="btn btn-outline-primary btn-sm"> Update</span>
                            </div>

                        </div>

                        <div>
                            Note: Turn off Pop-up Blocker to allow for PDF previewing.
                        </div>

                        <div class="mb-5">
                            <button class="btn btn-primary" @click="previewPdf">Preview PDF</button>
                        </div>

                        <div v-if="displayElements.showPriceSummary" class="frequency-headline py-2 fs-4 text-center mb-1">
                            Summary
                        </div>

                        <div v-if="displayElements.showPriceSummary">

                            <table class="table">

                                <thead>
                                    <tr class="bg-color-3-lt">
                                        <th class="border-end border-gray-0">Date Drop</th>
                                        <th class="border-end border-gray-0">Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="border-bottom border-gray-2" v-for="drop in mailingDetails" :key="drop.mailDate">
                                        <td>{{site.formatDisplayDate(drop.mailDate)}}</td>
                                        <td>{{drop.quantity}}</td>
                                        <td>{{site.convertNumberToCurrency(drop.price)}}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>

                        <div v-if="displayElements.showPriceSummary" class="mt-2 d-flex fw-bold">
                            <div class="price-message">Pricing prior to potential applied discount.</div>
                            <div class="m-auto">Total: {{site.convertNumberToCurrency(orderTotal)}}</div>
                        </div>

                        <div class="mt-3 mb-5">

                            <router-link :to="previous" class="btn btn-outline-primary me-3">
                                Previous
                            </router-link>

                            <button @click="saveForLater"  v-if="isAgencySelected"  class="btn btn-primary me-3">Save For Later</button>

                            <button @click="checkout" class="btn btn-primary" :disabled="!canAddToCart">
                                Add to Cart
                            </button>


                        </div>

                    </div>

                    <div class="col-sm-5 offset-1">
                        <!-- TODO get the actual PDF -->
                        <iframe if="pdfPreviewUrl.length > 0" :src="pdfPreviewUrl" scrolling="yes" width="100%" height="800px" />
                        <!--<PdfViewer :pdfDetails="pdfDetails" :height="700" />-->
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .personalize-step-4 {

        .btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead {
            background: $color-2;
            color: $gray-4;
            border-color: $gray-0;
        }

        .price-message {
            color: $error;
            font-size: .75rem;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 33%;
            }
        }

        .text-color-2 {
            color: $color-2;
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }
    }
</style>
