<script>

    const constants = {
        TabTypes: {
            CROSS_SELL: 'CrossSell',
            PROSPECTING: 'Prospecting',
            RETENTION: 'Retention'
        },
        punchOutActions: {
            CREATE: 'create',
            EDIT: 'edit',
            COMMIT: 'commit',
            REORDER: 'reorder'
        }
    };

    // Exports
    export const TabTypes = constants.TabTypes;
    export const punchOutActions = constants.punchOutActions;

</script>
<script setup>

    // Imports
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute, useRouter } from 'vue-router';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import { stepCounter } from '@/site/components/SelectMailListStepper';
    import { Util } from "@dd-nucleus/nucleus-vue";
    import TwoStepStepper from '@/site/components/TwoStepStepper';
    import { ProductRestrictions } from '@/constants';
    import PreviousButton from '@/site/components/PreviousButton';
    import { isNotEmpty } from '@/util';

    const displayStepper = computed(() => {
        let displayState = {
            showTwoStepper: true,
        };
        return displayState;
    });

    const _iOSDevice = computed(() => {
        return /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    });

    // State
    const route = useRoute();

    const detail = reactive({ product: {} });

    const iframeSrc = ref('');

    const frameHeight = ref(1000);

    const showIframe = ref(false);

    const agencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    const router = useRouter();

    const showButton = ref(false);

    // Methods ----
    async function getTicket() {

        let payload = {}
        const BASE_API_URL = process.env.VUE_APP_PAGE_FLEX_RETURN_URL;

        const userInfo = site.user;
        const userName = userInfo.userName.replace(".Test@test.com", '');

        let returnUrl = `${BASE_API_URL}personalization/customize-download-return?userName=${userName}%26productId=${detail.product.id}`;

        if (Util.isNotEmpty(agencyDetails.statId)) {
            returnUrl = `${returnUrl}%26statCode=${agencyDetails.statId}`;
        }

        if (detail.product?.attributes?.isWelcomeKit) {
            returnUrl = returnUrl + "%26isWelcomeKit=true"
            if (Util.isNotEmpty(route.query.parentId)) {
                returnUrl = returnUrl + "%26parentId=" + route.query.parentId;
            }
            Object.assign(payload, { NextStepText: 'Save/Finish' });
        }

        Object.assign(payload, { ReturnUrl: returnUrl });

        const agencyName = isNotEmpty(agencyDetails.agencyName) ? encodeURIComponent(agencyDetails.agencyName): agencyDetails.agencyName;
        const firstName = isNotEmpty(userInfo.firstName) ? encodeURIComponent(userInfo.firstName): userInfo.firstName;
        const lastName = isNotEmpty(userInfo.lastName) ? encodeURIComponent(userInfo.lastName): userInfo.lastName;
        const address1 = isNotEmpty(agencyDetails.address1) ? encodeURIComponent(agencyDetails.address1): agencyDetails.address1;
        const address2 = isNotEmpty(agencyDetails.address2) ? encodeURIComponent(agencyDetails.address2): agencyDetails.address2;
        const city = isNotEmpty(agencyDetails.city) ? encodeURIComponent(agencyDetails.city): agencyDetails.city;


        Object.assign(payload, { UserName: userName });
        Object.assign(payload, { AgencyName: agencyName });
        Object.assign(payload, { AgentName: firstName });
        Object.assign(payload, { AgentLastName: lastName });
        Object.assign(payload, { AgentAddress: address1 });
        Object.assign(payload, { AgentAddress2: address2 });
        Object.assign(payload, { AgentCity: city });
        Object.assign(payload, { AgentZip: agencyDetails.zip });
        Object.assign(payload, { AgentState: agencyDetails.state });
        Object.assign(payload, { AgentPhone: agencyDetails.phone });
        Object.assign(payload, { AgentEmail: agencyDetails.emailAddress });
        Object.assign(payload, { PHONE: agencyDetails.phone });
        Object.assign(payload, { DocumentCode: detail.product.itemNumber });

        if (detail.product && detail.product['attributes'] && detail.product.attributes['restriction'] == ProductRestrictions.NOTFORMA) {
            Object.assign(payload, { NotForMA: true });
        }

        if (detail.product && detail.product['attributes'] && detail.product.attributes['restriction'] == ProductRestrictions.NOTFORCO) {
            Object.assign(payload, { NotForCO: true });
        }

        // If it is reorder or create, expecting both parameters. edit also will have documentId
        if (Util.isNotEmpty(route.query.documentId) && route.query.reorder == 'true') {
            console.warn('route.query.documentId', route.query.documentId);
            Object.assign(payload, { Action: punchOutActions.REORDER });
            Object.assign(payload, { DocumentId: route.query.documentId });
        } else if (Util.isNotEmpty(route.query.documentId) && route.query.edit == 'true') {
            Object.assign(payload, { Action: punchOutActions.EDIT });
            Object.assign(payload, { DocumentId: route.query.documentId });
        } else {
            Object.assign(payload, { DocumentId: detail.product.itemNumber });
            Object.assign(payload, { Action: punchOutActions.CREATE });
        }
        console.warn('payload', payload);

        const response = await site.personalizationInfo.getPunchoutTicket(payload);

        const ticketId = response.ticketId.replace("OK:", "");

        const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;

        iframeSrc.value = `${BASE_PUNCHOUT_URL}/UserPMCreateEdit.aspx?ticket=${ticketId}`;

        showIframe.value = true;
    }

    function onLoadIframe() {
        frameHeight.value = window.frames.outerHeight + 100;
        showButton.value = true;
    }

    // Handlers ----
    onMounted(async () => {
        const productId = route.query.productId
        if (Util.isNotEmpty(productId)) {
            Object.assign(detail.product, await site.products.getProductDetail(productId));
            if (detail.product && detail.product.itemNumber) {
               await getTicket();
            }
        }
    })

    function back() {
        router.back();
    }
</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container print-on-demand-customize-step1">

                <div class="row">

                    <div class="col">
                        <TwoStepStepper v-if="displayStepper.showTwoStepper" :currentStep="stepCounter.ONE" stepTitle="Download" />
                    </div>

                </div>

                <div class="row my-5">
                    <div class="col-12 text-danger fw-bolder my-3" v-if="_iOSDevice">
                        If you are using an iOS device (iPhone/iPad) please go to Settings > Safari > uncheck the box for Prevent Cross-Site Tracking in order to proceed with Personalization.
                    </div>

                    <div class="col border-bottom border-color-1">

                        <div class="d-flex">
                            <div class="text-color-1">
                                <h2 class="mb-0">Personalize This Item</h2>
                            </div>
                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>
                        </div>

                    </div>
                    <PreviousButton v-if="showButton" @back="back()" />

                </div>

                <div class="row" v-if="showIframe">

                    <div class="col">

                        <iframe name="punchout" @load="onLoadIframe" :src="iframeSrc" scrolling="auto" width="100%" frameborder="0" :height="frameHeight"></iframe>

                    </div>
                </div>
                <PreviousButton v-if="showButton" @back="back()" />

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .print-on-demand-customize-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }

        .multi-channel-headline {
            background: $color-3-dk;
        }

        .multi-channel-detail {
            background: $gray-1;
        }
    }
</style>
