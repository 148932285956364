
// AspNetAuthenticator
// Provides functionality for local authentication via DD.Nucleus.Base.Security.AspNetCoreAuthentication.

// Constants ----
const JWT_TOKEN_KEY = 'access_token';
const I_TOKEN = 'i-token';

// Imports ----
import { Authenticator } from '@dd-nucleus/nucleus-vue';

export default class SSOAuthenticator extends Authenticator {

    // Constructor
    constructor(site) {
        super(site);
    }

    // #region Authenticator Overrides

    /**
     * Called to determine whether the user is currently logged in.
     * 
     */
    async isSignedIn() {
        const token = this.#getAccessToken();
        if (token) {
            const parsed = this.#parseToken(token);
            if (parsed.exp < Date.now() / 1000) {
                this.#trace('JWT expired, removing token from localStorage');
                this.#removeToken();

                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    /**
     * Called to allow the authenticator to redirect to sign in if needed. Local authenticators should return a route, where
     * authenticators that use SSO should perform any redirect needed, preferably retaining the supplied route as an eventual
     * return destination.
     */
    async getSignInRoute(to) {
        if (to) {
            return {
                name: 'SignIn',
                params: { path: to?.fullPath }
            }
        }
        return { name: 'SignIn' };
    }

    /**
     * Signs the user out by removing the stored token.
     */
    async signOut() {
        await this.site.api.post(`/site/asp-net-auth/logout`);
        this.#removeToken();
    }

    /**
     * Called to allow the authenticator to redirect to sign out.
     */
    async getSignOutRoute() {
        return { name: 'SignIn', query: { signout: 'true' } };
    }

    /**
     * Called for authenticated API calls to apply the appropriate header(s).
     */
    async getApiHeaders() {
        // If User is authenticated, add header with access token
        if (this.site.user.isAuthenticated) {
            const headers = {};

            const impersonatedUserToken = sessionStorage.getItem(I_TOKEN);
            if (impersonatedUserToken) {
                headers[I_TOKEN] = impersonatedUserToken;
            }

            // Get access token from localStorage
            const token = await this.#getAccessToken();

            headers['Authorization'] = `Bearer ${token}`;

            return headers;
        }
        else
           return null;
    }

    // #endregion

    /**
     * Called from a sign-in form to actually sign the user in.
     * @param {string} username
     * @param {string} password
     */
    async signIn(userId) {
        try {
            const options = { authenticate: false };
            this.site.user.isAuthenticated = false;

            const response = await this.site.api.get(`/site/asp-net-auth/get-token/${userId}`, options);


            if (response && response.access_token) {
                this.#removeToken();
                this.#storeToken(response.access_token);
                return true;
            } else {
                return false;
            }
        }
        catch (e) {
            console.log("error in ssoSignIn: ", e);
            return false;
        }

    }

    /**
    * delete impersonate session storage.
    */
    deleteImpersonateSession() {
        sessionStorage.removeItem(I_TOKEN);
    }

    // Removes the JWT stored in localStorage.
    removeLoginToken() {
        localStorage.removeItem(JWT_TOKEN_KEY);
    }
 
    /**
     * Updates user name and email
     * TODO: Keep this?
     */
    async updateInfo(username, email) {
        var response = await this.site.api.post('api/user/current', { username: username, email: email });

        return response.succeeded;
    }


    // #region Private Functions

    // Gets an API JWT access token for the active account.
    #getAccessToken() {
        const token = localStorage.getItem(JWT_TOKEN_KEY) || false;

        if (token) {
            this.#trace(`Access token retrieved from localStorage - ${token.substring(0, 8)}...`);
        }

        return token;
    }

    // Decode the provided token.
    #parseToken(token) {
        if (token.indexOf('.')) {
            const payload = token.split('.')[1];

            // TODO: atob() is deprecated
            return JSON.parse(atob(payload));
        }

        return false;
    }

    // Removes the JWT stored in localStorage.
    #removeToken() {
        localStorage.removeItem(JWT_TOKEN_KEY);
    }

    // Adds the provided token to localStorage.
    #storeToken(token) {
        localStorage.setItem(JWT_TOKEN_KEY, token);
    }

    // Returns whether tracing is enabled
    #doTrace() {
        return (process.env.VUE_APP_TRACE_ASP_NET_AUTH === 'true');
    }

    // Traces a message, if enabled.
    #trace(message, ...args) {
        if (this.#doTrace())
            console.log('[ASP.NET AUTH] ' + message, ...args);
    }

    // #endregion
}