<script setup>
    // PersonalizeStep2ReviewPage
    // Step 2 of pick pack personalize orders and the review and check out or add to cart
    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import TwoStepStepper, { stepCounter } from '@/site/components/TwoStepStepper';

    // Imports
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute, useRouter } from 'vue-router';
    import { Util } from "@dd-nucleus/nucleus-vue";
    import { TabTypes } from './PersonalizeStep1Page';
    import { ChannelTypes, ProgramType } from '@/constants'

    const router = useRouter();
    const route = useRoute();

    // State
    const detail = reactive({ product: {} });

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const showInputTargetCount = ref(true);
    const customQuantity = ref(0);

    const productLimitMessage = ref('');

    const addingToCart = ref(false);

    // Methods
    async function addToCart() {
        
        addingToCart.value = true;
        const response = await site.personalizationInfo.addToCart(orderDetails.value.productId, orderDetails.value.targetCount);

        if (response.succeeded) {
            site.toast.success(detail.product.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }
        addingToCart.value = false;
    }


    async function checkout() {
        if (!site.errorHandler.canStartOrder(orderDetails.value.productId)) {
            return false;
        }
        await addToCart();

        router.push({
            name: 'CartPage'
        });
    }

    function previewPdf() {
        site.personalizationInfo.openPdfPreview(orderDetails.value.pageFlexDocumentId);
    }

    const validateQuantity = async () => {
        const limits = await site.products.getProductLimits({ id: orderFlowDetails.productId });
        for (let i = 0; i < limits.length; i++) {
            if (customQuantity.value > limits[i].available) {
                productLimitMessage.value = limits[i].limitText;
            }
        }
    }

    const updateTargetCount = async () => {
        productLimitMessage.value = "";
        await validateQuantity();

        if (Util.isEmpty(productLimitMessage.value)) {
            showInputTargetCount.value = false;
            orderFlowDetails.targetCount = customQuantity.value;
            await site.personalizationInfo.updateTargetCount();

        }

    }

    function getTotalPrice(qty) {
        return site.convertNumberToCurrency(qty * orderDetails.value.unitPrice)
    }

    // Computed
    const orderDetails = computed(() => {
        return site.personalizationInfo.orderFlowDetails;
    });

    const userInfo = computed(() => {
        return site.user;
    });

    const pdfPreviewUrl = computed(() => {

        if (Util.isNotEmpty(orderFlowDetails.pageFlexDocumentId) && Util.isNotEmpty(userInfo.value.userName)) {
            const userName = userInfo.value.userName.replace(".Test@test.com", '');
            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;
            return `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${orderFlowDetails.pageFlexDocumentId}&UserName=${userName}`;
        }
        else
            return '';

    });

    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    const tabName = computed(() => {
        switch (orderFlowDetails.programType) {
            case TabTypes.CROSS_SELL:
            case TabTypes.PET_INSURANCE:
                return TabIDs.CROSS_SELL_TAB_ID;
            case TabTypes.PROSPECTING:
            case TabTypes.DATA_LIST_UPLOAD:
                return TabIDs.PROSPECTING_TAB_ID;
            case TabTypes.RETENTION:
            case TabTypes.WINBACK:
            case TabTypes.ANNUAL_AUTO:
                return TabIDs.RETENTION_TAB_ID;
            default:
                return '';
        }
    });

    const isLoaded = computed(() => {
        return Util.isNotEmpty(detail.product);
    });

  
    const displayElements = computed(() => {
        let displayState = {
            showSubscription: true,
            showPriceSummary: true,
            showRenewalText: false,
        };
        switch (orderDetails.value.programType) {
            case ProgramType.ANNUAL_AUTO:
                displayState.showSubscription = false;
                displayState.showPriceSummary = false;
                displayState.showRenewalText = true;
                break;
            default:
                break;
        }
        return displayState;
    });

    const canAddToCart = computed(() => {
        return orderFlowDetails.targetCount > 0 && !addingToCart.value
    });


    // Lifecycle ----
    onMounted(async () => {

        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            await site.personalizationInfo.getOrderState(orderStateId);
            
        }

        if (orderFlowDetails && orderFlowDetails.productId && orderFlowDetails.productId.length > 0) {
            Object.assign(detail.product, await site.products.getProductDetail(orderFlowDetails.productId));
        }

        customQuantity.value = orderFlowDetails.targetCount > 0 ? orderFlowDetails.targetCount : 0;
        
    });

</script>
<template>
    <PageTemplate>
        <div class="container root-page personalize-step-3-review">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />
            <div class="root-page-container" v-if="isLoaded">

                <div class="row">

                    <div class="col">
                        <TwoStepStepper :currentStep="stepCounter.TWO" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>
                                <h1>Review & Add to Cart</h1>
                            </div>

                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>

                        </div>

                    </div>

                </div>

                <p v-if="displayElements.showRenewalText" class="text-center fw-bold text-danger py-2">Your customer will be mailed a letter 60-90 days prior to their respective renewal date.</p>


                <div class="row mt-5">

                    <div class="col-sm-6">

                        <div class="mb-3">

                            <div class="fw-bold">Item</div>
                            <div class="text-color-2">
                                {{detail.product.name}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Item ID</div>
                            <div class="text-color-2">
                                {{detail.product.itemNumber}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Personalization <router-link :to="toPersonalizationPage" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Total Order Count <button @click="showInputTargetCount = !showInputTargetCount" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2">
                                {{orderDetails.targetCount}} x (<UomLabel :item="detail.product" />)
                            </div>
                            <div class="text-danger" v-if="Util.isNotEmpty(productLimitMessage)">
                                <i class="bi icon bi-exclamation-circle-fill"></i> Limit of {{productLimitMessage}} exceeded
                            </div>
                            <div v-if="showInputTargetCount" class="mt-3">
                                <input v-model="customQuantity" type="number" />
                                <span @click="updateTargetCount" class="btn btn-outline-primary btn-sm mx-2"> Update</span>
                            </div>

                        </div>


                        <div>
                            Note: Turn off Pop-up Blocker to allow for PDF previewing.
                        </div>

                        <div class="mb-5">
                            <button class="btn btn-primary" @click="previewPdf">Preview PDF</button>
                        </div>

                        <div v-if="displayElements.showPriceSummary">
                            <div class="frequency-headline py-2 fs-4 text-center mb-1 fw-bold">
                                Summary
                            </div>
                            <div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Unit Price</th>
                                            <th>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-bottom border-gray-2">
                                            <td>{{site.convertNumberToCurrency(orderDetails.unitPrice)}}</td>
                                            <td>{{getTotalPrice(orderDetails.targetCount)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="mt-3 mb-5">

                            <router-link :to="toPersonalizationPage" class="btn btn-outline-primary me-3">
                                Previous
                            </router-link>

                            <button @click="checkout" :disabled="!canAddToCart" class="btn btn-primary">
                                Add to Cart
                            </button>

                        </div>

                    </div>

                    <div class="col-sm-5 offset-1">
                        <!-- TODO get the actual PDF -->
                        <iframe if="pdfPreviewUrl.length > 0" :src="pdfPreviewUrl" scrolling="yes" width="100%" height="800px" />
                        <!--<PdfViewer :pdfDetails="pdfDetails" :height="700" />-->
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .personalize-step-3-review {

        .btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        .summary {
            background: $color-2-dk;
            color: $gray-0;
        }

        thead tr {
            background: $color-2;
            color: $gray-0;
        }

        thead tr th {
            font-weight: 400;
            border-right: 1px solid #fff;
        }

        thead tr th:last-child {
            border-right: none;
        }

        tbody tr {
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }

        .text-color-2 {
            color: $color-2;
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }
    }
</style>