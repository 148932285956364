<script setup>

    // PersonalizeStep2Page
    // Step 2 Personalization

    // Imports
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute, useRouter } from 'vue-router';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import ThreeStepStepper from '@/site/components/ThreeStepStepper';
    import SelectPolicyholders from './components/SelectPolicyholders';
    import SelectSubscriptionPolicyholders from './components/SelectSubscriptionPolicyholders';
    import ZipCodePanel from './components/ZipCodePanel';
    import UploadListPanel from './components/UploadListPanel';
    import ProspectingMailListType from './components/ProspectingMailListType';
    import AnnualAutoSelectPolicyholders from './components/AnnualAutoSelectPolicyholders';
    import WinBackSelectPolicyholders from './components/WinBackSelectPolicyholders';
    import AgencyReferralSelectPolicyholders from './components/AgencyReferralSelectPolicyholders';
    import PetInsuranceSelectPolicyholders from './components/PetInsuranceSelectPolicyholders';
    import { Util } from "@dd-nucleus/nucleus-vue";
    import { ProgramType, getTabName } from '../../../constants';

    const route = useRoute();
    const router = useRouter();

    const mailListType = ref(null);

    const isLoaded = ref(false);

    const orderFlowDetails = reactive({});
    const product = reactive({});

    // Computed
    const programType = computed(() => {
        // TODO: need to know the biz rules between zip code look up and upload list
        return orderFlowDetails.programType;
    });

    // Computed
    const tabName = computed(() => {
        return getTabName(orderFlowDetails.programType);
    });

    // Methods
    function mailListTypeSelected(type) {
        mailListType.value = type;
    }

    async function saveForLater() {
        if (site.errorHandler.handleSaveForLaterRequest()) {
            router.push({
                name: 'Home',
            });
        }
    }

    // Handlers ----
    onMounted(async () => {
        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            const response = await site.personalizationInfo.getOrderState(orderStateId);
            if (response.succeeded) {
                Object.assign(orderFlowDetails, response.model);
                if (Util.isNotEmpty(response.model.productId)) {
                    Object.assign(product, await site.products.getProductDetail(response.model.productId));
                }
            }
        }
        if (orderFlowDetails.programType === ProgramType.DATA_LIST_UPLOAD) {
            mailListType.value = 'upload';
        }
        if (orderFlowDetails.programType === ProgramType.PROSPECTING) {
            mailListType.value = 'purchase';
        }
        isLoaded.value = true;
    })

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />

            <div class="row mt-3">

                <div class="col">

                    <ThreeStepStepper v-if="[ProgramType.RETENTION, ProgramType.ANNUAL_AUTO, ProgramType.PET_INSURANCE].includes(orderFlowDetails.programType)" :currentStep="stepCounter.TWO" />
                    <SelectMailListStepper v-else :currentStep="stepCounter.TWO" />

                </div>

            </div>

            <div class="root-page-container personalize-step2" v-if="isLoaded">
                <SelectPolicyholders v-if="programType == ProgramType.CROSS_SELL" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <ProspectingMailListType v-if="programType == ProgramType.PROSPECTING && mailListType == null" @mailListType="mailListTypeSelected" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <ZipCodePanel v-if="programType == ProgramType.PROSPECTING && mailListType == 'purchase'" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <UploadListPanel v-if="programType == ProgramType.DATA_LIST_UPLOAD && mailListType == 'upload'"  :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <SelectSubscriptionPolicyholders v-if="programType == ProgramType.RETENTION" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <AnnualAutoSelectPolicyholders v-if="programType == ProgramType.ANNUAL_AUTO" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater" />
                <WinBackSelectPolicyholders v-if="programType == ProgramType.WINBACK" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater"/>
                <AgencyReferralSelectPolicyholders v-if="programType == ProgramType.AGENCY_REFERRAL" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater"/>
                <PetInsuranceSelectPolicyholders v-if="programType == ProgramType.PET_INSURANCE" :order-state="orderFlowDetails" :product="product" @save-for-later="saveForLater"/>
            </div>

        </div>
    </PageTemplate>

</template>

<style lang="scss">
    .personalize-step2 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }
    }
</style>
