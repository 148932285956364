// UserRoles
// Services and actions related to User Roles.

// Imports ----

export default class UserRoles {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    // Test was added for Mock Authentication default role 
    getRolesForAllAccess() {
        return [
            "TM", "RM", "Employee", "Agent", "TEST", "MarketingAdmin", "Marketing", "Qualfon.CsdAdmin", "Client.Admin", "SA", "Qualfon.Csd"
        ]
    }

    stateRestrictionRoles() {
        return [
            "TM", "RM", "Agent"
        ];
    }

    getRolesForAdminAccess() {
        return [
            'Qualfon.Csd', 'Qualfon.CsdAdmin', 'Client.Admin', 'SA','MarketingAdmin'
        ]
    }

    showAgencyModalRoles() {
        return ['Agent'];
    }

    getRolesForAdminReport() {
        return [
            'Employee', 'Marketing', 'MarketingAdmin','Qualfon.Csd','Qualfon.CsdAdmin'
        ]
    }

    getRolesToUpdateAgencyAddress() {
        return [
            'Qualfon.Csd', 'Qualfon.CsdAdmin', 'Client.Admin', 'SA', 'MarketingAdmin'
        ]
    }

}
