<script setup>

    // AgencyReferral Select Policy Holders
    // Step 2 SelectPolicyholders

    // Constants ----

    const columns = [
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'policyType', title: 'Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'customerSinceDate', title: 'Customer Since', custom: true, isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
    ];

    // Imports
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { reactive, computed, ref, defineProps, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/constants'


    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';
    import { isNotEmpty, isEmpty } from '@/util';

    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    // State
    const search = new Search('bridge-cross-sell-detail', 'row', 'policyNumber');
    search.setFilterInitialValue('cross-sell-type-code-sub-code', orderFlowDetails.oppId, orderFlowDetails.statCode);

    const router = useRouter();
    const searchTerm = ref('statCode');

    let selectedList = reactive([]);
    const selectedListByPage = reactive([]);

    const detail = reactive({ product: props.product });

    const emit = defineEmits(['saveForLater']);

    const disableButton = ref(false);

    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    // Methods
    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    async function next() {
        await updateRecipients();
        router.push({
            name: 'PersonalizeStep3Page',
            params: {
                id: orderFlowDetails.id
            }
        });
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        await updateRecipients();
        emit('saveForLater');
    }

    async function updateRecipients() {

        disableButton.value = true;
        //TODO needs to be copied to other places.
        ///Getting all the records before store the selected recipients.
        const newSearch = new Search('bridge-cross-sell-detail', 'row', 'firstName');
        newSearch.setFilterInitialValue('cross-sell-type-code-sub-code', orderFlowDetails.oppId, orderFlowDetails.statCode);
        await newSearch.activate();

        const allRecords = newSearch.results.rows;
        const selected = allRecords.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {
            return {
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2 ? sl.address2.trim() : "",
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "policyType": sl.policyType,
                "renewalDate": sl.renewalDate,
                "customerSinceDate": sl.customerSinceDate
            };
        });
        await site.personalizationInfo.addMailContacts(selected);
    }

    function sortSearch(value) {
        if (searchTerm.value.includes(value)) {
            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }
        } else {
            searchTerm.value = value;
        }
        search.setSortId(searchTerm.value);
    }

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        if (search?.results?.rows?.length == listLength.value) {
            return true;
        }
        const currentRecordsByPage = search?.results?.rows?.map(r => r.policyNumber);
        const currentPageSelectedRecords = selectedList.filter(sl => currentRecordsByPage.includes(sl));
        return currentPageSelectedRecords.length == search?.results?.rows?.length;
    });


    const recordsByPage = computed(() => {
        const currentPageNumber = search.results.pageNumber;
        return selectedListByPage.find(slp => slp.pageNumber == currentPageNumber)?.selectedRecords || [];
    });


    //methods
    function selectAll(isSelectAll, list) {
        const currentSelectedAllRecords = list.map(l => l.policyNumber);
        let newSelectedList = [];
        if (isSelectAll) {
            setSelectedRecordsByPage(currentSelectedAllRecords);
            newSelectedList = selectedList.concat(currentSelectedAllRecords);
        } else {
            setSelectedRecordsByPage([]);
            newSelectedList = selectedList.filter(sl => !currentSelectedAllRecords.includes(sl));
        }
        const selectedSet = new Set(newSelectedList);
        selectedList.length = 0;
        Object.assign(selectedList, Array.from(selectedSet));

    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
        const currentRecordsByPage = recordsByPage.value.filter(cr => selectedList.includes(cr));
        setSelectedRecordsByPage(currentRecordsByPage);
    }

    function setSelectedRecordsByPage(currentSelectedRecords) {
        const pageNumber = search.results.pageNumber;
        const pageExists = selectedListByPage.find(slp => slp.pageNumber == pageNumber);
        console.warn('pageExists', pageExists, pageNumber);
        if (isNotEmpty(pageExists)) {
            selectedListByPage[pageNumber].selectedRecords.length = 0;
            selectedListByPage[pageNumber].selectedRecords = currentSelectedRecords;
        } else {
            selectedListByPage.push({ pageNumber: pageNumber, selectedRecords: currentSelectedRecords });
        }
    }

    search.onResults(() => {
        let currentSelectedRecords = [];
        if (props.orderState?.mailContacts?.length == 0) {
            if (isEmpty(recordsByPage.value)) {
                currentSelectedRecords = search.results?.rows?.map(mc => mc.policyNumber);
            } else {
                currentSelectedRecords = recordsByPage.value;
            }
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const allSelectedRecords = props.orderState?.mailContacts.map(mc => mc.policyNumber);
            if (selectedList.length == 0) {
                Object.assign(selectedList, allSelectedRecords);
            }
            if (isEmpty(recordsByPage.value)) {
                //Get all selected records from the db.
                //get current page all records
                const currentPageAllRecords = search.results?.rows?.map(mc => mc.policyNumber);
                //find db records without current page records
                const recordsWithoutCurrentPageRecords = allSelectedRecords.filter(cpr => !currentPageAllRecords.includes(cpr))
                //find current Page DB records
                const currentPagesSelectedDbRecords = allSelectedRecords.filter(cpr => !recordsWithoutCurrentPageRecords.includes(cpr))
                if (currentPagesSelectedDbRecords?.length > 0) {
                    //Additional step to make sure only db records are filters from the current page all records.
                    const currentPageRecords = currentPageAllRecords.filter(cpr => currentPagesSelectedDbRecords.includes(cpr));
                    currentSelectedRecords = currentPageRecords;
                    //Assume that all the records need to be selected.
                    //currentSelectedRecords = currentPageAllRecords;
                }
            } else {
                currentSelectedRecords = recordsByPage.value;
            }
        }

        setSelectedRecordsByPage(currentSelectedRecords);
        const selectedRecords = selectedList.concat(currentSelectedRecords);
        const selectedSet = new Set(selectedRecords);
        Object.assign(selectedList, Array.from(selectedSet));
    });

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col">
            <SearchContainer :search="search" default-view="list" :deep-link="false">

                <div class="row mb-2">
                    <div class="d-flex">
                        <div class="me-auto">
                            <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                        </div>
                    </div>
                </div>
                <div class="data-table">
                    <SearchTableV1 :columns="columns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">

                        <template v-slot:renewalDate="row">
                            {{formatDate(row.renewalDate)}}
                        </template>

                        <template v-slot:customerSinceDate="row">
                            {{formatDate(row.customerSinceDate)}}
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            None of your policyholders currently meet the criteria for this type of campaign.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTableV1>
                </div>
            </SearchContainer>
        </div>
    </div>

    <div class="row mt-3">

        <div class="d-flex mb-3">
            <div class="me-auto">
                <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>
            </div>
            <div class="d-flex">
                <div class="mx-5"><button v-if="listLength > 0" @click="saveForLater" class="btn btn-primary" :disabled="disableButton">Save For Later</button></div>
                <button v-if="listLength > 0" @click="next" class="btn btn-primary" :disabled="disableButton">Next</button>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
</style>
