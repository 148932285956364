<script setup>

    // OrderDetails
    // Shows order details in the my account area.

    // Import ----
    import { reactive, ref, onMounted, computed } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router'
    import { site } from '@/Site';
    import { formatStatCode } from '@/util';

    // Components ----
    import OrderItemTile from './OrderItemTile.vue';
    import SelectedPaymentMethod from '@/site/areas/cart/components/SelectedPaymentMethod.vue';

    // State
    const route = useRoute();
    const router = useRouter();
    let order = reactive({});

    const isLoaded = ref(false);

    onMounted(async () => {

        let response;

        if (Util.isNotEmpty(route.query.orderNumber)) {
            response = await site.getLegacyOrderDetails(route.query.orderNumber);
        } else {
            response = await site.orderDetails.getOrderDetails(route.params.id);
        }

        Object.assign(order, response.model);
        const allStatCodes = [];


        //get all the statcodes if item has express order
        order.items?.forEach(item => {
            Object.assign(allStatCodes, allStatCodes.concat(item.attributes?.OrderStates?.map(os => os.statCode)));
        });

        //Get the agency name for all the statcodes.
        if (Util.isNotEmpty(allStatCodes)) {
            Object.assign(allStatCodes, new Set(allStatCodes));
            const subCodeAgencyDetails = await site.expressOrderPersonalizationInfo.getAgencyDetails(allStatCodes);
            if (Util.isNotEmpty(subCodeAgencyDetails)) {
                order.items?.forEach(item => {
                    item.attributes?.OrderStates?.forEach(os => {
                        os.agencyName = subCodeAgencyDetails.find(sd => sd.statId == os.statCode)?.agencyName;
                    });
                });
            }
        }


        isLoaded.value = true;
        window.scrollTo(0, 0);
    })

    //computed
    const totalPrice = computed(() => { return site.convertNumberToCurrency(order.total); });
    const subTotalPrice = computed(() => {
        return site.convertNumberToCurrency(order.subTotal + order.additionalChargeTotal);
    });

    const discountTotal = computed(() => {
        if (order.discountTotal) {
            return site.convertNumberToCurrency(order.discountTotal);
        } else {
            return '';
        }
    });
    const tax = computed(() => { return site.convertNumberToCurrency(order.tax) });
    const shippingPrice = computed(() => {
        if (order.shipping < 1)
            return "Free";
        return site.convertNumberToCurrency(order.shipping);
    });
    
    const shippingMethodCost = computed(() => { return site.convertNumberToCurrency(order.shipMethod?.cost) });

    const totalAmount = computed(() => {
        if (order.amountPaid) {
            return site.convertNumberToCurrency(order.amountPaid);
        }
        else {
            return site.convertNumberToCurrency(order.total);
        }
    });

    const agencyName = computed(() => {
        if (order?.attributes?.Agency?.statCode && order?.attributes?.Agency?.name) {
            return order?.attributes?.Agency?.name
        } else if (order?.shipTo?.companyName) {
            return order?.shipTo?.companyName;
        }
        return "";
    });

    const statCode = computed(() => {
        if (order?.attributes?.Agency?.statCode && order?.attributes?.Agency?.name) {
            return order?.attributes?.Agency?.statCode
        } else if (order?.shipTo?.companyName) {
            return order.items[0].attributes?.Data?.statCode;
        }
        return "";
    });


    const icon = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'bi bi-check-circle-fill';
            case 2: // PendingApproval
                return 'bi bi-pause-circle-fill';
            case 99: // Canceled
                return 'bi bi-x-circle-fill';
            case 100: // Completed
                return 'bi bi-check-circle-fill';
        }
    });

    const cssClass = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'pending';
            case 2: // PendingApproval
                return 'pending-approval';
            case 99: // Canceled
                return 'canceled';
            case 100: // Completed
                return 'completed';
        }
    });

    const showShipping = computed(() => {

        return order && order.items && order.items.filter(i => i.product?.attributes?.isRequireShipping)?.length > 0;
    });

    function back() {
        router.go(-1);
    }

    

</script>

<template>
    <div class="account-panel order-details-panel" v-if="isLoaded">
        <div class="row mt-2">
            <div class="col">
                <a href="#" @click="back" class="no-underline"><i class="bi bi-chevron-left"></i>Back</a>
            </div>
        </div>
        <div class="row">
            <div class="me-auto">
                <h1 class="cart-page-header mb-3">Order Details</h1>
            </div>
        </div>
        <div class="order-details-header px-4 py-3">
            <div class="row">
                <div class="col-5">
                    <div>
                        <strong>{{Util.date(order.orderDate)}}</strong>
                        <span class="mr-2">&nbsp;|&nbsp;</span>
                        <span class="order-number">Order #: {{order.orderNumber}}</span>
                    </div>
                    <div class="status" :class="cssClass">
                        <i :class="icon"></i>
                        &nbsp;{{order.orderStatus}}
                    </div>
                </div>
                <div class="col agency-detail">
                    <div><strong>Agency Name:</strong> {{agencyName}}</div>
                    <div><strong>Agency #:</strong> {{formatStatCode(statCode)}}</div>
                    <div><strong>Ordered by:</strong> {{order.shipTo.addressee}}</div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <OrderItemTile v-for="(orderItem, index) in order.items" :totalItems="order.items.length" :index="index" :key="orderItem.orderId" :orderItem="orderItem" :orderNumber="order.orderNumber" :totalAmount="totalAmount" />
        </div>

        <div class="row mt-5">
            <div class="col-6">
                <div class="order-details rounded">

                    <h2 class="px-3 mt-3">Your Order</h2>

                    <hr />

                    <div class="row px-3">
                        <div class="col-6">
                            Subtotal
                        </div>
                        <div class="col text-end">
                            {{subTotalPrice}}
                        </div>
                    </div>

                    <div class="row mt-3 px-3" v-if="order.tax > 0">
                        <div class="col-6">
                            Tax
                        </div>
                        <div class="col text-end">
                            {{tax}}
                        </div>
                    </div>

                    <div class="row mt-3 px-3">
                        <div class="col-6">
                            Shipping
                        </div>
                        <div class="col text-end">
                            {{shippingPrice}}
                        </div>
                    </div>

                    <div class="row mt-3 px-3 text-danger" v-if="order.discountTotal > 0">
                        <div class="col-6">
                            Discount <!--span v-if="discountCode">({{discountCode}})</span-->
                        </div>
                        <div class="col-6 text-end">
                            -{{discountTotal}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <hr />
                        </div>
                    </div>

                    <div class="row px-3 mb-3">
                        <div class="col-6">
                            <strong class="fs-3">Order Total</strong>
                        </div>
                        <div class="col text-end fw-bold fs-3">
                            {{totalPrice}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3" v-if="showShipping">
            <h1 class="p-4 bg-light">Shipping Details</h1>
            <div class="row">
                <div class="col-6">
                    <div class="mb-2 fs-3 fw-bold">Shipping to</div>
                    <div class="shipping-info d-flex flex-column">
                        <span>{{ order.shipTo.addressee }}</span>
                        <span>{{ order.shipTo.addressLine1 }}</span>
                        <span>{{ order.shipTo.city }}, {{ order.shipTo.state }} {{ order.shipTo.postalCode }}</span>
                    </div>
                </div>

                <div class="col-6" v-if="order.shipMethod?.name">
                    <div class="mb-2 fs-3 fw-bold">Shipping Method</div>
                    <div class="shipping-info"> {{order.shipMethod?.name}} - {{shippingMethodCost}} </div>
                </div>
            </div>
        </div>

        <div class="row mt-3" v-if="order.billTo.addressee.length > 1">
            <h1 class="p-4 bg-light">Payment Information</h1>
            <div class="row">
                <div class="col-6">
                    <SelectedPaymentMethod :payment-method-id="order.paymentMethodId" />
                </div>

                <div class="col-6">
                    <div class="mb-2 fs-3 fw-bold">Billing Address</div>
                    <div class="shipping-info d-flex flex-column">
                        <span>{{ order.billTo.addressee }}</span>
                        <span>{{ order.billTo.addressLine1 }}</span>
                        <span>{{ order.billTo.city }}, {{ order.billTo.state }} {{ order.billTo.postalCode }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .order-details-header {
        background: $gray-10;
    }

    .order-number {
        color: $color-1
    }

    .agency-detail {
        font-size: 16px;
    }

    .shipping-info {
        font-size: 14px;
    }

    .status {
        margin-top: 0.5rem;
        font-weight: $bold;
        font-size: 16px;
    }

    .status.pending {
        .bi {
            opacity: 0.5;
            color: $ok;
        }
    }

    .status.pending-approval {
        .bi {
            color: $warning;
        }
    }

    .status.canceled {
        .bi {
            color: $error;
        }
    }

    .status.completed {
        .bi {
            color: $ok;
        }
    }

    .selected-payment-method div {
        font-size: 14px;
    }
</style>