<script setup>

    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // OneTimeDrop
    // Form finding the start date of the drop

    // Imports ----
    import { onMounted, ref, computed, reactive } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    import { site } from '@/Site';
    import { isNotEmpty } from '@/util';
    import { DropType, Frequency } from '@/constants'

    import Datepicker from '@vuepic/vue-datepicker';
    import { addDays, addMonths, lastDayOfMonth } from 'date-fns';


    // State ----
    const bulkOrderState = reactive({});
    const route = useRoute();
    const router = useRouter();
    const dropDate = ref();
    const mailingDetails = reactive([]);
    const productDetail = reactive({});
    const isLoaded = ref(false);

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }
    const disabledDates = ref([]);

    const minDate = computed(() => addDays(new Date(), 2));
    const maxDate = computed(() => lastDayOfMonth(addMonths(new Date(), 4)));

    const showFrequencyDetails = computed(() => {
        return mailingDetails.length > 0;
    });

    const totalMailingCount = computed(() => {
        return bulkOrderState?.orderStates?.reduce((sum, orderState) => (sum + Number(orderState.targetCount)), 0);
    });

    const startDate = computed(() => {
       if (dropDate.value) {
            const selectedDate = new Date(dropDate.value);
            selectedDate.setUTCHours(0,0,0,0);
            const startDateJ = JSON.stringify(selectedDate);
            const startDate = startDateJ.replace('Z', '');
            return JSON.parse(startDate)
       }

       return null;
    });

    // Handlers ----
    //const emit = defineEmits(['change']);

    function removePastDates() {
        // Remove the last 500 days from the calendar
        for (let i = 0; i < 500; i++) {
            disabledDates.value.push(addDays(new Date(), -i))
        }
    }

    async function change() {
        //emit('change', date.value);
        
        if (dropDate.value) {
            const payload = {
                dataListTypeCode: productDetail?.attributes?.dataListType,
                startDate: startDate.value,
                mailingType: DropType.ONE.value,
                frequency: Frequency.WEEKLY.value,
                drops: DropType.ONE.value,
                quantity: totalMailingCount.value,
                pricePerPiece: bulkOrderState.unitPrice,
                assetNumber: productDetail?.itemNumber
            }
            const response = await site.expressOrderPersonalizationInfo.getMailingDetails(payload);
            if (response) {
                Object.assign(mailingDetails, response);
            }
        }
        else {
            dropDate.value = null;
        }
    }

    onMounted(async () => {
        removePastDates();
        var bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
            Object.assign(productDetail, await site.products.getProductDetail(bulkOrderState.productId));
        }
        if (bulkOrderState?.dropDetails?.startDate) {
            dropDate.value = new Date(bulkOrderState.dropDetails.startDate);
            change();
        }
        isLoaded.value = true;
    });

    async function next() {
        const payload = {
            id: bulkOrderState.id,
            dropDetails: {
                dropType: DropType.ONE.text,
                frequency: Frequency.WEEKLY.text,
                numberOfMailings: DropType.ONE.value,
                startDate: startDate.value,
                mailingDetails: mailingDetails
            }
        }
        await site.expressOrderPersonalizationInfo.setDropDetails(payload);
        router.push({
            name: 'ExpressOrderStepFour',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId
            }
        });
    }

    function previous() {
        router.push({
            name: 'ExpressOrderStepTwo',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId
            }
        });
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar />
            <div class="root-page-container express-order-drops">

                <div class="row">
                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.THREE" />
                    </div>
                </div>
                <template v-if="isLoaded">
                    <div class="row border-bottom border-gray-2 mt-5">

                        <div class="col">

                            <div class="d-flex">
                                <div>
                                    <h1>One Time Drop</h1>
                                </div>
                                <div class="ms-auto">
                                    {{productDetail.name}} ({{productDetail.itemNumber}})
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-6">
                            <div class="one-time-drop text-center">
                                <h2 class="text-center">Select Drop Start Date</h2>

                                <Datepicker class="w-50 m-auto"
                                            v-model="dropDate"
                                            :highlight="disabledDates"
                                            :disabledDates="disabledDates"
                                            :format="format"
                                            highlightDisabledDays
                                            @update:modelValue="change"
                                            placeholder="Select Drop Date"
                                            :enable-time-picker="false"
                                            :auto-apply="true"
                                            :min-date="minDate"
                                            :max-date="maxDate"
                                            prevent-min-max-navigation
                                            week-start="0"
                                            :disabled-week-days="[0, 6]" />

                            </div>
                        </div>
                    </div>
                    <div class="row my-5" v-if="showFrequencyDetails">
                        <div class="col-sm-6 offset-sm-3">

                            <div class="frequency-headline py-2 text-center fs-4 fw-bold mb-2">
                                Frequency Summary
                            </div>

                            <div v-if="mailingDetails.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr class="bg-color-3-lt">
                                            <th class="border-end border-gray-0">Date Drop</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="drop in mailingDetails" :key="drop.mailDate">
                                            <td>{{site.formatDisplayDate(drop.mailDate)}}</td>
                                            <td>{{drop.quantity}}</td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col">
                            <div class="d-flex">
                                <div class="p-2">
                                    <button @click="previous" class="m-2 btn btn-outline-primary">Previous</button>
                                </div>
                                <div class="ms-auto p-2" v-if="showFrequencyDetails">
                                    <button @click="next" class="m-2 btn btn-primary" v-if="showFrequencyDetails">Next</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </template>
            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .one-time-drop {
        padding: 60px 0px;
        margin-top: 42px;
        background-color: aliceblue;
    }

    .express-order-drops {
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        .drop-start-container {
            background: $color-3-xlt;
        }

        thead {
            background: $color-2;
            color: $gray-4;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 50%;
            }
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }

        .emailMessage {
            color: #B85D00;
        }
    }
</style>