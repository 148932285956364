<script setup>

    // HomePage
    // Main home page

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import { TabBar, ShowOnlyForRoles } from '@dd-nucleus/nucleus-vue';
    import OpportunitiesTile from '@/site/components/OpportunitiesTile';
    import ProspectingOpportunitiesTile from '@/site/areas/prospecting/components/ProspectingOpportunitiesTile';
    import HeroImageSlider from './components/HeroImageSlider';
    import IconPanel from './components/IconPanel';
    import WhatToDoPanel from './components/WhatToDoPanel';
    import ExpandableLink from './components/ExpandableLink';
    import LinkItem from './components/LinkItem';
    import HeadlineWithIcon from './components/HeadlineWithIcon';
    import IgniteProspectingPromo from '@/site/components/IgniteProspectingPromo';

    // Imports
    import { reactive, ref, computed, onMounted, watch } from 'vue';
    import { site } from '@/Site';
    import { ProgramType, ProductType, TabIds, ProductFormat } from '@/constants';
    import { useRoute } from 'vue-router';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { isNotEmpty } from '@/util';
    import useEventsBus from '@/site/services/EventBus';

    const { bus } = useEventsBus();

    const route = useRoute();
    const dataLoaderAnnouncement = ref(false);
    const savedItems = reactive({ orderStates: [] });
    const bannerData = reactive({banners: []})
    let announcementToShow = reactive([]);

    // For Adobe analytics
    onMounted(async () => {
        await getSavedItems();
        await site.analyticsHandler.push({ "tileCode": "Home" });
        const response = await site.banners.getBanners();
        bannerData.banners = response;

        const responseAnnouncement = await site.announcement.getAnnouncementsForUser();
        if (responseAnnouncement.succeeded) {
            announcementToShow.push(...responseAnnouncement.announcements);
            dataLoaderAnnouncement.value = true;
        } else {
                site.handleApiError(responseAnnouncement);
            return null;
        }
        await site.analyticsHandler.push({ "tileCode": "Home" });   
    });  


    const images = computed(() => {
        return bannerData?.banners?.map((banner) => {
            return {
                id: banner.id,
                image: banner.url,
                url: banner.redirectUrl
            }
        })
    })

    // State
    const growthTabs = reactive([
        {
            id: 'growth-cross-sell',
            title: 'Cross-sell'
        },
        {
            id: 'growth-prospecting',
            title: 'Prospecting & Campaigns'
        },
        {
            id: 'growth-retention',
            title: 'Retention'
        }
    ]);

    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    // Computed
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

    const selectedTab = computed(() => {
        switch (route.query.tab) {
            case ProgramType.PROSPECTING:
                return TabIds.PROSPECTING;
            case ProgramType.RETENTION:
                return TabIds.RETENTION;
            default:
                return TabIds.CROSS_SELL;
        }
    });

    function isActive(id) {
        if (id == selectedTab.value) {
            return 'active show';
        }
    }

    async function getSavedItems() {
        savedItems.orderStates.length = 0;
        if (site.agencyDetails.defaultAgency.id > 0) {
            const response = await site.personalizationInfo.getSavedItems();
            if (response && response.length > 0) {
                savedItems.orderStates = response;
            }
        }
    }

    async function deleteOrderState(id) {
        const response = await site.personalizationInfo.deleteOrderState(id);
        if (response.succeeded) {
            const toDeleteOrderState = savedItems.orderStates.find(os => os.id = id);
            savedItems.orderStates = savedItems.orderStates.filter((item) => item.id != id);
            if (isNotEmpty(toDeleteOrderState.parentId)) {
                if (toDeleteOrderState.parentId == id) {
                    savedItems.orderStates = savedItems.orderStates.filter(item => item.parentId != id);
                } else if (toDeleteOrderState.parentId != id) {
                    savedItems.orderStates = savedItems.orderStates.filter(item => item.id != toDeleteOrderState.parentId);
                }
            }
        }
    }

    function resumeOrderState(orderState) {
        const parentId = orderState.parentId ? orderState.parentId : orderState.id;
        const orderStateId = orderState.parentId ? orderState.parentId : orderState.id;
        const channelType = orderState.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderStateId}/${parentId}/${orderState.productId}/?edit=true&isSubscription=${orderState.product?.attributes?.isSubscription}&channelType=${channelType}`;
    }

    //watch
    watch(() => bus.value.get('changeAgency'), async () => {
        await getSavedItems();
    });
   

</script>

<template>
    <PageTemplate>
        <div class="container root-page home-page">

            <GlobalTabBar :active-id="TabIDs.HOME_TAB_ID" />

            <div class="root-page-container">

                <div class="row" v-if="dataLoaderAnnouncement">

                    <div class="col" v-if="announcementToShow.length">

                        <div class="announcements" v-for="announcement in announcementToShow" :key="announcement.id">
                            <h2 class="announcement-title" v-if="!!announcement.title">{{ announcement.title }}</h2>
                            <p class="announcement-message" v-if="!!announcement.body" v-html="announcement.body"></p>
                        </div>

                    </div>
                    <div class="col" v-else>
                        <h1>Announcements</h1>
                        <p>
                            Welcome to the new Marketing Solutions Center&trade;.  It has been redesigned from the ground up to include new technology and a cleaner, sleeker design.  The goal is to provide you with a quick view and a better user experience to make it faster and easier to connect with customers.
                        </p>
                        <p>
                            At any time, please feel free to use the "Provide Feedback" in the footer to let us know what you think and/or how we can continue to improve your experience on the site.
                        </p>
                    </div>
                </div>

                <div class="row" v-if="!isEmployee">

                    <div class="col col-8">

                        <HeadlineWithIcon iconPath="/images/icons/growth-opportunities-icon.png" title="My growth opportunities" />

                        <h1 class="growth"><i class="icon graph-up-arrow"></i> </h1>
                        
                        <TabBar :tabs="growthTabs" :activeId="selectedTab" />

                        <div class="tab-content">
                            <div class="tab-pane fade" id="growth-cross-sell" :class="isActive(TabIds.CROSS_SELL)" role="tabpanel">
                                <div class="sub-tab-container">
                                    <OpportunitiesTile filter-id="opportunities-cross-sell" />
                                    <div class="cross-sell">
                                        <router-link :to="{ path: '/cross-sell' }" class="no-underline">Learn more about Cross-sell</router-link>
                                        <span>&#10240;|&#10240;</span>
                                        <router-link :to="{ path: '/products', query: { 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state } }" class="no-underline">View Cross-sell assets</router-link>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="tab-pane fade" id="growth-prospecting" :class="isActive(TabIds.PROSPECTING)" role="tabpanel">
                                <div class="sub-tab-container">
                                    <ProspectingOpportunitiesTile filter-id="prospecting" />
                                </div>
                            </div>

                            <div class="tab-pane fade" id="growth-retention" :class="isActive(TabIds.RETENTION)" role="tabpanel">
                                <div class="sub-tab-container">
                                    <OpportunitiesTile filter-id="opportunities-retention" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col">

                        <IgniteProspectingPromo />
                        <br />

                        <WhatToDoPanel>
                            <ExpandableLink title="Grow your business from within" link="#" :isExternal="true" :openNewWindow="false">
                                <LinkItem class="mb-3" title="Cross-sell to your customers" link="/cross-sell" :isExternal="false" :openNewWindow="false" :level="1" />
                                <LinkItem class="mb-3" title="Cross-sell specialty products" :link="{ path: '/products', query: { 'f-product-type': ProductType.CROSS_SELL_SPECIALITY, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state }}" :isExternal="false" :openNewWindow="false" :level="1" />
                                <LinkItem class="mb-3" title="Order marketing materials" link="/marketing" :isExternal="false" :openNewWindow="false" :level="1" />
                            </ExpandableLink>

                            <ExpandableLink title="Find new customers" link="#" :isExternal="true" :openNewWindow="false">
                                <LinkItem class="mb-3" title="Sell to new prospects" link="/prospecting" :isExternal="false" :openNewWindow="false" :level="1" />
                                <LinkItem class="mb-3" title="Promote at an event" link="https://libertymutualpromo.com/" :isExternal="true" :openNewWindow="true" :level="1" />
                                <LinkItem class="mb-3" title="Promote online presence" :link="{ path: '/products', query: { 'f-product-format': ProductFormat.WEBSITE_CONTENT}}" :isExternal="false" :openNewWindow="false" :level="1" />
                                <LinkItem class="mb-3" title="Order marketing materials" :link="{ path: '/products', query: { 'f-product-tatic': 'Prospecting', 'f-product-state': selectedAgencyDetails.state }}" :isExternal="false" :openNewWindow="false" :level="1" />
                            </ExpandableLink>

                            <ExpandableLink title="Increase retention & add value" link="#" :isExternal="true" :openNewWindow="false">
                                <!--For Phase 2-->
                                <LinkItem class="mb-3" title="Deepen client relationships" link="/retention/relationship-marketing" :isExternal="false" :openNewWindow="false" :level="1" />
                                <!--TODO: Link to products page with Filter: product= marketing materials, in State-->
                                <LinkItem class="mb-3" title="Order marketing materials" link="/marketing" :isExternal="false" :openNewWindow="false" :level="1" />
                            </ExpandableLink>

                        </WhatToDoPanel>
                    </div>

                </div>

                <div class="row">

                    <div class="col my-4">

                        <HeroImageSlider :transition-time-ms="4000" :images="images" />

                    </div>

                </div>

                <div class="row">

                    <div class="col-12 col-sm-4">
                        <IconPanel title="My Links" icon="images/icons/my-links.png">
                            <LinkItem class="mb-3" title="My Favorite Marketing Materials" link="/account/favorites" :isExternal="true" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="My Favorite Reports" link="/reports" :isExternal="true" :openNewWindow="false" />
                            <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                                <LinkItem class="mb-3" title="My Order History/Lists" link="/account/order-history" :isExternal="true" :openNewWindow="false" />
                            </ShowOnlyForRoles>
                            <!--<LinkItem class="mb-3" title="Most Recent Downloads" link="/most-recent-downloads" :isExternal="true" :openNewWindow="false" />-->
                        </IconPanel>
                    </div>

                    <div class="col-12 col-sm-4">
                        <IconPanel title="My Items Saved for Later" icon="images/icons/documents-multiple.png">
                            <div v-if="savedItems.orderStates.length > 0">
                                <template v-for="item in savedItems.orderStates.slice(0,2)" :key="item.id">
                                    <div class="row save-for-later my-1">
                                        <div class="col-11">
                                            <router-link :to="resumeOrderState(item)" class="product">{{ item.product.itemNumber }} - {{ item.product.name }}</router-link>
                                        </div>
                                        <div class="col-1">
                                            <div @click="deleteOrderState(item.id)" class="delete mt-1"><i class="bi bi-trash3"></i></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="my-1" v-if="savedItems.orderStates.length > 2">
                                    <LinkItem style="font-size: 1rem" title="View All" link="/account/save-for-later" :isExternal="true" :openNewWindow="false" />
                                </div>
                            </div>
                            <div v-else class="mx-2">
                                You have no saved marketing materials at this time.
                            </div>
                        </IconPanel>
                    </div>

                    <div class="col-12 col-sm-4">
                        <IconPanel title="Top picks" icon="images/icons/lifetime-repair-guarrantee-checkmark.png">
                            <LinkItem class="mb-3" title="Auto Insurance Brochures" :link="{ path: '/products', query: { 'f-product-type': 'Auto', 'f-product-format': 'Brochures' }}" :isExternal="false" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="Home Insurance Brochures" :link="{ path: '/products', query: { 'f-product-type': 'Home', 'f-product-format': 'Brochures' }}" :isExternal="false" :openNewWindow="false" />
                            <!--LinkItem class="mb-3" title="Annual Auto Letter"  :link="{ path: '/products', query: { 'f-program-type': 'AnnualAuto', 'f-product-format': 'Letter' }}" :isExternal="false" :openNewWindow="false" /-->
                            <!-- TODO: Add a link to view all -->
                                <LinkItem title="View All" :link="{ path: '/products'}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">

    h1.growth {
        font-weight: $normal;
    }

    .tab-content .cross-sell {
        font-size: 14px;
        display: flex;
        justify-content: flex-end;
    }

    .home-page {

        .save-for-later {
            p {
                font-size: 12px;
                margin: 0px;
                margin-bottom: 4px;
            }

            .product {
                cursor: pointer;
                font-size: 1.0rem;
                text-decoration: none;
                display: inline-block;
            }

            .delete {
                cursor: pointer;
                color: red;
                font-size:1.0rem;
            }
        }

    }
</style>
