<script setup>

    // ImageSlider
    // An image slider that take an Array of Objects for the images which is required. 

    // Each images in the "images" array has the following:
    // - id             (Not Required) Add this more if we expand to using an API for this and the data being set / update in the backend.
    // - image          (Required)  The path to the images inside of public/images 
    // - url           (Required)  These are calls to actions and should drive a user to a certain page or asset.

    // Imports ----
    import { computed, onMounted, ref, defineProps } from 'vue';
    
    // Props ----
    const props = defineProps({
        // transitionTimeMs is not required but available if you want to set it something other than 4000ms. 4000 is the default.
        transitionTimeMs: {
            type: Number,
            required: false,
            default: 4000
        },
        images: {
            type: Array,
            required: true
        }
    });

    // State ----
    let timer = ref(4000);

    let currentIndex = ref(0);

    // Handlers ----
    function startSlide() {
        timer.value = setInterval(function () {
            next();
        }, props.transitionTimeMs);
    }

    function next() {
        currentIndex.value += 1;
    }

    // Lifecycle Hooks ----
    onMounted(() => {
            startSlide();
    });

    const currentImg = computed(() => {
        return props.images[Math.abs(currentIndex.value) % props.images.length];
    });

</script>

<template>

    <div class="image-slider">
        <div v-for="i in [currentIndex]" :key='i'>
            <router-link v-if="currentImg" :to="currentImg.url">
                <img :src="currentImg.image" class="img-fluid w-100" />
            </router-link>
        </div>
    </div>

</template>