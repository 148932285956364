<script>

    // GlobalTabBar
    // Provides the navigation for root-level pages, such as home, cross-sell program, prospecting & lead gen, etc.
    // Constants:
    // - TabIDs - IDs of the different tabs. Use these for the active-id prop to set the selected tab

    // Constants
    const constants = {
        TabIDs: {
            HOME_TAB_ID: 'home',
            CROSS_SELL_TAB_ID: 'cross-sell',
            PROSPECTING_TAB_ID: 'prospecting',
            RETENTION_TAB_ID: 'retention',
            MARKETING_TAB_ID: 'marketing',
            REPORTS_TAB_ID: 'reports',
            AGENT_REFERRAL_TAB_ID: 'agency-referral'
        }
    };

    // Exports
    export const TabIDs = constants.TabIDs;

</script>


<script setup>

    // Components
    import { TabBar } from '@dd-nucleus/nucleus-vue';
    import ImpersonateAlert from './ImpersonateAlert.vue'

    // Imports
    import { ref, defineProps, computed, reactive } from 'vue';
    import { site } from '@/Site';
    import { ProgramType, ProductType, ProductTatic, getOppTypeFromProductType, getOppIdFromProductType } from '@/constants'


    // Props
    const props = defineProps({
        // ID of the tab that is currently active
        activeId: {
            type: String,
            required: false
        }
    });

    // State
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);
    const tabs = computed(() => {
        return [
            {
                id: constants.TabIDs.HOME_TAB_ID,
                title: 'Home',
                url: '/',
                isExternalUrl: false
            },
            {
                id: constants.TabIDs.CROSS_SELL_TAB_ID,
                title: 'Cross-sell',
                url: '/cross-sell',
                isExternalUrl: false,
                children: [
                    {
                        id: 'HomeToAuto',
                        title: 'Cross-sell Home to Auto',
                        url: {
                            path: '/personalization/select-channel', query: { productType: ProductType.HOME, programType: ProgramType.CROSS_SELL, statCode: selectedAgencyDetails.statId, oppType: getOppTypeFromProductType(ProductType.HOME), oppId: getOppIdFromProductType(ProductType.HOME) }
                        },
                        /*
                        url: {
                            path: '/products', query: { 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(";"), 'f-product-data-list-option': DataListOption.HomeCrossSell, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        */
                        isExternalUrl: false
                    },
                    {
                        id: 'AutoToHome',
                        title: 'Cross-sell Auto to Home',
                        url: {
                            path: '/personalization/select-channel', query: { productType: ProductType.AUTO, programType: ProgramType.CROSS_SELL, statCode: selectedAgencyDetails.statId, oppType: getOppTypeFromProductType(ProductType.AUTO), oppId: getOppIdFromProductType(ProductType.AUTO) }
                        },
                        /*
                        url: {
                            path: '/products', query: { 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(";"), 'f-product-data-list-option': DataListOption.AutoCrossSell, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        */
                        isExternalUrl: false
                    },
                    {
                        id: 'UmbrellaToAuto',
                        title: 'Cross-sell Umbrella to qualified Auto customers',
                        url: {
                            path: '/products', query: { 'f-product-type': ProductType.UMBRELLA, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: { 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(";"), 'f-product-data-list-option': [DataListOption.UmbrellaCrossSell, DataListOption.Umbrella, DataListOption.FloridaUmbrella, DataListOption.UmbrellaEdcuationCrossSell].join(";"), 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        */
                        isExternalUrl: false
                    },
                    {
                        id: 'Specialty',
                        title: 'Cross-sell Specialty',
                        url: {
                            path: '/personalization/select-channel', query: { productType: ProductType.CROSS_SELL_SPECIALITY, programType: ProgramType.CROSS_SELL, statCode: selectedAgencyDetails.statId, oppType: getOppTypeFromProductType(ProductType.CROSS_SELL_SPECIALITY), oppId: getOppIdFromProductType(ProductType.CROSS_SELL_SPECIALITY) }
                        },
                        /*
                        url: {
                            path: '/products', query: { 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(";"), 'f-product-data-list-option': DataListOption.SpecialtyCrossSell, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        */
                        isExternalUrl: false
                    },
                    {
                        id: 'LandlordProtection',
                        title: 'Cross-sell Landlord Protection',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.CROSS_SELL, 'f-product-type': ProductType.LLP, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: { 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(";"), 'f-product-data-list-option': DataListOption.LandLordProtectionCrossSell, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        */
                        isExternalUrl: false
                    },
                    {
                        id: 'PetInsurance',
                        title: 'Cross-sell Pet Insurance',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PET_INSURANCE, 'f-product-type': ProductType.PET_INSURANCE, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.CROSS_SELL_TAB_ID }
                        },
                        isExternalUrl: false
                    }
                ]
            },
            {
                id: constants.TabIDs.PROSPECTING_TAB_ID,
                title: 'Prospecting & Lead Gen',
                url: '/prospecting',
                isExternalUrl: false, 
                children: [
                    {
                        id: 'Package',
                        title: 'Package',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PROSPECTING, 'f-product-type': ProductType.SAFECO_PACKAGE, 'f-product-tatic': ProductTatic.PROSPECTING,'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },
                        /*url: {
                            path: '/products', query: {
                                'f-product-type': ProductType.PACKAGE,
                                'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(";"),
                                'f-product-state': selectedAgencyDetails.state,
                                'f-product-search-term': DataListType.PROSPECT, 
                                'active- tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },*/
                        isExternalUrl: false
                    },
                    {
                        id: 'Auto',
                        title: 'Auto',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PROSPECTING, 'f-product-type': ProductType.AUTO, 'f-product-tatic': ProductTatic.PROSPECTING, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: {
                                'f-product-type': ProductType.AUTO,
                                'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(";"),
                                'f-product-state': selectedAgencyDetails.state,
                                'f-product-search-term': DataListType.PROSPECT, 
                                'active-tab': constants.TabIDs.PROSPECTING_TAB_ID
                            }
                        },*/
                        isExternalUrl: false
                    },
                    {
                        id: 'Home',
                        title: 'Home',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PROSPECTING, 'f-product-type': ProductType.HOME, 'f-product-tatic': ProductTatic.PROSPECTING, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: {
                                'f-product-type': ProductType.HOME,
                                'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(";"),
                                'f-product-state': selectedAgencyDetails.state,
                                'f-product-search-term': DataListType.PROSPECT, 
                                'active-tab': constants.TabIDs.PROSPECTING_TAB_ID
                            }
                        },*/
                        isExternalUrl: false
                    },
                    {
                        id: 'Renters',
                        title: 'Renters',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PROSPECTING, 'f-product-type': ProductType.RENTERS,  'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: {
                                'f-product-type': ProductType.RENTERS,
                                'f-product-data-list-type': [DataListType.PROSPECT].join(";"),
                                'f-product-state': selectedAgencyDetails.state,
                                'f-product-search-term': DataListType.PROSPECT, 
                                'active-tab': constants.TabIDs.PROSPECTING_TAB_ID
                            }
                        },*/
                        isExternalUrl: false
                    },
                    {
                        id: 'Other',
                        title: 'Other',
                        url: {
                            path: '/products', query: { 'f-program-type': ProgramType.PROSPECTING, 'f-product-type': ProductType.OTHER, 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.PROSPECTING_TAB_ID }
                        },
                        /*
                        url: {
                            path: '/products', query: {
                                'f-product-type': ProductType.OTHER,
                                'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(";"),
                                'f-product-state': selectedAgencyDetails.state,
                                'f-product-search-term': DataListType.PROSPECT, 
                                'active-tab': constants.TabIDs.PROSPECTING_TAB_ID
                            }
                        },*/
                        isExternalUrl: false
                    }
                ]
            },
            {
                id: constants.TabIDs.RETENTION_TAB_ID,
                title: 'Retention & Loyalty',
                url: '/retention',
                isExternalUrl: false,
                /*
                children: [
                    {
                        id: 'MovingwithSafeco',
                        title: 'Moving with Safeco',
                        url: '/retention/moving',
                        isExternalUrl: false
                    },
                    {
                        id: 'InsuranceCheck-up',
                        title: 'Insurance Check-up',
                        url: '/retention/insurance-check-up',
                        isExternalUrl: false
                    },
                    {
                        id: 'ClientReferral',
                        title: 'Client Referral',
                        url: '/retention/client-referral',
                        isExternalUrl: false
                    },
                    {
                        id: 'RelationshipMarketing',
                        title: 'Relationship Marketing',
                        url: '/retention/relationship-marketing',
                        isExternalUrl: false
                    },
                    {
                        id: 'WinBackProgram',
                        title: 'Win Back Program',
                        url: '/retention/win-back',
                        isExternalUrl: false
                    },
                    {
                        id: 'WelcomeKit',
                        title: 'New Client Welcome Kit',
                        url: {
                            path: '/products', query: { 'f-product-search-term': 'welcome kit', 'f-product-state': selectedAgencyDetails.state, 'active-tab': constants.TabIDs.RETENTION_TAB_ID }
                        },
                        isExternalUrl: false
                    }
                ]
                */
            },
            {
                id: constants.TabIDs.MARKETING_TAB_ID,
                title: 'Marketing Materials',
                url: '/marketing',
                isExternalUrl: false
                /*
                children: [
                    {
                        id: 'OnlineLibrary',
                        title: 'Online Library',
                        url: {
                            path: '/products', query: {
                                'f-product-marketing-materials': 'OnlineContent',
                                'active-tab': constants.TabIDs.MARKETING_TAB_ID
                            }
                        },
                        isExternalUrl: false
                    },
                    {
                        id: 'BrandedSupplies',
                        title: 'Branded Supplies',
                        url: {
                            path: '/products', query: {
                                'f-product-marketing-materials': 'BrandedSupplies',
                                'active-tab': constants.TabIDs.MARKETING_TAB_ID
                            }
                        },
                        isExternalUrl: false
                    },
                    {
                        id: 'EventsSponsorship',
                        title: 'Events / Sponsorship',
                        url: {
                            path: '/products', query: {
                                'f-product-marketing-materials': 'EventsSponsorship',
                                'active-tab': constants.TabIDs.MARKETING_TAB_ID
                            }
                        },
                        isExternalUrl: false
                    },
                    {
                        id: 'Promotional Merchandise',
                        title: 'Promotional Merchandise',
                        url: 'https://libertymutual.corpmerchandise.com',
                        isExternalUrl: true,
                        target: '_blank'
                    }
                ]
                */
            },
            {
                id: constants.TabIDs.REPORTS_TAB_ID,
                title: 'Reports',
                url: '/reports',
                isExternalUrl: false,
            }
        ]
    });

    // State
    const activeId = ref(props.activeId);

</script>


<template>

    <ImpersonateAlert />
    <TabBar :tabs='tabs' :active-id='activeId' :justify='true' />

</template>


<style lang='scss'>
    .tabBar {
        .dropdown-menu li {
            border-bottom: 1px solid $gray-2;

            &:last-child {
                border-bottom: none;
            }
        }
    }
</style>
