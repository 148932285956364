<script setup>

    // HeadlineContainer
    // Headline component that accepts a title as a prop and builds the h2 container

    // Imports
    import { defineProps } from 'vue';

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
    })

</script>

<template>
    <div class="headline-container">
        <h2 class="px-5 py-3">{{props.title}}</h2>
    </div>
</template>

<style lang="scss">
    .headline-container {
        background: $color-3-xlt;
    }
</style>