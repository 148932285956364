<script setup>
    // Imports
    import { reactive, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    // Components
    import { isNotEmpty } from '@/util';

    // State
    const route = useRoute();

    const bulkOrderState = reactive({});

    async function setDocumentInfo(orderStateId, isSamePersonalization) {
        if (isSamePersonalization == 'true') {
            const docIds = route.query.DocID?.split(",");
            var payload = {
                id: bulkOrderState.id,
                pageFlexDocumentIds: docIds
            };
            await site.expressOrderPersonalizationInfo.updateBulkOrderStateDocumentIds(payload);
        } else {
            await site.personalizationInfo.getOrderState(orderStateId);
            await site.personalizationInfo.updateDocId(route.query.DocID);
        }
    }

    // Handlers ----
    onMounted(async () => {
        const bulkOrderStateId = route.params.bulkOrderStateId
        const orderStateId = route.params.orderStateId;
        const isLastRecord = route.query.isLastRecord;
        const currentStep = route.query.currentStep;
        const isSamePersonalization = route.query.isSamePersonalization

        if (isNotEmpty(bulkOrderStateId)) {

            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId));
            if (route && route.query && route.query.DocID && route.query.DocID.length > 0) {
                await setDocumentInfo(orderStateId, isSamePersonalization);
                if (isLastRecord == 'true') {
                    if (currentStep) {
                        window.parent.location.href = `/expressorder/step-1/${bulkOrderStateId}/${orderStateId}`;
                    }
                    else {
                        window.parent.location.href = `/expressorder/step-2/${bulkOrderStateId}`;
                    }
                }
                else {
                    window.parent.location.href = `/expressorder/step-1/${bulkOrderStateId}/${orderStateId}`;
                }
            }
            else {
                //TODO : redirect to some error page.
            }
        }
    })

</script>

<template>
    <div></div>
</template>

<style lang="scss">
    .print-on-demand-customize-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }

        .multi-channel-headline {
            background: $color-3-dk;
        }

        .multi-channel-detail {
            background: $gray-1;
        }
    }
</style>
