<script setup>

    // VideoPlayer
    // Plays the video
    import { defineProps  } from 'vue';
    import { v4 as uuidv4 } from 'uuid';
    import { VideoPlayer } from 'vue-hls-video-player';

    const videoId = uuidv4();

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        videoUrl: {
            type: String,
            required: true
        },
        videoWidth: {
            type: Number,
            required: false,
            default: 360
        },
        // Different video format options
        videoType: {
            type: String,
            required: false,
            default: 'application/vnd.ms-sstr+xml'
        }
    });
    
</script>

<template>
    <div class="video-player">

        <div>

            <VideoPlayer type="default" :id="videoId" :width="props.videoWidth" :link="props.videoUrl" :isControls="true"/>

        </div>

        <div class="fw-bold">
            {{props.title}}
        </div>
    </div>
</template>

<style lang="scss">

</style>