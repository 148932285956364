<script setup>

    // MovingWithSafecoListPage
    // Displays the lists for moving with safeco

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 12
    }

    const columns = [
        { field: 'actions', title: 'View List', custom: true },
        { field: 'date', title: 'List Date', custom: true },
        { field: 'count', title: 'Records' },
        { field: 'export', title: 'Export', custom: true },
    ];

    const fieldsMapper = {
        agencyCode: "Agency Code",
        recordDate: "Record Date",
        referenceId: "Reference ID",
        policy: "Policy #",
        type: "Policy Type",
        firstName: "First Name",
        lastName: "Last Name",
        address1: "Policy Address 1",
        address2: "Policy Address 2",
        city: "Policy City",
        state:"Policy State",
        zip:"Policy Zip",
        phone:"Policy Phone",
        forwardAddress1:"Forwarding Address 1",
        forwardAddress2:"Forwarding Address 2",
        forwardCity:"Forwarding City",
        forwardState:"Forwarding State",
        forwardZip:"Forwarding Zip",
        forwardPhone:"Forwarding Phone",
        moveSource:"Move Source",
        moveType:"Move Type",
        moveDate:"Move Date",
        comments: "Comments"
    }

    // Imports ----
    import { ref, reactive, computed, watch , onMounted } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { ExportParser } from "@/site/services/ExportParser";
    import useEventsBus from '@/site/services/EventBus';
    import { site } from '@/Site';

    const { bus } = useEventsBus();

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    // State ----
    const showSearch = ref(true);

    let header = ref('test');

    const search = new Search('retention-list-moving-with-safeco-search', 'tile', 'date-desc', constants.DEFAULT_PAGE_SIZE);

    const listDetails = reactive([]);

    // Methods ----
    function viewDetails(date) {

        header.value = formateDate(date);

        // Reset the list every click
        listDetails.length = 0;

        search.results.rows.forEach((item) => {
            
            if (item.date == date) {
                Object.assign(listDetails, item.records)
            }
        });
    }

    function exportCSVList(date) {

        // Reset the list every click
        listDetails.length = 0;

        search.results.rows.forEach((item) => {

            if (item.date == date) {
                Object.assign(listDetails, item.records)
            }
        });

        exportCSV();
    }

    function exportXLSList(date) {

        // Reset the list every click
        listDetails.length = 0;

        search.results.rows.forEach((item) => {

            if (item.date == date) {
                Object.assign(listDetails, item.records)
            }
        });

        exportXLS();
    }

    function exportCSV() {
        ExportParser().exportCSVFromJSON(listDetails, null, null, fieldsMapper);
    }

    function exportXLS() {
        ExportParser().exportXLSFromJSON(listDetails, null, null, fieldsMapper);
    }


    function formateDate(date) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        return `${mo} ${ye}`;
    }


    // Computed
    const listLength = computed(() => {
        return listDetails.length;
    });

    const numberofLists = computed(() => {
        return search.results.rows.length;
    });

    // Event Bus ---
    watch(() => bus.value.get('changeAgency'), () => {
        showSearch.value = false;
        listDetails.length = 0;
        setTimeout(() => {
            showSearch.value = true;
        }, 500);

        
    });

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Moving", "lob": "Reports", "productNames": "MSC_Moving" });
    });
</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container retention-moving">

                <div class="row mb-3">
                    <div class="col">
                        <h1 class="fw-normal mb-3">Moving With Safeco List</h1>
                        <h2>How to use this list:</h2>
                        <p>Identify customers whose insurance needs may be changing. The MWS program helps identify customers whose insurance needs may be changing. The monthly lists help identify those customers who may be in need of an Insurance Check-Up in that month. We suggest you follow-up with these customers to ensure they have the right coverage for their current circumstance. The lists do not change month over month, only current month's list is added.</p>
                        <p>If you have any questions, please contact your TM or call the help desk at (800) 845-9860.</p>
                    </div>
                </div>

                <SearchContainer :search="search" default-view="list" :deep-link="true" v-if="showSearch">

                    <div class="row">

                        <div class="col-6 border-end pe-5">
                            <div class="d-flex mb-3">
                                <div>
                                    <h2>Moving With Safeco List</h2>
                                </div>
                                <div class="ms-auto">
                                    {{numberofLists}} Lists
                                </div>
                            </div>
                            <div class="data-table">
                                <SearchTable :columns="columns">

                                    <!-- Action column -->
                                    <template v-slot:actions="row">
                                        <a class="no-underline" @click="viewDetails(row.date)">View Details</a>
                                    </template>

                                    <template v-slot:date="row">
                                        {{formateDate(row.date)}}
                                    </template>

                                    <template v-slot:export="row">
                                        <a class="no-underline" @click="exportCSVList(row.date)">CSV</a> |
                                        <a class="no-underline" @click="exportXLSList(row.date)">Excel</a>
                                    </template>

                                    <!-- Template for no results -->
                                    <template #empty>
                                        No Moving With Safeco Lists were found that match your criteria.
                                    </template>

                                    <!-- Template while results are initially loading -->
                                    <template #loading>
                                        <TableSkeleton />
                                    </template>
                                </SearchTable>
                            </div>
                        </div>

                        <div class="col-6 ps-5" v-if="listDetails.length > 0">
                            <div class="d-flex mb-3">
                                <div>
                                    <h2>Records for {{header}}</h2>
                                </div>
                                <div class="ms-auto">
                                    <i @click="exportXLS" title="Download in Excel format" class="bi bi-filetype-xls me-3 fs-2"></i>
                                    <i @click="exportCSV" title="Download in CSV format" class="bi bi-filetype-csv me-3 fs-2"></i>
                                    {{listLength}} Records
                                </div>
                            </div>

                            <div class="data-table">
                                <table class="n-search-table table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="col-head" scope="col">Policy #</th>
                                            <th class="col-head" scope="col">Policy Type</th>
                                            <th class="col-head" scope="col">First Name</th>
                                            <th class="col-head" scope="col">Last Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="list in listDetails" :key="list.agencyCode">
                                            <td>{{list.policy}}</td>
                                            <td>{{list.type}}</td>
                                            <td>{{list.firstName}}</td>
                                            <td>{{list.lastName}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>

                </SearchContainer>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .retention-moving {
        .bi {
            color: $color-2;
        }
    }
</style>