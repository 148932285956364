<script setup>

    // PersonalizeStep3DropDatePage
    // Set up the drop dates of mailings

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar  from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import { dropType } from './PersonalizeStep3Page';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import OneTimeDrop from './components/OneTimeDrop';
    import MultipleDrops from './components/MultipleDrops';
    import { addDays, addMonths, lastDayOfMonth } from 'date-fns';

    // Imports
    import { computed, ref, onMounted, reactive } from 'vue';
    import { site } from '@/Site';
    import { useRouter, useRoute } from 'vue-router';
    import { isNotEmpty } from '@/util';
    import { MinMailingCount, getTabName } from '@/constants'

    const router = useRouter();
    const route = useRoute();

    // State
    const startDate = ref(new Date());
    const multipleDrop = reactive({ startDate: null, frequency: null, numberOfMailings: null });
    const selectedDate = ref(null);


    const detail = reactive({ product: {} });

    const mailFrequency = reactive([]);

    const orderFlowDetails = reactive({});

    const isLoaded = ref(false);

    //computed
    const minDate = computed(() => addDays(new Date().setUTCHours(0, 0, 0, 0), 2));
    const maxDate = computed(() => lastDayOfMonth(addMonths(new Date().setUTCHours(0, 0, 0, 0), 4)));

    // Methodsfunction

    async function next() {

        if (orderFlowDetails.isMultiChannel) {
            let queryParams = route.query

            //Assume that the request comes from Review Page to edit.
            if (queryParams.review && queryParams.review == 'true') {
                router.push({
                    name: 'PersonalizeStep4MultiPage',
                    query: { parentId: orderFlowDetails.parentId }
                });
            } else {
                //return `/personalization/step-1/${orderStateId}/${parentId}/${orderState.productId}/?edit=true&isSubscription=${orderState.product?.attributes?.isSubscription}&channelType=${channelType}`;
                queryParams['channelType'] = ChannelTypes.EMAIL;
                queryParams['isMultiChannel'] = orderFlowDetails.isMultiChannel;
                let redirectToSelectAssetPage = true;

                if (orderFlowDetails.isStateSaved) {
                    const response = await site.personalizationInfo.getOrderStates(orderFlowDetails.id);
                    const email = response.orderStates.find(o => o.channelType === ChannelTypes.EMAIL);
                    if (isNotEmpty(email)) {
                        redirectToSelectAssetPage = false;
                        router.push({
                            name: 'PersonalizeStep1Page',
                            params: {
                                id: email.id,
                                parentId: email.parentId,
                                productId: email.productId
                            },
                            query: {
                                channelType: ChannelTypes.EMAIL
                            }
                        });
                    }
                }
                if (redirectToSelectAssetPage) {
                    router.push({
                        name: 'SelectAssetPage',
                        params: {
                            channel: ChannelTypes.EMAIL
                        },
                        query: queryParams
                    });
                }
            }
        } else {
            router.push({
                name: 'PersonalizeStep4Page',
                params: { id: orderFlowDetails.id }
            });
        }
    }

    //TODO: May be to move to common place???
    async function saveForLater() {
        if (site.errorHandler.handleSaveForLaterRequest()) {
            router.push({
                name: 'Home',
            });
        }
    }

    async function switchToOneTime() {
        // Reset the mail Frequency table
        console.warn("switchToOneTime called");
        mailFrequency.length = 0;
        await site.personalizationInfo.setDropType(dropType.ONE);
    }

    async function switchToMultiple() {
        // Reset the mail Frequency table
        console.warn("switchToMultiple called");
        mailFrequency.length = 0;
        await site.personalizationInfo.setDropType(dropType.MULTIPLE);
    }

    async function oneTimeDropDate(date) {
        if (date) {
            const formattedDate = formatDate(date);
            console.warn('formattedDate', formattedDate);
            await site.personalizationInfo.setDropDetails(formattedDate, 'weekly', 1);
        }
        else {
            startDate.value = null;
        }
    }

    const formatDate = (date) => {
      if (date) {
        const selectedDate = new Date(date);
        selectedDate.setUTCHours(0, 0, 0, 0); // Set time to midnight in UTC
        const startDateJ = selectedDate.toISOString(); // Convert to ISO 8601 format
        return startDateJ;
      }

      return null;
    };

    async function multipleDropDates(multiple) {
        Object.assign(multipleDrop, multiple);
        if (multiple && multiple.startDate) {
            const formattedDate = formatDate(multiple.startDate);
            selectedDate.value = formattedDate;
            if (formattedDate && multiple.frequency && multiple.numberOfMailings) {
                await site.personalizationInfo.setDropDetails(formattedDate, multiple.frequency, multiple.numberOfMailings);
            }
        }
    }

    // Computed
    const tabName = computed(() => {
        return getTabName(orderFlowDetails.programType);
    });

    const showFrequencyDetails = computed(() => {
        if (mailingDetails.value.length > 0) {
            return true;
        }
        return false;
    });

    const hasLessMailingList = computed(() => {
        return orderFlowDetails.targetCount <= MinMailingCount
    })

    const dropTypeSelected = computed(() => {
        if (orderFlowDetails.dropDetails && orderFlowDetails.dropDetails.dropType) {
            if (orderFlowDetails.targetCount <= MinMailingCount) {
                return dropType.ONE;
            }   
        }
        return orderFlowDetails.dropDetails && orderFlowDetails.dropDetails.dropType;
    });

    const totalQuantity = computed(() => {
        return orderFlowDetails.targetCount;
    });

    // Computed
    const mailingDetails = computed(() => {
        return orderFlowDetails.dropDetails?.mailingDetails || [];
    });

    const hasProductDataType = computed(() => {
        return detail.product?.attributes?.hasProductDataType;
    });

    const dropTypeHeadline = computed(() => {
        switch (dropTypeSelected.value) {
            case dropType.ONE:
                return 'One Time Drop';
            case dropType.MULTIPLE:
                return 'Multiple Drops';
            default:
                return '';
        }
    });

    const switchHeadline = computed(() => {
        switch (dropTypeSelected.value) {
            case dropType.ONE:
                return 'Multiple';
            case dropType.MULTIPLE:
                return 'One Time';
            default:
                return '';
        }
    });

    const multipleDropValues = computed(() => {
        const multipleDrop = { frequency: null, numberOfMailings: null, startDate: null };
        if (orderFlowDetails.dropDetails) {
            if (orderFlowDetails.dropDetails.startDate)
                multipleDrop.startDate = orderFlowDetails.dropDetails.startDate.replace('Z', '');
            if (orderFlowDetails.dropDetails.frequency)
                multipleDrop.frequency = orderFlowDetails.dropDetails.frequency;
            if (orderFlowDetails.dropDetails.numberOfMailings)
                multipleDrop.numberOfMailings = orderFlowDetails.dropDetails.numberOfMailings;
        }
        return multipleDrop;
    });

    const showNext = computed(() => {
        if (dropTypeSelected.value == dropType.ONE) {
            return !!startDate.value
        }
        else if (dropTypeSelected.value == dropType.MULTIPLE) {
            return !!(multipleDropValues.value.frequency && multipleDropValues.value.numberOfMailings && multipleDropValues.value.startDate);
        }
        return false;
    });

    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    //OnMounted
    onMounted(async () => {

        const orderStateId = route.params.id
        if (isNotEmpty(orderStateId)) {
            const response = await site.personalizationInfo.getOrderState(orderStateId);
            Object.assign(orderFlowDetails, response.model);
            let startDate = orderFlowDetails?.dropDetails?.startDate;
            if (isNotEmpty(startDate)) {
                startDate = new Date(startDate);
                if (startDate >= minDate.value && startDate <= maxDate.value) {
                    selectedDate.value = formatDate(startDate);
                }
                else {
                    orderFlowDetails.dropDetails.mailingDetails = [];
                }
            }
        }

        if (isNotEmpty(orderFlowDetails.productId)) {
            detail.product = Object.assign(detail.product, await site.products.getProductDetail(orderFlowDetails.productId));
        }
        isLoaded.value = true;

    });


</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />

            <div class="root-page-container personalize-select-drops">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.THREE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5" v-if="isLoaded">

                    <div class="col">

                        <div class="d-flex">
                            <div>
                                <h1>{{dropTypeHeadline}}</h1>
                            </div>
                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>
                        </div>

                    </div>

                </div>
                <template v-if="isLoaded">
                    <div class="row" v-show="hasProductDataType">
                        <div class="col fs-5">
                            <a v-if="dropTypeSelected === dropType.ONE && !hasLessMailingList" class="no-underline" @click="switchToMultiple">Switch to {{switchHeadline}} Drops</a>
                            <a v-if="dropTypeSelected === dropType.MULTIPLE" class="no-underline" @click="switchToOneTime">Switch to {{switchHeadline}} Drop</a>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-6 offset-3 drop-start-container p-5">
                            <OneTimeDrop v-if="dropTypeSelected === dropType.ONE" @change="oneTimeDropDate" :selectedDate="selectedDate" />
                            <MultipleDrops v-if="dropTypeSelected === dropType.MULTIPLE" @change="multipleDropDates"
                                           :multipleDropValues="multipleDropValues" />
                        </div>
                    </div>

                    <div class="row" v-if="dropTypeSelected === dropType.ONE && orderFlowDetails.channelType == ChannelTypes.EMAIL && orderFlowDetails.isMultiChannel == false">
                        <div class="text-center emailMessage">
                            Your email will be sent approximately 1 week after the date selected.
                        </div>
                    </div>

                    <div v-if="dropTypeSelected === dropType.ONE && orderFlowDetails.isMultiChannel">
                        <div class="text-center emailMessage">
                            Emails will be sent approximately 10 days after your Direct Mail date.
                        </div>
                    </div>

                    <div v-if="dropTypeSelected === dropType.ONE && orderFlowDetails.targetCount <= 25">
                        <div class="text-center emailMessage">
                            Note: If you have selected fewer than {{ MinMailingCount }} policyholders, your mailing will go out in a single drop.
                        </div>
                    </div>

                    <div class="row my-5" v-if="showFrequencyDetails">
                        <div class="col-sm-6 offset-sm-3">

                            <div v-if="dropTypeSelected === dropType.MULTIPLE">
                                <p class="fw-bold">Total Count: {{totalQuantity}}</p>
                            </div>

                            <div class="frequency-headline py-2 text-center fs-4 fw-bold mb-2">
                                Frequency Summary
                            </div>

                            <div v-if="mailingDetails.length > 0">

                                <table class="table">

                                    <thead>
                                        <tr class="bg-color-3-lt">
                                            <th class="border-end border-gray-0">Date Drop</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="drop in mailingDetails" :key="drop.mailDate">
                                            <td>{{site.formatDisplayDate(drop.mailDate)}}</td>
                                            <td>{{drop.quantity}}</td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="row my-5">

                        <div class="col">

                            <div class="d-flex">
                                <div class="p-2" v-if="hasLessMailingList">
                                    <router-link :to="`/personalization/step-2/${orderFlowDetails.id}`" class="btn btn-outline-primary">Previous</router-link>
                                </div>
                                <div class="p-2" v-else>
                                    <router-link :to="`/personalization/step-3/${orderFlowDetails.id}`" class="btn btn-outline-primary">Previous</router-link>
                                </div>


                                <div class="ms-auto p-2" v-if="showFrequencyDetails">
                                    <button @click="saveForLater" v-if="isAgencySelected" class="btn btn-primary me-3">Save For Later</button>
                                    <button @click="next" class="btn btn-primary" :disabled="!showNext">Next</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </template>
            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .personalize-select-drops {
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        .drop-start-container {
            background: $color-3-xlt;
        }

        thead {
            background: $color-2;
            color: $gray-4;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 50%;
            }
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }

        .emailMessage {
            color: #B85D00;
        }
    }
</style>
