<script>

    // CalendarPanel
    // Shows the calendar panel of the my account area.

    // Constants
    const constants = {
        eventTypes: [
            {
                id: "ALL", text: 'All Events'
            },
            {
                id: "SCHEDULED_EVENTS", text: 'My Scheduled Events'
            },
            {
                id: "AGENCY_EVENTS", text: 'Agency Level Events'
            },
            {
                id: "NATIONAL_EVENTS", text: 'National Marketing Events'
            }
        ],
        eventCategories: [
            {
                id: "ALL", text: 'All Categories'
            },
            {
                id: "MAIL_DROP", text: 'Mail Drop'
            },
            {
                id: "COLLATERAL_ORDERS", text: 'Collateral Orders'
            }
        ],
        eventClass: [
            {
                id: "NATIONAL", text: 'national'
            },
            {
                id: "AGENCY", text: 'agency'
            },
            {
                id: "PERSONAL", text: 'personal'
            }
        ]
    };

    // Exports
    export const eventTypes = constants.eventTypes;
    export const eventCategories = constants.eventCategories;
    export const eventClass = constants.eventClass;

</script>

<script setup>

    // Imports 
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import VueCal from 'vue-cal';
    import 'vue-cal/dist/vuecal.css';
    import AddEventForm from '../forms/AddEventForm';

    // Component
    import AddEvent from './AddEvent';
    
    // State 
    const eventForm = reactive(new AddEventForm());

    const type = ref('ALL');

    const category = ref('ALL');

    const calendar = reactive([]);

    const showEvents = ref(true);

    // Methods 
    function addEvent() {
        showEvents.value = !showEvents.value;
    }

    async function submitEvent() {
        const response = await site.postCalendarEvent(eventForm.model);
    }


    // Computed
    const events = computed(() => {

        let response = [];

        if (type.value != 'ALL' || category.value != 'ALL') {

            calendar.forEach(x => {

                let i = 0;

                if (x.type == type.value) {
                    i++;
                }

                if (x.category == category.value) {
                    i++;
                }

                if (i >= 1) {
                    response.push(x);
                }
            });

            return response;
        }

        return calendar;
    });

    // Computed
    const buttonText = computed(() => {
        return showEvents.value == true ? "Add Event" : "Cancel Add Event";
    });


    // Lifecycle Hooks
    onMounted(async () => {
        const response = await site.getUsersCalendar();
        calendar.push(...response.results);

        eventForm.create();
    });

</script>

<template>
    <div class="account-panel calendar-panel">

        <div class="row mb-5">

            <div class="col">
                <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Calendar</span></h1>
            </div>

            <div class="col text-end">
                <button class="btn btn-primary" @click="addEvent">{{buttonText}}</button>
            </div>

        </div>

        <div v-if="showEvents">

            <div class="row search-container px-2 py-3">

                <div class="col-4">
                    <label class="form-label">Event Type</label>
                    <select class="form-select" v-model="type">
                        <option v-for="event in eventTypes" :key="event.id" :value="event.id">{{event.text}}</option>
                    </select>
                </div>

                <div class="col-4">
                    <label class="form-label">Event Category</label>
                    <select class="form-select" v-model="category">
                        <option v-for="event in eventCategories" :key="event.id" :value="event.id">{{event.text}}</option>
                    </select>
                </div>

                <div class="col-4">

                </div>

            </div>

            <div class="row mb-5">
                <div class="col text-center personal">
                    Personal Events
                </div>

                <div class="col text-center agency">
                    Agency Event
                </div>

                <div class="col text-center national">
                    National Marketing Event
                </div>
            </div>

            <div class="row">

                <div class="col">

                    <vue-cal events-on-month-view="short"
                             active-view="month"
                             :disable-views="['years', 'year']"
                             :events="events"
                             style="height: 800px" />

                </div>

            </div>

        </div>

        <!-- Add Event View -->
        <div v-else>

            <div class="row mb-3">
                <div class="col">
                    <AddEvent :form="eventForm" />
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button class="btn btn-primary " @click="submitEvent" :disabled="eventForm.status.isInvalid">
                        Add Event
                    </button>
                </div>
            </div>

        </div>



    </div>
</template>

<style lang="scss">
    .calendar-panel {
        label {
            font-weight: $bold;
        }

        .search-container {
            background: $gray-2;
        }

        .vuecal__event.national, .national {
            background-color: $color-1-lt;
            border: $color-1;
            color: #fff;
        }

        .vuecal__event.agency, .agency {
            background-color: $color-2-lt;
            border: $color-2;
            color: $gray-4;
        }

        .vuecal__event.personal, .personal {
            background-color: $highlight-2;
            border: $highlight-1;
            color: $gray-4;
        }
    }
</style>