<script setup>

    // UploadRecipients
    // This will accept a drag and drop of file or a select file box that can upload multiple files.

    // Constants
    const fileFormats = {
        XLS: 'application/vnd.ms-excel',
        XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        CSV: 'text/csv'
    }

    // Imports
    import { ref, defineEmits, defineProps } from 'vue';
    import { site } from '@/Site';

    // State
    const active = ref(false);
    const isInvalidFileType = ref(false);
    const uploadFailed = ref(false);
    const fileName = ref('');

    const props = defineProps({
        productId: {
            type: String,
            required: true
        }
    });

    const acceptedUploadFormats = ref(Object.values(fileFormats));

    // Methods
    async function uploadFile(e) {

        const formData = new FormData();
        
        let files;

        if (e.type == 'change') {
            files = e.target.files;
        }

        if (e.type == 'drop') {
            files = e.dataTransfer.files;
        }
        
        for (let file of files) {

            if (acceptedUploadFormats.value.indexOf(file.type) == -1) {
                isInvalidFileType.value = true;
                return;

            } else {
                isInvalidFileType.value = false;
                fileName.value = file.name;
                formData.append('file', file);
                formData.append('productId', props.productId);
            }
        }

        active.value = false;

        const response = await site.personalizationInfo.uploadRecipients(formData);
        if (response)
            uploadFailed.value = false;
        else
            uploadFailed.value = true;

        emits('uploadedList', response);

    }

    const toggleActive = () => {
        active.value = !active.value;
    }

    // Emits ----
    const emits = defineEmits(['uploadedList']);

</script>

<template>

    <div class="file-drop-zone">
        <div class="row mb-2 invalid-file-format" v-if="isInvalidFileType">
            <div class="invalid-file-message p-2">
                <span><strong>File format not accepted</strong> </span>
                <span>Accepted file formats are .xlsx, .xls & .csv  </span>
            </div>
        </div>
        <div class="row mb-2 invalid-file-format" v-else-if="uploadFailed">
            <div class="invalid-file-message p-2">
                <span>
                    There was an error with your file upload. Please download the appropriate list template below for your list type
                    and ensure the fields and column titles match and entries are provided.
                </span>
            </div>
        </div>
        <div class="row">
            <div class="file-upload mb-2">
                <input class="dropzone"
                       @dragenter.prevent="toggleActive"
                       @dragleave.prevent="toggleActive"
                       @dragover.prevent
                       @drop.prevent="uploadFile($event)"
                       :class="{ 'active-dropzone' : active }" placeholder="Drop files here" />
                <label class="btn btn-primary mx-2" for="formFile">Browse</label>
                <input @change="uploadFile($event)" type="file" id="formFile" :accept="acceptedUploadFormats">
            </div>
            <div class="mb-2">
                <label for="dropfile" class="accepted-file"> (Accepted file formats are .xlsx, .xls & .csv)</label>
            </div>
            <div class="mb-2">
                <a class="download-link" href="/downloads/Upload-List-Template.xlsx">Download sample list template(xlsx)</a><br />
                <a class="download-link" href="/downloads/Upload-List-Template.xls">Download sample list template(xls)</a><br />
                <a class="download-link" href="/downloads/Upload-List-Template.csv">Download sample list template(csv)</a><br />
            </div>
            <div class="my-2 text-success" v-if="fileName && !uploadFailed">
                {{fileName}} Has been loaded
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .file-drop-zone {

        .show-center {
            padding-left: 12px;
            padding-right: 12px;
        }

        .dropzone {
            border: 1px solid $color-1;
            display: inline-flex;
            padding: 6px 12px;
        }

        #formFile {
            display: none;
        }

        .active-dropzone {
            background: $color-1;
        }

        .invalid-file-format {
            padding: 6px 12px;
        }

        .invalid-file-message {
            display: inline-grid;
            color: red;
            border: 1px solid red;
        }
    }

    .accepted-file {
        color: #997870;
        font-size: 12px !important;
    }

    .file-upload {
        display: inline-flex;
    }
    .download-link {
        font-size: 14px;
    }
</style>