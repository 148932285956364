// AgencyDetails
// Services and actions related to agencies.

// Imports ----
import { reactive, ref } from 'vue';

const INIT_AGENCY_ID_KEY = 'agencyId';

export default class AgencyDetails {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;

        this.agencyDefaultId = ref(null);

        this.defaultAgency = reactive({
            id:0,
            name:'',
            statCode: '',
            isOwnerMaster: false,
            isLicenseNumberRequired: false
        });

        this.selectedAgencyDetails = reactive({});

        this.isAgencySelectedOnce = ref(false)

        this.selectedAgencyIgniteDetails = reactive({
            "discountShortCode": "",
            "discountCode": "",
            "discountName": null,
            "discountDescription": "",
            "discountValue": 0,
            "usage": 0,
            "remaining": 0,
            "statId": "",
            "igniteStatus": "",
            "subTier": "",
            "reductionRate": 0.0
        });

        store.onSignedIn(() => {
            this.loadDefaultAgency();
        });
    }


    // #region Getters & Setters ----

    // #endregion



    // #region Public Methods ----
    /**
     * Goes out and gets the current agency ID
     */
    async loadDefaultAgency() {
        const response = await this.store.api.get(`/site/user/current/agency`);

        this.agencyDefaultId.value = response.agencyId;
        this.defaultAgency.id = response.agencyId;
        this.defaultAgency.name = response.agencyName;
        this.defaultAgency.statCode = response.statId;
        this.defaultAgency.isOwnerMaster = response.isOwnerMaster;
        this.defaultAgency.isLicenseNumberRequired = response.isLicenseNumberRequired;

        if (this.agencyDefaultId.value > 0) {
            this.loadAgencyDetails();
            this.loadAgencyIgniteDetails();
        } else {
            //reset the selected agency
            // reactive was not wotking so had to delete each property of an object to reset the state
            // TODO: research why replacing with {} was not working
            Object.keys(this.selectedAgencyDetails).forEach((key) => {
                delete this.selectedAgencyDetails[key];
            })
        }
    }

    /**
     * get the selected agncy details 
     */
    async loadAgencyDetails() {

        Object.assign(this.selectedAgencyDetails, await this.store.api.get(`/site/user/current/agency-detail`));
            
    }

    /**
     * get the selected agency ignite details
     */
    async loadAgencyIgniteDetails() {

        Object.assign(this.selectedAgencyIgniteDetails, await this.store.api.get(`/site/user/current/agency-ignite-detail`));
        return this.selectedAgencyIgniteDetails;
    }

    /**
     * Sets the current agency for the logged in user 
     * @param selectId - The ID of the selected agency 
    */
    async setDefaultAgency(selectId) {
        const payload = { id: selectId }
        const response = await this.store.api.post(`/site/user/current/agency`, payload);

        if (response.succeeded) {
            this.loadDefaultAgency();
        } else {
            this.store.handleApiError(response);
        }
    }

    /**
     * Return to the default agency 
     * @param id - The ID is defaulted to 0 here 
     * @param isDefault - needs to be passed as true 
     */
    async returnToDefault(payload) {
        const response = await this.store.api.post(`/site/user/current/agency`, payload);
        if (response.succeeded) {        
            this.loadDefaultAgency();
        } else {
            this.store.handleApiError(response);
        }
    }

    /**
     * adds an email preference for user agency.
     * @param addAgencyPayload - The details of the email that we are creating 
     * @param companySegmentId - ID that is passed to create the payload 
     */
    async addAgencyEmail(addAgencyPayload, companySegmentId) {

        let request = {
            companySegmentId: companySegmentId,
            name: addAgencyPayload.name,
            email: addAgencyPayload.email
        }

        return await this.store.api.post(`/site/user/current/add-agency-email`, request);

    }

    /**
     * updates an email preference for user agency.
     * @param editAgencyPayload - An object of the details of the email being edited 
     */
    async updateAgencyEmail(editAgencyPayload) {

        let request = {
            emailId: editAgencyPayload.emailId,
            name: editAgencyPayload.name,
            email: editAgencyPayload.email
        }

        return await this.store.api.put(`/site/user/current/update-agency-email`, request);
    }

    /**
    * deletes an email preference for user agency.
    * @param emailId - the id of the agency email we are deleting 
    */
    async deleteAgencyEmail(emailId) {

        return await this.store.api.delete(`/site/user/current/delete-agency-email/` + emailId);
    }

    async getAgencies(payload) {
        return await this.store.api.post(`/site/agencies/list`, payload);
    }

    removeInitialAgencyId() {
        localStorage.removeItem(INIT_AGENCY_ID_KEY);
    }
    storeInitialAgencyId(agencyId) {
        localStorage.setItem(INIT_AGENCY_ID_KEY, agencyId);
    }
    getInitialAgencyId() {
        return localStorage.getItem(INIT_AGENCY_ID_KEY);
    }


    async getAgencySubCodes(oppId) {
        return await this.store.api.get(`/site/user/current/agencies-by-ownermaster-agency?crossSellTypeCode=${oppId}`);
    }

    async getAgencyDetails(statCode) {
        return await this.store.api.get(`/site/agency/${statCode}/detail-statcode`);
    }

    async updateAgencyAddress(agencyAddress) {
        return await this.store.api.put(`/site/agency-address/${agencyAddress.id}`, agencyAddress);
    }
    // #region Private Methods ----

    // #endregion
}

