<script setup>

    // CustomizableDownloadStep1Page
    // Step 1 of 2 on customizing a downloadable piece 

    // Imports
    import { ref, reactive } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import CustomizableDownloadStepper, { stepCounter } from '@/site/components/CustomizableDownloadStepper';
    import ModalBox from '@/site/components/ModalBox';
    import UserImageLibrary from '@/site/areas/image-library/UserImageLibrary';
    import ImageDropZone from '@/site/areas/image-library/ImageDropZone';
    import PdfViewer from '@/site/components/PdfViewer';

    // State
    const selectedImageId = ref('');

    const approvals = reactive({
        'finalApproval': false,
        'termsAndConditions': false,
        'noUseInMass': false
    });

    // TODO: this will come from the API
    const pdfDetails = {
        "fileUrl": "/downloads/SafecoMSC-Relationship-Marketing-Cards.pdf",
        "totalPages": 10
    }

    // Methods
    function previewPdf() {
        // TODO: Call the API to re-render the PDF
    }

    function setSelectedImage(id) {
        selectedImageId.value = id;
    }

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <ModalBox modalId="imageLibrary" title="Select from Image Library" modalSize="modal-xl">
                <template v-slot:body>

                    <UserImageLibrary @selectImage="setSelectedImage" />

                </template>
            </ModalBox>

            <ModalBox modalId="imageDropZone" title="Upload Image" modalSize="modal-lg">
                <template v-slot:body>

                    <ImageDropZone />

                </template>
            </ModalBox>

            <GlobalTabBar />

            <div class="root-page-container print-on-demand-customize-step1 pb-5">


                <div class="row">

                    <div class="col">

                        <CustomizableDownloadStepper :currentStep="stepCounter.ONE" />

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col border-bottom border-color-1">

                        <div class="d-flex">
                            <div class="text-color-1">
                                <h2 class="mb-0">Personalize</h2>
                            </div>
                            <div class="ms-auto">
                                <!-- TODO: Add the correct product name from the API -->
                                Insurance Check Up Form (Customizable) Print On Demand (AP-3967P)
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row">

                    <div class="col-sm-4">

                        <h2 class="border-bottom border-color-1">Agent Image (Optional)</h2>

                        <button @click="openImageLibrary" class="btn btn-outline-primary me-3" data-bs-toggle="modal" data-bs-target="#imageLibrary">
                            Image Library
                        </button>

                        <button class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#imageDropZone">
                            Upload New Image
                        </button>

                        <h2 class="mt-5 border-bottom border-color-1">Preview & Approve</h2>

                        <button class="btn btn-outline-primary mt-2" @click="previewPdf">
                            Preview PDF
                        </button>

                        <!-- TODO: Probably need the form components for these -->
                        <div class="form-check mt-4">
                            <input class="form-check-input" type="checkbox" v-model="approvals.finalApproval" id="finalApproval">
                            <label class="form-check-label" for="finalApproval">
                                PDF Preview Content <strong class="text-error">Final Approval</strong>.
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="approvals.termsAndConditions" id="termsAndConditions">
                            <label class="form-check-label" for="termsAndConditions">
                                <!-- TODO: link to the correct T&C -->
                                View and Accept <a href="#TODO">Terms and Conditions</a>.
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="approvals.noUseInMass" id="noUseInMass">
                            <label class="form-check-label" for="noUseInMass">
                                I Certify that <strong class="text-error">I Will Not</strong> use this Item in the State of Massachusetts.
                            </label>
                        </div>

                        <div class="my-5">
                            <!-- TODO: Not sure what happens when you click cancel -->
                            <button class="btn btn-outline-primary me-3">
                                Cancel
                            </button>
                            <!-- TODO: Using the form validation make this button enabled based on checkboxes above-->
                            <router-link to="/customizable-download-step2" class="btn btn-primary">
                                Next
                            </router-link>
                        </div>


                    </div>

                    <div class="col-sm">
                        <PdfViewer :pdfDetails="pdfDetails" :height="700" />
                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .customizable-download-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }
    }
</style>