<script setup>

    // IconPanel
    // Icon Panel for use when we have an icon in the header of the panel
    import { onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';

    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
        const redirectUrl = route.query.redirectUrl
        if (redirectUrl) {
            router.push({
                path: redirectUrl,
                query: { ...route.query }
            });
        }
        else {
            router.push("/");
        }
    });

</script>
<template>
    <div>

    </div>
</template>

<style lang="scss">
</style>
