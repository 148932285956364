<script setup>

    // FavoritesPanel
    // Shows the favorites panel of the my account area.

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 12
    }

    // Components ----
    import ProductGridTile from '@/site/areas/products/components/ProductGridTile';
    import ProductListTile from '@/site/areas/products/components/ProductListTile';

    // Imports ----
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { reactive, ref, computed } from 'vue';
    import { site } from '@/Site'
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { getProgramTypeForOrderState, getOppIdForOrderState } from '@/constants'
    import { isNotEmpty } from '@/util'

    const router = useRouter();

    //import { site } from '@/Site';
    // State ----
    const search = new Search('product-list', 'detail', 'product-list-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);
    search.setFilterValue('product-state-restriction', '');
    search.setFilterValues('product-explore', ['Favorite']);

    const sortOptions = [
        { id: 'product-list-newest-to-oldest', text: 'Newest to Oldest' },
        { id: 'product-list-oldest-to-newest', text: 'Oldest to Newest' },
        { id: 'product-list-itemNumber', text: 'Asset Number (A-Z)' },
        { id: 'product-list-itemNumber-desc', text: 'Asset Number (Z-A)' },
        { id: 'product-list-name', text: 'Asset Name (A-Z)' },
        { id: 'product-list-name-desc', text: 'Asset Name (Z-A)' },
        { id: 'ignite-eligible-first', text: 'Ignite eligible first' }
    ];

    const pageSizeOptions = [12, 24, 36, 48, 60];

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    async function selected(id) {

        const product = search.results.rows.find(p => p.id == id);

        if (product.isExpressOrderingAllowed && agencyDetails.value.isOwnerMaster) {
            const oppId = getOppIdForOrderState(product.dataListOption);
            const allStatCodes = await site.agencyDetails.getAgencySubCodes(oppId);

            const ownerMasterPolicyCount = allStatCodes.find(a => a.statId == agencyDetails.value.statId)?.policyHoldersCount;
            const subcodesHasPolicyHolders = allStatCodes.filter(a => a.statId != agencyDetails.value.statId && a.policyHoldersCount > 0);

            if (ownerMasterPolicyCount && subcodesHasPolicyHolders?.length > 0) {
                router.push({
                    name: 'ExpressOrderConfirmationPage',
                    params: {
                        productId: product.id
                    }
                });
                return;
            }
        }

        if (!site.errorHandler.canStartOrder()) {
            return false;
        }
        orderFlowDetails.productId = id;
        const programType = getProgramTypeForOrderState(product.dataListType);
        if (programType) {
            orderFlowDetails.programType = programType;
            orderFlowDetails.productType = product.productTypes?.join(";");
            orderFlowDetails.oppId = getOppIdForOrderState(product.dataListOption);
            let channelType = ChannelTypes.DIRECT_MAIL;
            if (product.isEmail) {
                channelType = ChannelTypes.EMAIL;
            }

            let response = await site.personalizationInfo.startOrder(channelType);
            if (site.errorHandler.handleStartOrderError(response)) {
                router.push({
                    name: 'PersonalizeStep1Page',
                    params: {
                        id: response.id,
                        parentId: response.id,
                        productId: orderFlowDetails.productId
                    },
                    query: {
                        channelType: ChannelTypes.DIRECT_MAIL,
                        isSubscription: product.isSubscription,
                        searchType: 'favorite'
                    }
                });
            }
        }
        else {
            console.error("Not able to identify the Program type based on the data list type");
        }

    }

    const eligibleToCustomize = computed(() => {
        if (isNotEmpty(agencyDetails.value?.statId)) {
            return true;
        }
        const adminRoles = site.userRoles.getRolesForAdminAccess();
        var rec = site.user.roles.filter(r => adminRoles.includes(r.id));
        return rec?.length > 0;
    });

    async function customDownloadSelected(id) {

        if (!eligibleToCustomize.value) {
            site.toast.error("The user's current agency cannot be found, or they're not assigned to a current agency.");
            return;
        }

        orderFlowDetails.productId = id;

        router.push({
            name: 'CustomizeDownloadPage',
            query: {
                productId: id,
            }
        });
    }


    async function downloadSelected(url, fileName) {
        if (url) {
            await site.downloadHandler.handleDownload(url, fileName, true);
        }
        else {
            site.toast.error('Error while downloading the pdf. please contact system administrator', 'Error');
        }
    }

</script>

<template>
    <div class="account-panel">
        <h1 class="cart-page-header mb-3"> My Account <span class="text-muted">/ My Favorites </span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="false">

            <div class="row mb-3">
                <div class="col"><SortOptionsList :options="sortOptions" /></div>
                <div class="col"><PageSizeList :options="pageSizeOptions" /></div>
                <div class="col"><ViewModeSelector /></div>
                <div class="col-4 text-end"><PageSelector /></div>
            </div>

            <SearchGrid>
                <!-- Template for each item in a grid view -->
                <template v-slot:grid="product">

                    <ProductGridTile :product="product" @productSelected="selected" @customDownloadSelected="customDownloadSelected" @downloadSelected="downloadSelected" />

                </template>

                <!-- Template for each item in a list view -->
                <template v-slot:list="product">

                    <ProductListTile :product="product" @productSelected="selected" @customDownloadSelected="customDownloadSelected" @downloadSelected="downloadSelected" />

                </template>

                <!-- Template for no results -->
                <template #empty>
                    No products were found that match your criteria.
                </template>

            </SearchGrid>

            <div class="row">
                <div class="col text-end fit-content"><PageSelector /></div>
            </div>

        </SearchContainer>

    </div>
</template>

<style lang="scss">
</style>