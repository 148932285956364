<script setup>

    // WhatToDoPanel
    // WhatToDoPanel is a common panel used in a few places with the headline What would you like to do?
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: false,
            default: 'What would you like to do?'
        }
    });

</script>

<template>

    <div class="what-to-do">

        <div class="card">

            <div class="card-body">

                <h2 class="card-title">{{props.title}}</h2>

                <slot></slot>

            </div>

        </div>

    </div>

</template>

<style lang="scss">
    .what-to-do {

        h2.card-title{
            font-weight: $normal;
        }
    }
</style>