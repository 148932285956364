<script setup>

    //Select recipients

    // Imports ----
    import { defineProps, ref, reactive, computed, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';

    import { site } from '@/Site';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';

    //import { isNotEmpty } from '@/util';

    const router = useRouter();
    // Props ----
    const props = defineProps({

        mailListId: {
            type: String,
            required: false,
            defalut: {}
        },
        product: {
            type: Object,
            required: true,
        },
        orderState: {
            type: Object,
            required: true
        },
    });


    const emits = defineEmits(['next']);

    // TODO: Return fixed pagination with correct page numbers.
    // Fix SearchTable to save state when going through pages.
    //// Constants ----
    //const constants = {
    //    DEFAULT_PAGE_SIZE: 25
    //}

    //const pageSizeOptions = reactive([25, 50, 100, 200]);

    const columns = [
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'addressLine1', title: 'Address Line 1', isSortable: true },
        { field: 'addressLine2', title: 'Address Line 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'postalCode', title: 'Zip Code', isSortable: true },
        { field: 'phone', title: 'Phone', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true }
    ];

    const searchTerm = ref('mail-list-contact-firstName');
    let sortPrefix = 'mail-list-contact-';

    let selectedList = reactive([]);

    //const isLoaded = ref(false);

    // State
    const search = new Search('mail-list-contact', 'row', 'mail-list-contact-firstName', null);

    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    const showNext = computed(() => {
        return listLength.value > 0;
    });

    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    async function next() {
        await addMailContacts();
        router.push({
            name: 'PersonalizeStep3Page',
            params: { id: props.orderState.id }
        });
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        await addMailContacts();
        router.push({
            name: 'Home',
        });
    }

    async function addMailContacts() {

        const allRecords = search.results.rows;
        const selected = allRecords.filter(sl => selectedList.includes(sl.mailListContactId));

        //reseting the previously selected value for prospecting values with zipcode selection.
        await site.personalizationInfo.resetProspectingZipCodes();
        const isOwnListUploaded = true;
        await site.personalizationInfo.addMailContacts(selected, isOwnListUploaded, props.product.unitPrice);

    }

    function back() {
        emits('next', 'UPLOAD');
    }


    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = sortPrefix + value;
            } else {
                searchTerm.value = sortPrefix + value + '-desc';
            }

        } else {
            searchTerm.value = sortPrefix + value;
        }

        search.setSortId(searchTerm.value);
    }

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    //methods
    function selectAll(isSelectAll, list) {
        selectedList.length = 0;
        if (isSelectAll)
            Object.assign(selectedList, list?.map(mc => mc.mailListContactId));
    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
    }

    search.onResults(() => {
        if (props.orderState?.mailContacts?.length == 0) {
            selectedList.length = 0;
            Object.assign(selectedList, search.results?.rows?.map(mc => mc.mailListContactId));
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const currentSelectedList = props.orderState?.mailContacts.map(mc => mc.mailListContactId);
            const filteredSelectedList = search?.results?.rows?.filter(mc => currentSelectedList.includes(mc.mailListContactId));
            if (filteredSelectedList.length > 0) {
                Object.assign(selectedList, filteredSelectedList.map(mc => mc.mailListContactId));
            }
            else {
                selectedList.length = 0;
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.mailListContactId));
            }
        }
    });

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0"> Select Records from Agency List</h2>
                </div>
                <div class="ms-auto">
                    {{props.product.name}} ({{props.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row mb-5 mt-2">
        <div class="col">

            <div class="row">
                <div class="col">
                    <h4> Finalize Mailing List</h4>
                    <p>Uncheck any individual record you wish to not mail.</p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <SearchContainer :search="search" default-view="list" :deep-link="false">

                        <!-- Hidden but used for filtering on maillistid selected-->
                        <TextBoxFilter v-show="false" filter-id="mail-list-id" :initial-value="props.mailListId" />
                        <TextBoxFilter v-show="false" filter-id="mail-list-restriction" :initial-value="props.product.id" />

                        <div class="row mb-2">
                            <div class="d-flex">
                                <div class="me-auto">
                                    <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                                </div>
                                <!--<div class="px-3">
                                    <PageSizeList :options="pageSizeOptions" />
                                </div>
                                <div>
                                    <div>
                                        <span v-if="search.results.loaded && search.results.rows.length > 0">{{ search.results.startIndex }} to  {{ search.results.endIndex }} of {{ search.results.totalCount }} results</span>
                                    </div>
                                    <PageSelector />
                                </div>-->
                            </div>
                        </div>
                        <div class="data-table">
                            <SearchTableV1 :columns="columns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="mailListContactId" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList" :showInvalid=true>

                                <!-- Template for no results -->
                                <template #empty>
                                    No
                                    <span v-html="listHeadline"></span> Contact Lists were found that match your criteria.
                                </template>

                                <!-- Template while results are initially loading -->
                                <template #loading>
                                    <div class="row">
                                        <div class="col text-center">
                                            <div class="spinner-border me-3" role="status"></div>
                                            or
                                            <div class="spinner-border me-3" role="status"></div>
                                            e-
                                            <span class="fw-bold">Performing a search for the data</span>
                                            <div class="spinner-border mx-3" role="status"></div>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                    </div>
                                    <TableSkeleton />
                                </template>
                            </SearchTableV1>
                        </div>

                        <!--<div class="row">
                            <div class="col text-end fit-content"><PageSelector /></div>
                        </div>-->

                    </SearchContainer>
                </div>
            </div>

            <div class="row my-5">
                <div class="d-flex mb-3">
                    <div class="me-auto">
                        <button class="btn btn-outline-primary" @click="back">Previous</button>
                    </div>
                    <div class="d-flex">
                        <div class="mx-5"><button v-if="isAgencySelected" @click="saveForLater" class="btn btn-primary">Save For Later</button></div>
                        <button class="btn btn-primary" @click="next" :disabled="!showNext">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .address-list {
        .title {
            font-size: inherit;
        }

        .table td.abbreviation {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .table td.abbreviation {
            max-width: 100px;
        }

        .InValid {
            background-color: lightcoral;
        }

        .mw-100 {
            min-width: 100%;
        }

        .passedRecords {
            color: green;
        }

        .failedRecords {
            color: red;
        }
    }
</style>