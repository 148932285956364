<script setup>

    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // Imports ----
    import { onMounted, reactive, computed, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { site } from '@/Site';
    import { isNotEmpty, formatStatCode } from '@/util';
    import { Util } from "@dd-nucleus/nucleus-vue";
    import { useRouter } from 'vue-router';

    const bulkOrderState = reactive({});
    const route = useRoute();
    const router = useRouter();
    const detail = reactive({ product: {} });
    const allSubCodes = reactive([]);
    const selectedAgencySubCodes = reactive([]);
    const isLoaded = ref(false);
    const iframeSrc = ref('');
    const addingToCart = ref(false);
    const selectedDocumentId = ref('');

    const mailingDetails = computed(() => {
        if (bulkOrderState?.dropDetails?.mailingDetails && bulkOrderState?.dropDetails?.mailingDetails.length > 0) {
            return bulkOrderState?.dropDetails?.mailingDetails;
        }
        return [];
    })



    const totalOrderCount = computed(() => {
        if (bulkOrderState.orderStates != undefined) {
            return bulkOrderState.orderStates.reduce((sum, orderState) => sum + orderState.targetCount, 0);
        }
        return 0;
    })

    const totalOrderPrice = computed(() => {
        if (bulkOrderState.orderStates != undefined) {
            return bulkOrderState.orderStates.reduce((sum, orderState) => sum + orderState.totalPrice, 0.0);
        }
        return 0;
    });

    const canAddToCart = computed(() => {
        return !addingToCart.value
    });


    function previous() {
        router.push({
            name: 'ExpressOrderStepThree',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId,
            }
        });
    }

    function takeToStepTwo() {
        router.push({
            name: 'ExpressOrderStepTwo',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId,
            }
        });
    }

    const previewPdf = ((documentId) => {

        if (isNotEmpty(documentId) && isNotEmpty(site.user.userName)) {
            selectedDocumentId.value = documentId;
            const userName = site.user.userName.replace(".Test@test.com", '');
            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;
            iframeSrc.value = `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${documentId}&UserName=${userName}`;
        }
    });

    function previewPdfInNewWindow(documentId) {
        site.personalizationInfo.openPdfPreview(documentId);
    }

    async function addToCart() {
        
        addingToCart.value = true;
        const response = await site.personalizationInfo.addToCart(bulkOrderState.productId, totalOrderCount.value);
        if (response.succeeded) {
            site.toast.success(detail.product.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }
        addingToCart.value = false;
    }

    async function checkout() {
        if (!site.errorHandler.canStartExpressOrder()) { return false }
        await addToCart();

        router.push({
            name: 'CartPage'
        });
    }

    onMounted(async () => {
        var bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
        }

        // Call Product
        if (isNotEmpty(bulkOrderState.productId)) {
            detail.product = Object.assign(detail.product, await site.products.getProductDetail(bulkOrderState.productId));
        }
        if (isNotEmpty(bulkOrderState.oppId)) {
            Object.assign(allSubCodes, await site.agencyDetails.getAgencySubCodes(bulkOrderState.oppId));
            const agencySubcodesDetails = bulkOrderState?.subCodesDetails;
            agencySubcodesDetails?.forEach(sd => {
                const agency = allSubCodes.find(asc => asc.statId == sd.statCode);
                const orderState = bulkOrderState?.orderStates?.find(asc => asc.statCode == sd.statCode);
                selectedAgencySubCodes.push({
                    statCode: sd?.statCode,
                    name: agency?.agencyName,
                    targetCount: orderState?.targetCount,
                    documentId: orderState?.pageFlexDocumentId,
                    price: site.convertNumberToCurrency(orderState?.totalPrice)
                })
            });
        }
        isLoaded.value = true;
        if (selectedAgencySubCodes?.length > 0) {
            selectedDocumentId.value = selectedAgencySubCodes[0].documentId;
            previewPdf(selectedDocumentId.value)
        }

    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar />
            <div class="root-page-container step-4">
                <div class="row">
                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.FOUR" />
                    </div>
                </div>
                <template v-if="isLoaded">
                    <div class="row border-bottom border-gray-2 mt-5">

                        <div class="col">

                            <div class="d-flex">

                                <div>
                                    <h1>Review & Add to Cart</h1>
                                </div>

                                <div class="ms-auto">
                                    {{ detail.product.name }} ({{ detail.product.itemNumber }})
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="row mt-5">

                        <div class="col-sm-6">

                            <div class="mb-3">

                                <div class="fw-bold">Item</div>
                                <div class="text-color-2">
                                    {{ detail.product.name }}
                                </div>

                            </div>

                            <div class="mb-3">

                                <div class="fw-bold">Item ID</div>
                                <div class="text-color-2">
                                    {{ detail.product.itemNumber }}
                                </div>

                            </div>

                            <div class="mb-3">

                                <div class="fw-bold">Personalization <router-link :to="`/expressorder/step-1/${bulkOrderState.id}`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>

                            </div>

                            <div class="mb-3">

                                <div class="fw-bold">
                                    Total Order Count
                                    <span @click="takeToStepTwo" class="btn btn-outline-primary btn-sm">
                                        <i class="bi bi-pencil-fill "></i> Edit
                                    </span>
                                </div>

                                <div class="text-color-2">
                                    {{ totalOrderCount }}
                                </div>

                            </div>

                            <div>
                                Note: Turn off Pop-up Blocker to allow for PDF previewing.
                            </div>


                            <div>
                                <table class="table">
                                    <thead>
                                        <tr class="bg-color-3-lt">
                                            <th class="border-end border-gray-0"></th>
                                            <th class="border-end border-gray-0">Sub Code</th>
                                            <th class="border-end border-gray-0">Agency Name</th>
                                            <th class="border-end border-gray-0">Quantity</th>
                                            <th class="border-end border-gray-0">Price</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="item in selectedAgencySubCodes" :key="item.id" class="border-bottom border-gray-2">
                                            <td><a href="#" @click="previewPdfInNewWindow(item.documentId)">PDF Preview</a></td>
                                            <td>{{ formatStatCode(item.statCode) }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.targetCount }}</td>
                                            <td>{{ item.price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="frequency-headline py-2 fs-4 text-center mb-1 mt-5">
                                Summary
                            </div>

                            <div>
                                <table class="table">
                                    <thead>
                                        <tr class="bg-color-3-lt">
                                            <th class="border-end border-gray-0">Date Drop</th>
                                            <th class="border-end border-gray-0">Quantity</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr class="border-bottom border-gray-2" v-for="drop in mailingDetails" :key="drop.mailDate">
                                            <td>{{Util.date(drop.mailDate, false)}}</td>
                                            <td>{{drop.quantity}}</td>
                                            <td>{{site.convertNumberToCurrency(drop.price)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mt-2 d-flex fw-bold">
                                <div class="price-message">Pricing prior to potential applied discount.</div>
                                <div class="m-auto">Total: {{site.convertNumberToCurrency(totalOrderPrice)}}</div>
                            </div>

                            <div class="my-5">

                                <button @click="previous" class="btn btn-outline-primary me-3">
                                    Previous
                                </button>

                                <button @click="checkout" class="btn btn-primary" :disabled="!canAddToCart">
                                    Add to Cart
                                </button>

                            </div>

                        </div>

                        <div class="col-sm-5 offset-1">
                            <div class="row">
                                <div class="col-sm-8">
                                    <select v-model="selectedDocumentId" class="n-page-size-list form-select">
                                        <option v-for="item in selectedAgencySubCodes" :value="item.documentId" :key="item.id">{{ formatStatCode(item.statCode) }} - {{item.name}}</option>
                                    </select>
                                </div>
                                <div class="col-sm-4">
                                    <button class="btn btn-primary" @click="previewPdf(selectedDocumentId)">Preview</button>
                                </div>
                            </div>
                            <div class="mt-5">
                                <iframe if="iframeSrc.length > 0" :src="iframeSrc" scrolling="yes" width="100%" height="800px" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .step-4 {

        .btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead {
            background: $color-2;
            color: $gray-4;
            border-color: $gray-0;
        }

        .price-message {
            color: $error;
            font-size: .75rem;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 26%;
            }
        }

        .text-color-2 {
            color: $color-2;
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }
    }
</style>