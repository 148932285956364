<script setup>

    // PrintOnDemandCustomizeStep1Page
    // Step 3 of 3 on customizing a print on demand piece

    // Imports 
    import { ref, reactive, computed } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ThreeStepStepper, { stepCounter } from '@/site/components/ThreeStepStepper';
    import PdfViewer from '@/site/components/PdfViewer';

    // State
    let showQty = ref(true);

    // TODO: Sounds like this needs to come from the product record as each product can be unique with these steps
    let quantitySteps = reactive({
        'min': 25,
        'max': 500,
        'step': 25,
        'value': 100,
        'priceEach': 0.25
    });

    // TODO: this will come from the API
    let pdfDetails = {
        "fileUrl": "/downloads/SafecoMSC-Relationship-Marketing-Cards.pdf",
        "totalPages": 10
    }

    // Methods
    function addToCart() {
        // TODO: Call the API to call the customized POD product to the cart
    }

    function previewPdf() {
        // TODO: Call the API to get a preview PDF 
    }

    function changeQty() {
        showQty.value = false;
    }

    function saveQty() {
        showQty.value = true;
    }

    // Computed
    const priceEstimate = computed(() => {
        let total = quantitySteps.priceEach * quantitySteps.value;
        return '$' + Math.trunc(total * 100) / 100;
    });


    

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar/>

            <div class="root-page-container print-on-demand-customize-step3">


                <div class="row">

                    <div class="col">

                        <ThreeStepStepper :currentStep="stepCounter.THREE"/>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col border-bottom border-color-1">

                        <div class="d-flex">
                            <div class="text-color-1">
                                <h2 class="mb-0">Review & Add to Cart</h2>
                            </div>
                            <div class="ms-auto">
                                <!-- TODO: Add the correct product name from the API -->
                                Insurance Check Up Form (Customizable) Print On Demand (AP-3967P)
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row">

                    <div class="col-sm-4">

                        <div class="mb-1 fw-bold">
                            Product
                        </div>

                        <div class="mb-3">
                            <!-- TODO: Add the correct product name and link -->
                            <router-link to="#TODO" class="no-underline">
                                Insurance  Check Up Form (Customizable) Print On Demand
                            </router-link>
                        </div>

                        <div class="mb-1 fw-bold">
                            Product ID
                        </div>

                        <div class="mb-3">
                            <!-- TODO: Add the correct product name and link -->
                            <router-link to="#TODO" class="no-underline">
                                AP-3967P
                            </router-link>
                        </div>

                        <div class="mb-1 fw-bold">
                            Total Order Count
                            <i @click="changeQty" v-if="showQty" class="bi bi-pencil-fill text-color-2-dk"></i>
                            <i @click="saveQty" v-else class="bi bi-save-fill text-color-2-dk"></i>
                        </div>

                        <div class="mb-5 text-color-2-dk" v-if="showQty">
                            {{quantitySteps.value}}
                        </div>

                        <div class="mb-5 text-color-2-dk" v-else>
                            <input type="number" class="form-control w-50" :min="quantitySteps.min" :max="quantitySteps.max" :step="quantitySteps.step" v-model="quantitySteps.value">
                        </div>

                        <div class="mb-5">
                            <p>Note: Turn off Pop-up Blocker to allow for PDF previewing.</p>
                            <button class="btn btn-primary" @click="previewPdf">
                                Preview PDF
                            </button>
                        </div>

                        <div class="mb-5">

                            <table class="table">

                                <thead class="bg-color-3-lt">
                                    <tr>
                                        <th class="border-end border-gray-0">Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>

                                <tbody class="table-group-divider">
                                    <tr>
                                        <th>{{quantitySteps.value}}</th>
                                        <td>{{priceEstimate}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        
                        <div class="my-5">
                            <router-link to="/print-on-demand/customize-step-2" class="btn btn-outline-primary me-3">
                                Previous
                            </router-link>
                            <button class="btn btn-primary" @click="addToCart">
                                Add to Cart
                            </button>
                        </div>

                    </div>

                    <div class="col-sm">
                        <PdfViewer :pdfDetails="pdfDetails" :height="700" />
                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .print-on-demand-customize-step3 {
    }
</style>