// PersonalizationInfo
// Services and actions related to personalizing assets 

// Imports ----
import { reactive } from 'vue';
import { site } from '@/Site';
import { ChannelTypes } from '../../constants';

export default class ExpressOrderPersonalization {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;

        this.bulkOrderState = reactive({
            id: '',
            cartId: '00000000-0000-0000-0000-000000000000',
            channelType: null,
            statCode: '',
            oppId: '',
            programType: '',
            productType: '',
            productId: null,
            orderStates: [],
            dropDetails: { // NEW name here for DM type 
                dropType: '', // Or ONE 
                frequency: '',  // or bi-weekly
                numberOfMailings: 0, // or 2 or 3
                startDate: new Date(), // array based on the options above
                mailingDetails: []
            },
            unitPrice: 0.00,
            subCodesDetails: [],
            isSamePersonalization: false,
        });

        store.cart.onCartLoaded(async () => {
            await this.#init();
        });

    }



    /**
      * Add a Personalized item to the cart 
      */
    async addToCart(productId, quantity) {

        return await this.store.api.post(`/api/store/cart/items`, { productId: productId, quantity: quantity });

    }

    async getProductDataType(productId) {

        return await this.store.api.get(`site/product/${productId}/has-product-data-type`);
    }

    /**
     * The start express Order create bulk order state.
     */
    async startOrder(bulkOrderState) {

        this.#setCartId();
        const payload = {
            cartId: this.bulkOrderState.cartId,
            productId: bulkOrderState.productId,
            statCode: bulkOrderState.statCode,
            productType: bulkOrderState.productType,
            programType: bulkOrderState.programType,
            channelType: ChannelTypes.DIRECT_MAIL,
            oppId: bulkOrderState.oppId,
        }

        const response = await this.store.api.post(`/site/user/current/order-state/bulk`, payload);

        if (response && response.succeeded) {
            await this.getBulkOrderState(response.id);
        }

        return response;

    }

    //TODO. Not sure this is required..

    async changeProductType(productType) {
        this.bulkOrderState.productType = productType;
        await this.#updateBulkOrderState();
    }

    async selectProduct(id, unitPrice) {
        this.bulkOrderState.productId = id;
        if (unitPrice) {
            this.bulkOrderState.unitPrice = unitPrice;
        }
        await this.#updateBulkOrderState();
    }

    async updateSubCodesDetails(reqPayload) {

        this.bulkOrderState.subCodesDetails = reqPayload.subCodesDetails;

        var payload = {
            bulkOrderState: this.bulkOrderState
        }
        const response = await this.store.api.put(`/site/user/current/order-state/bulk`, payload);
        if (response.succeeded) {
            Object.assign(this.bulkOrderState, response.model);
        }

        return response;
    }

    async setDropType(type) {

        this.bulkOrderState.dropDetails.dropType = type;
        this.bulkOrderState.dropDetails.startDate = null;
        this.bulkOrderState.dropDetails.numberOfMailings = 0;
        this.bulkOrderState.dropDetails.frequency = null;
        this.bulkOrderState.dropDetails.mailingDetails = [];
        await this.#updateBulkOrderState();
    }


    async setDropDetails(payload) {
        this.#resetDropDetails();
        this.bulkOrderState.dropDetails.dropType = payload.dropDetails.dropType;
        this.bulkOrderState.dropDetails.startDate = payload.dropDetails.startDate || '';
        this.bulkOrderState.dropDetails.frequency = payload.dropDetails.frequency || '';
        this.bulkOrderState.dropDetails.numberOfMailings = payload.dropDetails.numberOfMailings || '';
        this.bulkOrderState.dropDetails.mailingDetails = payload.dropDetails.mailingDetails;
        await this.#updateBulkOrderState();
    }

    async resetBulkOrderState() {
        await this.#reset();
    }

    async reloadBulkOrderState(bulkOrderState) {

        Object.assign(this.bulkOrderState, bulkOrderState);

    }

    // #region Private Methods ----

    #reset() {
        this.bulkOrderState.id = '';
        this.bulkOrderState.cartId = '00000000-0000-0000-0000-000000000000';
        this.bulkOrderState.statCode = '';
        this.bulkOrderState.channelType = '';
        this.bulkOrderState.oppId = '';
        this.bulkOrderState.programType = '';
        this.bulkOrderState.productType = '';
        this.bulkOrderState.productId = null;
        Object.assign(this.bulkOrderState.dropDetails, { dropType: '', frequency: '', numberOfMailings: 0, startDate: new Date(), mailingDetails: [] });
        this.bulkOrderState.unitPrice = 0.00;
        this.bulkOrderState.subCodesDetails = [];
        this.bulkOrderState.isSamePersonalization = false;
    }
    async newLogin() {
        this.#reset();
        await this.#init();
    }


    #resetDropDetails() {

        this.bulkOrderState.dropDetails.frequency = '';
        this.bulkOrderState.dropDetails.numberOfMailings = '';
        this.bulkOrderState.dropDetails.mailingDetails = [];
    }

    async #init() {
        this.#setCartId();
    }

    #setCartId() {
        if (site.cart
            && site.cart.current
            && site.cart.current.id)
            this.bulkOrderState.cartId = site.cart.current.id;
    }


    async getBulkOrderState(id) {
        const newBulkOrderState = await this.store.api.get(`/site/user/current/order-state/bulk/${id}`);
        Object.assign(this.bulkOrderState, newBulkOrderState.model);
        return newBulkOrderState.model;
    }

    async #updateBulkOrderState() {
        const payload = {
            bulkOrderState: {}
        };
        Object.assign(payload.bulkOrderState, this.bulkOrderState);
        const response = await this.store.api.put(`/site/user/current/order-state/bulk`, payload);
        Object.assign(this.bulkOrderState, response.model);
    }

    async updateBulkOrderStateSubCodes(reqPayload) {
        this.bulkOrderState.subCodesDetails = reqPayload.subCodesDetails;

        var payload = {
            bulkOrderState: this.bulkOrderState
        }

        const response = await this.store.api.put(`/site/user/current/order-state/bulk/dependents`, payload);
        if (response && response.succeeded) {
            const getResponse = await this.getBulkOrderState(response.bulkOrderState.id);
            Object.assign(this.bulkOrderState, getResponse.model);
        }
        return response;
    }

    async updateBulkOrderStateDocumentIds(reqPayload) {

        this.bulkOrderState.pageFlexDocumentIds = reqPayload.pageFlexDocumentIds;
        var payload = {
            bulkOrderState: this.bulkOrderState
        }
        const response = await this.store.api.put(`/site/user/current/order-state/bulk/docid`, payload);
        if (response && response.succeeded) {
            const getResponse = await this.getBulkOrderState(response.bulkOrderState.id);
            Object.assign(this.bulkOrderState, getResponse.model);
        }
        return response;
    }

    async updateBulkOrderStatePersonalization(reqPayload) {

        this.bulkOrderState.isSamePersonalization = reqPayload.isSamePersonalization;

        var payload = {
            bulkOrderState: this.bulkOrderState
        }
        const response = await this.store.api.put(`/site/user/current/order-state/bulk`, payload);
        if (response.succeeded) {
            Object.assign(this.bulkOrderState, response.model);
        }

        return response;
    }

    async getProductItemNumber() {

        const response = await this.store.api.get(`/api/store/product/${this.bulkOrderState.productId}`);

        return response?.model?.itemNumber;
    }

    async getMailingDetails(payload) {
        return await this.store.api.post(`/site/mailing-schedule`, payload);
    }

    async getAgencyDetails(statCodes) {
        return await this.store.api.post(`/site/agencies/details`, statCodes);
    }

    async placeOrder(source, isPaid, paymentProcessorId) {
        const response = await this.store.api.post('site/orders', { cartId: site.cart.current.id, source: source, isPaid: isPaid, paymentProcessorId: paymentProcessorId });
        return response;
    }
}