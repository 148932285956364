<script setup>

    // CustomizableDownloadStep2Page
    // Step 2 of 2 on customizing a downloadable piece 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import CustomizableDownloadStepper, { stepCounter } from '@/site/components/CustomizableDownloadStepper';

    // Methods
    function download() {
        // TODO: Call the API to download the PDF 
    }
</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container print-on-demand-customize-step2">


                <div class="row mb-5">

                    <div class="col">

                        <CustomizableDownloadStepper :currentStep="stepCounter.TWO" />

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-sm-6 offset-sm-3 text-center">

                        <h1>Your Customizable Download is Ready.</h1>

                        <p>You may also download this item at any later date from <router-link to="/my-order-history-lists" class="no-underline">Order History</router-link>.</p>

                        <div class="my-5">
                            <!-- TODO: Continue Shopping should go to the all search results page -->
                            <router-link to="/" class="btn btn-outline-primary me-3">Continue Shopping</router-link>
                            <button class="btn btn-primary" @click="download">Download</button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .customizable-download-step2 {
    }
</style>