<script setup>

    // ZipCodeLookup
    // A component that will search for zip codes and emit it to the parent

    // Imports 
    import { reactive, defineEmits, computed, onMounted, defineProps, watch } from 'vue';
    import { site } from '@/Site';
    import { store } from '@dd-nucleus/nucleus-vue';
    import ZipSearch from '../forms/ZipSearch';

    //props
    const props = defineProps({
        reload: {
            type: Number,
            required: false,
            default: 0
        }
    });
    // State 
    const select = reactive({ id: '', city: '', state: '', zipCode: '' });

    const selected = reactive({ id: '', city: '', state: '', zipCode: '' });

    const selectedZipCodes = reactive([]);

    const searchedZipCodes = reactive([]);

    const zipSearch = reactive(new ZipSearch());

    //computed
    const hasZipCodes = computed(() => {
        if (searchedZipCodes.length > 0 || selectedZipCodes.length > 0) {
            return true;
        } else {
            return false;
        }
    });

    // Methods 
    async function lookupZip() {

        const response = await site.personalizationInfo.zipLookup(zipSearch.model.city, zipSearch.model.state)

        if (response.length > 0) {
            for (var i = 0; i < response.length; i++) {

                if (!searchedZipCodes.find(item => item.zipCode === response[i].zipCode)
                    && !selectedZipCodes.find(item => item.zipCode === response[i].zipCode)) {
                    searchedZipCodes.push({ state: response[i].state, city: response[i].city, zipCode: response[i].zipCode });
                }
            }
        }        
    }

    function selectRow(data) {
        Object.assign(select, {});
        Object.assign(select, data);
    }

    function selectedRow(data) {
        Object.assign(selected, {});
        Object.assign(selected, data);
    }

    function add() {
        if (select.city.length > 0) {
            if (!selectedZipCodes.find(item => item.zipCode === select.zipCode)) {
                selectedZipCodes.push({ state: select.state, city: select.city, zipCode: select.zipCode });
            }

            for (var i = 0; i < searchedZipCodes.length; i++) {

                if (searchedZipCodes[i].zipCode === select.zipCode) {

                    searchedZipCodes.splice(i, 1);
                }

            }

            Object.keys(select).forEach(key => select[key] = '');
        }
    }

    function remove() {
        if (selected.city.length > 0) {
            for (var i = 0; i < selectedZipCodes.length; i++) {

                if (selectedZipCodes[i].zipCode === selected.zipCode) {

                    selectedZipCodes.splice(i, 1);
                }
            }

                if (!searchedZipCodes.find(item => item.zipCode === selected.zipCode)) {
                    searchedZipCodes.push({ state: selected.state, city: selected.city, zipCode: selected.zipCode });
                }
            

            Object.keys(selected).forEach(key => selected[key] = '');
        }
    }

    const emitSelectedZipCodes = () => {
        emits('selectedZips', selectedZipCodes);
    }

    // Emits 
    const emits = defineEmits(['selectedZips']);

    // Handlers ----
    onMounted(async () => {
        
        zipSearch.create();

    });

    watch(() => props.reload, () => {
        Object.assign(zipSearch, new ZipSearch());
        selectedZipCodes.length = 0;
        searchedZipCodes.length = 0;
    });


</script>

<template>
    <div class="zip-code-lookup">
        <div class="row">

            <div class="col">

                <p class="fw-bold">Lookup ZIP Code by City and/or State</p>

            </div>

        </div>
        <div class="row">

            <FormContainer :form="zipSearch" optional-text="(Optional)">
                <FormMessages />
                <slot name="top"></slot>

                <div class="col-6">
                    <FieldLabel field-name="city" label="City">
                        <TextBoxField v-model="zipSearch.model.city" placeholder="City" />
                        <FieldMessages />
                    </FieldLabel>
                </div>

                <div class="col-6">
                    <FieldLabel field-name="state" label="State">
                        <DropDownListField v-model="zipSearch.model.state" :options="store.shipping.states" null-option-text="Select State" />
                        <FieldMessages />
                    </FieldLabel>
                </div>

            </FormContainer>

        </div>

        <div class="row mb-5 mt-2">

            <div class="col text-end">

                <button @click="lookupZip" class="btn btn-primary" :disabled="zipSearch.status.isInvalid">
                    Lookup
                </button>
            </div>

        </div>

        <div class="row" v-if="hasZipCodes">

            <div class="col-5 bg-color-3-xlt p-3">

                <div class="fs-4">Select ZIP Code(s)</div>

                <table class="table">
                    <thead class="bg-color-2-dk">
                        <tr class="text-gray-0">
                            <td class="border-end border-gray-0">
                                City
                            </td>
                            <td>
                                Zip Code
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="data in searchedZipCodes" :key="data.city">
                            <tr :class="{selectedRow: data.zipCode == select.zipCode}" @click="selectRow(data)">
                                <td>{{data.city}}</td>
                                <td>{{data.zipCode}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="col-2 text-center mt-5">
                <div class="mt-5">
                    <button class="btn btn-outline-primary" @click="add">
                        Add
                    </button>
                </div>
                <div class="mt-3">
                    <button class="btn btn-outline-primary" @click="remove">
                        Remove
                    </button>
                </div>
            </div>

            <div class="col-5 bg-color-3-xlt p-3">
                <div class="fs-4">Selected ZIP Code(s)</div>
                <table class="table">
                    <thead class="bg-color-2-dk">
                        <tr class="text-gray-0">
                            <td class="border-end border-gray-0">
                                City
                            </td>
                            <td>
                                Zip Code
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="data in selectedZipCodes" :key="data.city">
                            <tr :class="{selectedRow: data.zipCode == selected.zipCode}" @click="selectedRow(data)">
                                <td>{{data.city}}</td>
                                <td>{{data.zipCode}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row mt-5" v-if="selectedZipCodes.length > 0">
            <div class="col text-end">
                <button @click="emitSelectedZipCodes" class="btn btn-primary">
                    Continue
                </button>
            </div>
        </div>
    </div>


</template>

<style lang="scss">
    .zip-code-lookup {

        .selectedRow {
            background: $color-1;
            color: $gray-0;
        }
    }
</style>
