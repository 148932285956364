
// AddEventForm
// Form for adding events to the user calendar.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';

export default class AddEventForm extends Form {

    // Constructor
    constructor(model, id = null) {
        // Pass our model to the base class
        super(Object.assign(model ?? {},
            {
                id: id,
                startDate: new Date(),
                endDate: new Date(),
                title: '',
                content: '',
                class: '',
                type: '',
                category: ''
            }));

        // Set required fields
        this.validator.fields.startDate.isRequired = true;
        this.validator.fields.endDate.isRequired = true;
        this.validator.fields.title.isRequired = true;
        this.validator.fields.class.isRequired = true;
        this.validator.fields.type.isRequired = true;
        this.validator.fields.category.isRequired = true;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    reset() {
        this.model.startDate = new Date();
        this.model.endDate = new Date();
        this.model.title = '';
        this.model.content = '';
        this.model.class = '';
        this.model.type = '';
        this.model.category = '';
    }

    /**
     * Call the form to reset for a new payment info.
     */
    create() {
        this.model.id = null;
        this.reset();
    }

    onValidate() {
        super.onValidate();
    }

    async onSubmit() {
    }
}