<script setup>

    // ReportingSidebar
    // To display the reports in the group.

    // Imports ----
    import {  defineProps, defineEmits } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { useRoute } from 'vue-router';
    import { ReportGroupType } from '@/constants';

    // Props ----
    const props = defineProps({
        groups: {
            type: Object,
            required: true,
            default: () => {}
        },
        selectedGroup: {
            type: Object,
            required: true,
            default: () => { }
        },
        showFavorites: {
            type: Boolean,
            required: false
        }
    });

    const emit = defineEmits(['changeReports', 'showFavoriteReports']);

    const route = useRoute();
    if (route.query.programReport == "annualauto") {
        emit('changeReports', ReportGroupType.ProgramReports, 'Annual Auto');
    }
    else if (route.query.programReport == "crosssell") {
        emit('changeReports', ReportGroupType.ProgramReports, 'Cross-sell');
    }

    // Methods ----
    function count(item) {
        if (Util.isNotEmpty(item)) {
            return item.length;
        } else {
            return 0
        }
    }
    function getRedirectUrl(name) {
        switch (name) {
            case 'Moving With Safeco':
                return '/retention/moving/list'
            default:
                return ""
        }
    }

    function showFavoriteReports() {
        emit('showFavoriteReports')
    }
</script>

<template>


    <div class="report-sidebar">

        <div class="row mb-3">
            <div class="col">
                <a class="no-underline favorite" @click="showFavoriteReports" :class="{ active: props.showFavorites }">
                    Favorites
                </a>
            </div>
        </div>

        <div class="accordion accordion-flush" v-for="(item,index) in props.groups" :key="index">

            <div v-if="item.label" :class="item.show ? 'group-div-show' :'group-div-hide'">
                <div class="accordion-header mb-3" :id="'flush-heading'+index">
                    <button class="accordion-button" type="button" :class="!item.open ? 'collapsed' :''" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+index" aria-expanded="false" :aria-controls="'flush-collapse-'+index">
                        {{item.label}}
                    </button>
                </div>
                <div :id="'flush-collapse'+index" :class="{ show: item.open }" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+index" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body mb-3">
                        <div class="ms-4 my-1" v-for="subItem in item.subGroups" :key="subItem.name">
                            <router-link v-if="getRedirectUrl(subItem.name)" 
                                         :to="getRedirectUrl(subItem.name)" 
                                         class="no-underline text-black">{{subItem.name}}</router-link>
                            <a v-else class="no-underline" :class="{ active: subItem.reportGroupType == selectedGroup.id && subItem.name == selectedGroup.name && !props.showFavorites }"
                               @click="emit('changeReports', subItem.reportGroupType, subItem.name)">
                                {{subItem.name}} ({{count(subItem.reports)}})
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<style lang="scss">
    .report-sidebar {
        a, a:visited, a:not([href]) {
            color: $gray-4;
        }

        a, a:visited, a:not(.active) {
            font-weight: normal
        }

        .active {
            font-weight: bold;
            color: $color-2-dk !important;
        }

        .accordion-button:not(.collapsed) {
            color: $color-2-dk !important;
            background-color: unset;
            box-shadow: unset;
            font-weight: bold;
        }

        .accordion-button {
            padding: 0;
        }

        .accordion-body {
            padding: 0;
        }

        .group-div-show {
            display: block;
        }

        .group-div-hide {
            display: none;
        }
    }
</style>