<script setup>

    // ShipTo
    // Allows the user to select from different options for specifying ship-to address(es).

    // Constants ----
    const ShipToTypes = {
        TO_USER: 'TO_USER',             // Shipping to the current user
        TO_CONTACT: 'TO_CONTACT',       // Shipping to a single contact
        TO_CONTACTS: 'TO_CONTACTS'      // Shipping to multiple contacts
    };

    // Components ----
    import ShipToUser from './ShipToUser';
    import ShipToContact from './ShipToContact';
    import ShipToContacts from './ShipToContacts';
    import ShipToList from './ShipToList';
    import OrderContactTile from '@/site/components/OrderContactTile';
    import OrderContactsTile from '@/site/components/OrderContactsTile';

    // Imports ----
    import { ref, reactive, defineProps, computed, onMounted, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { DeliveryType } from '../../../../constants';
    // Props ----
    const props = defineProps({
        // Whether this ship-to is editable
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        // Whether to start in editing mode
        editing: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State ----
    const editing = ref(props.editing);
    const isLoaded = ref(false);
    const shipToModeLoading = ref(false);
    const allContactsTypeSelected = ref('');
    const allContactType = {
        SAVED: "SAVED",
        UPLOAD: "UPLOAD"
    }

    const allContactsTypes = [
        {
            label: "Select from my contacts",
            value: allContactType.SAVED
        },
        {
            label: "Upload a list of contacts",
            value: allContactType.UPLOAD
        }
    ];

    const shipToTypes = reactive([
        { id: ShipToTypes.TO_USER, text: 'Ship to My Address' },
        { id: ShipToTypes.TO_CONTACT, text: 'Ship to a Contact' }
    ]);    

    // Computed ----
    const shipToType = computed(() => {
        switch (site.cart.current.shipToMode) {
            case site.cart.ShipToMode.ShipToUser:
            case site.cart.ShipToMode.DistributionList:
                return ShipToTypes.TO_USER;
            case site.cart.ShipToMode.ShipToContact:
                return ShipToTypes.TO_CONTACT;
            case site.cart.ShipToMode.ShipToContacts:
                return ShipToTypes.TO_CONTACTS;
            default:
                if (typeof site.cart.current.shipToMode !== 'undefined')
                    console.error(`ERROR: Unexpected ship to mode '${site.cart.current.shipToMode}' encountered`);
                return ShipToTypes.TO_USER;
        }
    });

    const hasNonPickPackItems = computed(() => {
        let hasNonPickPackItem = false
        for (let item of site.cart.current.items) {
            if (item.product.deliveryType != DeliveryType.PHYSICAL) {
                hasNonPickPackItem = true;
                break
            }
        }
        return hasNonPickPackItem
    })

    const shipToTypeTitle = computed(() => {
        switch (shipToType.value) {
            case ShipToTypes.TO_USER:
                return "Ship to This Address:";
            case ShipToTypes.TO_CONTACT:
                return "Ship to This Contact:";
            case ShipToTypes.TO_CONTACTS:
                return "Ship to Multiple Contacts:";
        }

        return null;
    });

    const hasPromotionApplied = computed(() => {
        if (site.cart.current.discountTotal != 0 || site.cart.current.additionalChargeTotal != 0) {
            return true;
        }
        else {
            return false;
        }
    });

    // Events ----
    const emit = defineEmits(['hasShippingDetails']);

    // Handlers ----
    onMounted(() => {
        update();
    });



    // Called once we know we have a cart to work with
    function update() {
        if (typeof site.cart.current.shipToList === 'undefined') return;
        
        let canMultiShip = true;
        if (canMultiShip) {
            if (Util.isUndefined(shipToTypes.find((item) => item.id === ShipToTypes.TO_CONTACTS)))
                shipToTypes.push({ id: ShipToTypes.TO_CONTACTS, text: 'Ship to Multiple Contacts' });
        }
        isLoaded.value = true;
    }
    
    function onChange() {
        editing.value = !editing.value;
    }

    function onHasShippingDetails(value) {
        emit('hasShippingDetails', value);
    }    

    async function setShipToMode(id) {
        shipToModeLoading.value = true;
        switch (id) {
            case ShipToTypes.TO_USER:
                await site.cart.setShipToModeToShipUser();
                break;
            case ShipToTypes.TO_CONTACT:
                await site.cart.setShipToModeToSingleContact();
                break;
            case ShipToTypes.TO_CONTACTS:
                allContactsTypeSelected.value = '';
                await site.cart.setShipToModeToMultipleContacts();
                break;
            default:
                break;
        }
        shipToModeLoading.value = false;
    }
</script>

<template>

    <div class="ship-to">
        <template v-if="editing">

            <ul class="nav nav-tabs">
                <li v-for="type in shipToTypes" :key="type.id" class="nav-item" @click="setShipToMode(type.id)">
                    <button :id="type.id" class="nav-link" :class="{ active: shipToType === type.id }" data-bs-toggle="tab" :data-bs-target="'#' + type.id + '-pane'" type="button" role="tab">{{ type.text }}</button>
                </li>
            </ul>

            <div class="loader" v-show="shipToModeLoading">
                <div class="spinner-grow" role="status">
                </div>
                <p> Loading ... </p>
            </div>

            <div class="tab-content" v-show="!shipToModeLoading">
                <div class="tab-pane fade pt-3" :class="{ show: shipToType === ShipToTypes.TO_USER, active: shipToType === ShipToTypes.TO_USER }" id="TO_USER-pane" role="tabpanel" aria-labelledby="TO_USER" tabindex="0">
                    <ShipToUser :can-edit="true" @hasShippingDetails="onHasShippingDetails" />
                </div>

                <div class="tab-pane fade pt-3" :class="{ show: shipToType === ShipToTypes.TO_CONTACT, active: shipToType === ShipToTypes.TO_CONTACT }" id="TO_CONTACT-pane" role="tabpanel" aria-labelledby="TO_CONTACT" tabindex="0">
                    <ShipToContact :can-edit="true" @hasShippingDetails="onHasShippingDetails" />
                </div>

                <div class="tab-pane fade pt-3" :class="{ show: shipToType === ShipToTypes.TO_CONTACTS, active: shipToType === ShipToTypes.TO_CONTACTS }" id="TO_CONTACTS-pane" role="tabpanel" aria-labelledby="TO_CONTACTS" tabindex="0">


                    <div class="ship-warning" v-if="hasNonPickPackItems">
                        <p>You have an item(s) in your cart that is not eligible for shipping to multiple addresses. Please complete that order or remove that item(s) from your cart before proceeding.</p>
                    </div>

                    <div class="ship-warning" v-if="hasPromotionApplied">
                        <p>You have an item(s) in your cart that has a promotion(s) applied. Shipping to multiple contacts is not available.</p>
                    </div>                    

                    <div v-else>

                        <div v-if="allContactsTypeSelected.length == 0">
                            <div class="form-check mb-3" v-for="type in allContactsTypes" :key="type.value">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="allContactsTypeSelected" :value="type.value" name="howToSend">
                                    {{type.label}}
                                </label>
                            </div>
                        </div>


                        <ShipToList v-if="allContactsTypeSelected === allContactType.UPLOAD" :can-edit="true" />

                        <ShipToContacts v-if="allContactsTypeSelected === allContactType.SAVED" :can-edit="true" />

                    </div>

                </div>

            </div>

        </template>

        <div v-else class="summary">
            <div class="ship-type-title mb-2">{{ shipToTypeTitle }}</div>

            <OrderContactTile v-if="isLoaded && shipToType !== ShipToTypes.TO_CONTACTS " :contact="site.cart.current.shipToList[0]" :border="false">
            </OrderContactTile>

            <OrderContactsTile v-if="isLoaded && shipToType === ShipToTypes.TO_CONTACTS" :contacts="site.cart.current.shipToList" :editable="false" :border="false">
            </OrderContactsTile>

            <ActionButton v-if="props.editable" icon="bi bi-arrow-left-right" label="Change" @click="onChange" />
        </div>
    </div>

</template>

<style lang="scss">

    .ship-to {
        .ship-type-title {
            font-size: 0.9rem;
            font-weight: $bold;
        }

        .ship-warning {
            background-color: lightgoldenrodyellow;
            padding: 12px 8px;
            text-align: left;
            margin: 0;
            border-radius: 4px;

            p {
                margin: 0;
            }
        }

        .loader {
            display: flex;
            margin-top: 120px;
            justify-content: center;

            p {
                margin: auto 12px;
            }
        }
    }
</style>