<script setup>

    // OrderHistoryPanel
    // Shows order history in the my account area.

    // Constents
    const constants = {
        DEFAULT_PAGE_SIZE: 10
    }

    const pageSizeOptions = [10, 20, 35, 48, 60];

    const statusOptions = [
        { id: 'pending-approval', text: 'In Progress' },
        { id: 'pending', text: 'Pending' },
        { id: 'completed', text: 'Complete' },
        { id: 'canceled', text: 'Cancelled' },
    ];
    const orderChannelTypeOptions = [
        { id: 'email', text: 'Email' },
        { id: 'direct-mail', text: 'Direct Mail' },
        // NOTE: these 3 filters are kept here for future use in case we want them
        // { id: 'ADD_FILTER_ID', text: 'Shipped Item' },
        // { id: 'ADD_FILTER_ID', text: 'Custom download' },
        // { id: 'ADD_FILTER_ID', text: 'Download' },
    ];
    const programTypeOptions = [
        { id: 'automated-cross-sell', text: 'Automated Cross-sell Orders' },
        { id: 'cross-sell', text: 'Cross-sell Orders' },
        { id: 'specialty-cross-sell', text: 'Specialty Cross-sell' },
        { id: 'prospecting', text: 'Prospecting' },
        { id: 'annual-auto', text: 'Annual Auto' },
        { id: 'relationship-marketing', text: 'Relationship Marketing' },
    ];

    const shippingOptions = [
        { id: '100', text: 'Shipped' },
        { id: '1', text: 'Not Shipped' },
        { id: '2', text: 'Partially Shipped' },
    ]

    const columns = [
        { field: 'orderPlacedByEmail', title: 'Order Placed By Email' },
        { field: 'orderDate', title: 'Order Date', formatter: (value) => Util.date(value) },
        { field: 'orderNumber', title: 'Order Number' },
        { field: 'orderStatus', title: 'Order Status' },
        { field: 'paymentStatus', title: 'Payment Status' },
        { field: 'shippingStatus', title: 'Shipping Status' },
        { field: 'subTotal', title: 'Sub Total' },
        { field: 'discountTotal', title: 'Discount Total' },
        { field: 'shipping', title: 'Shipping' },
        { field: 'total', title: 'Total' },
        { field: 'amountPaid', title: 'Amount Paid' },
    ]

    // Components ----
    import OrderHistoryTile from '@/site/components/OrderHistoryTile';
    import MockProductListTile from '@/site/areas/products/components/MockProductListTile';

    // Imports ----
    import { computed } from 'vue';
    import { ExportParser } from "@/site/services/ExportParser";
    import { Search, Util } from '@dd-nucleus/nucleus-vue';
    import { useRoute } from 'vue-router';
    import { getFieldsMapperFromColumns, beforeTableEncode } from '@/util';


    // State ----
    const search = new Search('orders-for-user', 'detail', 'order-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);
    const route = useRoute();

    const fieldsMapper = getFieldsMapperFromColumns(columns);

    search.setFilterInitialValue('order-include-archived', "true");

    if (route.query.programType) {
        search.setFilterValue('order-program-type', route.query.programType.toLowerCase());
    }

    const searchDownload = new Search('orders-for-user', 'detail', 'order-newest-to-oldest', null);
    searchDownload.setFilterInitialValue('order-include-archived', "true");
    // Computed 
    const data = computed(() => {
        if (searchDownload.results && searchDownload.results.rows && searchDownload.results.rows.length > 0) {
            return searchDownload.results.rows;
        }
        return [];
    });

    function setDownloadFilterValues() {

        const searchTermFilterValues = search.getFilterValues('search-term');
        const orderDateRangeFilterValues = search.getFilterValues('order-date-range');
        
        const orderChannelTypeFilterValues = search.getFilterValues('order-channel-type');
        const orderStatusFilterValues = search.getFilterValues('order-status');
        const shippingStatusFilterValues = search.getFilterValues('shipping-status-multiple');
        const orderProgramTypeFilterValues = search.getFilterValues('order-program-type');

        if (route.query.programType) {
            searchDownload.setFilterValues('order-program-type', route.query.programType.toLowerCase());
        }

        if (searchTermFilterValues != null) {
            searchDownload.setFilterValues('search-term', searchTermFilterValues);
        }

        if (orderDateRangeFilterValues != null) {
            searchDownload.setFilterValues('order-date-range', orderDateRangeFilterValues);
        }

        if (orderChannelTypeFilterValues != null) {
            searchDownload.setFilterValues('order-channel-type', orderChannelTypeFilterValues);
        }

        if (orderStatusFilterValues != null) {
            searchDownload.setFilterValues('order-status', orderStatusFilterValues);
        }

        if (shippingStatusFilterValues != null) {
            searchDownload.setFilterValues('shipping-status-multiple', shippingStatusFilterValues);
        }
        
        if (orderProgramTypeFilterValues != null) {
            searchDownload.setFilterValues('order-program-type', orderProgramTypeFilterValues);
        }
    }

    // Methods ---
    async function downloadExcel() {
        
        setDownloadFilterValues();
        await searchDownload.activate(route);
        ExportParser().exportXLSFromJSON(data.value, null, null, fieldsMapper, beforeTableEncode(columns));
    }

    async function downloadCsv() {

        setDownloadFilterValues();
        await searchDownload.activate(route);
        ExportParser().exportCSVFromJSON(data.value, null, null, fieldsMapper, beforeTableEncode(columns));
    }

</script>

<template>
    <div class="account-panel order-history-panel">
        <h1 class="cart-page-header mb-3 text-primary">My Account <span class="text-muted">/ My Order History/Lists </span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="false">
            <div class="d-flex flex-column">
                <div class="filter-box container">
                    <div class="mb-4 row">
                        <div class="col-4">
                            <label class="fw-bold">Search by</label>
                            <TextBoxFilter title="Order no#, name or email"  filter-id="search-term" placeholder="Order no#, name or email" />
                        </div>

                        <div class="col-4">
                            <label class="fw-bold">Order Date</label>
                            <DateRangeFilter filter-id="order-date-range" />
                        </div>

                        <div class="col-4">
                            <label class="fw-bold">Agency</label>
                            <TextBoxFilter title="Search by agency name or statcode" filter-id="search-agency" placeholder="Search by agency name or statcode" />
                        </div>
                    </div>
                    <div class="mb-4 row">
                        <div class="col-12">
                            <label class="fw-bold">Status</label>
                            <div class="row">
                                <div class="col-3" v-for="opt of statusOptions" :key="opt.id">
                                    <AddFilterCheckBox filter-id="order-status" :values="[opt.id]" :label="opt.text" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4">
                            <label class="fw-bold">Delivery Type</label>
                            <div class="row">
                                <div class="col-3" v-for="opt of orderChannelTypeOptions" :key="opt.id">
                                    <AddFilterCheckBox filter-id="order-channel-type" :values="[opt.id]" :label="opt.text" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4">
                            <label class="fw-bold">Shipping Type</label>
                            <div class="row">
                                <div class="col-3" v-for="opt of shippingOptions" :key="opt.id">
                                    <AddFilterCheckBox filter-id="shipping-status-multiple" :values="[opt.id]" :label="opt.text" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4">
                            <label class="fw-bold">Program List Type</label>
                            <div class="row">
                                <div class="col-4" v-for="opt of programTypeOptions" :key="opt.id">
                                    <AddFilterCheckBox filter-id="order-program-type" :values="[opt.id]" :label="opt.text" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="options-box d-flex align-items-center mt-3 mb-5 align-self-end">
                    <i class="bi bi-file-earmark-excel text-secondary" title="Download in Excel format" style="font-size: 30px;" @click="downloadExcel" role="button"></i>
                    <i class="bi bi-filetype-csv text-secondary" title="Download in CSV format" style="font-size: 30px;" @click="downloadCsv" role="button"></i>
                    <div><PageSizeList :options="pageSizeOptions" /></div>
                    <div class="flex-fill text-end"><PageSelector /></div>
                </div>
            </div>

            <SearchGrid>
                <template v-slot:list="order">
                    <OrderHistoryTile :order="order" />
                </template>

                <template #empty>
                    <div>
                        You do not currently have any previously-placed orders.
                    </div>
                </template>

                <template #loading-list>
                    <MockSearchGrid>
                        <MockProductListTile />
                    </MockSearchGrid>
                </template>
            </SearchGrid>

            <div class="d-flex">
                <div class="flex-fill text-end"><PageSelector /></div>
            </div>

        </SearchContainer>


    </div>
</template>

<style lang="scss">

    .order-history-panel {
        .n-search-grid .tile {
            margin-bottom: 0.5rem;
            padding: 0.5rem;
        }

        .n-search-grid .tile:nth-child(odd) {
            background-color: #efefef;
        }

        .filter-box {
            padding: 2rem;
            background: #f2fcfc;

            .n-date-range-filter{
                display: flex;
                gap: 0.5rem;
            }
        }

        .options-box {
            gap: 1rem;
        }
    }
</style>
