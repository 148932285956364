<script setup>
    const columns = [
        { field: 'statCode', title: 'Sub Code', isSortable: true, custom: true },
        { field: 'mailingDate', title: 'Mailing Date', custom: true, isSortable: true },
        { field: 'marketingChannel', title: 'Marketing Channel', custom: true, isSortable: true },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true },
        { field: 'phone', title: 'Phone', isSortable: true },
    ];

    const spColumns = [
        { field: 'motorCycle', title: 'Motorcycle', custom: true, isSortable: true },
        { field: 'waterCraft', title: 'Watercraft', custom: true, isSortable: true },
        { field: 'rv', title: 'RV', custom: true, isSortable: true },
    ];

    const specialtyColumns = columns.concat(spColumns);

    // Imports
    import { Search } from '@dd-nucleus/nucleus-vue';
    import SearchTableV1 from '@/site/components/searches/SearchTableV1'
    import { ref, reactive, computed, onMounted, defineProps, watch, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';
    import { crossSellTypes } from '@/site/components/OpportunitiesTile';

    //import { useRoute } from 'vue-router';
    import { formatStatCode, isNotEmpty } from '@/util';
    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    // Props ----
    const props = defineProps({
        bulkOrderState: {
            type: Object,
            required: true
        },
        statCode: {
            type: String,
            required: true
        },
        orderStateId: {
            type: String,
            required: true
        },
        showNextSubCode: {
            type: Boolean,
            required: false
        }
    });

    // State
    const router = useRouter();

    const emit = defineEmits(['updatedMailContacts', 'stepTwoSelectAllSelected']);
    const search = new Search('bridge-cross-sell-detail', 'row', 'statCode');
    const bulkOrderState = reactive(props.bulkOrderState);
    //const route = useRoute();
    const searchTerm = ref('statCode');
    const selectedList = reactive([]);
    const detail = reactive({ product: {} });
    const statCode = ref(props.statCode);
    const orderStateId = ref(props.orderStateId);
    const currentOrderState = reactive({});
    const isLoaded = ref(false);

    //computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    const exportColumns = computed(() => {
        switch (bulkOrderState.oppId) {
            case crossSellTypes.SpecialtyCrossSell: return specialtyColumns;
            default: return columns;
        }
    })

    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    async function previous() {
        emit("stepTwoSelectAllSelected");
    }

    async function next() {
        await updatePolicyHolders();
        goToNextStep();

    }
    async function updatePolicyHolders() {
        const allRecords = search.results.rows;
        const selected = allRecords.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {
            return {
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2,
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "policyType": sl.policyType,
                "renewalDate": sl.renewalDate,
                "motorCycle": sl.motorCycle,
                "rv": sl.rv,
                "waterCraft": sl.waterCraft,
                "email": sl.email,
                "phone": sl.phone
            };
        });
        if (isNotEmpty(selected)) {
            await site.personalizationInfo.getOrderState(orderStateId.value);
            await site.personalizationInfo.addMailContacts(selected);
        }
    }

    async function nextSubCode() {
        isLoaded.value = false;
        await updatePolicyHolders();
        emit('updatedMailContacts', { orderStateId: orderStateId.value, statCode: props.statCode });
        isLoaded.value = true;
    }

    function goToNextStep() {
        router.push({
            name: 'ExpressOrderStepThree',
            params: {
                bulkOrderStateId: bulkOrderState.id,
            }
        });
    }

    function selectAll(isSelectAll, list) {
        selectedList.length = 0;
        if (isSelectAll)
            for (var i = 0; i < list.length; i++) {
                selectedList.push(list[i].policyNumber)
            }
    }

    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
    }

    function formatFlag(flag) {
        if (flag == 'Y' || flag == 'N') {
            return flag;
        }
        else {
            if (flag) {
                return 'Y';
            }
            else {
                return 'N';
            }
        }
    }

    onMounted(async () => {

        // Call Product
        if (isNotEmpty(bulkOrderState.productId)) {
            detail.product = Object.assign(detail.product, await site.products.getProductDetail(bulkOrderState.productId));
        }

        if (isNotEmpty(props.orderStateId)) {
            const response = await site.personalizationInfo.getOrderState(props.orderStateId);
            if (response.succeeded) {
                Object.assign(currentOrderState, response.model);
                Object.assign(selectedList, currentOrderState?.mailContacts?.map(mc => mc.policyNumber));
            }
        }
        isLoaded.value = true;
    });

    watch(() => props.statCode, async (newValue) => {
        // TODO, why the statcode has to depend on watcher and not prop
        if (newValue) {
            selectedList.length = 0;
            statCode.value = newValue;
            await search.setFilters([{
                'filterId': 'cross-sell-type-code-sub-code',
                'values': ["SP", newValue]
            }]);
        }
    });

    watch(() => props.orderStateId, async (newValue, oldValue) => {
        if (newValue != oldValue) {
            orderStateId.value = newValue;
            if (isNotEmpty(props.orderStateId)) {
                const response = await site.personalizationInfo.getOrderState(props.orderStateId);
                if (response.succeeded) {
                    Object.assign(currentOrderState, response.model);
                    Object.assign(selectedList, currentOrderState?.mailContacts?.map(mc => mc.policyNumber));
                }
            }
        }
    });
</script>

<template>
    <div class="row my-5">

        <div class="row">
            <div class="col">

            </div>
        </div>

        <div class="col border-bottom border-color-1" v-if="isLoaded">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row" v-if="isLoaded">
        <div class="col">

            <SearchContainer v-if="bulkOrderState.oppId && statCode" :search="search" default-view="list" :deep-link="false">

                <!-- Hidden but used for filtering on filterInitiallValue-->
                <DateRangeFilter v-show="false" filter-id="cross-sell-type-code-sub-code" :initial-start-date="bulkOrderState.oppId" :initial-end-date="statCode" />

                <div class="row mb-2">
                    <div class="d-flex">
                        <div class="me-auto">
                            <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                        </div>
                    </div>
                </div>
                <div class="data-table">
                    <SearchTableV1 :columns="exportColumns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">
                        <template v-slot:statCode="row">
                            {{ formatStatCode(row.statCode)}}
                        </template>
                        <template v-slot:mailingDate="row">
                            {{formatDate(row.mailingDate)}}
                        </template>

                        <template v-slot:renewalDate="row">
                            {{formatDate(row.renewalDate)}}
                        </template>

                        <template v-slot:motorCycle="row">
                            {{formatFlag(row.motorCycle)}}
                        </template>

                        <template v-slot:waterCraft="row">
                            {{formatFlag(row.waterCraft)}}
                        </template>

                        <template v-slot:rv="row">
                            {{formatFlag(row.rv)}}
                        </template>

                        <template v-slot:marketingChannel="row">
                            <div v-if="row.mailingDate">Direct Mail</div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            None of your policyholders currently meet the criteria for this type of campaign.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTableV1>
                </div>


            </SearchContainer>
        </div>
    </div>
    <template v-if="isLoaded">
        <div class="row mt-3 text-center express-order-policy-holders">
            <template v-if="showNextSubCode">
                <div class="col-12 mb-3">
                    <div v-if="listLength==0" class="text-danger mb-3 warning-message">
                        To proceed, at least 1 policyholder must be selected, otherwise please deselect this sub code.
                    </div>
                    <div class="me-auto">
                        <button class="btn btn-primary" @click="nextSubCode" :disabled="(listLength==0)">Go to Next Sub Code</button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-12 mb-3">
                    <div v-if="listLength==0" class="text-danger mb-3 warning-message">
                        To proceed, at least 1 policyholder must be selected, otherwise please deselect this sub code.
                    </div>
                </div>
            </template>

        </div>
        <div class="row mt-5">
            <div class="d-flex mb-3">
                <div class="me-auto">
                    <button class="btn btn-outline-primary" @click="previous">Previous</button>
                </div>
                <div>
                    <button v-if="listLength > 0" @click="next" class="btn btn-primary">Next</button>
                </div>
            </div>

        </div>
    </template>

</template>

<style lang="scss">
    .express-order-policy-holders {
        .warning-message {
            font-weight: bold
        }
    }

</style>