<script setup>

    // PrintOnDemandCustomizeStep1Page
    // Step 2 of 3 on customizing a print on demand piece

    // Imports 
    import { computed, reactive } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ThreeStepStepper, { stepCounter } from '@/site/components/ThreeStepStepper';

    // State 
    // TODO: Sounds like this needs to come from the product record as each product can be unique with these steps
    let quantitySteps = reactive({
        'min': 25,
        'max': 500,
        'step': 25,
        'value': 100,
        'priceEach': 0.25
    });

    // Computed
    const priceEstimate = computed(() => {
        let total = quantitySteps.priceEach * quantitySteps.value;
        return '$' + Math.trunc(total * 100) / 100;
    });


</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar/>

            <div class="root-page-container print-on-demand-customize-step1">


                <div class="row">

                    <div class="col">

                        <ThreeStepStepper :currentStep="stepCounter.TWO"/>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col border-bottom border-color-1">

                        <div class="d-flex">
                            <div class="text-color-1">
                                <h2 class="mb-0">Select Quantity</h2>
                            </div>
                            <div class="ms-auto">
                                <!-- TODO: Add the correct product name from the API -->
                                Insurance Check Up Form (Customizable) Print On Demand (AP-3967P)
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row">

                    <div class="col-4">

                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Quantity</label>
                            <input type="number" class="form-control" :min="quantitySteps.min" :max="quantitySteps.max" :step="quantitySteps.step" v-model="quantitySteps.value">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Price Estimate</label>
                            <input type="text" class="form-control" v-model="priceEstimate" disabled>
                        </div>

                        <div class="mb-5">
                            <router-link to="/print-on-demand/customize-step-1" class="btn btn-outline-primary me-3">
                                Previous
                            </router-link>
                            <!-- TODO: Using the form validation make this button enabled based on checkboxes above-->
                            <router-link to="/print-on-demand/customize-step-3" class="btn btn-primary">
                                Next
                            </router-link>
                        </div>
                    </div>
                    
                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .print-on-demand-customize-step2 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }
    }
</style>