<script>

    // ProductListTile
    // A tile for a single product intended to be displayed in list mode.

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        tileHeight: '12rem',           // Height of the full tab, fixed for alignment purposes
        productNameLines: 2            // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;

</script>

<script setup>

    // Components ----

    // Imports ----
    import { reactive, defineProps, computed, defineEmits } from 'vue';
    import { productRestrictions } from '@/site/areas/cart/CartPage';
    import { AddToCartForm, Util } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { getUnitOfMeasureFriendlyName } from '@/constants'

    // Props ----
    const props = defineProps({
        // The product whose information to show.
        // API model: DD.Nucleus.Storefront.Api.ProductTileModel
        product: {
            type: Object,
            required: true
        }
    });

    // Handlers ----
    const emit = defineEmits(['productSelected', 'customDownloadSelected', 'downloadSelected']);


    // State ----
    const product = reactive(props.product);
    const form = reactive(new AddToCartForm(product, 1));

    // Methods ----
    function productPrice(price) {
        return site.convertNumberToCurrency(price);
    }

    // Computed ----
    const isNotForMA = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORMA)
            return 'NOT FOR USE IN MASSACHUSETTS';
        else
            return '';
    });

    const isNotForCO = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORCO)
            return 'NOT FOR USE IN COLORADO';
        else
            return '';
    });

    // Handlers ----
    function personalize(productId) {
        emit('productSelected', productId);
    }
    function customizeDownload(productId) {
        emit('customDownloadSelected', productId);
    }
    function download(url,productId) {
        emit('downloadSelected', url, productId );
    }

</script>

<template>

    <div class="product-list-tile" :style="{ 'min-height': setup.tileHeight }">
        <div class="inner-row d-flex flex-row">
            <div class="left">
                <ProductThumbnail :product="product" :height="setup.tileHeight" width="200px" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill">
                <!-- Stretchy middle part-->

                <div class="tags mb-2">
                    <ProductTags :product="product" />
                </div>

                <!-- Product name and item number -->
                <router-link class="title-link" :to="`/product/${product.id}`">
                    <div class="name" v-snip="{ lines: setup.productNameLines }" :title="product.name">
                        {{ product.name }}
                    </div>
                </router-link>
                <p class="description" v-html="product.description" v-snip="{ lines: setup.productNameLines }"></p>
                <div class="item-number"><router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link></div>
                <div class="sale-price mb-1"> {{ productPrice(product.unitPrice) }} {{getUnitOfMeasureFriendlyName(product)}} </div>
                <div class="product-restriction text-danger"> {{ isNotForMA }} </div>
                <div class="product-restriction text-danger"> {{ isNotForCO }} </div>

                <div class="favorite">
                    <ProductFavoriteIcon :product="product" label="Add as Favorite" />
                </div>
            </div>
            <!-- Fixed right panel -->
            <div class="right d-flex flex-column" :style="{ height: setup.tileHeight }">
                <MessageList v-if="Util.isNotEmpty(product.messages)" :messages="product.messages" :add-box="false" />
                <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                    <FormContainer :form="form" v-if="product.canAddToCart">
                        <div class="flex-fill">
                            <ProductAddToCartQty :product="product" />
                            <FormMessages field-name="quantity" :add-box="false" class="mt-2" />
                            <FieldMessages field-name="quantity" class="mt-2" />
                        </div>
                        <div class="d-grid mt-3">
                            <button class="btn btn-primary mt-3" @click="form.submit()" :disabled="form.status.isInvalid">Add&nbsp;To&nbsp;Cart</button>
                        </div>
                    </FormContainer>
                </ShowOnlyForRoles>
                <div>
                    <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                        <button v-if="product.isPersonalizable" @click="personalize(product.id)" class="btn btn-primary w-100 mt-3" :disabled="!product.hasProductDataType"> Personalize </button>
                    </ShowOnlyForRoles>
                    <button v-if="product.isDownloadble" @click="download(product.downloadableAssetFileUrl, product.downloadableFileName)" class="btn btn-primary w-100 mt-3"> Download </button>
                    <button v-if="product.isCustomizeDownloadble" @click="customizeDownload(product.id)" class="btn btn-primary w-100 mt-3">Customize</button>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .product-list-tile {
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .name {
            font-size: 1.0rem;
            font-weight: 600;
            line-height: 1.1rem;
            color: #476791;
        }

        .description {
            font-size: 12px;
            padding-right: 1.5rem;
            margin-bottom: 0;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .sale-price {
            font-size: 14px;
            font-weight: bold;
        }

        .product-restriction {
            font-size: 14px;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }

        .middle {
            padding: 1rem;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            min-width: 14rem;
        }

        .n-message.text {
            font-size: 14px;
        }
    }
</style>