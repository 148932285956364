<script setup>
    // Imports
    import { reactive, watch, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    // Components
    import { Util } from "@dd-nucleus/nucleus-vue";
    import { ChannelTypes } from './SelectChannelPage';
    import { programTypes } from '@/site/components/OpportunitiesTile';

    // State
    const route = useRoute();

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    async function setDocumentInfo() {
        await site.personalizationInfo.updateDocId(route.query.DocID, route.params.parentId);
    }

    // Handlers ----
    onMounted(async () => {
        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            await site.personalizationInfo.getOrderState(orderStateId);
        }
    })

    watch(() => orderFlowDetails,
        async (details) => {
            //TODO to update the docId here itself for all other flow before redirecting to other pages..
            if (Util.isNotEmpty(details.id)) {
                if (route && route.query && route.query.DocID && route.query.DocID.length > 0) {
                    let review = '';
                    if (route.query.review && route.query.review == 'true') {
                        review = `&review=${route.query.review}`;
                    }
                    if (Number(route.query.channelType) == ChannelTypes.EMAIL && route.params.isMultiChannel != 'true') {
                        await setDocumentInfo();
                        window.parent.location.href = `/personalization/step-2/${route.params.id}/?docId=${route.query.DocID}${review}`;
                    }
                    else if (Number(route.query.channelType) == ChannelTypes.DIRECT_MAIL) {
                        await setDocumentInfo();
                        if (route.query.programType == programTypes.RETENTION && route.query.isSubscription == 'false') {
                            window.parent.location.href = `/personalization/step-2-review/${route.params.id}`;
                        } else {
                            window.parent.location.href = `/personalization/step-2/${route.params.id}/?docId=${route.query.DocID}${review}`;
                        }
                    }
                    else if (route.params.isMultiChannel == 'true') {
                        await setDocumentInfo()
                        window.parent.location.href = `/personalization/step-4-multi-channel/?parentId=${route.params.parentId}&docId=${route.query.DocID}${review}`
                    }
                    else {
                        //TODO : redirect to some error page.
                    }
                }
            }
        },
        { deep: true }
    );

</script>

<template>
    <div></div>
</template>

<style lang="scss">
    .print-on-demand-customize-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }

        .multi-channel-headline {
            background: $color-3-dk;
        }

        .multi-channel-detail {
            background: $gray-1;
        }
    }
</style>
