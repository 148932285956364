<script setup>

    // ImageDropZone
    // The drop down will accept a drag and drop of images or a select file box that can upload multiple files.

    // Constants
    const fileFormats = {
        PNG: 'image/png',
        JPG: 'image/jpeg'
    }

    // Imports
    import { ref, defineEmits } from 'vue';
    import { site } from '@/Site';

    // State
    const active = ref(false);

    const acceptedUploadFormats = Object.values(fileFormats);

    // Methods
    async function uploadImage(e) {

        const formData = new FormData();

        let files;

        if (e.type == 'change') {
            files = e.target.files;
        }

        if (e.type == 'drop') {
            files = e.dataTransfer.files;
        }

        for (let file of files) {
            formData.append('file', file);
        }

        active.value = false;

        await site.imageLibrary.uploadUsersImages(formData);

        emits('refreshImages');

    }

    const toggleActive = () => {
        active.value = !active.value;
    }

    // Emits ----
    const emits = defineEmits(['refreshImages']);

</script>

<template>

    <div class="image-drop-zone">

        <div class="row">

            <div class="col text-center">

                <div class="dropzone p-2" 
                     @dragenter.prevent="toggleActive" 
                     @dragleave.prevent="toggleActive" 
                     @dragover.prevent 
                     @drop.prevent="uploadImage($event)"
                     :class="{ 'active-dropzone' : active }"
                >
                    <h2>
                        Drop file to upload
                    </h2>

                    <p>or</p>

                    <div class="mt-3">
                        <label class="btn btn-outline-primary" for="formFile">Select Files</label>
                        <input @change="uploadImage($event)" type="file" id="formFile" :accept="acceptedUploadFormats">
                    </div>

                    <div class="mt-3 fs-6">
                        (Accepted File Formats are "JPG, JPEG, TIF , EPS, Vector Image and TIFF)
                    </div>

                </div>

            </div>
            
        </div>

    </div>

</template>

<style lang="scss">
    .image-drop-zone {

        .dropzone {
            border: 3px dashed $color-1;

            input {
                display: none;
            }
        }

        .active-dropzone {
            background: $color-1;
            border-color: $gray-0;
            color: $gray-0;

            h2 {
                color: $gray-0;
            }

            p {
                color: $gray-0;
            }
        }
    }
</style>