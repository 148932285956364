<script setup>

    // CrossSellSelectMultipleDropsStep3Page
    // Set up for multiple drops of mailings

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // Imports
    import { computed, ref, reactive } from 'vue';
    import { site } from '@/Site';

    // Constants
    const frequencyOptions = {
        BI_WEEKLY: 'bi-weekly',
        WEEKLY: 'weekly'
    }

    const numberOfMailingsOptions = {
        TWO: 2,
        THREE: 3,
        FOUR: 4
    }

    // State
    const frequency = ref("");

    const numberOfMailings = ref("");

    const startDate = ref("");

    const mailFrequency = reactive({
        "summary": [],
        "totals": {},
    });

    // Methods
    async function getMailFrequencySummary() {
        // If all fields are filled in call the API (they dont want a button for this just do it if all fields are filled in)
        if (frequency.value && numberOfMailings.value && startDate.value) {
            let frequencyResponse = await site.getFrequencySummary(frequency.value, numberOfMailings.value, startDate.value);

            // TODO this is probably not going to be how this is returned from the API 
            mailFrequency.summary = frequencyResponse.summary;
            mailFrequency.totals = frequencyResponse.totals;
        }
    }

    function switchToOneTime() {
        // Call API to switch to One Time Mailing
    }

    function saveForLater() {
        // TODO make the API call to save this product for later
    }

    // Computed
    const showFrequencyDetails = computed(() => {
        if (mailFrequency.summary.length > 0) {
            return true;
        }
        return false;
    });

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="root-page-container cross-sell-select-multiple-drops">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.THREE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">
                            <div>
                                <h1>Multiple Drops</h1>
                            </div>
                            <div class="ms-auto">
                                <!-- TODO: output the selected product -->
                                Prospecting Letter, Safeco Package (AP-9999)
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row">

                    <div class="col fs-5">
                        <a href="#" class="no-underline" @click="switchToOneTime">Switch to One-Time Drop</a>
                    </div>

                </div>

                <div class="row my-5">

                    <div class="col-sm-6 offset-sm-3 bg-color-3-xlt text-center px-5 py-3">

                        <div class="mb-5">
                            <label class="form-label fw-bold">Choose Frequency</label>
                            <select @change="getMailFrequencySummary" class="form-select" v-model="frequency">
                                <option value="">Choose Frequency</option>
                                <option :value="frequencyOptions.WEEKLY">Weekly</option>
                                <option :value="frequencyOptions.BI_WEEKLY">Bi-Weekly</option>
                            </select>
                        </div>

                        <div class="mb-5">
                            <label class="form-label fw-bold">Choose Number of Mailings</label>
                            <select @change="getMailFrequencySummary" class="form-select" v-model="numberOfMailings">
                                <option value="">Choose Number of Mailings</option>
                                <option :value="numberOfMailingsOptions.TWO">2</option>
                                <option :value="numberOfMailingsOptions.THREE">3</option>
                                <option :value="numberOfMailingsOptions.FOUR">4</option>
                            </select>
                        </div>

                        <div class="mb-5">
                            <label class="form-label fw-bold">Select Drop Start Date</label>
                            <!-- TODO: Add the calendar input when forms are merged in -->
                            <!-- This is just a dummy input to show the table below this -->
                            <input @change="getMailFrequencySummary" class="form-control" v-model="startDate" placeholder="Drop Start Date"/>
                        </div>

                    </div>

                </div>

                <div class="row my-5" v-if="showFrequencyDetails">
                    <div class="col-sm-6 offset-sm-3">

                        <div>
                            <p class="fw-bold">Total Prospect Count:{{mailFrequency.totals.quantity}}</p>
                        </div>

                        <div class="bg-color-2-dk py-2 text-center fs-4 fw-bold text-gray-0 mb-2">
                            Mail Frequency Summary
                        </div>

                        <div>

                            <table class="table">

                                <thead>
                                    <tr class="bg-color-3-lt">
                                        <th class="border-end border-gray-0">Date Drop</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="border-bottom border-gray-2" v-for="drop in mailFrequency.summary" :key="drop.dropDate">
                                        <td>{{drop.dropDate}}</td>
                                        <td>{{drop.quantity}}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>

                        <div class="text-center">
                            <p>Email will be sent approximately 2-3 weeks after direct mail drop date.</p>
                        </div>
                    </div>
                </div>

                <div class="row my-5">

                    <div class="col">

                        <div class="d-flex">
                            <div class="p-2">

                                <!-- TODO: Add the router back link-->
                                <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>
                            </div>
                            <div class="ms-auto p-2" v-if="showFrequencyDetails">
                                <a href="#" class="btn btn-outline-primary me-3" @click="saveForLater">Save for Later</a>
                                <!-- TODO: Add the router link to the next step-->
                                <router-link to="#TODO" class="btn btn-primary">Next</router-link>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .cross-sell-select-multiple-drops {
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }
    }
</style>