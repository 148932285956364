<script setup>

    // RoyaltyLoyaltyMovingPage
    // Landing page Royalty & Loyalty Moving with Safeco

    // Imports
    import { ProductSearchTerm } from '@/constants'
    import { onMounted } from 'vue';
    import { site } from '@/Site'

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import CallToActionImage from '@/site/components/CallToActionImage';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';
    import HeadlineWithIcon from '@/site/components/HeadlineWithIcon';

   
    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Moving", "lob": "Retention", "productNames": "MSC_Moving" });
    });
</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container royalty-loyalty-moving">

                <div class="row mb-5">

                    <div class="col-sm-8">

                        <div class="row">

                            <HeadlineWithIcon iconPath="/images/icons/real-estate-icon.png" title="Moving with Safeco" subTitle="Identify customers whose insurance needs may be changing." />

                        </div>

                        <div class="row mt-3">

                            <div class="col">
                                <p>
                                    The Moving with Safeco Program can help you boost retention by keeping in touch with your customers and staying on top of their changing insurance needs. Every month, Safeco will notify you via email if one of your customers' insurance needs may be changing. This is an excellent opportunity to engage in an Insurance Check-up.
                                </p>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <hr />

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="col">

                                <h2>How it works</h2>

                            </div>

                        </div>

                        <div class="row how-it-works">

                            <div class="col text-center">

                                <img src="/images/retention/moving-step1.png" class="img-fluid" alt="step 1" />

                                <p class="text-start">
                                    <strong>1. </strong> Each month, Safeco searches for your current customers whose insurance needs may be changing.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/moving-step2.png" class="img-fluid" alt="step 2" />
                                <!-- TODO: link to the product/asset when it is loaded -->
                                <p class="text-start">
                                    <strong>2. </strong> If your customer(s) are identified, Safeco will send you an email with a link to access your "Moving With Safeco List".
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/moving-step3.png" class="img-fluid" alt="step 3" />
                                <!-- TODO: link to the product/asset when it is loaded -->
                                <p class="text-start">
                                    <strong>3. </strong> Once you click on the link, you can view your <router-link to="/retention/moving/list" class="no-underline fw-bold">Moving with Safeco Customer List</router-link>. You can also review this list anytime.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/moving-step4.png" class="img-fluid" alt="step 4" />
                                <!-- TODO: link to the product/asset when it is loaded -->
                                <p class="text-start">
                                    <strong>4. </strong> Use our helpful tools, including the <router-link :to="`/products?f-product-search-term=${ProductSearchTerm.INSURANCE_CHECKUP_FORM}&active-tab=${TabIDs.RETENTION_TAB_ID}`" class="no-underline fw-bold">Insurance Check-Up Form</router-link> to follow-up with your customers.
                                </p>

                            </div>

                        </div>

                    </div>

                    <div class="col-sm">
                        <CallToActionImage class="ps-3" imageUrl="/images/retention/moving-with-safeco.jpg" linkUrl="/retention/moving/list" buttonText="View My Moving with Safeco List" />
                        
                        <!-- TODO: We need to add the correct routes and will likely have 2 more links -->
                        <!-- NOTE from creative: We need to align these two links with what is in the BRD (annual auto reporting).-->
                        <IconPanel class="mt-5" title="Moving with Safeco resources" icon="/images/icons/search-icon.png">
                            <LinkItem class="mb-3" title="Personalize and Download the Insurance Check-Up Form" :link="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.INSURANCE_CHECKUP_FORM, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="Personalize and Download the Insurance Check-Up Poster" :link="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.INSURANCE_CHECKUP_POASTER, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="Download the Moving with Safeco Brochure Sheet" :link="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.MOVING_WITH_SAFECO, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>

</template>

<style lang="scss">
    .royalty-loyalty-moving {

        .how-it-works {

            p {
                font-size: 1rem;
            }

            p strong {
                color: $color-2;
                font-size: 1.25rem
            }
        }
    }
</style>