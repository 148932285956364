<script setup>

    // WinBackSelectPolicyholders
    // Step 2 SelectPolicyholders

    // Constants ----

    const columns = [
        { field: 'agencyNumber', title: 'Sub Code' },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'cancelDate', title: 'Cancel Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
    ];

    // Imports
    import { reactive, computed, onMounted, ref, defineProps, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/constants'
     
    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';
    import { Util, Search } from "@dd-nucleus/nucleus-vue";

    //props

    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    // State
    const search = new Search('winback-list-search', 'row', 'winback-firstName');

    const router = useRouter();

    const searchTerm = ref('statCode');

    let selectedList = reactive([]);

    const detail = reactive({ product: props.product });

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const emit = defineEmits(['saveForLater']);

    const disableButton = ref(false);

    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });


    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    async function next() {
        await updateRecipients();

        router.push({
            name: 'PersonalizeStep3WinBackPage',
            params: {
                id: orderFlowDetails.id
            }
        });
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        await updateRecipients();
        emit('saveForLater');
    }

    async function updateRecipients() {

        disableButton.value = true;
        //TODO needs to be copied to other places.
        ///Getting all the records before store the selected recipients.

        const newSearch = new Search('winback-list-search', 'row', 'winback-firstName');
        await newSearch.activate();

        const allRecords = newSearch.results.rows;
        const selected = allRecords.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {
            return {
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2,
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "policyType": sl.policyType,
                "renewalDate": sl.renewalDate,
                "cancelDate": sl.cancelDate,
                "externalId": sl.externalId,
                "orderStateId": orderFlowDetails.id

            };
        });
        await site.personalizationInfo.addMailContacts(selected);
    }

    function sortSearch(value) {
        value = "winback-" + value

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }

    onMounted(async () => {
        if (Util.isNotEmpty(props.orderState)) {
            if (props.orderState?.mailContacts?.length > 0) {
                Object.assign(selectedList, props.orderState?.mailContacts.map(mc => mc.policyNumber));
            } else {
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.policyNumber));
            }
        }
    });

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    //methods
    function selectAll(isSelectAll, list) {
        selectedList.length = 0;
        if (isSelectAll)
            for (var i = 0; i < list.length; i++) {
                selectedList.push(list[i].policyNumber)
            }
    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
    }

    search.onResults(() => {
        if (props.orderState?.mailContacts?.length == 0) {
            selectedList.length = 0;
            Object.assign(selectedList, search.results?.rows?.map(mc => mc.policyNumber));
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const currentSelectedList = props.orderState?.mailContacts.map(mc => mc.policyNumber);
            const filteredSelectedList = search?.results?.rows?.filter(mc => currentSelectedList.includes(mc.policyNumber));
            if (filteredSelectedList.length > 0) {
                Object.assign(selectedList, filteredSelectedList.map(mc => mc.policyNumber));
            }
            else {
                selectedList.length = 0;
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.policyNumber));
            }
        }
    });

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col">
            <SearchContainer :search="search" default-view="list" :deep-link="false">

                <div class="row mb-2">
                    <div class="d-flex">
                        <div class="me-auto">
                            <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                        </div>
                    </div>
                </div>
                <div class="data-table">
                    <SearchTableV1 :columns="columns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">
                        <template v-slot:mailingDate="row">
                            {{formatDate(row.mailingDate)}}
                        </template>

                        <template v-slot:renewalDate="row">
                            {{formatDate(row.renewalDate)}}
                        </template>

                        <template v-slot:cancelDate="row">
                            {{formatDate(row.cancelDate)}}
                        </template>

                        <template v-slot:marketingChannel="row">
                            <div v-if="row.mailingDate">Direct Mail</div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            None of your policyholders currently meet the criteria for this type of campaign.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTableV1>
                </div>
            </SearchContainer>
        </div>
    </div>

    <div class="row mt-3">

        <div class="d-flex mb-3">
            <div class="me-auto">
                <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>
            </div>
            <div class="d-flex">
                <div class="mx-5"><button v-if="listLength > 0" @click="saveForLater" class="btn btn-primary" :disabled="disableButton">Save For Later</button></div>
                <button v-if="listLength > 0" @click="next" class="btn btn-primary" :disabled="disableButton">Next</button>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
</style>
