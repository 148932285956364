<script setup>

    // ReviewTile
    // A tile for reviewing the product info before adding to the cart

    // Components ----

    // Imports ----
    import { defineProps, computed, onMounted, ref, onUnmounted } from 'vue';
    import { site } from '@/Site';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { isNotEmpty } from '@/util';

    // Props ----
    const props = defineProps({
        product: {
            type: Object,
            required: true
        },
        personalization: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // Methods ----
    function previewPdf(pageFlexDocumentId) {
        site.personalizationInfo.openPdfPreview(pageFlexDocumentId);
    }

    // Computed ----

    const userInfo = computed(() => {
        return site.user;
    });

    const pdfPreviewUrl = computed(() => {

        if (isNotEmpty(props.personalization.pageFlexDocumentId) && isNotEmpty(userInfo.value.userName)) {
            const userName = userInfo.value.userName.replace(".Test@test.com", '');
            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;
            return `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${props.personalization.pageFlexDocumentId}&UserName=${userName}`;
        }
        else
            return '';

    });

    const toPersonalizationPage = computed(() => {
        const parentId = props.personalization.parentId ? props.personalization.parentId : props.personalization.id;
        const channelType = props.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${props.personalization.id}/${parentId}/${props.personalization.productId}/?edit=true&review=true&isSubscription=${props.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });


    const pdfData = ref('');
    const isLoaded = ref(false);

    async function showPdfContent() {
        const url = `/site/customize-download/preview-document?documentId=${props.personalization.pageFlexDocumentId}`
        await site.blob.getBlob(url)
            .then(response => {
                if (response && response.headers && response.headers['content-type'] == 'application/pdf') {
                    if (response && response.data) {
                        pdfData.value = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    }
                }
            }).catch(error => {
                console.error(error);
            });
    }

    onMounted(async () => {
        await showPdfContent();
        isLoaded.value = true;
    });
    onUnmounted(() => {
        URL.revokeObjectURL(pdfData.value);
    })

</script>

<template>

    <div class="row review-tile">

        <div class="col-sm-6">

            <div class="mb-3">

                <div class="fw-bold">Item</div>
                <div class="text-color-2">
                    {{props.product.name}}
                </div>

            </div>

            <div class="mb-3">

                <div class="fw-bold">Item ID</div>
                <div class="text-color-2">
                    {{props.product.itemNumber}}
                </div>

            </div>

            <div class="mb-3">

                <div class="fw-bold">Personalization <router-link :to="toPersonalizationPage" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>

            </div>

            <div class="mb-3">

                <div class="fw-bold">
                    Frequency
                    <template v-if="editable">
                        <router-link :to="`/personalization/step-3-drop-date/${props.personalization.id}?review=true`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link>
                    </template>
                </div>
                <div class="text-color-2 text-capitalize">
                    {{props.personalization.dropDetails.frequency}}
                </div>

            </div>

            <div class="mb-3">

                <div class="fw-bold">
                    Number of Mailings
                    <template v-if="editable">
                        <router-link :to="`/personalization/step-3-drop-date/${props.personalization.id}?review=true`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link>
                    </template>
                </div>
                <div class="text-color-2">
                    {{props.personalization.dropDetails.numberOfMailings}}
                </div>

            </div>

            <div class="mb-3" v-if="props.personalization.dropDetails.mailingDetails[0]">

                <div class="fw-bold">
                    Drop Start Date
                    <template v-if="editable">
                        <router-link :to="`/personalization/step-3-drop-date/${props.personalization.id}?review=true`" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link>
                    </template>
                </div>
                <div class="text-color-2">
                    {{site.formatDisplayDate(props.personalization.dropDetails.mailingDetails[0].mailDate)}}
                </div>

            </div>

            <div class="mb-3">

                <div class="fw-bold">Total Order Count</div>
                <div class="text-color-2">
                    {{props.personalization.targetCount}}
                </div>

            </div>

            <div>
                Note: Turn off Pop-up Blocker to allow for PDF previewing.
            </div>

            <div class="mb-5">
                <button class="btn btn-primary" @click="previewPdf(personalization.pageFlexDocumentId)">Preview PDF</button>
            </div>

            <div class="frequency-headline py-2 fs-4 text-center mb-1">
                Summary
            </div>

            <div>

                <table class="table">

                    <thead>
                        <tr class="bg-color-3-lt">
                            <th class="border-end border-gray-0">Date Drop</th>
                            <th class="border-end border-gray-0">Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="border-bottom border-gray-2" v-for="drop in props.personalization.dropDetails.mailingDetails" :key="drop.mailDate">
                            <td>{{site.formatDisplayDate(drop.mailDate)}}</td>
                            <td>{{drop.quantity}}</td>
                            <td>{{site.convertNumberToCurrency(drop.price)}}</td>
                        </tr>
                    </tbody>

                </table>

            </div>

            <div class="mt-2 d-flex fw-bold">
                <div class="price-message">Pricing prior to potential applied discount.</div>
            </div>

        </div>

        <div class="col-sm-5 offset-1" v-if="isLoaded">
            <template v-if=" pdfData.length > 0">
                <div id="preview-pdf-content">
                    <object :data="pdfData" type="application/pdf" :style="{width: '100%', height: '650px'}"></object>
                </div>
            </template>
            <template v-else>
                <iframe v-if="pdfPreviewUrl.length > 0" :src="pdfPreviewUrl" scrolling="yes" :style="{width: '100%', height: '650px'}" :id="personalization.pageFlexDocumentId" />
            </template>
        </div>

    </div>

</template>

<style lang="scss">

    .review-tile {
    }
</style>