// OrderDetails
// Services and actions related to order details

export default class OrderDetails {

    // Constructor
    constructor(store) {
        this.store = store;
    }

    /**
     * Fetch a specific order by id
     * @param {string} uuid - the unique identifer for the order
     */
    async getOrderDetails(uuid) {
        return await this.store.api.get(`/api/store/order/${uuid}/detail`)
    }
}