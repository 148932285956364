<script setup>

    // SelectAssetPage
    // First step in the order flow for personilaztion show them the options.

    // Constants ----
    const constants = {
        DEFAULT_TITLE: 'Search Results',          // Used when no category is selected
        DEFAULT_PAGE_SIZE: 100
    }

    // Components ----
    import ProductGridTile from './components/ProductGridTile';
    import MockProductGridTile from '@/site/areas/products/components/MockProductGridTile';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';

    // Imports ----
    import { computed, reactive, ref } from 'vue';
    import { site } from '@/Site'
    import { useRouter, useRoute } from 'vue-router';
    import { TabTypes } from './PersonalizeStep1Page';
    import { getProductFormat, ChannelTypes } from './SelectChannelPage';
    import { crossSellTypes } from '@/site/components/OpportunitiesTile';
    import { Util, Search } from "@dd-nucleus/nucleus-vue";
    import { ProgramType, getDataListTypeFromProgramType, getDataListOptionFromOppId, getProgramTypeForOrderState, getOppIdForOrderState, ProductType } from '@/constants'

    // State ----
    const router = useRouter();
    const route = useRoute();
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const channel = ref(route.params.channel);
    const searchTerm = ref(route.params.searchTerm);

    if (route.query.programType) {
        orderFlowDetails.programType = route.query.programType;
    }
    if (route.query.oppType) {
        orderFlowDetails.oppType = route.query.oppType;
    }
    if (route.query.statCode) {
        orderFlowDetails.statCode = route.query.statCode;
    }
    if (route.query.oppId) {
        orderFlowDetails.oppId = route.query.oppId;
    }
    if (route.query.channelType) {
        orderFlowDetails.channelType = parseInt(route.query.channelType);
    }
    if (route.query.productType) {
        orderFlowDetails.productType = route.query.productType;
    }
    if (route.query.isMultiChannel && route.query.isMultiChannel === 'true') {
        orderFlowDetails.isMultiChannel = true
    }
    if (route.query.isMultiChannel && route.query.isMultiChannel === 'false') {
        orderFlowDetails.isMultiChannel = false
    }
    ///orderFlowDetails.isMultiChannel = route.query.isMultiChannel || false;


    // Computed
    const headline = computed(() => {
        switch (orderFlowDetails.programType) {
            case ProgramType.CROSS_SELL:
                switch (orderFlowDetails.oppId) {
                    case crossSellTypes.AutoCrossSell:
                        return "Cross-sell auto to your monoline home policyholders.";
                    case crossSellTypes.HomeCrossSell:
                        return "Cross-sell home to your monoline auto policyholders.";
                    case crossSellTypes.UmbrellaCrossSell:
                        return "Cross-sell umbrella to your monoline auto policyholders.";
                    case crossSellTypes.SpecialtyCrossSell:
                        return "Cross-sell specialty products to your eligible policyholders.";
                    case crossSellTypes.LandLordProtectionCrossSell:
                        return "Cross-sell to your existing Landlord Protection policyholders.";
                    default:
                        return '';
                }
            case ProgramType.PROSPECTING:
                switch (orderFlowDetails.productType) {
                    case ProductType.AUTO:
                        return 'Promote auto to your prospects.';
                    case ProductType.SAFECO_PACKAGE:
                        return 'Promote package to your prospects.';
                    default:
                        return '';
                }
            case ProgramType.RETENTION:
                return 'Retain your current customers with personalized note cards.';
            default:
                return '';
        }
    });

    const subHeadline = computed(() => {
        if (channel.value && channel.value == ChannelTypes.EMAIL) {
            return 'Select an Email piece.'
        } else {
            return 'Select a Direct Mail piece.'
        }
    });

    const crossSellNote = computed(() => {
        return 'Availability for Cross-sell campaigns may vary by state. Please check back for future updates to availability and reach out to your TM with any questions.'
    });

    const tabName = computed(() => {
        switch (orderFlowDetails.programType) {
            case TabTypes.CROSS_SELL:
            case TabTypes.PET_INSURANCE:
                return TabIDs.CROSS_SELL_TAB_ID;
            case TabTypes.PROSPECTING:
            case TabTypes.DATA_LIST_UPLOAD:
                return TabIDs.PROSPECTING_TAB_ID;
            case TabTypes.RETENTION:
            case TabTypes.WINBACK:
            case TabTypes.ANNUAL_AUTO:
                return TabIDs.RETENTION_TAB_ID;
            default:
                return '';
        }
    });

    const productTypes = computed(() => {
        if (Util.isNotEmpty(orderFlowDetails.productType)) {
            if (orderFlowDetails.productType.indexOf(";")) {
                return orderFlowDetails.productType.split(";");
            }
            return orderFlowDetails.productType.split(",");
        }
        return [];
    });

    const productFormat = computed(() => {
        if (Util.isNotEmpty(orderFlowDetails.channelType)) {
            return getProductFormat(orderFlowDetails.channelType).split(",");
        }
        return [];
    });


    // Pre-build the search here, so we can hook into its events and properties.
    const search = new Search('product-list', 'detail', 'product-list-itemNumber', constants.DEFAULT_PAGE_SIZE);

    function setFilters() {
        const searchFilters = []

        // Set state restriction filter
        const productStateFilter = {
            'filterId': "product-state-restriction",
            'values': ['']
        }

        // Set product data list type filter
        const productDataListTypeFilter = {
            'filterId': 'product-data-list-type'
        }
        if (route.query.channelType == ChannelTypes.EMAIL && orderFlowDetails.programType == ProgramType.CROSS_SELL) {
            productDataListTypeFilter['values'] = getDataListTypeFromProgramType(ProgramType.CROSS_SELL_EMAIL)
        } else {
            productDataListTypeFilter['values'] = getDataListTypeFromProgramType(orderFlowDetails.programType)
        }
        searchFilters.push(productStateFilter, productDataListTypeFilter);

        // Set product search term filter only for prospecting
        if (orderFlowDetails.programType == ProgramType.PROSPECTING && !searchTerm.value) {
            searchFilters.push({
                'filterId': 'product-search-term',
                'value': orderFlowDetails.programType
            })
        }
        // Set product search term filter only for retention
        if (orderFlowDetails.programType == ProgramType.RETENTION && searchTerm.value) {
            searchFilters.push({
                'filterId': 'product-search-term',
                'value': searchTerm.value
            })
        }
        if ((orderFlowDetails.programType == ProgramType.CROSS_SELL ||
            orderFlowDetails.programType == ProgramType.ANNUAL_AUTO)
            && orderFlowDetails.oppId) {
            searchFilters.push({
                'filterId': 'product-data-list-option',
                'values': getDataListOptionFromOppId(orderFlowDetails.oppId)
            });
        }

        // Set product format
        searchFilters.push({
            'filterId': 'product-format',
            values: productFormat.value
        })

        // Set product type
        searchFilters.push({
            'filterId': 'product-type',
            values: productTypes.value
        })

        // Set all filters
        search.setFilters(searchFilters)
    }


    // Methods ----
    function back() {
        
        const back_url = router.options.history.state.back
        if (back_url.includes('home')) {
            if ([ProgramType.ANNUAL_AUTO, ProgramType.RETENTION, ProgramType.WINBACK].includes(orderFlowDetails.programType)) {
                router.push({ name: 'Home', query: { tab: ProgramType.RETENTION } })
            } else {
                router.push({ name: 'Home' })
            }
        } else if (back_url.includes('step-1')) {
            router.push({
                name: 'SelectChannelPage',
                query: {
                    statCode: orderFlowDetails.statCode,
                    productType: orderFlowDetails.productType,
                    programType: orderFlowDetails.programType,
                    oppType: orderFlowDetails.oppType,
                    oppId: orderFlowDetails.oppId
                }
            });
        }
        else {
            router.back()
        }

    }

    async function selected(id) {
        const product = search.results.rows.find(p => p.id == id);
        orderFlowDetails.productId = id;
        orderFlowDetails.programType = getProgramTypeForOrderState(product.dataListType);
        orderFlowDetails.productType = product.productTypes?.join(";");
        orderFlowDetails.oppId = getOppIdForOrderState(product.dataListOption);
        const channelType = product.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;

        let response;

        if (orderFlowDetails.isMultiChannel && channelType == ChannelTypes.EMAIL) {

            orderFlowDetails.parentId = orderFlowDetails.id;
            response = await site.personalizationInfo.startOrder(ChannelTypes.EMAIL);

            if (site.errorHandler.handleStartOrderError(response)) {

                router.push({
                    name: 'PersonalizeStep1Page',
                    params: {
                        id: response.id,
                        parentId: orderFlowDetails.parentId,
                        productId: orderFlowDetails.productId
                    },
                    query: {
                        channelType: ChannelTypes.EMAIL
                    }
                });
            }
        } else {
            router.push({
                name: 'SaveForLaterConfirmationPage',
                params: {
                    productId: product.id
                },
                query: {
                    isMultiChannel: route.query.isMultiChannel
                }
            });
        }
    }

    setFilters()

</script>

<template>
    <PageTemplate>

        <div class="container">
            <GlobalTabBar v-if="tabName" :active-id="tabName" />

            <div class="row">
                <div class="col-2 mt-4">
                    <button @click="back" class="btn btn-outline-primary">Previous</button>
                </div>
                <div class="col-8">
                    <p class="text-center fs-2">
                        {{headline}}
                    </p>

                    <h1 class="text-center mb-3">
                        {{subHeadline}}
                    </h1>
                    <p class="text-center mb-3" v-if="orderFlowDetails.programType == ProgramType.CROSS_SELL">
                        {{crossSellNote}}
                    </p>
                </div>
            </div>

            <div class="search-page">

                <SearchContainer :search="search" default-view="grid" :deep-link="false">

                    <div class="row">

                        <div class="col">



                            <SearchGrid>
                                <!-- Template for each item in a grid view -->
                                <template v-slot:grid="product">

                                    <ProductGridTile :product="product" @productSelected="selected" />

                                </template>

                                <!-- Template for no results -->
                                <template #empty>
                                    No products were found that match your criteria.
                                </template>

                                <!-- Template while results are initially loading -->
                                <template #loading-grid>
                                    <MockSearchGrid>
                                        <MockProductGridTile />
                                    </MockSearchGrid>
                                </template>

                            </SearchGrid>

                        </div>
                    </div>

                </SearchContainer>

            </div>

            <div class="row">
                <div class="col">
                    <button @click="back" class="btn btn-outline-primary">Previous</button>
                </div>
            </div>


        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .search-page {
        margin-top: 1rem;

        h1 {
            color: $black;
        }

        .sub-title {
            font-weight: $normal;
            font-size: 50%;
        }
    }

    .filter-bar {
    }

    .filter-section {
        border-top: 1px solid $gray-20;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .filter-section:first-child {
        padding-top: 0rem;
        border-top: 0px;
        padding-bottom: 1rem;
    }

    .filter-section:last-child {
        border-bottom: 0px;
        padding-bottom: 0rem;
    }

    .filter-section .header {
        font-weight: $bold;
        margin-bottom: 0.5rem;
    }
</style>