<script>

    // ProductGridTile
    // A tile for a single product intended to be displayed in grid mode.

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        tileHeight: '28rem',           // Height of the full tab, fixed for alignment purposes
        productNameLines: 2            // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;

</script>

<script setup>

    // Components ----

    // Imports ----
    import { reactive, defineProps, defineEmits, computed } from 'vue';
    import { site } from '@/Site';
    import { productRestrictions } from '@/site/areas/cart/CartPage';
    import { getUnitOfMeasureFriendlyName } from '@/constants'

    // Props ----
    const props = defineProps({
        // The product whose information to show.
        // API model: DD.Nucleus.Storefront.Api.ProductTileModel
        product: {
            type: Object,
            required: true
        }
    });


    // State ----
    const product = reactive(props.product);

    // Computed ----
    const isNotForMA = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORMA)
            return "NOT FOR USE IN MASSACHUSETTS";
        else return "";
    });

    const isNotForCO = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORCO)
            return 'NOT FOR USE IN COLORADO'
        else
            return ''
    });

    // Handlers ----
    const emit = defineEmits(['productSelected']);

    function select(productId, unitPrice, deliveryType) {
        emit('productSelected', productId, unitPrice, deliveryType);
    }

</script>

<template>

    <div class="product-grid-tile d-flex flex-column" :style="{ 'height': setup.tileHeight }">
        <div class="top">
            <ProductThumbnail :product="product" height="200px" no-image-available-url="/images/no-image.png" />
        </div>
        <div class="middle flex-fill">
            <!-- Stretchy middle part-->

            <div class="d-flex flex-row">
                <div class="flex-fill">
                    <ProductTags :product="product" />
                </div>
                <div>
                    <ProductFavoriteIcon :product="product" />
                </div>
            </div>

            <!-- Product name and item number -->
            <div class="title-link">
                <div @click="select(product.id, product.unitPrice)" class="name" v-snip="{ lines: setup.productNameLines }" :title="product.name">
                    {{ product.name }}
                </div>
            </div>
            <p class="description" v-snip="{ lines: setup.productNameLines }"> {{ product.description }}</p>
            <div class="item-number"><router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link></div>
            <div class="sale-price mb-2"> {{ site.convertNumberToCurrency(product.unitPrice) }} {{getUnitOfMeasureFriendlyName(product)}} </div>
            <div class="product-restriction text-danger"> {{ isNotForMA }} </div>
            <div class="product-restriction text-danger"> {{ isNotForCO }} </div>

        </div>
        <div class="bottom">
            <div>
                <button v-if="product.isPersonalizable" @click="select(product.id, product.unitPrice)" class="btn btn-primary w-100 mt-3">Select</button>
                <button v-if="product.isDownloadble" @click="download(product.downloadableFileName)" class="btn btn-primary w-100 mt-3">Download</button>
                <button v-if="product.isCustomizeDownloadble" @click="customizeDownload(product.id)" class="btn btn-primary w-100 mt-3">Customize</button>
            </div>  
        </div>
    </div>

</template>

<style lang="scss">

    .product-grid-tile {
        .name {
            font-size: 1.0rem;
            font-weight: 600;
            line-height: 1.1rem;
            color: #476791;
        }

        .description {
            font-size: 12px;
            margin-bottom: 0;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .sale-price {
            font-size: 14px;
            font-weight: bold;
        }

        .product-restriction {
            font-size: 14px;
            min-height: 20px;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }
    }
</style>