<script setup>

    // ProductDetail
    // Full detail for a product

    // Imports ----
    import { reactive, ref, defineProps, computed, onMounted } from 'vue';
    import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { getProgramTypeForOrderState, getOppIdForOrderState, getUnitOfMeasureFriendlyName } from '@/constants'
    import { isNotEmpty } from '@/util'

    const router = useRouter();

    // Components ----
    import ProductImageGallery from './ProductImageGallery';

    // Props ----
    const props = defineProps({
        // The product whose information to show.
        // API model: DD.Nucleus.Storefront.Api.ProductDetailModel
        productId: {
            type: String,
            required: true
        }
    });

    // State ----
    const productId = ref(props.productId);
    const isLoaded = ref(false);
    const form = new AddToCartForm({ id: props.productId }, 1);
    const detail = reactive({ product: { attributes: { availability: [] } } });
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    // Computed ----
    const product = computed(() => { return detail.product; });

    const productMessages = computed(() => { return product.value.messages || [] });

    const productPrice = computed(() => { return site.convertNumberToCurrency(product.value.unitPrice) });


    const availableStates = computed(() => {
        return detail.product.attributes.availability.join(', ')
    });

    const disablePersonalization = computed(() => {
        return !product.value.attributes?.hasProductDataType || product.value.attributes?.isKitItem;
    });

    const eligibleToCustomize = computed(() => {
        if (isNotEmpty(agencyDetails.value?.statId)) {
            return true;
        }
        const adminRoles = site.userRoles.getRolesForAdminAccess();
        var rec = site.user.roles.filter(r => adminRoles.includes(r.id));
        return rec?.length > 0;
    });

    // Methods ----
    async function addPersonalization() {
        if (product.value.attributes?.isExpressOrderingAllowed && agencyDetails.value.isOwnerMaster) {
            const oppId = getOppIdForOrderState(product.value.attributes?.dataListOption);
            if (isNotEmpty(oppId)) {
                const allStatCodes = await site.agencyDetails.getAgencySubCodes(oppId);
                console.warn('allStatCodes', allStatCodes);
                const ownerMasterPolicyCount = allStatCodes.find(a => a.statId == agencyDetails.value.statId)?.policyHoldersCount;
                const subcodesHasPolicyHolders = allStatCodes.filter(a => a.statId != agencyDetails.value.statId && a.policyHoldersCount > 0);

                if (ownerMasterPolicyCount && subcodesHasPolicyHolders?.length > 0) {
                    router.push({
                        name: 'ExpressOrderConfirmationPage',
                        params: {
                            productId: product.value.id
                        }
                    });
                    return;
                }
            }
        }
        if (!site.errorHandler.canStartOrder()) {
            return false;
        }
        orderFlowDetails.productId = props.productId;
        const programType = getProgramTypeForOrderState(product.value.attributes.dataListType);
        if (programType) {

            orderFlowDetails.programType = getProgramTypeForOrderState(product.value.attributes.dataListType);
            orderFlowDetails.productType = product.value.attributes?.productTypes?.join(";");
            orderFlowDetails.oppId = getOppIdForOrderState(product.value.attributes.dataListOption);
            let channelType = ChannelTypes.DIRECT_MAIL;
            if (product.value && product.value.attributes && product.value.attributes.isEmail) {
                channelType = ChannelTypes.EMAIL
            }
            const response = await site.personalizationInfo.startOrder(channelType, product.value.attributes.isSubscription);
            if (site.errorHandler.handleStartOrderError(response)) {
                router.push({
                    name: 'PersonalizeStep1Page',
                    params: {
                        id: response.id,
                        parentId: response.id,
                        productId: orderFlowDetails.productId
                    },
                    query: {
                        channelType: ChannelTypes.DIRECT_MAIL,
                        isSubscription: product.value.attributes.isSubscription,
                        searchType: 'productdetail'
                    }
                });
            }
        }
    }

    async function handleDownload(pdfUrl, fileName) {

        if (pdfUrl) {
            await site.downloadHandler.handleDownload(pdfUrl, fileName, true);
        }
        else {
            site.toast.error('Error while downloading the pdf. please contact system administrator', 'Error');
        }
    }

    async function customizeDownload(id) {

        if (!eligibleToCustomize.value) {
            site.toast.error("The user's current agency cannot be found, or they're not assigned to a current agency.");
            return;
        }

        orderFlowDetails.productId = id;

        router.push({
            name: 'CustomizeDownloadPage',
            query: {
                productId: id,
            }
        });
    }
    function onBack() {
        router.push({
            name: 'ProductSearch',
            query: {
                backToResults: 'true'
            }
        });
    }
    // Handlers ----
    onMounted(async () => {
        detail.product = Object.assign(detail.product, await site.products.getProductDetail(productId.value));
        isLoaded.value = true;
        form.product = detail.product;
        form.validate();
    });
</script>

<template>

    <div class="product-detail" v-if="isLoaded">

        <div class="row mt-2">
            <div class="col">
                <a href="#" @click="onBack()" class="no-underline"><i class="bi bi-chevron-left"></i> Back to Results</a>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 col-sm-7">
                <ProductImageGallery :product="product" />
            </div>

            <div class="col-12 mt-2 col-sm-5 mt-sm-0">

                <div class="tags">
                    <ProductTags :product="product" />
                </div>

                <h1 class="name" :title="product.name">
                    {{ product.name }}
                </h1>

                <div class="item-number">{{ product.itemNumber }}</div>

                <div class="price">
                    {{productPrice}} {{getUnitOfMeasureFriendlyName(product)}}
                </div>

                <FormContainer :form="form" v-if="product.attributes.canAddToCart">

                    <div class="mt-2">Quantity:</div>
                    <ProductAddToCartQty :product="product" />
                    <FieldMessages field-name="quantity" class="mt-2" />

                    <button class="btn btn-primary mt-3" @click="form.submit()" :disabled="form.status.isInvalid">Add To Cart</button>
                    <FormMessages :add-box="false" class="mt-2" />

                </FormContainer>

                <button v-if="product.attributes?.isPersonalizable" class="btn btn-primary mt-3" @click="addPersonalization" :disabled="disablePersonalization"> Add Personalization </button>

                <h5 class="text-uppercase fw-bold mt-4">Description</h5>
                <div class="description mt-2" v-html="product.description"></div>

                <div class="availability mt-2">{{ product.itemNumber }} available for these States: {{availableStates}}</div>

                <div class="mt-3 mb-3">
                    <ProductFavoriteIcon :product="product" class="text-color-1 fs-2" />
                </div>

                <button v-if="product.attributes?.isDownloadble" class="btn btn-primary mt-3" @click="handleDownload(product.pdfUrl, product.attributes?.downloadableFileName)"> Download </button>
                <button v-if="product.attributes?.isCustomizeDownloadable" @click="customizeDownload(product.id)" class="btn btn-primary mt-3">Customize</button>
                <MessageList :messages="productMessages" :add-box="false" />
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .product-detail {
        min-height: 30rem;

        .name {
            font-size: 1.875rem;
            line-height: 2rem;
        }

        .title-link {
            text-decoration: none;
        }

        .item-number {
            font-size: 1.125rem;
            color: $gray-3;
        }

        .price {
            font-size: 1.375rem;
        }

        .availability {
            font-size: 14px;
        }
    }
</style>
