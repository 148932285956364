<script setup>

    // ShippingAddressTile
    // Customizes the standard AddressTile component with options/markup specific to user shipping addresses.

    // Components ----

    // Imports ----
    import { defineProps, computed, defineEmits } from 'vue';
    import { site } from '@/Site';

    // Props ----
    const props = defineProps({
        // The address to show
        address: {
            type: Object,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to add a "set default" action button
        canSetDefault: {
            type: Boolean,
            required: false,
            default: false
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        },
        // An optional height to enforce for the address block.
        addressHeight: {
            type: String,
            required: false,
            default: null
        },
        // Whether to show edit button
        isEditShown: {
            type: Boolean,
            required: false,
            default: false
        },
        // Whether to show Default button
        isDefaultShown: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State ----

    // Computed ----
    const isDefault = computed(() => {
        if (typeof props.address === 'undefined')
            return false;
        else
            return (site.shipping.addresses.length > 0 && props.address.id === site.shipping.addresses[0].id);
    });

    // Events ----
    const emit = defineEmits(['edit','setDefault']);

    // Handlers ----
    async function onSetDefault() {
        await site.shipping.setDefaultShippingAddress(props.address.id);
        emit('setDefault');
    }

    function onEditClicked() {
        emit('edit');
    }

</script>

<template>

    <div class="shipping-address-tile">
        <AddressTile :address="props.address" :height="props.height" :address-height="props.addressHeight" :border="props.border">
            <template #top>
            </template>

            <div class="mt-2">
                <div class="tags">
                    <span v-if="isDefault" class="badge default-shipping"><i class="bi bi-star-fill"></i> Default Shipping</span>
                </div>
                <ActionButton v-if="props.canSetDefault && !isDefault" icon="bi bi-star" label="Set Default" @click="onSetDefault" />
                <ActionButton v-if="isEditShown" icon="bi bi-pencil-fill" @click="onEditClicked" />
            </div>
           
        </AddressTile>
        
    </div>

</template>

<style lang="scss">

    .shipping-address-tile {
        overflow-x: clip;

        .n-action-button {
            margin-right: 0.5rem;
        }

        .default-shipping {
            background-color: $highlight-1;         // TODO: SCSS variable
        }
    }

</style>