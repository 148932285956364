<script setup>

    // ModalBox
    // Bootstrap Modal
    import { onMounted, ref, defineProps, defineExpose } from 'vue';
    import { Modal } from 'bootstrap';

    let modalEle = ref(null);
    let thisModalObj = null;

    onMounted(() => {
        thisModalObj = new Modal(modalEle.value);
    });

    function _show() {
        thisModalObj.show();
    }

    function _close() {
        thisModalObj.hide()
    }

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: false
        },
        // This ID will match the id in the button that calls it with data-bs-target="#exampleModal"
        modalId: {
            type: String,
            required: true
        },
        // Valid Sizes...
        // Size	        Class       Modal max-width
        // Small	    .modal-sm	300px
        // Default	    None	    500px
        // Large	    .modal-lg	800px
        // Extra-large	.modal-xl	95%
        modalSize: {
            type: String,
            required: false
        }
    });

    // Expose 
    defineExpose({ show: _show, close: _close });
</script>

<template>

    <div class="modal-box">

        <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true" ref="modalEle">
            <div class="modal-dialog" :class="props.modalSize">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title w-100 text-center fw-bold">{{props.title}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
    .modal-box {
        .modal-xl {
            max-width: 95%;
        }
    }
</style>