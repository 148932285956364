<script setup>

    // RoyaltyLoyaltyWinBackPage
    // Landing page Royalty & Loyalty > Win Back

    //Imports
    import { reactive, onMounted, computed } from 'vue';
    import { site } from '@/Site';
    import { ProductFormat, ProductSearchTerm, ProductTatic,ReportName} from '@/constants'




    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import CallToActionImage from '@/site/components/CallToActionImage';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';
    import HeadlineWithIcon from '@/site/components/HeadlineWithIcon';
    import { isEmpty } from '@/util';

    

    // State
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    const userReportDetails = reactive({});
    const winCustReport=reactive({});
    const url = '/report/detail/{id}';

    // Computed ----
    const winbackCustUrl = computed(() => {
        return url.replace('{id}', winCustReport.value);
    });
    
    onMounted(async () => {
        Object.assign(userReportDetails, await site.reporting.getUserReportDetail());
        if (!isEmpty(userReportDetails) && !isEmpty(userReportDetails.reports)) {
            winCustReport.value = userReportDetails.reports.find(r => r.name == ReportName.WIN_BACK_AUTO_CUSTOMER_LIST)?.id;
        }

       // For Adobe analytics
       await site.analyticsHandler.push({ "tileCode": "Win Back", "lob": "Retention", "productNames": "MSC_Win Back" });
    });
</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container royalty-loyalty-win-back">

                <div class="row">

                    <div class="col-sm-8">

                        <div class="row">

                            <HeadlineWithIcon iconPath="/images/icons/partnership-icon.png" title="Win Back Program" subTitle="Grow your business by winning back former auto customers." />

                        </div>

                        <div class="row mt-3">

                            <div class="col">
                                
                                <p>Not all new business has to come from new customers. The Safeco Win Back Program is designed to help you increase new business by "winning back" former Safeco auto customers. Safeco can provide your agency with information on former auto customers in an effort to win them back. Safeco's Win Back direct mailer can also be mailed to a list of former customers that you provide.  </p>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <hr />

                            </div>

                        </div>
                        
                        <div class="row mt-3">

                            <div class="col">

                                <h2>How it works</h2>

                            </div>

                        </div>

                        <div class="row how-it-works mb-5">

                            <div class="col text-center">

                                <img src="/images/retention/win-back-step1.png" class="img-fluid" alt="step 1" />

                                <p class="text-start">
                                    <span>1. </span> Safeco compiles and provides you with a  list of former auto  customers over the past 30 days and up to 3 years.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/win-back-step2.png" class="img-fluid" alt="step 2" />

                                <p class="text-start">
                                    <span>2. </span> From this list, you can select which customers you'd like to contact based on loyalty indicators, renewal indicators and their cancellation month. <strong>Hint:</strong> you can upload and use your own list.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/win-back-step3.png" class="img-fluid" alt="step 3" />

                                <p class="text-start">
                                    <span>3. </span> Safeco provides a letter template that highlights the benefits of you and Safeco Insurance that you can personalize to leverage your past relationship with the customer.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/win-back-step4.png" class="img-fluid" alt="step 4" />

                                <p class="text-start">
                                    <span>4. </span> Once you place your order, Safeco will mail the letter on the 13th of the following month. The list is updated monthly, so be sure to check back regularly.
                                </p>

                            </div>

                        </div>

                    </div>

                    <div class="col-sm">
                        <!-- NOTE from creative: will take user to the 3 DM assets -->
                        <!--<CallToActionImage class="ps-3" imageUrl="/images/retention/win-back.jpg" :linkUrl="{path: '/products', query: { 'f-product-data-list-type': DataListType.WINBACK, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.RETENTION_TAB_ID }}" buttonText="Place Win Back Order" /> -->
                        <CallToActionImage class="ps-3" imageUrl="/images/retention/win-back.jpg" :linkUrl="{path: '/products', query: { 'f-product-tatic': ProductTatic.WINBACK, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.RETENTION_TAB_ID }}" buttonText="Place Win Back Order" />

                        <IconPanel class="mt-5" title="Win Back resources" icon="/images/icons/search-icon.png">
                            <LinkItem class="mb-3" title="Win Back Program Overview" :link="{path: '/products', query: { 'f-product-search-term': ProductSearchTerm.WINBACK_OVERVIEW, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                            <!-- <LinkItem class="mb-3" title="Win Back Letters and Postcard" :link="{path: '/products', query: { 'f-product-data-list-type': DataListType.WINBACK, 'f-product-state': selectedAgencyDetails.state, 'f-product-format': ProductFormat.LETTER, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" /> -->
                            <LinkItem class="mb-3" title="Win Back Letter" :link="{path: '/products', query: { 'f-product-tatic': ProductTatic.WINBACK, 'f-product-state': selectedAgencyDetails.state, 'f-product-format': ProductFormat.LETTER, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                        <!-- TODO: We need to add the correct routes -->
                        <IconPanel class="mt-3" title="Win Back lists" icon="/images/icons/newsletter-newspaper-icon.png">
                            <LinkItem class="mb-3" title="Win Back Customer List" :link="winbackCustUrl"  :openNewWindow="false" />
                            <LinkItem class="mb-3" title="View Win Back Mailing List" link="/account/order-history" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>

</template>

<style lang="scss">
    .royalty-loyalty-win-back {

        .how-it-works{

            p{
                font-size: 1rem;
            }
            
            p span{
                color: $color-2;
                font-size: 1.25rem;
                font-weight: bold;
            }
        }
    }
</style>