<script setup>
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import { useRouter, useRoute } from 'vue-router';
    import { onMounted, ref, reactive } from 'vue';

    import { site } from '@/Site';
    import { isNotEmpty } from '@/util';


    const router = useRouter();
    const route = useRoute();

    const bulkOrderState = reactive({});
    const isLoaded = ref(false);

    async function selectOption(option) {
        const personalizeAll = option
        const payload = {
            id: bulkOrderState.id,
            isSamePersonalization: personalizeAll
        }
        const response = await site.expressOrderPersonalizationInfo.updateBulkOrderStatePersonalization(payload);

        if (site.errorHandler.handleStartOrderError(response)) {
            router.push({
                name: 'ExpressOrderStepOne',
                params: {
                    bulkOrderStateId: route.params.bulkOrderStateId
                }
            });
        }
    }

    onMounted(async () => {
        const bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
        }
        isLoaded.value = true
    })

</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar />

            <div class="row mt-5" v-if="isLoaded">

                <div class="col-12 text-center mt-5">
                    <h1>Would you like to apply same personalization for all the Sub Codes selected?</h1>

                </div>
                <div class="col-4"></div>
                <div class="col-4 text-center ">
                    <div class="mt-4">
                        <button class="m-2 px-5 btn btn-outline-primary" @click="selectOption(false)">No</button>
                        <button class="m-2 px-5 btn btn-primary" @click="selectOption(true)">Yes</button>
                    </div>
                </div>

            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
</style>