<script setup>

    // ReportingSidebar
    // To display the reports in the group.

    // Imports ----
    import { defineProps, computed, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';
    import { isNotEmpty } from '@/util';

    const router = useRouter();

    const emit = defineEmits(['changeFavorite']);

    // Props ----
    const props = defineProps({
        groups: {
            type: Object,
            required: true,
            default: () => { }
        },
        selectedGroup: {
            type: Object,
            required: true,
            default: () => { }
        },
        favoriteReports: {
            type: Array,
            required: true
        },
        showFavorites: {
            type: Boolean,
            required: false
        }
    });

    const reportData = computed(() => {
        let result = {
            name: "",
            reports: []
        }
        if (props.selectedGroup.id) {
            const group = props.groups[props.selectedGroup.id]
            const subGroup = group.subGroups.find(item => {
                return item.name === props.selectedGroup.name
            });

            if (isNotEmpty(subGroup)) {
                result.reports = subGroup.reports.sort((a, b) => a.name.localeCompare(b.name))
                result.name = subGroup.name
            }
        }
        return result
    });

    const hasFavorites = computed(() => {
        return props.favoriteReports && props.favoriteReports.length > 0;
    });

    // Methods 
    function viewReport(id,reportname) {
        router.push({ name: 'ReportDetails', 
            params: {id: id,name: reportname} })
    }

    async function addFavorite(id, name) {
        await site.reporting.addFavoriteReport(id);

        emit('changeFavorite', id, name, true);
    }

    async function removeFavorite(id, name) {
        await site.reporting.deleteFavoriteReport(id);

        emit('changeFavorite', id, name, false);
    }

</script>
<template>

    <div class="report-panel">
        <div v-if="props.showFavorites">
            <h1 class="mb-3">Favorites</h1>
            <table class="table table-striped" v-if="hasFavorites">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th>Report Description</th>
                        <th>Category</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="data in props.favoriteReports" :key="data.id">
                        <td>
                            <a href="#" class="no-underline" @click="viewReport(data.reportId, data.name)">{{data.name}}</a>
                        </td>
                        <td class="description">{{data.description}}</td>
                        <td>{{data.reportGroupName}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" v-else>
                <h2>No favorite reports please select a group from the left side.</h2>
            </div>
        </div>

        <div v-else>
            <h1 class="mb-3">{{reportData.name}}</h1>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th>Report Description</th>
                        <th>Favorite</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="data in reportData.reports" :key="data">
                        <td>
                            <router-link v-if="data.externalUrl.startsWith('/')" :to="data.externalUrl" class="no-underline">{{data.name}}</router-link>
                            <a v-else href="#" class="no-underline" @click="viewReport(data.reportId, data.name)">{{data.name}}</a>
                        </td>
                        <td class="description">{{data.description}}</td>
                        <td class="text-center">
                            <i v-if="data.isFavorite" @click="removeFavorite(data.reportId, reportData.name); data.isFavorite == true" class="favoriteIcon icon bi-heart-fill"></i>
                            <i v-else @click="addFavorite(data.reportId, reportData.name)" class="favoriteIcon bi bi-heart"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</template>

<style lang="scss">
    .report-panel {

        thead {
            background: #06748c;
            color: #ffffff;
            font-size: 1rem;

            th:not(:last-child) {
                border-right: 1px solid #ffffff;
            }
        }

        tbody {
            border: unset;
        }

        td.description {
            width: 45%;
            white-space: break-spaces;
        }

        .table > :not(caption) > * > * {
            border-bottom-width: 0;
        }

        .favoriteIcon {
            color: $color-2;
        }
    }
</style>