<script setup>

    // FrequentlyAskedQuestionsPage
    // Frequently Asked Questions with a toggle for questions and answers

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import HelpCenterSidebar from '@/site/areas/help-center/components/HelpCenterSidebar';
    import CollapsiblePanel from '@/site/areas/help-center/components/CollapsiblePanel';
    import HeadlineContainer from '@/site/components/HeadlineContainer';
    import LinkItem from '@/site/components/LinkItem';

    // Imports
    import { ref, computed } from 'vue';

    // State -----
    const open = ref(false);

    // Methods ----
    function expandAll() {
        open.value = !open.value;
    }

    // Computed ----
    const viewHideText = computed(() => {
        if (open.value) {
            return 'Hide All';
        } else {
            return 'View All';
        }
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.HOME_TAB_ID" />

            <div class="frequently-asked-questions">

                <div class="row">

                    <div class="col-3 mt-5">
                        <HelpCenterSidebar />
                    </div>

                    <div class="col mt-5">
                        <h1>Frequently Asked Questions</h1>

                        <div class="text-end fw-bold text-color-1">
                            <a href="/downloads/AP-5702-E-Marketing-Solutions-Center-Frequently-Asked-Questions.pdf" target="_blank" class="no-underline">Download FAQ PDF</a> <span class="text-color-2-dk px-3">|</span> <a href="#" class="no-underline" @click="expandAll">{{viewHideText}}</a>
                        </div>

                        <HeadlineContainer class="my-3" title="Overview" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                What are the benefits of using the Marketing Solutions Center (MSC)?
                            </template>
                            <template v-slot:content>
                                <p>The Marketing Solutions Center (MSC) was designed with you, the agent in mind, and aims to support an enhanced user experience through streamlined menus and offerings. As your strategic partner, Safeco is committed to helping you grow your business. By redesigning and integrating the MSC with the Ignite platform, we've made it easier for you to quickly find tools and resources that will help with growth, retention, cross-selling, prospecting, as well as tools for your agency.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is the MSC integrated with the Ignite platform?
                            </template>
                            <template v-slot:content>
                                <p>Yes. The MSC supports the Ignite platform by offering tools and programs to help agents grow. For example, Cross-sell, referral, lead generation, and more.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is the MSC secure?
                            </template>
                            <template v-slot:content>
                                <p>Providing you with a secure online experience is one of Safeco's highest priorities. We recognize that your privacy and security are of the utmost importance and have devoted a great deal of effort to ensure that your personal information is safeguarded. Safeco utilizes industry-standard Secure Sockets Layer (SSL) technology to allow for the encryption of:</p>
                                <ul>
                                    <li>Critically sensitive data, such as credit card information</li>
                                    <li>Potentially sensitive information, such as your name and address</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                My default agency information is incorrect on the MSC. How do I change it?
                            </template>
                            <template v-slot:content>
                                <p>To update your agency information permanently, you'll need to update it in Safeco's Agency system. Please contact your Territory Manager directly to get your agency information permanently updated.</p>
                                <p>For direct mail campaigns and customizable brochures, you have the option to change your agency's information. During your order process, your contact information will be automatically pre-populated in the corresponding fields on the Personalize Page - Step 1 of the order process. You have the opportunity to modify or change any of this information. Once complete, your revised agency information will not be stored on the MSC for future programs. For additional information, please contact your Territory Manager directly.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How does Safeco select prospects for the lead generation programs?
                            </template>
                            <template v-slot:content>
                                <p>Safeco uses third party data based on pre-determined selection criteria. It's important to understand that a prospect is not a lead; they are consumers whose profile potentially matches our preferred customers. They may not necessarily be shopping for insurance. Safeco offers telemarketing scripts to help start the conversation, found on the MSC <em>Prospecting</em> landing page.</p>
                            </template>
                        </CollapsiblePanel>

                        <HeadlineContainer class="my-3" title="Navigation" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I find what I need?
                            </template>
                            <template v-slot:content>
                                <p>The MSC has an advanced search feature that allows you to search by key words, category, product, format, program or form numbers (AP #'s) to find what you are looking for. It is located in the upper right-hand corner of the screen, denoted by a magnifying glass.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I find the brochure or collateral that I'm looking for?
                            </template>
                            <template v-slot:content>
                                <p>All available brochures can be found with the Advanced Search Feature. You can find this feature in the support tab at the top of the page, denoted by a magnifying glass. The results of your search will deliver all available resources or materials that match your search criteria.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Where can I order supplies?
                            </template>
                            <template v-slot:content>
                                <p>
                                 While the MSC offers marketing programs to help sell Safeco products or retain your existing customers, there are other materials available to your agency for day-to-day operations. Order these miscellaneous items (such as envelopes, letterhead and more) via the <LinkItem style="display: inline-block;"  title="Branded Supplies" link="/marketing#Branded Supplies" :isExternal="true" :openNewWindow="true" :showChevron="false" /> tab under <em>Marketing Materials</em>. For other items, please contact your Territory Manager.
                                </p>
                            </template>
                        </CollapsiblePanel>

                        <HeadlineContainer class="my-3" title="Ordering" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Where can I find information about my orders?
                            </template>
                            <template v-slot:content>
                                <p>All information regarding orders placed on the MSC can be found in the <em>My Account</em> section under <em>Order History</em>. Here you can see the details of each order placed including the order number, quantities ordered, date the order was placed, as well as other relevant data. If you are looking for a mailing list for an order, you can find this in the <em>My Account</em> section under <em>My Lists</em>, approximately one week after the order is placed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I know when my marketing materials have shipped?
                            </template>
                            <template v-slot:content>
                                <p>Once you have placed an order on the MSC, you will receive an email confirmation with order details. You will receive a second email once the order ships, which includes the ship date and tracking number.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Will I get a sample of my direct mail order delivered to my office?
                            </template>
                            <template v-slot:content>
                                <p>For all MSC direct mail programs, a sample piece will be mailed to the agent whose contact information is listed on the letter. This is called a "seed." The seed is mailed to notify you when the mail is delivering to your customer's or prospect's mailbox. Please note, if you placed several orders you will receive a seed for each order placed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I access my mailing list?
                            </template>
                            <template v-slot:content>
                                <p>The mailing list can be found in the <em>My Account</em> tab under <em>My Lists</em>. The mailing list is available to download the day <u>after</u> the mail date. You can locate the mail date for your order within the confirmation email you'll receive once your order has been mailed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I know when my direct mail order has mailed?
                            </template>
                            <template v-slot:content>
                                <p>You can locate the mail date for your order within the confirmation email that's sent to you once your order has been completed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I customize brochures or direct mail with my agency information?
                            </template>
                            <template v-slot:content>
                                <p>You have the opportunity to change your agency information during Step 1 of the ordering process (Personalization). Once the order is complete, your revised agency information will be printed on the marketing piece but will not be stored on the MSC. Be sure your contact name and phone number are accurate by clicking on and approving the PDF preview. Once the order is placed and printed, it cannot be modified. To permanently change your agency information within the Safeco main agency database, please contact your Territory Manager directly.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I upload my agency logo?
                            </template>
                            <template v-slot:content>
                                <p>You can use your own agency logo or head shot photograph of yourself to customize your brochures and direct mail postcards and letters. In order to provide a clear, crisp image of your logo and/or picture, it is important to upload images that are high resolution. For photos, a headshot (shoulders and head) with a solid color background works best. Most digital cameras or phones will produce an image that will meet these quality requirements. For the best results, use a high resolution image of 300 DPI minimum. The higher the DPI, the clearer the photo or logo will be once it's printed. Acceptable file formats include Vector image, EPS, JPEG, TIF and TIFF.</p>
                                <p>You can upload a photo or logo in the <em>My Account</em> section under <em>My Images Library</em>. Click "Select Files" to begin the upload process.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is it possible to return items?
                            </template>
                            <template v-slot:content>
                                <p>Safeco does not accept returned merchandise.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is it possible to cancel orders?
                            </template>
                            <template v-slot:content>
                                <p>If you ordered a mailing to policyholders within your book of business, or if you ordered a customized printed brochure (Print-On-Demand), we can cancel orders <u>if the request is received within 24 hours</u> of order placement. If you ordered a prospect mailing where Safeco is purchasing names on your behalf, the order cannot be cancelled once order is placed.</p>
                                <p>If you need to modify or cancel a direct mail order that is not prospecting, call the MSC Help Desk at (800) 845- 9860 within 24 hours of placing your order.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How does the pricing work on brochures?
                            </template>
                            <template v-slot:content>
                                <p>In most cases, you are eligible to receive a limited quantity of brochures or collateral at no charge per quarter. The MSC does allow you to purchase additional quantities beyond the free quarterly allowance for an additional cost.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                What is the benefit of customizing brochures for Print-on-Demand (POD)?
                            </template>
                            <template v-slot:content>
                                <p>Customization is an excellent enhancement to your marketing efforts. The benefit of Print-on-Demand (POD) is the ability to customize the piece with your agency information including phone number and website address. The brochures are then professionally printed on high-quality paper at a price lower than most local copy centers, and shipped directly to you.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I order larger quantities of "free" items than the system will allow?
                            </template>
                            <template v-slot:content>
                                <p>Larger quantities of items are available. Most items do have a maximum quantity allowable by quarter per agent at no cost. The MSC does allow you to purchase additional quantities beyond the free quarterly allowance for an additional cost.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Does Safeco offer logo merchandise?
                            </template>
                            <template v-slot:content>
                                <p>Safeco offers agents access to Safeco branded merchandise at a reasonable price. These can be accessed directly from the <em>Promotional Merchandise</em> tab, under <em>Marketing Materials</em>.</p>
                            </template>
                        </CollapsiblePanel>

                        <HeadlineContainer class="my-3" title="Support" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Where do I go for help with the MSC?
                            </template>
                            <template v-slot:content>
                                <p>Review the Frequently Asked Questions under the Help Center link in the footer. If you have additional questions, you can:</p>
                                <ul>
                                    <li>Call the Marketing Solutions Center Help Desk at (800) 845-9860. Customer service representatives are available to help you between the hours of 8:00 AM - 9:00 PM ET.</li>
                                    <li>Send an email to <a href="mailto:create@safeco.com">create@safeco.com</a> and a Safeco representative will respond.</li>
                                    <li>Contact your Territory Manager directly.</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <div class="mt-5">
                            <p class="fw-bold">
                                Didn't find what you were looking for in this FAQ?
                            </p>
                            <p>
                                Send an email with your question to <a href="mailto:create@safeco.com">create@safeco.com</a>
                            </p>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .frequently-asked-questions {
        .panel-title {
            color: $color-1;
        }

        .panel-content {
            color: $gray-3;
        }

        .py-3 {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }
    }
</style>