<script setup>

    // RoyaltyLoyaltyClientReferralPage
    // Landing page client referrals 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import CallToActionImage from '@/site/components/CallToActionImage';
    import HeadlineWithIcon from '@/site/components/HeadlineWithIcon';

    // Imports
    import { ProductSearchTerm } from '@/constants'
    import { onMounted } from 'vue';
    import { site } from '@/Site'

   // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Client Referral", "lob": "Retention" ,"productNames": "MSC_Client Referral" });
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container royalty-loyalty-client-referral">

                <div class="row">

                    <div class="col-sm-8">

                        <div class="row">

                            <HeadlineWithIcon iconPath="/images/icons/teamwork-icon.png" title="Client Referral Program" subTitle="Safeco’s Client Referral Program" />

                        </div>

                        <div class="row mt-3">

                            <div class="col">

                                <p>Referrals are the number one source for new business for most agencies. In fact, nothing carries more weight with potential clients than a positive referral from someone they know and trust.   Safeco’s Referral Program provides a toolkit of resources that help agents generate new business from within - and strengthen your existing relationships.</p>

                            </div>

                        </div>

                        <div class="row mt-2">

                            <div class="col">

                                <div class="d-flex justify-content-evenly">

                                    <div class="fw-bold"><img src="/images/icons/teal-checkmark-icon.png" /> Grow your book</div>
                                    <div class="fw-bold"><img src="/images/icons/teal-checkmark-icon.png" /> Increase profitability</div>
                                    <div class="fw-bold"><img src="/images/icons/teal-checkmark-icon.png" /> Close more business</div>

                                </div>

                            </div>

                        </div>

                        <div class="row my-5">

                            <div class="col">

                                <hr />

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <h2>How it works</h2>

                            </div>

                        </div>

                        <div class="row how-it-works">

                            <div class="col-sm-4 text-center">

                                <img src="/images/retention/client-referral-step1.png" class="img-fluid" />

                                <p class="text-start">
                                    <strong><span class="heading">1. </span> Communication Strategy  </strong>
                                        Add a referral program to your customer communication strategy - such as Insurance Check-up, email correspondence, or a monthly/quarterly newsletter.
</p>

                            </div>

                            <div class="col-sm-4 text-center">

                                <img src="/images/retention/client-referral-step2.png" class="img-fluid" />

                                <p class="text-start">
                                    <strong><span class="heading">2.</span> Customize your Style</strong>
                                    Your style of communication drives the quantity of referrals. Share personal stories about you and your team outside of work, such as: volunteer efforts, community involvement, and sponsorships. <strong>Hint:</strong> The more your customers know about you, the more they can and will share with their friends and family.
                                </p>

                            </div>

                            <div class="col-sm-4 text-center">

                                <img src="/images/retention/client-referral-step3.png" class="img-fluid" />

                                <p class="text-start">
                                    <strong><span class="heading">3.</span> Leverage your Customers</strong>
                                    Your customers already know and trust you. Encourage them to spread the word to their friends and family with the Safeco Client Referral Program.
                                </p>

                            </div>

                        </div>

                       

                    </div>

                    <div class="col-sm">

                        <CallToActionImage  class="ps-3" imageUrl="/images/retention/client-referral.jpg" :linkUrl="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.Client_Referral, 'active-tab': TabIDs.RETENTION_TAB_ID }}" buttonText="View All Client Referral Program Materials" />
                        
                    </div>

                </div>
            
            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .royalty-loyalty-client-referral {

        .how-it-works {

            p {
                font-size: 1rem;
            }

            p .heading {
                color: $color-2;
                font-size: 1.25rem
            }
        }
    }
</style>