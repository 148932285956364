<script setup>

    // ZipCodePanel
    // Step 2 on selecting by zipcode

    // Constants
    const zipRadius = {
        ZERO: { value: 0, text: '0 Miles' },
        FIVE: { value: 5, text: '5 Miles' },
        TEN:  { value: 10, text: '10 Miles' }, 
        FIFTEEN: { value: 15, text: '15 Miles' }, 
        TWENTY_FIVE: { value: 25, text: '25 Miles' },

    }

    const prospectTypeOption = {
        WITHALL: 'withPhoneAll',
        WITHSELECT: 'withPhoneSelect',
        WITHOUTALL: 'withoutPhoneAll',
        WITHOUTSELECT: 'withoutPhoneSelect',
    }

    // Imports
    import { ref, reactive, onMounted, computed, defineProps, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';

    // Components
    import ModalBox from '@/site/components/ModalBox';
    import ZipCodeLookup from './ZipCodeLookup';
    import { ChannelTypes } from '@/constants'

    //props
    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });
    const router = useRouter();
    const emit = defineEmits(['saveForLater']);

    //State
    const postalCodes = ref("");
    const postalRadius = ref(zipRadius.ZERO.value);
    const crossState = ref(false);
    const showPricing = ref(false);
    const zipModal = ref();
    const countWithPhoneNumber = ref(0)
    const countWithoutPhoneNumber = ref(0)
    const originalCountWithPhoneNumber = ref(0)
    const originalCountWithoutPhoneNumber = ref(0)
    const priceWithPhoneNumber = ref({});
    const priceWithoutPhoneNumber = ref({});
    const prospectType = ref(prospectTypeOption.WITHALL);
    const detail = reactive({ product: props.product });
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const availablePricing = ref(null);
    const reload = ref(0);

    // Methods
    async function submit() {

        if (detail.product && detail.product.itemNumber) {
            const data = {
                "zipCodes": postalCodes.value.split(','),
                "zipRadius": postalRadius.value,
                "crossStateBoundary": crossState.value,
                "itemNumber": detail.product.itemNumber,
            }

            const countPrices = await site.personalizationInfo.getProspectCountPrice(data);
            if (countPrices && countPrices.length == 2) {
                var withPhone = countPrices.find(cp => cp.price.includePhoneNumber);
                var withOutPhone = countPrices.find(cp => !cp.price.includePhoneNumber);
                countWithoutPhoneNumber.value = withOutPhone?.count?.countWithFactor;
                countWithPhoneNumber.value = withPhone.count?.countWithFactor;

                originalCountWithoutPhoneNumber.value = withOutPhone?.count?.countWithFactor;
                originalCountWithPhoneNumber.value = withPhone?.count?.countWithFactor;

                priceWithPhoneNumber.value = withPhone?.price;

                priceWithoutPhoneNumber.value = withOutPhone?.price
            }
            else {
                countWithoutPhoneNumber.value = countWithPhoneNumber.value = originalCountWithoutPhoneNumber.value = originalCountWithPhoneNumber.value = 0;

                priceWithPhoneNumber.value = priceWithoutPhoneNumber.value = {};
            }

            showPricing.value = true;
            if (availablePricing.value) {
                availablePricing.value.scrollIntoView({ behavior: "smooth" });
            }

        }
    }

    function setSelectedZipCodes(zipCodes) {

        postalCodes.value = zipCodes.map((item) => { return item.zipCode }).join(',')


        zipModal.value.close();

        submit();
    }

    async function recalculate() {

        let maxCount = 0;
        priceWithPhoneNumber.value.targetCount = originalCountWithPhoneNumber.value;
        priceWithoutPhoneNumber.value.targetCount = originalCountWithoutPhoneNumber.value;

        if (prospectType.value == prospectTypeOption.WITHOUTSELECT) {
            maxCount = Number(originalCountWithoutPhoneNumber.value);
        } else {
            maxCount = Number(originalCountWithPhoneNumber.value)
        }
        confirmMax(maxCount);
    }

    async function confirmMax(max) {
        const targetCountForWithoutPhoneNumber = priceWithoutPhoneNumber.value.targetCount;
        if (prospectType.value == prospectTypeOption.WITHOUTSELECT) {
            let targetCount = parseInt(targetCountForWithoutPhoneNumber);
            if (isNaN(targetCount)) {
                return
            }
            if (targetCount > max) {
                priceWithoutPhoneNumber.value.targetCount = max;
                targetCount = max;
            }
            priceWithoutPhoneNumber.value = await site.personalizationInfo.getProspectPrice({
                "itemNumber": detail.product.itemNumber,
                "targetCount": targetCount,
                "includePhoneNumber": false,
            });
        }

        const targetCountForPhoneNumber = priceWithPhoneNumber.value.targetCount;
        if (prospectType.value == prospectTypeOption.WITHSELECT) {
            let targetCount = parseInt(targetCountForPhoneNumber);
            if (isNaN(targetCount)) {
                return
            }
            if (targetCount > max) {
                priceWithPhoneNumber.value.targetCount = max;
                targetCount = max;
            }
            priceWithPhoneNumber.value = await site.personalizationInfo.getProspectPrice({
                "itemNumber": detail.product.itemNumber,
                "targetCount": targetCount,
                "includePhoneNumber": true,
            });
        }
    }


    function getPayload() {

        const payload = {
            zipCodes: postalCodes.value,
            zipRadius: postalRadius.value,
            crossStateBoundary: crossState.value,
            isOwnListUploaded: false,
        }

        if (prospectType.value == prospectTypeOption.WITHALL || prospectType.value == prospectTypeOption.WITHSELECT) {
            Object.assign(payload, { includePhoneNumber: true });
            Object.assign(payload, { targetCount: priceWithPhoneNumber.value.targetCount });
            Object.assign(payload, { totalPrice: priceWithPhoneNumber.value.finalPrice });
            Object.assign(payload, { unitPrice: priceWithPhoneNumber.value.finalUnitPrice });
        }

        if (prospectType.value == prospectTypeOption.WITHOUTALL || prospectType.value == prospectTypeOption.WITHOUTSELECT) {
            Object.assign(payload, { includePhoneNumber: false });
            Object.assign(payload, { targetCount: priceWithoutPhoneNumber.value.targetCount });
            Object.assign(payload, { totalPrice: priceWithoutPhoneNumber.value.finalPrice });
            Object.assign(payload, { unitPrice: priceWithoutPhoneNumber.value.finalUnitPrice });
        }

        return payload;
    }

    async function next() {

        const payload = getPayload();

        await site.personalizationInfo.updateProspectingZipCodes(payload);

        router.push({
            name: 'PersonalizeStep3Page',
            params: {
                id: orderFlowDetails.id
            },
        });
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        const payload = getPayload();
        await site.personalizationInfo.updateProspectingZipCodes(payload);
        emit('saveForLater');
    }

    async function showModal() {
        zipModal.value.show();
        if (postalCodes.value?.length == 0) {
            reload.value = reload.value + 1;
        }
    }


    //computed
    const showNext = computed(() => {
        return (((prospectType.value == prospectTypeOption.WITHSELECT || prospectType.value == prospectTypeOption.WITHALL)
            && priceWithPhoneNumber.value && priceWithPhoneNumber.value.targetCount > 0)
            || ((prospectType.value == prospectTypeOption.WITHOUTSELECT || prospectType.value == prospectTypeOption.WITHOUTALL)
                && priceWithoutPhoneNumber.value && priceWithoutPhoneNumber.value.targetCount > 0));
    });

    const showCheckAvailability = computed(() => {

        return postalCodes.value && postalCodes.value.length > 0;

    });

    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    // Computed
    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    onMounted(async () => {

        postalCodes.value = props.orderState.zipCodes ? props.orderState.zipCodes : postalCodes.value;
        postalRadius.value = props.orderState.zipRadius ? props.orderState.zipRadius : postalRadius.value;
        crossState.value = props.orderState.crossStateBoundary ? props.orderState.crossStateBoundary : crossState.value;
    });


</script>

<template>

    <ModalBox modalId="zipCodeLookUp" title="ZIP Code Look Up" modalSize="modal-lg" ref="zipModal">

        <template v-slot:body>

            <ZipCodeLookup @selectedZips="setSelectedZipCodes" :reload="reload" modal-size="large" />

        </template>

    </ModalBox>

    <div class="prospecting-step2-zip-code">

        <div class="row">
            <div class="col-sm-6 offset-sm-3 text-center bg-color-3-xlt zip-code-lookup">
                <button class="btn btn-outline-primary mt-3" @click="showModal">
                    Zip Code Lookup
                </button>
                <div class="py-2 d-flex align-items-center">
                    <hr class="flex-grow-1" />
                    <div class="px-5">OR</div>
                    <hr class="flex-grow-1" />
                </div>
                <div class="mb-3 text-start">
                    <label class="form-label"><strong>ZIP Code(s)</strong> (Separate by commas)</label>
                    <input type="text" v-model="postalCodes" class="form-control">
                </div>
                <div class="mb-3 text-start">
                    <label class="form-label fw-bold">ZIP Code Radius <a href="/downloads/Zip-Code-Radius.pdf" class="no-underline fw-normal fs-6" target="_blank">Information on Zip Radius</a></label>
                    <select class="form-select" v-model="postalRadius">
                        <option :value="zipRadius.ZERO.value">{{zipRadius.ZERO.text}}</option>
                        <option :value="zipRadius.FIVE.value">{{zipRadius.FIVE.text}}</option>
                        <option :value="zipRadius.TEN.value">{{zipRadius.TEN.text}}</option>
                        <option :value="zipRadius.FIFTEEN.value">{{zipRadius.FIFTEEN.text}}</option>
                        <option :value="zipRadius.TWENTY_FIVE.value">{{zipRadius.TWENTY_FIVE.text}}</option>
                    </select>
                </div>
                <div class="mb-3 form-check text-start">
                    <label class="form-check-label">
                        <input type="checkbox" v-model="crossState" class="form-check-input">
                        Okay to cross state boundaries
                    </label>
                </div>
                <button type="submit" class="btn btn-primary mb-3" :disabled="!showCheckAvailability" @click="submit">Check Availability</button>
            </div>
        </div>
        <div ref="availablePricing">
            <div class="row mt-3" v-if="showPricing">
                <div class="col-sm-6 offset-sm-3 text-center bg-color-2-dk p-2 fw-bold fs-4">
                    Estimated Available Prospects & Pricing
                </div>
            </div>
        </div>
        <div class="row mt-3 price-section" v-if="showPricing">
            <div class="col-sm-6 offset-sm-3">
                <div class="row">
                    <div class="col p-0 fw-bold">
                        Safeco recommends including phone numbers when available.
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-5">
                        <h4 class="fw-normal">Prospects with Phone Numbers</h4>
                        <p class="fst-italic fs-6">(Additional charges apply)</p>
                    </div>
                    <div class="col-sm-6 offset-1">
                        <div class="form-check">
                            <label class="form-check-label fw-bold">
                                <input class="form-check-input" type="radio" :value="prospectTypeOption.WITHALL" v-model="prospectType" name="prospectType" @click="recalculate(originalCountWithPhoneNumber)">

                                <div>
                                    <span class="text-danger">{{countWithPhoneNumber}}</span>

                                    <span class="fw-normal fs-6 text-gray-4">
                                        (${{priceWithPhoneNumber.finalUnitPrice}} Each)
                                    </span>
                                </div>
                                <div class="fs-6 text-gray-4">
                                    <span class="fw-normal">Total:</span>
                                    {{site.convertNumberToCurrency(priceWithPhoneNumber.finalPrice)}}
                                </div>
                                <div class="fs-6 text-danger">
                                    Pricing prior to potential applied discount.
                                </div>
                            </label>
                        </div>
                        <div class="mb-3 form-check">
                            <input class="form-check-input" type="radio" :value="prospectTypeOption.WITHSELECT" name="prospectType" v-model="prospectType" @input="recalculate">
                            <label class="form-check-label" for="selectQuantity">
                                Select Quantity
                            </label>
                        </div>
                        <div class="form-check" v-if="prospectType == prospectTypeOption.WITHSELECT">
                            <label class="form-label">Quantity</label>
                            <input type="number" class="form-control" v-model="priceWithPhoneNumber.targetCount" @input="confirmMax(countWithPhoneNumber)" :max="countWithPhoneNumber">
                        </div>
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col">
                        <hr />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-5">
                        <h4 class="fw-normal">Prospects without Phone Numbers</h4>
                    </div>
                    <div class="col-sm-6 offset-1">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="prospectType" :value="prospectTypeOption.WITHOUTALL" v-model="prospectType" @click="recalculate(originalCountWithoutPhoneNumber)">
                            <label class="form-check-label fw-bold" for="allAvailableQuantity">
                                <div>
                                    <span class="text-danger">{{countWithoutPhoneNumber}}</span>
                                    <span class="fw-normal fs-6 text-gray-4">(${{priceWithoutPhoneNumber.finalUnitPrice}} Each)</span>
                                </div>
                                <div>
                                    <div class="fs-6 text-gray-4">
                                        <span class="fw-normal">Total:</span>
                                        {{site.convertNumberToCurrency(priceWithoutPhoneNumber.finalPrice)}}
                                    </div>
                                    <div class="fs-6 text-danger">
                                        Pricing prior to potential applied discount.
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="mb-3 form-check">
                            <input class="form-check-input" type="radio" :value="prospectTypeOption.WITHOUTSELECT" name="prospectType" v-model="prospectType" @input="recalculate">
                            <label class="form-check-label" for="selectQuantity">
                                Select Quantity
                            </label>
                        </div>
                        <div class="form-check" v-if="prospectType == prospectTypeOption.WITHOUTSELECT">
                            <label class="form-label">Quantity</label>
                            <input type="number" class="form-control" v-model="priceWithoutPhoneNumber.targetCount" @input="confirmMax(countWithoutPhoneNumber)" :max="countWithoutPhoneNumber">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-5">

            <div class="col-sm-6">

                <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>


            </div>

            <div class="col-sm-6 text-end">
                <button v-if="isAgencySelected" @click="saveForLater" class="btn btn-primary me-3" >Save For Later</button>
                <button class="btn btn-primary" @click="next" :disabled="!showNext">Next</button>
            </div>

        </div>

    </div>
</template>
<style lang="scss">
    .prospecting-step2-zip-code {
        .zip-code-lookup {
            padding: 0 150px
        }

        label {
            font-size: 1.125rem;
        }

        .price-section {
            min-height: 20rem;
        }
    }
</style>