<script setup>

    // IconPanel
    // Icon Panel for use when we have an icon in the header of the panel
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    });
    

</script>

<template>

    <div class="card icon-panel border-0">

        <div class="card-body">

            <h3 class="card-title"><img :src="props.icon" :alt="title"> {{props.title}}</h3>

            <slot></slot>

        </div>

    </div>

</template>

<style lang="scss">
    .icon-panel {

        h3.card-title {
            font-weight: $normal;
            padding-bottom: 7px;
            border-bottom: 1px solid $gray-2;
        }

        .card-title img {
            height: 25px;
            margin-right: 10px;
        }
    }
</style>