<script setup>

    // ProspectingHomePage
    // Landing page for the prospecting & lead gen program

    // Imports ----
    import { onMounted, reactive, ref, computed } from 'vue';
    import { site } from '@/Site';
    import { Search, TabBar } from '@dd-nucleus/nucleus-vue';
    import { ProductFormat, ProductSearchTerm, ProgramType } from '@/constants'
    const search = new Search('cross-sell-programs-for-current-agency', 'detail', 'title', 1000);

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import ProspectingOpportunitiesTile from './components/ProspectingOpportunitiesTile';
    import AgencyBestPractices from './components/AgencyBestPractices';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';
    import IgniteProspectingPromo from '@/site/components/IgniteProspectingPromo';


    // State ----
    const prospectingTabs = [
        {
            id: 'prospecting',
            title: 'My prospecting opportunities & campaigns'
        },
        {
            id: 'agency-best-practices',
            title: 'Agency best practices'
        }
    ];

    const results = reactive([]);

    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);

    

    // Lifecycle Hooks
    onMounted(async () => {
        Object.assign(results, await search.results);

        await site.analyticsHandler.push({ "tileCode": "Prospecting and Lead Gen Overview", "lob": "Prospecting" });
    });

    // Computed
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

   </script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />

            <div class="root-page-container prospecting-home-page">

                <div class="row">

                    <div class="col-sm-8">

                        <div class="row">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/auto-home-policy.png" alt="Corporation" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h1>Prospecting & Lead Gen</h1>
                                    <p class="fw-bold">Work with Safeco to target and reach potential new customers.</p>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <p>The ability to generate, cultivate and convert leads plays a major role in growing your book of business. Driving sustainable business growth requires continuous prospecting efforts to create a steady stream of high-quality leads. The good news is that Safeco can help you build your prospect pipeline with a variety of tools, resources, and tips to successfully target prospects most likely to purchase a Safeco policy. We understand how important it is to continually generate and nurture new prospects-and we're here to help you every step of the way.</p>

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/growth-opportunities-icon.png" alt="Growth Opportunities" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h2>My growth opportunities</h2>

                                </div>

                            </div>

                        </div>

                        <div class="row" v-if="isEmployee">

                            <div class="col">

                                <AgencyBestPractices />

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col">

                                <TabBar :tabs="prospectingTabs" />

                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="prospecting" role="tabpanel">
                                        <div class="sub-tab-container">
                                            <ProspectingOpportunitiesTile filter-id="prospecting" />
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="agency-best-practices" role="tabpanel">
                                        <AgencyBestPractices />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <hr />

                            </div>

                        </div>
                        <div class="row mt-3">

                            <div class="col text-center">

                                <p>Availability for Prospecting campaigns may vary by state. Please check back for future updates to availability and reach out to your TM with any questions.</p>

                            </div>
                        </div>
                        <!--<div class="row mt-3">

                            <div class="col">

                                <h2>How it works</h2>

                            </div>

                        </div>-->

                        <!--<div class="row how-it-works">

                            <div class="col text-center">

                                <img src="/images/prospecting/prospecting-step1.png" class="img-fluid" alt="step 1" />

                                <p class="text-start">
                                    <strong>1. </strong> <span class="fw-bold">Browse Prospecting Materials:</span> Choose to send direct mail based on your communication channel preferences.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/prospecting/prospecting-step2.png" class="img-fluid" alt="step 2" />

                                <p class="text-start">
                                    <strong>2. </strong> <span class="fw-bold">Customize Your Mailers:</span> Fully customize your direct mail with your agency details and logo.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/prospecting/prospecting-step3.png" class="img-fluid" alt="step 3" />

                                <p class="text-start">
                                    <strong>3. </strong> <span class="fw-bold">Target Your Prospects:</span> Select how many leads you want and in what location. Simply choose a specific zip code(s) and/or radius around your agency zip code.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/prospecting/prospecting-step4.png" class="img-fluid" alt="step 4" />

                                <p class="text-start">
                                    <strong>4. </strong> <span class="fw-bold">Follow-up:</span> Your list will be available after your first (and subsequent) mail dates. Once it becomes available, we recommend you download your list and start the follow-up process.
                                </p>

                            </div>

                        </div>-->

                    </div>

                    <div class="col-sm">

                        <div class="row">

                            <div class="col">

                                <IgniteProspectingPromo />
                               
                                <IconPanel class="mt-5 boost-conversation" title="Pro Tip" icon="/images/icons/approvals-target-icon.png">
                                    <span class="title">Boost conversations: </span> <span class="content">Studies have shown that using email and direct mail together can increase your conversion rate by up to 35% from using either method individually.</span>
                                    <br /><br />
                                    <span class="fw-bold">Source: </span>Marketing Insider Group
                                </IconPanel>
                                <!-- TODO: Need to add the proper links -->
                                <IconPanel class="mt-5" title="Prospecting resources" icon="/images/icons/search-icon.png">
                                    <LinkItem class="mb-3" title="View Mail & Phone List" :link="{ path: '/account/order-history', query: { 'programType': ProgramType.PROSPECTING } }" :isExternal="false" :openNewWindow="false" />
                                    <LinkItem class="mb-3" title="Prospecting Overview" :link="{ path: '/products', query:{ 'f-product-search-term': ProductSearchTerm.PROSPECTING_OVERVIEW, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                                    <!--<LinkItem class="mb-3" title="View Call Scripts" :link="{ path: '/products', query:{ 'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(';'), 'f-product-format': ProductFormat.CALL_SCRIPTS, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
    <LinkItem class="mb-3" title="Download Letter Templates" :link="{ path: '/products', query:{ 'f-product-data-list-type': [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD].join(';'), 'f-product-format': ProductFormat.LETTER_TEMPLATES, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />-->
                                    <LinkItem class="mb-3" title="View Call Scripts" :link="{ path: '/products', query:{ 'f-program-type': ProgramType.PROSPECTING, 'f-product-format': ProductFormat.CALL_SCRIPTS, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                                    <LinkItem class="mb-3" title="Download Letter Templates" :link="{ path: '/products', query:{ 'f-program-type': ProgramType.PROSPECTING, 'f-product-format': ProductFormat.LETTER_TEMPLATES, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                                </IconPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .prospecting-home-page {
        .how-it-works {
            p {
                font-size: 1rem;
            }

            p strong {
                color: $color-2;
                font-size: 1.25rem;
            }

            p span {
                color: $color-2;
            }
        }

        .boost-conversation {
            background: #EBFAFB;

            .title {
                color: #06748c;
                font-weight: bold;
            }

            .content {
                font-weight: 300;
            }
        }
    }
</style>
