<script setup>

    // ProspectingMailListType
    // Step 3 selecting how you would like the mail sent

    // Constants ----
    const mailListType = [
        { value: 'purchase', label: 'Purchase a list of prospects' },
        { value: 'upload', label: 'Select your list of prospects' }
    ];

    // Imports ----
    import { defineEmits, ref, reactive, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';
    const router = useRouter();

    // State ---- 
    const mailListTypeSelected = ref();

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails)
    const detail = reactive({ product: {} });
    // Handlers ----
    const emit = defineEmits(['mailListType']);

    // Methods ----
    function back() {
        router.go(-1);
    }

    function next() {
        emit('mailListType', mailListTypeSelected.value);
    }

    onMounted(async () => {
        detail.product = Object.assign(detail.product, await site.products.getProductDetail(orderFlowDetails.productId));
    });

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select or upload your mailing list</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">

        <div class="col text-center">

            <h1>
                Please choose the type of Mail List you would like to use:
            </h1>

        </div>

    </div>

    <div class="row mb-5 mt-2">

        <div class="col-sm-4 offset-sm-4">

            <div class="form-check mb-3" v-for="type in mailListType" :key="type.value">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" v-model="mailListTypeSelected" :value="type.value" name="howToSend">
                    {{type.label}}
                </label>
            </div>

        </div>

    </div>

    <div class="row mb-5">

        <div class="col text-center">

            <button @click="back" class="btn btn-outline-primary me-3">Previous</button>
            <button @click="next" class="btn btn-primary">Next</button>

        </div>

    </div>
</template>
<style lang="scss">
    .cross-sell-page3 {

        label{
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
</style>