<script setup>

    // ProfilePanel
    // Shows the profile panel of the my account area.

    // TODO: Add a (universally-located) scroll so that adding a new shipping ssess will bring the form into view.

    // Constants ----

    // Components ----
    import ShippingAddressTile from '@/site/components/ShippingAddressTile';
    import HeadlineContainer from '@/site/components/HeadlineContainer';
    import AccountTitle from './AccountTitle';
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';

    // Imports ----
    import { ref, reactive, onMounted, computed, watch } from 'vue';
    import { site, DEFAULT_COUNTRY_CODE } from '@/Site';
    import { Search, ShippingAddressForm } from '@dd-nucleus/nucleus-vue';
    import useEventsBus from '@/site/services/EventBus';
    import AgencyAddressForm from '@/site/forms/AgencyAddressForm';
    import { AddressConfig } from '@dd-nucleus/nucleus-vue';
    import { formatPhone } from '@/util'

    const { bus } = useEventsBus();

    // State ----
    const selectedCountry = ref(DEFAULT_COUNTRY_CODE);
    const search = new Search('addresses-for-user', 'tile', 'address-sequence', 1000);
    search.setEmptyFilter('address-not-default');

    let addShippingAddressForm = new ShippingAddressForm();
    
    const showOverlay = ref(false);

    const showAgencyEmailPref = ref(true);

    const addingShippingAddress = ref(false);

    const editAgencyEmail = reactive({ emailId: "", email: "", name: "" });

    const idToDelete = ref('');

    // More programs to be added here but moving with safeco is the only one that is funcational as of 7/5/22.
    const programs = reactive([
        { title: "Moving with Safeco", id: 1 }
    ]);

    const selectedPrograms = reactive([]);

    const userAccountModel = reactive({});

    const contact = reactive({});

    const agencyModel = reactive({});

    let agencyEmails = reactive([]);

    const currentUser = ref('');

    const keyIndex = ref(0);

    const agencyAddressLabel = {
        companyName: "Agency Name"
    }

    // Computed ----
    const agencyEmailLength = computed(() => {
        if (agencyEmails) {
            return agencyEmails.length;
        }

        return 0;
    });

    // Computed
    const username = computed(() => {
        return site.user.userName.toLowerCase();
    });

    // Methods ----
    function onAddShippingAddress() {

        // TODO: Need to be revisited and model reset should be implemented correctly in base shippingaddressform.
        addShippingAddressForm.model.companyName = '';
        addShippingAddressForm.model.addressLine1 = '';
        addShippingAddressForm.model.addressLine2 = '';
        addShippingAddressForm.model.addressLine3 = '';
        addShippingAddressForm.model.addressLine4 = '';
        addShippingAddressForm.model.city = '';
        addShippingAddressForm.model.state = '';
        addShippingAddressForm.model.postalCode = '';
        addShippingAddressForm.model.id = '';
        addShippingAddressForm.model.companyName = '';
        addShippingAddressForm.model.isResidential = false;
        addShippingAddressForm.model.countryCode = selectedCountry.value;
        addShippingAddressForm.model.id = null;
        addingShippingAddress.value = true;
    }

    function onCancelAddShippingAddress() {
        addingShippingAddress.value = false;
    }

    async function onSetDefaultShippingAddress() {
        keyIndex.value = keyIndex.value + 1;
    }

    async function onSaveShippingAddress() {
        await addShippingAddressForm.submit().then(() => {
            site.refreshSearches('addresses-for-user');
            addingShippingAddress.value = false;
        });
    }

    async function onEditAddesss(address) {

       addShippingAddressForm.model.companyName = address.companyName;
       addShippingAddressForm.model.addressLine1 = address.addressLine1;
       addShippingAddressForm.model.addressLine2 = address.addressLine2;
       addShippingAddressForm.model.addressLine3 = address.addressLine3;
       addShippingAddressForm.model.addressLine4 = address.addressLine4;
       addShippingAddressForm.model.city = address.city;
       addShippingAddressForm.model.state = address.state;
       addShippingAddressForm.model.postalCode = address.postalCode;
       addShippingAddressForm.model.id = address.id;
       addShippingAddressForm.model.isResidential = address.isResidential;
       addShippingAddressForm.model.countryCode = selectedCountry.value;

        addingShippingAddress.value = true;
        validateForm();
    }


    function validateForm() {
        addShippingAddressForm.validate();
    }

    function addAgencyEmailPref() {

        showAgencyEmailPref.value = false;

        Object.assign(editAgencyEmail, { emailId: "", email: "", name: "" });

    }

    function cancelAgencyEmailPref() {
        showAgencyEmailPref.value = true;
    }

    function cancelConfirmation() {
        showOverlay.value = false;
        idToDelete.value = '';
    }

    async function deleteAgencyEmailPref() {
        showOverlay.value = false;

        if (idToDelete.value)
            await site.agencyDetails.deleteAgencyEmail(idToDelete.value);

        refresh();

    }

    function editAgencyEmailPref(id) {
        showAgencyEmailPref.value = false;

        editAgencyEmail.emailId = id;

        agencyEmails.forEach((item) => {
            if (item.id == id) {
                Object.assign(editAgencyEmail, item);
            }
        });

    }

    async function refresh() {

        // Get the current user and set it
        setUser();

        let response = await site.userFromBridge.getUserInfo(currentUser.value);

        Object.assign(userAccountModel, response.userAccountModel);
        Object.assign(contact, response.userAccountModel.contact);

        //empty the array / object before we reload it
        agencyEmails.length = 0;
        for (const prop of Object.getOwnPropertyNames(agencyModel)) {
            delete agencyModel[prop];
        }

        // Re-Populate those values
        Object.assign(agencyEmails, response.agencyEmails);

        if (response.agencyModel && response.agencyModel.companySegment && response.agencyModel.companySegment.addresses && response.agencyModel.companySegment.addresses.length > 0) {
            Object.assign(agencyModel, response.agencyModel.companySegment.addresses[0]);
        }

    }

    async function saveAgencyEmailPref() {
        showAgencyEmailPref.value = true;

        if (editAgencyEmail.emailId.length > 1) {
            await site.agencyDetails.updateAgencyEmail(editAgencyEmail);
        }
        else {
            await site.agencyDetails.addAgencyEmail(editAgencyEmail, agencyModel.companySegmentId);
        }

        refresh();
    }

    function showConfirmation(id) {
        showOverlay.value = true;
        idToDelete.value = id;
    }

    function updatePrograms(programId, checked) {

        if (checked) {
            selectedPrograms.push(programId);
        } else {
            selectedPrograms.splice(selectedPrograms.indexOf(programId), 1);
        }
    }

    function setUser() {
        currentUser.value = username.value.replace(".test@qualfon.com", "");
        currentUser.value = username.value.replace(".test@test.com", "");
    }

    //Agency address updates

    const addressConfig = new AddressConfig();
    addressConfig.companyName.Required = true;
    let agencyAddressForm = new AgencyAddressForm({}, null, addressConfig);

    const editAgencyAddress = ref(false);
    const agencyInfo = computed(() => {
        return site.agencyDetails?.selectedAgencyDetails
    });

    const showAgencyAddress2 = computed(() => {
        return agencyInfo.value?.address2 && agencyInfo.value?.address2.length > 0;
    });

    const isEligibleToUpdateAgencyAddress = computed(() => {
        const rolesToUpdateAddress = site.userRoles.getRolesToUpdateAgencyAddress();
        var rec = site.user.roles.filter(r => rolesToUpdateAddress.includes(r.id));
        return rec?.length > 0;
    });

    async function onEditAgencyAddress() {
        const agencyAddress = agencyInfo.value;
        const phone = agencyAddress?.phone;
        agencyAddressForm.model.companyName = agencyAddress?.agencyName;
        agencyAddressForm.model.addressee = agencyAddress?.agencyName;
        agencyAddressForm.model.name = agencyAddress?.agencyName;
        agencyAddressForm.model.addressLine1 = agencyAddress?.address1;
        agencyAddressForm.model.addressLine2 = agencyAddress?.address2;
        agencyAddressForm.model.city = agencyAddress?.city;
        agencyAddressForm.model.state = agencyAddress?.state;
        agencyAddressForm.model.countryCode = selectedCountry.value;
        agencyAddressForm.model.postalCode = agencyAddress?.zip;
        agencyAddressForm.model.phoneNumber = phone ? formatPhone(phone) : '';
        agencyAddressForm.model.email = agencyAddress?.emailAddress;
        agencyAddressForm.model.id = agencyModel.id;
        agencyAddressForm.model.companySegmentId = agencyModel.companySegmentId;
        editAgencyAddress.value = true;
        agencyAddressForm.validate();
    }

    async function onAgencyAddressChanged() {
        if (selectedCountry.value != agencyAddressForm.model.countryCode) {
            selectedCountry.value = agencyAddressForm.model.countryCode;
            agencyAddressForm.model.state = '';
            agencyAddressForm.model.province = '';
        }
        const formattedPhone = formatPhone(agencyAddressForm.model.phoneNumber);
        agencyAddressForm.model.phoneNumber = formattedPhone;
        agencyAddressForm.validate();
    }


    async function saveAgencyAddress() {
        var response = await agencyAddressForm.submit();
        if (!response.succeeded) {
            console.warn('response', response);
            site.toast.error("Error while updating the agency address, please contact system administrator for further assistance.");
        }
        await site.agencyDetails.loadAgencyDetails();
        editAgencyAddress.value = false;
    }

    function cancelAgencyAddress() {
        editAgencyAddress.value = false;
    }

    // Lifecycle ----
    onMounted(async () => {
        refresh();
    });

    watch(username, () => {
        refresh();
    });

    watch(() => bus.value.get('changeAgency'), () => {
        setTimeout(() => {
            refresh();
        }, 250)
    });

    function onChanged(form) {
        if (selectedCountry.value != form.model.countryCode) {
            selectedCountry.value = form.model.countryCode;
            form.model.state = '';
            form.model.province = '';
        }
        validateForm();
    }

</script>

<template>
    <div class="account-panel">

        <ConfirmationOverlay v-if="showOverlay">
            <template v-slot:headline>
                <h2>Are you sure you want to delete this Agency Email Preference?</h2>
            </template>
            <template v-slot:buttons>
                <button class="btn btn-outline-primary me-5" @click="cancelConfirmation">Cancel</button>
                <button class="btn btn-outline-primary ms-5" @click="deleteAgencyEmailPref">Delete</button>
            </template>
        </ConfirmationOverlay>

        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Profile</span></h1>

        <BodyPanel>
            <template #title>
                <HeadlineContainer title="Personal Information" />
            </template>
            <div class="row mt-4">
                <div class="col-6">

                    <AccountTitle :isLocked="true">
                        <template v-slot:accountInfo>
                            <div class="fw-bold">
                                {{contact.lastName}} {{contact.firstName}}
                            </div>
                            <div>
                                {{contact.email}}
                            </div>
                        </template>
                    </AccountTitle>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <p class="info-text">
                        <i class="bi bi-info-circle"></i>
                        Agents, please contact your Territory Manager if changes are required to your agency contact information on the MSC.
                    </p>
                </div>
            </div>

        </BodyPanel>

        <BodyPanel class="mt-4" v-if="agencyModel.companySegmentId">
            <template #title>
                <HeadlineContainer title="Agency Information" />
            </template>
            <div class="row mt-4" v-if="!editAgencyAddress">
                <div class="col-6">
                    <AccountTitle :isLocked="!isEligibleToUpdateAgencyAddress">
                        <template v-slot:accountInfo>
                            <div class="fw-bold">
                                {{site.agencyDetails.selectedAgencyDetails.agencyName}}
                            </div>
                            <div>
                                {{site.agencyDetails.selectedAgencyDetails.address1}}
                            </div>
                            <div>
                                {{site.agencyDetails.selectedAgencyDetails.address2}}
                            </div>
                            <div v-if="site.agencyDetails.selectedAgencyDetails.city">
                                {{site.agencyDetails.selectedAgencyDetails.city}}, {{site.agencyDetails.selectedAgencyDetails.state}} {{site.agencyDetails.selectedAgencyDetails.zip}}
                            </div>
                            <div>
                                {{site.agencyDetails.selectedAgencyDetails.phone}}
                            </div>
                            <div>
                                {{site.agencyDetails.selectedAgencyDetails.emailAddress}}
                            </div>
                            <div class="mt-2" v-if="isEligibleToUpdateAgencyAddress">
                                <ActionButton icon="bi bi-pencil-fill" @click="onEditAgencyAddress" />
                            </div>
                        </template>
                    </AccountTitle>
                </div>
            </div>
            <div class="row mt-4" v-if="editAgencyAddress">
                <div class="col-12">
                    <div v-if="editAgencyAddress" class="subform mt-2" ref="editAgencyAddressForm">
                        <div class="col-12 col-md-8">
                            <div class="header"> <h3>Edit Agency Address</h3></div>
                            <EditShippingAddress :form="agencyAddressForm" :address-only="true" @change="onAgencyAddressChanged" :showResidential="false" :field-label="agencyAddressLabel" :selectedCountry="selectedCountry" :start-address-lines="showAgencyAddress2 ? 2: 1" />
                            <FormContainer :form="agencyAddressForm" optional-text="(Optional)">
                                <slot name="top"></slot>
                                <div class="mb-2 col-6">
                                    <FieldLabel field-name="phoneNumber" label="Phone">
                                        <TextBoxField v-model="agencyAddressForm.model.phoneNumber" placeholder="Phone Number" @change="onAgencyAddressChanged" />
                                        <FieldMessages />
                                    </FieldLabel>
                                </div>
                                <div class="mb-2 col-6">
                                    <FieldLabel field-name="email" label="Email">
                                        <TextBoxField v-model="agencyAddressForm.model.email" placeholder="Email" @change="onAgencyAddressChanged" />
                                        <FieldMessages />
                                    </FieldLabel>
                                </div>
                            </FormContainer>
                        </div>
                        <div class="col-12 pt-2">
                            <button class="btn btn-outline-primary me-2" @click="cancelAgencyAddress">Cancel</button>
                            <button class="btn btn-primary" @click="saveAgencyAddress" :disabled="agencyAddressForm.status.isInvalid">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <p class="info-text">
                        <i class="bi bi-info-circle"></i>
                        Agents, please contact your Territory Manager if changes are required to your agency contact information on the MSC.
                    </p>
                </div>
            </div>
        </BodyPanel>

        <BodyPanel class="mt-5">

            <template #title>
                <HeadlineContainer title="Shipping Address" />
            </template>

            <div class="row mt-3">

                <div class="col-6" v-if="site.shipping.addresses.length > 0">
                    <h3>
                        Default Shipping Address
                    </h3>
                    <AccountTitle :isLocked="false">
                        <template v-slot:accountInfo>
                            <ShippingAddressTile :address="site.shipping.addresses[0]" :border="false" height="9rem" />
                        </template>
                    </AccountTitle>
                </div>

                <div class="col-6" v-if="site.account.address">
                    <h3>
                        Account Address
                    </h3>
                    <AccountTitle :isLocked="false">
                        <template v-slot:accountInfo>
                            <ShippingAddressTile :address="site.account.address" :border="false" height="9rem" />
                        </template>
                    </AccountTitle>
                </div>
                <div class="fs-4 text-center col" v-else>
                    You have no default shipping address saved.
                </div>
            </div>

            <div class="row mt-3">
                <div class="mb-2"><strong>Other Shipping Addresses</strong></div>

                <div v-if="addingShippingAddress" class="subform mt-2" ref="shippingAddressForm">
                    <div class="col-12 col-md-8">
                        <div class="header">{{ addShippingAddressForm.model.id === null ? 'New Shipping Address' : 'Edit Shipping Address' }}</div>
                        <EditShippingAddress :form="addShippingAddressForm" :address-only="true" :selectedCountry="selectedCountry" @change="onChanged" />
                    </div>
                    <div class="col-12 pt-2">
                        <button class="btn btn-outline-primary me-2" @click="onCancelAddShippingAddress">Cancel</button>
                        <button class="btn btn-color-2" @click="onSaveShippingAddress" :disabled="addShippingAddressForm.status.isInvalid">Save</button>
                    </div>
                </div>

                <div v-else>
                    <SearchContainer :search="search" :deep-link="false" :key="keyIndex">

                        <SearchGrid :columnsXl="3" :columnsXxl="3">
                            <!-- Template for each item in a grid view -->
                            <template v-slot:grid="address">
                                <ShippingAddressTile :address="address" :can-set-default="true" height="14rem" :isEditShown="true" @edit="onEditAddesss(address)" @setDefault="onSetDefaultShippingAddress">
                                </ShippingAddressTile>
                            </template>
                        </SearchGrid>

                    </SearchContainer>

                    <div class="mt-1">
                        <ActionButton icon="bi bi-plus-lg" label="Add Shipping Address" @click="onAddShippingAddress" />
                    </div>
                </div>
            </div>

        </BodyPanel>

        <BodyPanel class="mt-5" v-if="agencyModel.companySegmentId">

            <template #title>
                <HeadlineContainer class="mb-3 mt-5" title="Agency Email Preferences" />
            </template>

            <div class="row" v-if="showAgencyEmailPref">
                <div class="col-6" v-for="(agencyEmail, index) in agencyEmails" :key="agencyEmail.email">

                    <AccountTitle :isLocked="false">

                        <template v-slot:accountInfo>
                            <div class="fw-bold">
                                {{agencyEmail.name}}
                            </div>
                            <div>
                                {{agencyEmail.email}}
                            </div>
                            <div class="fw-bold">
                                Programs
                            </div>
                            <div v-for="program in programs" :key="program.id">
                                {{program.title}}
                            </div>
                            <div class="mt-2">
                                <a @click="editAgencyEmailPref(agencyEmail.id)"><i class="bi bi-pencil-fill me-3"></i></a>
                                <a @click="showConfirmation(agencyEmail.id, index)"><i class="bi bi-trash-fill"></i></a>
                            </div>
                        </template>

                    </AccountTitle>

                </div>

            </div>

            <div class="row mt-3">

                <div class="col-6">

                    <div v-if="!showAgencyEmailPref">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Name" v-model="editAgencyEmail.name">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control" placeholder="Name" v-model="editAgencyEmail.email">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Program(s)</label>
                        </div>
                        <div class="mb-3 form-check" v-for="program in programs" :key="program.id">
                            <input type="checkbox" class="form-check-input" :id="program.id" v-on:change="updatePrograms(program.id, $event.target.checked)">
                            <label class="form-check-label" :for="program.id">{{program.title}}</label>
                        </div>

                        <button type="submit" class="btn btn-outline-primary me-3" @click="cancelAgencyEmailPref">Cancel</button>
                        <button type="submit" class="btn btn-primary" @click="saveAgencyEmailPref">Save</button>
                    </div>


                    <div v-if="agencyEmailLength == 0 && showAgencyEmailPref">
                        You have no Agency Email Prefrences saved.
                    </div>

                    <div v-if="agencyEmailLength <= 1 && showAgencyEmailPref">
                        <div class="row mt-4 mb-5">
                            <div class="col">
                                <p>
                                    <button type="submit" class="btn btn-outline-primary" @click="addAgencyEmailPref">Add Email Address</button>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </BodyPanel>
    </div>
</template>

<style lang="scss">

    .account-panel {

        .bi-info-circle {
            color: $info !important;
        }

        .section-subtitle {
            border-bottom: 1px solid $gray-10;
        }

        .info-text {
            color: $info !important;
        }

        .confirmation-overlay h2 {
            color: $gray-0;
        }
    }
</style>