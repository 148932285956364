// UserRoles
// Services and actions related to User Roles.

// Imports ----

export default class DownloadHandler {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    async handleDownload(url, fileName, annonymous = false) {
        if (!fileName) {
            const fileNameFromUrl = url.split("/");
            fileName = fileNameFromUrl[fileNameFromUrl.length - 1];
        }

        await this.store.blob.getBlob(url, annonymous)
            .then(response => {
                if (response && response.data) {
                    fileName = fileName ? fileName : 'download.pdf'
                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    this.store.toast.error('Error while downloading the file. please contact system administrator', 'Error');
                }
            });
    }
    

}
