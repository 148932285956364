// PersonalizationInfo
// Services and actions related to personalizing assets 

// Imports ----
import { reactive } from 'vue';
import { site } from '@/Site';
import { isEmpty, isNotEmpty } from '@/util';

const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;

export default class Personalization {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;

        this.orderFlowDetails = reactive({
            id: '',
            cartId: '00000000-0000-0000-0000-000000000000',
            parentId: '',
            channelType: null,
            pageFlexDocumentId: '',
            pageFlexOrderId: '',
            statCode: '',
            oppId: '',
            oppType: '',
            programType: '',
            productType: '',
            userState: '',
            isMultiChannel: false,
            productId: null,
            recipients: [],
            mailContacts: [],
            dropDetails: { // NEW name here for DM type 
                dropType: '', // Or ONE 
                frequency: '',  // or bi-weekly
                numberOfMailings: 0, // or 2 or 3
                startDate: new Date(), // array based on the options above
                mailingDetails: []
            },
            pdfFinalApproval: false,
            acceptTermsAndConditions: false,
            willNotUseMassachusetts: false,
            states: '',
            targetCount: 0,
            totalPrice: 0.00,
            unitPrice: 0.00,
            zipCodes: '',
            zipRadius: 0,
            availableCount: 0,
            crossStateBoundary: false,
            includePhoneNumber: false,
            subscriptionPeriod: 0,
            seasonalSummary: [],
            isOwnListUploaded: null,
            isStateSaved: null,
        });

        store.cart.onCartLoaded(async () => {
            await this.#init();
        });

    }
    /**
     * Send the form data to the PDF preview endpoint
     */
    async generatePdfPreview(payload) {
        // TODO: Connect this to the PDF service once choosen and implemented 
        console.warn("payload: ", payload);
        return 'Success Return';
    }

    openPdfPreview(docId) {
        if (isNotEmpty(site.user.userName)) {
            const userName = site.user.userName.replace(".Test@test.com", '');
            const url = `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${docId}&UserName=${userName}`;
            window.open(url, '_blank');
        }
    }

    getPdfPreview(docId) {
        if (isNotEmpty(site.user.userName)) {
            const userName = site.user.userName.replace(".Test@test.com", '');
            return `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${docId}&UserName=${userName}`;
        }
    }

    /**
     * Proceed to step of personalization 
     */
    async submitPesonlization(payload) {
        // TODO: Connect this to the PDF service once choosen and implemented 
        console.warn("payload: ", payload);
        return 'Success Return';
    }

    /**
     * Add a Personalized item to the cart 
     */
    async addToCart(productId, quantity) {
        return await this.store.api.post(`/api/store/cart/items`, { productId: productId, quantity: quantity });

    }

    async setSaveForLater(isStateSaved = false, orderStateId = null, parentOrderStateId = null) {
        const payload = {
            orderStateId: orderStateId ? orderStateId : this.orderFlowDetails.id,
            parentOrderStateId: parentOrderStateId ? parentOrderStateId : this.orderFlowDetails.parentId,
            isStateSaved: isStateSaved
        };
        console.warn('payload', payload);
        return await this.store.api.put(`/site/order-state`, payload);

    }

    async getProductDataType(productId) {

        return await this.store.api.get(`site/product/${productId}/has-product-data-type`);
    }

    /**
     * The starting of an order get the statCode and ProductId being used in the order
     */
    async startOrder(channelType, isStateSaved = false) {

        this.orderFlowDetails.channelType = channelType;

        await this.#setCartId();

        if (isEmpty(this.orderFlowDetails.isMultiChannel)) {
            this.orderFlowDetails.isMultiChannel = false;
        }

        const payload = {
            cartId: this.orderFlowDetails.cartId,
            productId: this.orderFlowDetails.productId,
            statCode: this.orderFlowDetails.statCode,
            productType: this.orderFlowDetails.productType,
            programType: this.orderFlowDetails.programType,
            channelType: this.orderFlowDetails.channelType,
            oppId: this.orderFlowDetails.oppId,
            isMultiChannel: this.orderFlowDetails.isMultiChannel,
            isStateSaved: isStateSaved
        }

        if (isNotEmpty(this.orderFlowDetails.parentId))
            Object.assign(payload, { parentId: this.orderFlowDetails.parentId })

        const response = await this.store.api.post(`/site/user/current/order-state`, payload);

        if (response && response.succeeded) {
            await this.getOrderState(response.id);

            if (isEmpty(this.orderFlowDetails.parentId))
                this.orderFlowDetails.parentId = response.id;
        }

        return response;

    }

    async changeProductType(productType) {
        this.orderFlowDetails.productType = productType;
        await this.#updateOrderState();
    }

    async acceptConsent(form) {
        this.orderFlowDetails.pdfFinalApproval = form.pdfFinalApproval;
        this.orderFlowDetails.acceptTermsAndConditions = form.pdfFinalApproval;
        this.orderFlowDetails.willNotUseMassachusetts = form.pdfFinalApproval;

        await this.#updateOrderState();
    }

    async selectProduct(id, unitPrice) {
        this.orderFlowDetails.productId = id;
        if (unitPrice) {
            this.orderFlowDetails.unitPrice = unitPrice;
        }
        await this.#updateOrderState();
    }

    async addRecipients(recipients) {

        this.orderFlowDetails.recipients = [];

        this.orderFlowDetails.recipients.push(...recipients);

        this.orderFlowDetails.targetCount = this.orderFlowDetails.recipients.length;

        await this.#updateOrderState();
    }

    async updateDocId(id, parentId) {

        this.orderFlowDetails.pageFlexDocumentId = id;
        //TODO: Need to check we always need to overwrite the parent id or if parent id will be updated
        //only its provided.

        if (parentId && parentId != 'null') {
            this.orderFlowDetails.parentId = parentId;
        }
        await this.#updateOrderState();
    }

    async updateTargetCount() {
        await this.#updateOrderState();
    }

    async updateOrderState(state) {

        const payload = {
            orderState: {}
        };

        Object.assign(payload.orderState, state);

        const response = await this.store.api.put(`/site/user/current/order-state`, payload);

        Object.assign(this.orderFlowDetails, response.model);
    }

    async setDropType(type) {

        this.orderFlowDetails.dropDetails.dropType = type;
        this.orderFlowDetails.dropDetails.startDate = null;
        this.orderFlowDetails.dropDetails.numberOfMailings = 0;
        this.orderFlowDetails.dropDetails.frequency = null;
        this.orderFlowDetails.dropDetails.mailingDetails = [];
        await this.#updateOrderState();
    }

    async setWinbackDropDetails() {

        this.#resetDropDetails();

        this.orderFlowDetails.dropDetails.startDate = null;
        this.orderFlowDetails.dropDetails.frequency = 'NONE';
        this.orderFlowDetails.dropDetails.dropType = 'NONE';
        this.orderFlowDetails.dropDetails.numberOfMailings = 0;
        this.orderFlowDetails.dropDetails.mailingDetails = [{
            "MailDate": this.getWinbackMailDate(),
            "Quantity": this.orderFlowDetails.targetCount,
            "DropNumber": 1
        }];
        await this.#updateOrderState();
    }

    getWinbackMailDate() {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        currentDate.setHours(0, 0, 0, 0);
        if (currentDay >= 13) {
            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(13);
        } else {
            currentDate.setDate(13);
        }
        return currentDate;
    }

    async setDropDetails(startDate, frequency, numberOfMailings) {

        this.#resetDropDetails();

        this.orderFlowDetails.dropDetails.startDate = startDate || '';
        this.orderFlowDetails.dropDetails.frequency = frequency || '';
        this.orderFlowDetails.dropDetails.numberOfMailings = numberOfMailings || '';
        this.orderFlowDetails.dropDetails.mailingDetails = [];

        await this.#updateOrderState();
        const id = this.orderFlowDetails.id;

        const response = await this.store.api.get(`/site/mailing-schedule/${id}`);

        if (response) {
            await this.orderFlowDetails.dropDetails.mailingDetails.push(...response);
            await this.#updateOrderState();
        }
    }

    async zipLookup(city, state) {
        const payload = {
            "city": city,
            "state": state
        }

        return await this.store.api.post(`/site/prospecting/zip-lookup`, payload);

    }

    async updateProspectingZipCodes(payload) {

        this.orderFlowDetails.targetCount = payload.targetCount;
        this.orderFlowDetails.totalPrice = payload.totalPrice;
        this.orderFlowDetails.unitPrice = payload.unitPrice;
        this.orderFlowDetails.zipCodes = payload.zipCodes;
        this.orderFlowDetails.zipRadius = payload.zipRadius;
        this.orderFlowDetails.availableCount = payload.availableCount;
        this.orderFlowDetails.crossStateBoundary = payload.crossStateBoundary;
        this.orderFlowDetails.includePhoneNumber = payload.includePhoneNumber;
        this.orderFlowDetails.isOwnListUploaded = payload.isOwnListUploaded;

        await this.#updateOrderState();
    }

    async resetProspectingZipCodes() {

        this.orderFlowDetails.targetCount = null;
        this.orderFlowDetails.totalPrice = null;
        this.orderFlowDetails.unitPrice = null;
        this.orderFlowDetails.zipCodes = null;
        this.orderFlowDetails.zipRadius = null;
        this.orderFlowDetails.availableCount = null;
        this.orderFlowDetails.crossStateBoundary = null;
        this.orderFlowDetails.includePhoneNumber = null;
    }

    async resetOrderState() {
        await this.#reset();
    }

    async reloadOrderState(orderFlowDetails) {

        Object.assign(this.orderFlowDetails, orderFlowDetails);

    }

    async uploadRecipients(file) {
        return await this.store.api.post(`/site/address-list/upload`, file);
    }

    // #region Private Methods ----

    async getPunchoutTicket(payload) {

        const response = await this.store.api.post(`/site/punch-out/ticket`, payload);

        return response

    }

    async commitPunchoutTicket(payload) {

        const response = await this.store.api.post(`/site/punch-out/commit-order`, payload);


        this.orderFlowDetails.pageFlexOrderId = response.orderId;

        await this.#updateOrderState();

    }
    async commitPunchOutForDownload(payload) {
        //return await this.store.api.post(`/site/punch-out/commit-order`, payload);
        return await this.store.api.post(`/site/customize-download`, payload);
    }

    #reset() {
        this.orderFlowDetails.id = '';
        this.orderFlowDetails.cartId = '00000000-0000-0000-0000-000000000000';
        this.orderFlowDetails.parentId = '';
        this.orderFlowDetails.statCode = '';
        this.orderFlowDetails.channelType = '';
        this.orderFlowDetails.oppId = '';
        this.orderFlowDetails.docId = '';
        this.orderFlowDetails.previewUrl = '';
        this.orderFlowDetails.oppType = '';
        this.orderFlowDetails.programType = '';
        this.orderFlowDetails.productType = '';
        this.orderFlowDetails.userState = '';
        this.orderFlowDetails.productId = null;
        this.orderFlowDetails.recipients = [];
        this.orderFlowDetails.mailContacts = [];
        this.orderFlowDetails.pdfFinalApproval = false;
        this.orderFlowDetails.acceptTermsAndConditions = false;
        this.orderFlowDetails.willNotUseMassachusetts = false;
        Object.assign(this.orderFlowDetails.dropDetails, { dropType: '', frequency: '', numberOfMailings: 0, startDate: new Date(), mailingDetails: [] });
        this.orderFlowDetails.states = '';
        this.orderFlowDetails.targetCount = 0;
        this.orderFlowDetails.totalPrice = 0.00;
        this.orderFlowDetails.unitPrice = 0.00;
        this.orderFlowDetails.zipCodes = '';
        this.orderFlowDetails.zipRadius = 0;
        this.orderFlowDetails.availableCount = 0;
        this.orderFlowDetails.crossStateBoundary = false;
        this.orderFlowDetails.includePhoneNumber = false;
        this.orderFlowDetails.pageFlexDocumentId = '';
        this.orderFlowDetails.pageFlexOrderId = '';
        this.orderFlowDetails.isMultiChannel = false;
        this.orderFlowDetails.subscriptionPeriod = 0;
        this.orderFlowDetails.seasonalSummary = [];
        this.orderFlowDetails.isOwnListUploaded = null;
        this.orderFlowDetails.isStateSaved = null;

    }
    async newLogin() {
        this.#reset();
        await this.#init();
    }


    #resetDropDetails() {

        this.orderFlowDetails.dropDetails.frequency = '';
        this.orderFlowDetails.dropDetails.numberOfMailings = '';
        this.orderFlowDetails.dropDetails.mailingDetails = [];
    }

    async #init() {

        this.#setCartId();
        this.#setUserState();

    }

    #setCartId() {
        if (site.cart
            && site.cart.current
            && site.cart.current.id)
            this.orderFlowDetails.cartId = site.cart.current.id;
    }

    #setUserState() {

        if (site.agencyDetails
            && site.agencyDetails.selectedAgencyDetails
            && site.agencyDetails.selectedAgencyDetails.companySegment
            && site.agencyDetails.selectedAgencyDetails.companySegment.addresses[0].state)
            this.orderFlowDetails.userState = site.agencyDetails.selectedAgencyDetails.companySegment.addresses[0].state;

    }

    async deleteOrderState(id) {
        return await this.store.api.delete(`/site/user/current/order-state/${id}`);
    }

    async getOrderState(id) {
        const newOrderState = await this.store.api.get(`/site/user/current/order-state/${id}`);
        Object.assign(this.orderFlowDetails, newOrderState.model);
        return newOrderState;
    }

    async getOrderStates(id) {
        return await this.store.api.get(`/site/user/current/order-states/${id}`);
    }

    async #updateOrderState() {
        const payload = {
            orderState: {}
        };

        Object.assign(payload.orderState, this.orderFlowDetails);

        const response = await this.store.api.put(`/site/user/current/order-state`, payload);
        Object.assign(this.orderFlowDetails, response.model);
    }

    async getProspectCount(payload) {
        return await this.store.api.post(`/site/prospecting/count`, payload);
    }

    async getProspectPrice(payload) {
        return await this.store.api.post(`/site/prospecting/price`, payload);
    }

    async getProspectCountPrice(payload) {
        return await this.store.api.post(`/site/prospecting/count-price`, payload);
    }

    async getRelationMarketingSummary(period) {
        return await this.store.api.post('/site/relationship-marketing/summary', { "subscriptionPeriod": period });
    }

    async getProductItemNumber() {

        const response = await this.store.api.get(`/api/store/product/${this.orderFlowDetails.productId}`);

        return response?.model?.itemNumber;
    }


    async addNewProspectingMailList(recipients, listName) {

        let request = {
            name: listName,
            mailContacts: recipients
        };

        const response = await this.store.api.post(`/site/prospecting/mail-list`, request)

        return response;
    }

    async getAllMailLists() {

        return await this.store.api.get(`/api/search/mail-list/row`);


    }

    async getMailListContactsById(mailListId) {
        return await this.store.api.get(`site/prospecting/mail-list/${mailListId}`);
    }

    async deleteMailListContactsById(mailListId) {
        await this.store.api.delete(`site/prospecting/mail-list/${mailListId}`);
    }

    async addMailContacts(mailContacts, isOwnListUploaded, unitPrice) {

        this.orderFlowDetails.mailContacts = [];

        this.orderFlowDetails.mailContacts.push(...mailContacts);

        this.orderFlowDetails.targetCount = this.orderFlowDetails.mailContacts.length;
        if (unitPrice) {
            this.orderFlowDetails.unitPrice = unitPrice;
        }
        this.orderFlowDetails.isOwnListUploaded = isOwnListUploaded;

        await this.#updateOrderState();
    }

    async addPolicyHolders(mailContacts) {

        this.orderFlowDetails.mailContacts = [];

        this.orderFlowDetails.mailContacts.push(...mailContacts);

        await this.#updateOrderState();
    }

    async addSubscriptionPolicyHolders(mailContacts) {

        this.orderFlowDetails.mailContacts = [];
        this.orderFlowDetails.mailContacts.push(...mailContacts);

        const payload = {
            orderState: {}
        };

        Object.assign(payload.orderState, this.orderFlowDetails);
        const response = await this.store.api.put(`/site/user/current/order-state/subscription`, payload);
        Object.assign(this.orderFlowDetails, response.model);
    }

    getExpressOrderItemInCart() {
        return site.cart?.current?.items.filter(item => item?.product?.attributes?.isExpressOrderingAllowed).length > 0;
    }

    getOrderItemInCart(productId) {
        return site.cart?.current?.items.filter(item => item?.product?.id === productId && item?.product?.attributes?.isPersonalizable).length > 0;
    }

    getItemInCart() {
        return site.cart?.current?.items.length > 0;
    }

    async sendPageFlexDocument(productUserCustomDownloadId) {
        if (isNotEmpty(productUserCustomDownloadId)) {
            var response = await this.store.api.post(`/site/welcome-kit/send-email-review/${productUserCustomDownloadId}`);

            if (response && response.succeeded) {
                site.toast.success('Email has been sent successfully');
            } else {
                site.toast.error('Error while sending an email. please contact system administrator', 'Error');
            }
        }
    }
    async saveForLaterInLocal() {
        this.orderFlowDetails.isStateSaved = true;
    }

    async enableSaveForLater(orderStateId = null, parentOrderStateId = null) {
        return await this.setSaveForLater(true, orderStateId, parentOrderStateId);
    }

    async getSavedItems() {
        return await this.store.api.get('/site/user/current/saved-items')
    }

    async getOrderStateByProductId(id) {
        return await this.store.api.get(`/site/user/current/order-state-by-product/${id}`)
    }
}
