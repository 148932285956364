// ImageLibrary
// Services to all things image Library

export default class CrossSell {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    /**
     * Get the current users image Library
     */
    async getUsersImages() {

        return await this.store.api.get('/api/images/user/');

    }

    /**
     * Delete an image based on the image ID
     */
    async deleteUsersImages(id) {

        return await this.store.api.delete('/api/image/' + id);

    }

    /**
     * Rename an image based on the image ID
     */
    async postRenameImage(id, name) {

        return await this.store.api.post('/api/image/rename?id=' + id + '&name=' + name);

    }

    /**
     * Search for user image 
     * TODO: Refacrot to use the search API
     */
    async searchUsersImages(modelTypeId, payload) {

        return await this.store.api.post('/api/search/images-for-user/' + modelTypeId, payload);

    }

    /**
     * Upload an image for a user 
     */
    async uploadUsersImages(payload) {

        return await this.store.api.post('/api/image/user-library/', payload);

    }
}