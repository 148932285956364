<script setup>

    // RelationshipMarketingPage
    // Landing page Relationship Marketing

    // Imports 
    import { useRouter } from 'vue-router';
    import { onMounted } from 'vue';
    import { site } from '@/Site';

    const router = useRouter();

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import HeadlineWithIcon from '@/site/components/HeadlineWithIcon';
    import OpportunitiesTile from '@/site/components/OpportunitiesTile';


    // Methods 
    function shopNoteCards() {

        router.push({
            name: 'ProductSearch',
            query: {
                "f-product-search-term" :'notecard'
            }
        });
    }

    function subscribe() {

        router.push({
            name: 'ProductSearch',
            query: {
                'f-product-search-term': 'AP-3954M'
            }
        });
    }

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Relationship Marketing", "lob": "Retention", "productNames": "MSC_Relationship Marketing" });
    });
</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container retention-home">

                <div class="row">

                    <HeadlineWithIcon iconPath="/images/icons/agencies-served-icon.png" title="Relationship Marketing" subTitle="Deepen client relationships through proactive outreach." />

                </div>

                <div class="row mt-4">

                    <div class="col">

                        <p>
                            Sending a personal note or message to your customers greatly increases customer loyalty and keeps you top-of-mind. Research has shown that regular communication with your customers improves loyalty, leads to more Cross-sell/up-sell opportunities and can <strong>increase retention rates by 2-5% on average</strong>. Safeco offers easy-to-use relationship marketing materials for you. View your list of customers up for renewal in the next three months below.
                        </p>


                    </div>

                </div>

                <div class="row">
                    <div class="tab-content">
                        <div class="tab-pane fade active show" id="growth-retention" role="tabpanel">
                            <div class="sub-tab-container">
                                <OpportunitiesTile filter-id="opportunities-retention" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-5">

                    <div class="col-sm-6">

                        <img src="/images/retention/subscription-relationship-marketing-program.png" class="img-fluid text-center mb-3" alt="subscription relationship marketing program" />

                        <h2>Subscription-based Relationship Marketing Program</h2>

                        <ul>

                            <li>
                                Enroll in the <strong class="text-color-2-dk">Relationship Marketing Program</strong> and Safeco will automatically send seasonal note cards to customers that you select.
                            </li>

                            <li>
                                You can enroll in the <strong>3-month</strong> or <strong>6-month</strong> subscription program. Depending on the time of year, Safeco will send a seasonal messages to your customers (one card sent in the 3-month period and two cards for the 6-month period).
                            </li>

                        </ul>

                        <p><strong class="text-color-2-dk">Bonus:</strong> If your customer has a birthday that falls within the subscription period, they'll receive a Birthday card in addition to the seasonal message.</p>

                        <div class="text-center">

                            <a href="/downloads/SafecoMSC-Relationship-Marketing-Cards.pdf" target="_blank" class="btn btn-outline-primary me-5">Download Preview</a>
                            <button @click="subscribe()" class="btn btn-primary">Subscribe</button>
                        </div>

                    </div>

                    <div class="col-sm-6 mt-4 mt-sm-0">

                        <img src="/images/retention/personalized-note-cards.png" class="img-fluid text-center mb-3" alt="personalized note cards" />

                        <h2>Personalized Note Cards</h2>

                        <ul>

                            <li>
                                Thank you, birthday, and/or seasonal messages are nice gestures to show your customers how much you appreciate their business.
                            </li>

                            <li>
                                Once ordered, the note cards will be shipped directly to you.
                            </li>

                            <li>
                                When you receive them, we suggest you write a personal note and mail or hand them to your customers.
                            </li>

                        </ul>

                        <div class="text-center">
                            <button @click="shopNoteCards()" class="btn btn-primary me-5">Shop Note Cards</button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .retention-home {

        ul {
            padding-left: 0;

            li {
                list-style: none;
                background: url('/public/images/icons/teal-checkmark-icon.png') left top no-repeat;
                padding-left: 40px;
                margin-bottom: 20px;
                line-height: 25px;
            }
        }
    }
</style>