// Opportunities
// Services to all things cross sell 

export default class Opportunities {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    /**
     * Get the current agency for the logged in user 
     */
    async setEnrollment(agenyCode, oppType, licenseNumber) {
        return await this.store.api.post(`site/cross-sell/opportunity/enroll`, { statCode: agenyCode, crossSellType: oppType, licenseNumber: licenseNumber });
    }
}