<script setup>

    // OrderContactTile
    // Customizes the standard AddressTile component with options/markup specific to an order contact (i.e. shipping address OR marketing contact).

    // Components ----

    // Imports ----
    import { defineProps } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';
    // Props ----
    const props = defineProps({
        // The order contact to show
        contacts: {
            type: Array,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
    });

    // State ----

    function removeAddressListItem(addressToRemove) {
        this.props.contacts.splice(this.props.contacts.indexOf(addressToRemove), 1);
    }

    function getAddressee(contact) {
        if (Util.isNotEmpty(contact.addressee)) {
            return contact.addressee;
        } else {
            return contact.firstName + " " + contact.lastName;
        }
    }
    function formatContact(contact) {
        let contactArray = [contact.addressLine1, contact.addressLine2, contact.city, contact.state, contact.postalCode];
        return contactArray.join(', ').replace(', , ', ', ');
    }

</script>

<template>

    <div class="order-contacts-tile">
        <template v-if="props.contacts.length > 0">
            <ul class="list-group">
                <li class="list-group-item" v-for="contact in contacts" :key="contact">
                    <div><b>{{ contact.companyName }} </b></div>
                    <div><b>{{ getAddressee(contact) }}</b>, {{ formatContact(contact)}} <span class="float-end remove"><ActionButton v-if="editable" icon="bi bi-x-lg" label="" @click="removeAddressListItem(contact)" /></span></div>
                </li>
            </ul>
        </template>
        <div v-else>
            <slot name="empty"></slot>
        </div>
    </div>

</template>

<style lang="scss">

    .order-contacts-tile {
        overflow-x: clip;

        .name-block {
            margin-bottom: 0.25rem;
        }

        .name {
            font-weight: $bold;
            font-size: 0.9rem;
            line-height: 1rem;
        }

        .default-shipping {
            background-color: $highlight-1; // TODO: SCSS variable
        }

        .list-group-item:nth-child(even) {
            background: $neutral-lt;
        }
    }
</style>