
// AgencyAddressForm
// Used for adding or editing a agency address.

// Imports ----
import { site } from '@/Site';
import { ShippingAddressForm } from '@dd-nucleus/nucleus-vue';

// TODO: Figure out enums
const ADDRESS_TYPE_SHIPPING = 2;
const DEFAULT_MODEL = {
    id: '',
    contactId: '',
    name: '',                   // NOTE: Not individual's name, name of the address, e.g. "Home"
    addressee: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    city: '',
    state: '',
    province: '',
    postalCode: '',
    countryCode: '',
    companyName: '',
    phoneNumber: '',
    email:'',
    isResidential: false,
    addressType: ADDRESS_TYPE_SHIPPING,
    isDefault: false
};

export default class AgencyAddressForm extends ShippingAddressForm {
    // Constructor
    constructor(model = {}, id = null , addressConfig = null) {
        super({
            ...DEFAULT_MODEL,
            id: id,
            ...model,
            addressConfig
        })
        delete this.model.contactId;
        this.model.attributes = {
            phoneNumber: '',
            email:''
        };
        this.validator.fields.companyName.isRequired = true;
        this.validator.fields.phoneNumber.isRequired = true;
        this.validator.fields.phoneNumber.maxLength = 15;
        this.validator.fields.email.isRequired = false;
        this.validator.fields.email.maxLength = 50;
        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    onValidate() {
        super.onValidate();
    }

    async onValidateAsync() {
        super.onValidateAsync();
    }

    async reset() {
        this.model = {
            ...this.model,
            ...DEFAULT_MODEL,
            id: this.model.id
        };
        this.validator.reset();
    }

    async onSubmit() {
        this.model.name = this.model.addressee = this.model.companyName;
        this.model.attributes.phoneNumber = this.model.phoneNumber;
        this.model.attributes.email = this.model.email;
        return await site.agencyDetails.updateAgencyAddress(this.model, false, this.validator);
    }
}