<script setup>
    // Imports
    import { ref } from 'vue';

	let expanded = ref(false);

	// Handlers
    function handleExpandCollapse() {
        expanded.value = !expanded.value;
    }

</script>

<template>
    <div class="d-flex justify-content-between" @click="handleExpandCollapse">
        <div class="accordian-header">
            <slot name="header"></slot>
        </div>
         <i :class="{'bi': true, 'bi-chevron-up': expanded, 'bi-chevron-down': !expanded}"></i>
     </div>
    <Transition name="accordian-dropdown">
        <div v-show="expanded">
            <slot name="options"></slot>
        </div>
    </Transition>
</template>

<style lang="scss" scoped>

    .accordian-header {
        font-weight: $bold;
        margin-bottom: 0.5rem;
    }

    .accordian-dropdown-enter-active,
    .accordian-dropdown-leave-active {
        transition: all 0.3s ease-in-out;
    }

    .accordian-dropdown-enter-from,
    .accordian-dropdown-leave-to {
        opacity: 0;
    }

</style>