<script setup>

    // SelectedPaymentMethod
    // Display panel for the selected payment method

    // Imports ----
    import { computed, defineProps } from 'vue';
    import { site } from '@/Site';
    import { getMaskPen, getCardTypeName, getCardType, getCardDate } from '@/site/areas/account/components/PaymentInfoPanelStripe';
    import { Util } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        paymentMethodId : {
            type: String,
            required: false
        },
    });

    // Computed ----
    const selectedPaymentMethodId = computed(() => {
        if (!Util.isEmpty(site.cart.current.paymentMethodId)) {
            return site.cart.current.paymentMethodId;
        } else {
            return props.paymentMethodId;
        }
    });

    const selectedPaymentMethod = computed(() => {
        let method = {};
        site.paymentInfo.paymentMethods.forEach((x) => {
            if (x.id == selectedPaymentMethodId.value) {
                Object.assign(method, x);
            } 
        });
        return method;
    });

    const cardType = computed(() => {
        if (!Util.isEmpty(selectedPaymentMethod.value) && !Util.isEmpty(selectedPaymentMethod.value.paymentData))
            return getCardTypeName(selectedPaymentMethod.value.paymentData);
        else
            return '';
    });

    const maskPen = computed(() => {
        if (!Util.isEmpty(selectedPaymentMethod.value) && !Util.isEmpty(selectedPaymentMethod.value.paymentData))
            return getMaskPen(selectedPaymentMethod.value.paymentData);
        else
            return '';
    });

    const cardDate = computed(() => {
        if (!Util.isEmpty(selectedPaymentMethod.value) && !Util.isEmpty(selectedPaymentMethod.value.expirationDate))
            return getCardDate(selectedPaymentMethod.value.expirationDate);
        else
            return '';
    });

    const cardTypeName = computed(() => {
        if (!Util.isEmpty(selectedPaymentMethod.value) && !Util.isEmpty(selectedPaymentMethod.value.paymentData))
            return getCardType(selectedPaymentMethod.value.paymentData);
        else
            return '';
    });

</script>

<template>

    <div class="selected-payment-method" v-if="selectedPaymentMethod">

        <h3>Payment Method</h3>
        <div class="fw-bold mb-3">Credit Card</div>
        <div class="fw-bold">{{selectedPaymentMethod.name}}</div>
        <div><i class="bi bi-credit-card me-2" :class="cardTypeName"></i> {{cardType}}</div>
        <div>{{maskPen}}</div>
        <div>{{cardDate}}</div>
    </div>
    <div v-else>
        No payment method selected please go back and select a payment method.
    </div>

</template>

<style lang="scss">

    .selected-payment-method {

    }

</style>