<script setup>

    // LinkItem
    // LinkItem will determine if click is internal or external.
    import { computed, defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        link: {
            type: [String, Object],
            required: true
        },
        isExternal: {
            type: Boolean,
            required: false,
            default: false
        },
        openNewWindow: {
            type: Boolean,
            required: true
        },
        // Detemines how many levels deep this links is used for indenting a link (children links)
        level: {
            type: Number,
            required: false,
            default: 0
        },
        // Takes in the number (or fractions) of rems to indent, defaults is 1rem
        indentPerLevelRem: {
            type: Number,
            required: false,
            default: 1
        },
        // This determines if we want to show the chevron right next to the link items
        showChevron: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // Lifecycle Hooks ----
    const target = computed(() => {
        if (props.openNewWindow) {
            return '_blank';
        }
        return '_self';
    });

    const indentStyle = computed(() => {
        return "margin-left: " + props.level * props.indentPerLevelRem + "rem;";
    });

    function scrollToTop(){
        window.scroll(0,0);
    }

</script>

<template>

    <div class="link-item" :style="indentStyle">

        <template v-if="!props.isExternal">
            <router-link :to="props.link" class="no-underline" @click="scrollToTop">
                {{props.title}} <i v-if="props.showChevron" class="bi bi-chevron-right"></i>
            </router-link>
        </template>

        <template v-else>
            <a :href="props.link" class="no-underline" :target="target">
                {{props.title}} <i v-if="props.showChevron" class="bi bi-chevron-right"></i>
            </a>
        </template>

    </div>

</template>

<style lang="scss">
    .link-item {

        .bi {
            font-size: 12px;
        }
    }
</style>