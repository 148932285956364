<script setup>

    // OrderItemTile
    // A tile to display an Order Item.

    // Constants
    const tableHeadings = ["Mail Date", "Drop #", "Quantity", "Price"];
    //const expressOrderTableHeadings = ["Sub Code", "Agency Name", "Mail Date", "Drop #", "Quantity", "Price", ""];
    const chargeMessage = "Note: You will only be charged for the actual number of items sent";
    
    // Imports ----
    import { computed, reactive, defineProps } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { getUnitOfMeasureFriendlyName, ProgramType } from '@/constants'

    // Props ----
    const props = defineProps({
        orderItem: {
            type: Object,
            required: true
        },
        orderNumber: {
            type: String,
            required: true
        },
        totalItems: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        totalAmount: {
            type: String,
            required: true
        }
    });

    // State ----
    const router = useRouter();
    const route = useRoute();

    const orderItem = reactive(props.orderItem);

    // Computed ---
    const itemPrice = computed(() => { return site.convertNumberToCurrency(orderItem.product.unitPrice) });
    
    
    const mailingDetails = computed(() => {
        //if (!isExpressOrder.value) {
            return orderItem?.attributes?.Data?.dropDetails?.mailingDetails || [];
        /*} else {
            const mailings = [];
            const bulkOrderStates = orderItem?.attributes?.OrderStates;
            for (let i = 0; i < bulkOrderStates?.length; i++) {
                const orderStateMailing = bulkOrderStates[i].dropDetails?.mailingDetails;
                if (isNotEmpty(orderStateMailing)) {
                    const newMailingDetails = {
                        mailDate: orderStateMailing[0]?.mailDate,
                        dropNumber: orderStateMailing[0]?.dropNumber,
                        quantity: orderStateMailing[0]?.quantity,
                        price: orderStateMailing[0]?.price,
                        statCode: bulkOrderStates[i]?.statCode,
                        agencyName: bulkOrderStates[i]?.agencyName,
                        orderStateId: bulkOrderStates[i].id
                    };
                    mailings.push(newMailingDetails);
                }
            }
            return mailings;
        }*/
    });
    
    const actualQty = computed(() => {
        if (orderItem?.attributes?.Data?.receivedCount) {
            return orderItem?.attributes?.Data?.receivedCount;
        }
        else {
            return orderItem?.attributes?.Data?.targetCount;
        }
    });
     
/*    const isExpressOrder = computed(() => {
        return orderItem?.attributes?.OrderStates && orderItem?.attributes?.OrderStates?.length > 0;
    });
*/
    const targetCount = computed(() => {
        //if (!isExpressOrder.value) {
            return orderItem.attributes?.Data?.targetCount
        /*} else {
            const bulkOrderStates = orderItem?.attributes?.OrderStates;
            return bulkOrderStates.reduce((sum, bos) => sum + bos.targetCount , 0);
        }*/
    });
    
    /*const headings = computed(() => {
        return isExpressOrder.value ? expressOrderTableHeadings : tableHeadings;
    });*/

    const completedDrops = computed(() => {
        const dropDetails = orderItem.attributes.Data?.dropDetails;
        if (dropDetails) {
            const completedMailings = dropDetails.mailingDetails?.filter(md => {
                const mailedDate = new Date(md.mailDate);
                const currentDate = new Date();
                return mailedDate < currentDate
            });
            return completedMailings.length;
        }
        return 0
    });

    const icon = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (orderItem.status) {
            default: // None
                return '';
            case 'Pending': // Pending
                return 'bi bi-check-circle-fill';
            case 'Complete': // Complete
                return 'bi bi-check-circle-fill';
            case 'Canceled': // Canceled
                return 'bi bi-x-circle-fill';
            case 'Removed': // Removed
                return 'bi bi-trash3';
        }
    });

    const cssClass = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (orderItem.status) {
            default: // None
                return '';
            case 'Pending': // Pending
                return 'pending';
            case 'Complete': // Complete
                return 'completed';
            case 'Canceled': // Canceled
                return 'canceled';
            case 'Removed': // Removed
                return 'removed';
        }
    });

    const dropDetails = computed(() => {
        return orderItem.attributes?.Data?.dropDetails;
    });

    const showViewList = computed(() => {
        if (isProspecting.value || isPetInsurance.value) {
            const mailingDetails = orderItem.attributes?.Data?.dropDetails?.mailingDetails;
            if (mailingDetails && mailingDetails.length > 0) {                
                let mailDate = mailingDetails[0].mailDate;
                if (mailDate) {
                    const mailedDate = new Date(mailDate);
                    const currentDate = new Date();
                    return mailedDate < currentDate && !isMailingListExpired(mailDate);
                }
                return false;
            }
            return false;
        } else {
            const dropDetails = orderItem.attributes?.Data?.dropDetails;
            if (dropDetails) {
                return dropDetails.numberOfMailings > 0;
            }
            return false;
        }
    });

    function isMailingListExpired(lastMailingDate) {
        const itemdate = new Date(lastMailingDate);
        itemdate.setDate(itemdate.getDate() + 60);

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let days = (itemdate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

        if (days < 0) {
            return true;
        }
        return false;
    }

    const isProspecting = computed(() => {
        const programType = orderItem.attributes?.Data?.programType;
        return programType == ProgramType.PROSPECTING;
    });

    const isPetInsurance = computed(() => {
        const programType = orderItem.attributes?.Data?.programType;
        return programType == ProgramType.PET_INSURANCE;
    });

    //Methods --
    function channelType(type) {
        switch (type) {
            case ChannelTypes.EMAIL:
                return "Email";
            case ChannelTypes.DIRECT_MAIL:
                return "Direct Mail";
            default:
                return "";
        }
    }

    // Handlers ----
    function handleViewListClick() {
        if (Util.isNotEmpty(route.query.orderNumber)) {
            router.push({ path: '/account/order-detail', query: { 'f-order-details-list-order-num': `${props?.orderNumber};${orderItem?.attributes?.Data?.pageFlexDocumentId}` } });
        }
        else {
            router.push({ path: '/account/order-detail', query: { 'f-order-details-list-order-num': `${props?.orderNumber};${orderItem?.attributes?.Data?.id}` } });
        }
    }


</script>

<template>

    <div class="order-item-tile mt-4">
        <span class="multi-channel-campaign" v-if="orderItem?.attributes?.Data?.isMultiChannel && index === 0">
            Multi-Channel (Direct Mail & Email)
        </span>
        <div class="my-4 fs-2 fw-bold text-primary" v-if="orderItem?.attributes?.Data?.isMultiChannel">{{channelType(orderItem?.attributes?.Data?.channelType)}} ({{ index+1 }} of {{totalItems}})</div>
        <div class="inner-row d-flex flex-row">
            <div class="left">
                <ProductThumbnail :product="orderItem.product" height="120px" width="120px" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill pr-4">
                <router-link class="title-link" :to="`/product/${orderItem.product.id}`">
                    <div class="name" v-snip="{ lines: 2 }" :title="orderItem.product.name">
                        {{ orderItem.product.name }}
                    </div>
                </router-link>

                <div class="item-number"><router-link class="title-link" :to="`/product/${orderItem.product.id}`">{{ orderItem.product.itemNumber }}</router-link></div>

                <div class="price">
                    <span class="fw-bold">{{itemPrice}}</span> {{getUnitOfMeasureFriendlyName(orderItem.product)}} <span v-if="orderItem?.attributes?.Data?.includePhoneNumber">(Includes Phone Numbers)</span>
                </div>

                <div class="delivery-type" v-if="channelType(orderItem?.attributes?.Data?.channelType)"><span class="fw-bold">Delivery Type:</span> {{channelType(orderItem?.attributes?.Data?.channelType)}}</div>

                <div class="mt-3 fs-4 fw-bold" v-if="dropDetails?.numberOfMailings">Total:</div>
                <div class="fs-3 fw-bold" v-if="dropDetails?.numberOfMailings">{{totalAmount}}</div>

                <div class="description mt-3 fs-5" v-html="orderItem.product.description"></div>

                <div class="d-flex flex-column mt-3" v-if="dropDetails?.numberOfMailings && !isPetInsurance">
                    <div class="fs-5 fw-bold">Mail Frequency: {{dropDetails?.dropType}} Drops ({{dropDetails?.frequency}}), {{dropDetails?.numberOfMailings}} Mailings</div>
                    <div class="fs-5">
                        <template v-if="isProspecting">
                            Total Prospect Count:
                        </template>
                        <template v-else>
                            Total Policy Holders Count:
                        </template>
                        {{targetCount}}
                    </div>
                </div>
                <div class="mail-info d-flex align-items-center mt-3" v-if="orderItem.attributes.Data?.dropDetails?.numberOfMailings && !isPetInsurance">
                    <i class="bi bi-info-circle-fill text-warning"></i>
                    {{ chargeMessage }}
                </div>

                <div class="mail-info d-flex align-items-center mt-3" v-if="dropDetails?.numberOfMailings && !isPetInsurance">
                    <i class="bi bi-info-circle-fill text-warning"></i>
                    Mailed out on the dates listed below. Allow up to 10 days for in-home delivery. 
                </div>
                <div class="mail-info d-flex align-items-center mt-3" v-if="dropDetails?.numberOfMailings">
                    <i class="bi bi-info-circle-fill text-warning"></i>
                    For customer mailings, prior to the first mail/email date, View List will display the records you selected for the order. Once the direct mail or email has gone out, View List will display the records contacted.
                </div>

                <div class="mt-3" v-if="dropDetails?.numberOfMailings && !isPetInsurance">
                    <table class="table">

                        <thead>
                            <tr class="table-heading">
                                <th v-for="(heading, index) in tableHeadings" :key="index" class="col-head" scope="col">
                                    {{ heading }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="row in mailingDetails" :key="row.dropNumber">
                                <td>
                                    {{Util.date(row.mailDate, false)}}
                                </td>
                                <td>{{row.dropNumber}}</td>
                                <td>{{row.quantity}}</td>
                                <td>{{site.convertNumberToCurrency(row.price)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Fixed right panel -->
            <div class="right d-flex flex-column px-4">
                <template v-if="showViewList">
                    <button class="btn btn-primary view-list" @click="handleViewListClick">View List</button>
                    <div class="my-1 text-danger" v-if="dropDetails?.numberOfMailings && !isPetInsurance">
                        Drops Complete: {{completedDrops}} of {{ dropDetails?.numberOfMailings}}
                    </div>
                    <!--div>
                        <p v-for="(item, index) in dropExpiredMessages" :key="index">
                            {{ item }}
                        </p>
                    </div-->
                </template>
                <div>
                </div>
                <div class="mt-3 fs-3">
                    <span class="fw-bold">Ordered Qty: </span> {{orderItem.quantity}} {{getUnitOfMeasureFriendlyName(orderItem.product)}}
                </div>
                <div class="status d-flex" :class="cssClass"><i :class="icon"></i>&nbsp; {{ orderItem.status }}</div>
                <div class="mt-3 fs-3">
                    <span class="fw-bold">Actual Qty: </span> {{actualQty}}
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .order-item-tile {
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .name {
            font-size: 1.1rem;
            line-height: 1.1rem;
        }

        .item-number a.title-link {
            font-size: 14px;
            color: $gray-60;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .description {
            color: $gray-70;
        }

        .price {
            font-size: 1rem;
        }

        .delivery-type {
            font-size: 14px;
        }

        .mail-info {
            font-size: 13px;
            font-weight: bold;

            i {
                font-size: 18px;
                margin-right: 5px;
            }
        }

        .table-heading {
            background: #c9f3f4;
        }

        .middle {
            min-width: 60%;
            padding-left: 1rem;
            padding-right: 2rem;
        }

        .right {
            width: 40% !important;
        }

        .status {
            margin-top: 0.5rem;
            font-weight: $bold;
            font-size: 1rem;
            align-items: center;
        }

        .status i {
            font-size: 1.2rem;
        }

        .status.pending {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.canceled, status.removed {
            .bi {
                color: $error;
            }
        }

        .status.completed {
            .bi {
                color: $ok;
            }
        }

        .multi-channel-campaign {
            color: white;
            background-color: orange;
            padding: 5px 12px;
            border-radius: 50px;
            font-size: 12px;
        }

        .view-list {
            white-space: nowrap;
        }
    }
</style>
