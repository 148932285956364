<script setup>

    // AccountPage
    // Shows various pages related to the user's account.

    // Components ----
    import AgencyListsPanel from './components/AgencyListsPanel';
    import ContactsPanel from './components/ContactsPanel';
    import FavoritesPanel from './components/FavoritesPanel';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ImageLibraryPanel from './components/ImageLibraryPanel';
    import OrderHistoryPanel from './components/OrderHistoryPanel';
    import PaymentInfoPanel from './components/PaymentInfoPanelStripe';
    import ProfilePanel from './components/ProfilePanel';
    import CalendarPanel from './components/CalendarPanel';
    import OrderDetailsPanel from './components/OrderDetailsPanel';
    import OrderDetails from './components/OrderDetails';
    import CustomDownloadListPanel from './components/CustomDownloadListPanel';
    import SaveForLaterPanel from '@/site/areas/account/components/SaveForLaterPanel';
    
    // Imports ----
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';


    const route = useRoute();

    // State ----
    let section = ref(route.params.section);

    // Update section when route (URL) changes
    watch(() => route.params, () => { section.value = route.params.section });

    // Computed ----

    // Methods ----
    function getLinkClass(forSection) {
        return { 'active': forSection === section.value };
    }
    // TODO: Not everyone will be able to change their password, depending on their auth method and/or store settings

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar />

            <div class="row mt-5">
                <div class="col-sm-4 col-md-3 d-none d-sm-block">
                    <div class="top-link side-link mb-2">My Account</div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('profile')"><router-link to="/account/profile">My Profile</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('favorites')"><router-link to="/account/favorites">My Favorites</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('contacts')"><router-link to="/account/contacts">My Contacts</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('payment-info')"><router-link to="/account/payment-info">My Payment & Billing Info</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('order-history')"><router-link to="/account/order-history">My Order History/Lists</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('downloads')"><router-link to="/account/downloads">My Custom Downloads</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('agency-lists')"><router-link to="/account/agency-lists">My Uploaded Agency Lists</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('image-library')"><router-link to="/account/image-library">My Image Library</router-link></div>
                    <div class="sub-link side-link mb-2" :class="getLinkClass('save-for-later')"><router-link to="/account/save-for-later">My Saved Items</router-link></div>
                    <!--
        TODO: Uncomment this nav item when are ready for the My Calendar Story
        <div class="sub-link side-link mb-2" :class="getLinkClass('calendar')"><router-link to="calendar">My Calendar</router-link></div>
    -->
                    <!-- LEAVING THIS IN HERE FOR NOW, AS IT PROVIDES A NICE EXAMPLE OF HOW TO SHOW MENU ITEMS BASED ON ROLES -->
                    <!--<ShowOnlyForRoles :roles="['SXM.OemAccountTeam', 'SXM.OemArdFieldTeam', 'SXM.RefreshFieldTeam', 'SXM.AffinityShopsFieldTeam', 'SXM.PreOwnedAccountTeam', 'SXM.RetailSalesTeam', 'Qualfon.CsdAdmin', 'Qualfon.Support']">
        <div class="sub-link side-link" :class="getLinkClass('')"><a target="_blank" href="/pdf/SXM Conference Orders form_v1.5.pdf">Event Order Form</a></div>
    </ShowOnlyForRoles>-->
                </div>

                <div class="col-sm-8 col-md-9">
                    <div v-if="section === 'profile'">
                        <!-- My Profile -->
                        <ProfilePanel />
                    </div>

                    <div v-if="section === 'favorites'">
                        <!-- My Favorites -->
                        <FavoritesPanel />
                    </div>

                    <div v-if="section === 'contacts'">
                        <!-- My Contacts -->
                        <ContactsPanel />
                    </div>

                    <div v-if="section === 'payment-info'">
                        <!-- My My Payment & Billing Info -->
                        <PaymentInfoPanel :largeCreateButton="true" />
                    </div>

                    <div v-if="section === 'order-history'">
                        <!-- My Order History -->
                        <OrderHistoryPanel />
                    </div>

                    <div v-if="section === 'order-detail'">
                        <!-- Order Details -->
                        <OrderDetailsPanel />
                    </div>

                    <div v-if="section === 'order'">
                        <!-- Order Details for single order -->
                        <OrderDetails />
                    </div>

                    <div v-if="section === 'agency-lists'">
                        <!-- My Uploaded Agency Lists -->
                        <AgencyListsPanel />
                    </div>

                    <div v-if="section == 'image-library'">
                        <!-- My Image Library -->
                        <ImageLibraryPanel />
                    </div>

                    <div v-if="section == 'calendar'">
                        <!-- My Calendar -->
                        <CalendarPanel />
                    </div>
                    <div v-if="section == 'downloads'">
                        <!-- My Calendar -->
                        <CustomDownloadListPanel />
                    </div>
                    <div v-if="section == 'save-for-later'">
                        <!-- Save for later -->
                        <SaveForLaterPanel />
                    </div>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .side-link {
        padding-top: 0.5rem;
        
        &.active {
            font-weight: $bold;
        }

        a, a:visited {
            color: $color-2;
            text-decoration: none;
        }
    }

    .top-link {
        padding-top: 0rem;
    }

    .sub-link {
        padding-left: 1rem;
    }
</style>