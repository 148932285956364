<script setup>

    // AnnualAutoPersonalizationSubCodesPage
    // Annual Auto process to select the same personalization for all sub codes 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';

    // Methods
    function samePersonalization(isSame) {
        // TODO: add the functionality to include the same personalization
        console.log("button clicked was: ", isSame);
    }

</script>

<template>
    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container annual-auto-personalization-sub-codes">

                <div class="row mt-5">

                    <div class="col text-center">

                        <h1>
                            Would you like to apply the same personalization for all the Sub Codes selected?
                        </h1>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col text-center">

                        <button class="btn btn-outline-primary me-3" @click="samePersonalization(false)">No</button>
                        <button class="btn btn-primary" @click="samePersonalization(true)">Yes</button>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">
                        <!-- TODO: Add the route to go back -->
                        <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>
</template>

<style lang="scss">
    .annual-auto-personalization-sub-codes {
    }
</style>