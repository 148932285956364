<script setup>

    // SelectSubscriptionPolicyholders
    // Step 2 SelectPolicyholders for Rentention Subscriptions

    // Constants ----

    const columns = [
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address', custom: true },
        { field: 'birthMonth', title: 'Birth Month', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Policy Type', isSortable: true },
        { field: 'isBirthdayCardEligible', title: 'Birthday', custom: true },
        { field: 'isSeasonalCardEligible', title: 'Seasonal', custom: true },
    ];

    const durationOptions = [{ title: '3-Month', value: '3' }, { title: '6-Month', value: '6' }];

    // Imports
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { ref, reactive, computed, onMounted, defineProps, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/constants'
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';

    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import { isNotEmpty } from '@/util';

    //props

    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    // State
    const router = useRouter();
    const emit = defineEmits(['saveForLater']);

    let selectedList = reactive([]);

    const detail = reactive({ product: props.product });

    const subscriptionPeriod = ref(durationOptions[0].value);

    const isLoaded = ref(false);
    const loadedAllRecords = ref(false);
    //const isSelectedAllChecked = ref(false);
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const search = new Search('loyalty-cards-list-search', 'row', 'subscription-firstName');

    search.onInitFilters(() => {
        if (orderFlowDetails.subscriptionPeriod) {
            search.setFilterInitialValue('subscription-period', String(orderFlowDetails.subscriptionPeriod));
        } else {
            search.setFilterInitialValue('subscription-period', '3');
        }
    });

    let summaryDetails = reactive([]);

    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    const listCount = computed(() => {
        let count = 0;
        for (var i = 0; i < summaryDetails.length; i++) {
            count = count + summaryDetails[i].listCount;
        }
        return count;
    });

    const totalSelected = computed(() => {
        let count = 0;
        for (var i = 0; i < summaryDetails.length; i++) {
            count = count + summaryDetails[i].selectedCount;
        }
        return count;

    });

    const showNext = computed(() => {
        return totalSelected.value > 0
    });

    const totalPrice = computed(() => {
        return totalSelected.value * detail.product.unitPrice;
    });

    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    async function saveForLater() {
        await site.personalizationInfo.enableSaveForLater();
        await UpdateRecipients();
        emit('saveForLater');
    }


    async function next() {
        // This needs to go to the review page check that it is working correctly

        await UpdateRecipients();
        router.push({
            name: 'PersonalizeStep3ReviewPage',
            params: { id: orderFlowDetails.id }
        });

    }

    async function UpdateRecipients() {
        orderFlowDetails.subscriptionPeriod = isNotEmpty(subscriptionPeriod.value) ? Number(subscriptionPeriod.value) : 0;

        orderFlowDetails.targetCount = totalSelected.value;

        summaryDetails = [...summaryDetails,
        {
            "assetName": "Total",
            "listCount": listCount.value,
            "selectedCount": totalSelected.value,
            "price": totalPrice.value
        }
        ];

        orderFlowDetails.seasonalSummary = summaryDetails;


        const newSearch = new Search('loyalty-cards-list-search', 'row', 'subscription-firstName');
        if (orderFlowDetails.subscriptionPeriod) {
            newSearch.setFilterInitialValue('subscription-period', String(orderFlowDetails.subscriptionPeriod));
        } else {
            newSearch.setFilterInitialValue('subscription-period', '3');
        }
        await newSearch.activate();

        const allRows = newSearch.results?.rows;

        const selected = allRows.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {

            return {
                "externalId": sl.externalId,
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2,
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "policyType": sl.policyType,
                "renewalData": sl.renewalDate,
                "birthDate": sl.birthDate,
                "isBirthdayCardEligible": sl.isBirthdayCardEligible,
                "isSeasonalCardEligible": sl.isSeasonalCardEligible,
                "birthMonthNumber": sl.birthMonthNumber,
                "birthDayMailDate": sl.birthDayMailDate,
                "renewalMailDate": sl.renewalMailDate,
            };
        });
        await site.personalizationInfo.addSubscriptionPolicyHolders(selected);
    }

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    function selectAll(isSelectAll, list) {
        selectedList.splice(0);
        summaryDetails.forEach(sr => {
            sr.selectedCount = 0;
            sr.price = 0;
        });
        if (isSelectAll) {
            Object.assign(selectedList, list.map(l => l.policyNumber));
            adjustBirthdaySelectedCount();
            adjustSeasonalSelectedCount();
        }
    }

    function adjustBirthdaySelectedCount() {
        const allRows = search.results?.rows;
        if (allRows) {
            const bdEligibleRows = allRows.filter(ar => selectedList.includes(ar.policyNumber) && ar.isBirthdayCardEligible);
            const bdRecord = summaryDetails.find(ad => ad.assetName == 'Birthday');
            if (isNotEmpty(bdRecord)) {
                bdRecord.selectedCount = bdEligibleRows?.length || 0;
                bdRecord.price = bdRecord.selectedCount * detail.product.unitPrice;
            }
        }
    }

    function adjustSeasonalSelectedCount() {
        const allRows = search.results?.rows;
        if (allRows) {
            const seasonalEligibleRows = allRows.filter(ar => selectedList.includes(ar.policyNumber) && ar.isSeasonalCardEligible && ar.birthDayMailDate);
            const seasonalRecords = summaryDetails.filter(ad => ad.assetName != 'Birthday');
            seasonalRecords.forEach(sr => {
                const seasonalRecordCount = seasonalEligibleRows.filter(sr => new Date(sr.birthDayMailDate).getMonth() != new Date(sr.mailDate).getMonth());
                console.warn('seasonalRecordCount', seasonalRecordCount.length)
                sr.selectedCount = seasonalRecordCount.length || 0;
                sr.price = seasonalRecordCount.length * detail.product.unitPrice;
            });
        }
    }

    async function refreshSearch(period) {
        loadedAllRecords.value = false;
        await getSummaryData(period);
        await search.setFilterValue('subscription-period', period);
    }

    async function getSummaryData(period) {

        const response = await site.personalizationInfo.getRelationMarketingSummary(period);
        summaryDetails.length = 0;

        for (var i = 0; i < response?.summary?.length; i++) {
            const summary = response.summary[i];
            summary.selectedCount = 0;
            summary.price = 0;
            summaryDetails.push(summary);
        }
    }

    onMounted(async () => {

        if (orderFlowDetails.subscriptionPeriod) {
            subscriptionPeriod.value = String(orderFlowDetails.subscriptionPeriod);
        }

        await getSummaryData(subscriptionPeriod.value);
        isLoaded.value = true;
    });

    const searchTerm = ref('firstName');

    function sortSearch(value) {

        value = 'subscription-' + value

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
        adjustBirthdaySelectedCount();
        adjustSeasonalSelectedCount();
    }

    search.onResults(() => {
        if (props.orderState?.mailContacts?.length == 0) {
            selectedList.length = 0;
            Object.assign(selectedList, search.results?.rows?.map(mc => mc.policyNumber));
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const currentSelectedList = props.orderState?.mailContacts.map(mc => mc.policyNumber);
            const filteredSelectedList = search?.results?.rows?.filter(mc => currentSelectedList.includes(mc.policyNumber));
            if (filteredSelectedList.length > 0) {
                Object.assign(selectedList, filteredSelectedList.map(mc => mc.policyNumber));
            }
            else {
                selectedList.length = 0;
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.policyNumber));
            }
        }
        adjustBirthdaySelectedCount();
        adjustSeasonalSelectedCount();
    });

</script>

<template>

    <div class="select-subscrition-policy-holders" v-if="isLoaded">
        <div class="row my-5">

            <div class="col border-bottom border-color-1">

                <div class="d-flex">
                    <div class="text-color-1">
                        <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                    </div>
                    <div class="ms-auto">
                        {{detail.product.name}} ({{detail.product.itemNumber}})
                    </div>
                </div>

            </div>

        </div>

        <div class="row select-duration p-3">
            <div class="col">
                <div class="fw-bold">
                    Select Subscription Duration
                </div>
                <div v-for="option in durationOptions" :key="option.value" class="form-check form-check-inline mt-3">
                    <input class="form-check-input"
                           type="radio"
                           v-model="subscriptionPeriod"
                           :value="option.value"
                           :id="option.value"
                           @click="refreshSearch(option.value)" />
                    <label class="form-check-label" :for="option.value">{{ option.title }}</label>
                </div>
            </div>
        </div>

        <div class="row mt-3" v-if="isLoaded">
            <div class="col">
                <SearchContainer :search="search" default-view="list" :deep-link="false">
                    <div class="row mb-2">
                        <div class="d-flex">
                            <div class="me-auto">
                                <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                            </div>
                        </div>
                    </div>
                    <div class="data-table">
                        <SearchTableV1 :columns="columns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">
                            <template v-slot:address1="row">
                                <div>{{row.address1}} {{row.address2}}</div>
                                <div>{{row.city}} {{row.state}} {{row.zip}}</div>
                            </template>

                            <template v-slot:isSeasonalCardEligible="row">
                                <div class="text-center fs-1">
                                    <i v-if="row.isSeasonalCardEligible" class="bi bi-x-square"></i>
                                    <i v-else class="bi bi-square"></i>
                                </div>
                            </template>

                            <template v-slot:isBirthdayCardEligible="row">
                                <div class="text-center fs-1">
                                    <i v-if="row.isBirthdayCardEligible" class="bi bi-x-square"></i>
                                    <i v-else class="bi bi-square"></i>
                                </div>
                            </template>

                            <template v-slot:renewalDate="row">
                                {{formatDate(row.renewalDate)}}
                            </template>

                            <!-- Template for no results -->
                            <template #empty>
                                None of your policyholders currently meet the criteria for this type of campaign.
                            </template>

                            <!-- Template while results are initially loading -->
                            <template #loading>
                                <div class="row">
                                    <div class="col text-center">
                                        <div class="spinner-border me-3" role="status"></div>
                                        <div class="spinner-border me-3" role="status"></div>
                                        <span class="fw-bold">Performing a search for the data</span>
                                        <div class="spinner-border mx-3" role="status"></div>
                                        <div class="spinner-border" role="status"></div>
                                    </div>
                                </div>
                                <TableSkeleton />
                            </template>
                        </SearchTableV1>
                    </div>
                </SearchContainer>
            </div>
        </div>

        <div class="row mt-3" v-if="isNotEmpty(summaryDetails)">
            <div class="col-6">
                <div class="fw-bold text-center summary p-1 mb-1">
                    Summary
                </div>
                <div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>List Count</th>
                                <th>Selected</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in summaryDetails" :key="detail.assetName">
                                <td>{{detail.assetName}}</td>
                                <td>{{detail.listCount}}</td>
                                <td>
                                    <span>
                                        {{detail.selectedCount}}
                                    </span>
                                </td>
                                <td>{{site.convertNumberToCurrency(detail.price)}}</td>
                            </tr>
                            <tr class="fw-bold">
                                <td>Total</td>
                                <td>{{listCount}}</td>
                                <td>{{totalSelected}}</td>
                                <td>{{site.convertNumberToCurrency(totalPrice)}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>

        <div class="row mt-3">

            <div class="d-flex mb-3">
                <div class="me-auto">
                    <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>
                </div>
                <div class="d-flex">
                    <div class="mx-5"><button v-if="isAgencySelected" @click="saveForLater" class="btn btn-primary" >Save For Later</button></div>
                    <button @click="next" class="btn btn-primary" :disabled="!showNext">Next</button>
                </div>
            </div>

        </div>
    </div>

</template>

<style lang="scss">

    .select-subscrition-policy-holders {

        .select-duration {
            background: $highlight-4;
        }

        .summary {
            background: $color-2-dk;
            color: $gray-0;
        }

        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead tr {
            background: $color-2;
            color: $gray-0;
        }

        thead tr th {
            font-weight: 400;
            border-right: 1px solid #fff;
        }

        thead tr th:last-child {
            border-right: none;
        }

        tbody tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }
        }
    }
</style>
