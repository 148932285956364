<script>

    // ProspectingSelectPromoteProspectsPage
    // Landing page for Prospecting & Lead Gen - Select Promote Prospects

    const constants = {
        prospectingTypes: {
            PACKAGE: "Package",
            AUTO: "Auto",
            HOME: "Home",
        }
    };

    export const prospectingTypes = constants.prospectingTypes;


</script>
<script setup>

    import { useRouter } from 'vue-router';
    import { site } from '@/Site'
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { ProgramType, ProductType } from '@/constants';

    const router = useRouter();

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';

    // Methods


    async function startOrder(productType) {

        router.push({
            name: 'SelectAssetPage',
            query: {
                statCode: site.agencyDetails.selectedAgencyDetails.statId,
                productType: productType,
                programType: ProgramType.PROSPECTING,
                isMultiChannel: false,
                channelType: ChannelTypes.DIRECT_MAIL
            }
        });

    }

    function back() {

        if (router.options.history.state.back == '/prospecting') {
            router.back();
        } else {
            router.push({ name: 'Home', query: { tab: ProgramType.PROSPECTING } })
        }
    }

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />

            <div class="root-page-container prospecting-select-promote-prospects">

                <div class="row mt-5">

                    <div class="col text-center">
                        <h1>What lines of business would you like to promote?</h1>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-2 text-center">
                    </div>
                    <div class="col-sm-8 text-center">

                        <p>Availability for Prospecting campaigns may vary by state. Please check back for future updates to availability and reach out to your TM with any questions.</p>

                    </div>
                </div>

                <div class="row my-5">

                    <div class="col">

                        <div class="d-flex justify-content-center">

                            <div class="text-center me-5">

                                <img src="/images/prospecting/package.png" alt="package insurance" />

                                <div>
                                    <button class="btn btn-primary mt-2" @click="startOrder(ProductType.SAFECO_PACKAGE)">Package</button>
                                </div>

                            </div>

                            <div class="text-center">

                                <img src="/images/prospecting/auto-insurance.png" alt="auto insurance" />

                                <div>
                                    <button class="btn btn-primary mt-2" @click="startOrder(ProductType.AUTO)">Auto</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <button @click="back" class="btn btn-outline-primary">Previous</button>

                    </div>

                </div>

            </div>

        </div>

</PageTemplate>

</template>

<style lang="scss">
    .prospecting-select-promote-prospects {
    }
</style>