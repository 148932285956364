<script setup>

    // OrderHistoryTile
    // A tile used for each order in order history.

    // Components ----

    // Imports ----
    import { defineProps, computed } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { formatStatCode } from '@/util';
    import { ProgramType } from '@/constants'

    // Props ----
    const props = defineProps({
        // The order whose information to show.
        // API model: DD.Nucleus.Storefront.Api.OrderTileModel
        order: {
            type: Object,
            required: true
        }
    });

    // Computed ----
    const icon = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (props.order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'bi bi-check-circle-fill';
            case 2: // PendingApproval
                return 'bi bi-pause-circle-fill';
            case 99: // Canceled
                return 'bi bi-x-circle-fill';
            case 100: // Completed
                return 'bi bi-check-circle-fill';
        }
    });

    const totalPrice = computed(() => { return site.convertNumberToCurrency(props.order.total) });
    const amountPaid = computed(() => { return site.convertNumberToCurrency(props.order.amountPaid) });
    const orderItems = computed(() => {
        if (props.order.items.length > 1)
            return props.order.items.length + "items"

        return props.order.items.length + "item"
    });

    const isLegacyOrder = computed(() => {
        if (props.order.id == '074c59a0-af04-4c18-a150-9cb0f9405f12') {
            return true;
        } else {
            return false;
        }
    });

    const isExpressOrder = computed(() => {
        return props.order.items.filter(i => i.attributes?.OrderStates?.length > 0)?.length > 0;
    });


    const isCompleted = computed(() => {
        if (props.order.orderStatusCode == 100)
            return true;
        return false;
    })

    const cssClass = computed(() => {
        // TODO: Hacked in. Create constants in Orders service plugged into singleton store object.
        switch (props.order.orderStatusCode) {
            default: // None
                return '';
            case 1: // Pending
                return 'pending';
            case 2: // PendingApproval
                return 'pending-approval';
            case 99: // Canceled
                return 'canceled';
            case 100: // Completed
                return 'completed';
        }
    });

    const agencyName = computed(() => {
        if (props.order?.attributes?.Agency?.statCode && props.order?.attributes?.Agency?.name) {
            return props.order?.attributes?.Agency?.name
        } else if (props.order?.shipTo?.companyName) {
            return props.order?.shipTo?.companyName;
        }
        return "";
    });

    const statCode = computed(() => {
        if (props.order?.attributes?.Agency?.statCode && props.order?.attributes?.Agency?.name) {
            return props.order?.attributes?.Agency?.statCode
        } else if (props.order?.shipTo?.companyName) {
            return props.order.items[0].attributes?.Data?.statCode;
        }
        return "";
    });

    //Methods --
    function channelType(type) {        
        switch (type) {
            case ChannelTypes.EMAIL:
                return "Email";
            case ChannelTypes.DIRECT_MAIL:
                return "Direct Mail";
            default:
                return "";
        }
    }

    function showViewList(item) {
        if (isProspecting(item)) {
            const mailingDetails = item.attributes?.Data?.dropDetails?.mailingDetails;
            if (mailingDetails && mailingDetails.length > 0) {
                let mailDate = mailingDetails[0].mailDate;
                if (mailDate) {
                    const mailedDate = new Date(mailDate);
                    const currentDate = new Date();
                    return mailedDate < currentDate && !isMailingListExpired(mailDate);
                }
                return false;
            }
            return false;
        } else if (isExpressOrder.value) {
            return false;
        }
        return true;
    }
    function isMailingListExpired(lastMailingDate) {
        const itemdate = new Date(lastMailingDate);
        itemdate.setDate(itemdate.getDate() + 60);

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let days = (itemdate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

        if (days < 0) {
            return true;
        }
        return false;
    }
    function getExpiryMessage(lastMailingDate) {
        if (!lastMailingDate) {
            return "";
        }
        const itemdate = new Date(lastMailingDate);
        const currentDate = new Date();
        if (itemdate > currentDate) {
            return ""
        }
        if (isMailingListExpired(lastMailingDate)) {
            return `(List Expired)`
        }

        const expiryDate = itemdate.setDate(itemdate.getDate() + 60);

        return `(List Expires on ${Util.date(expiryDate)})`
    }

    function hasListView(item) {
        const dropDetails = item.attributes?.Data?.dropDetails
        const programType = item.attributes?.Data?.programType;
        if ((dropDetails && dropDetails?.dropType != null) || (programType == ProgramType.ANNUAL_AUTO || programType == ProgramType.PET_INSURANCE ))
            return true
        return false
    }

    function isProspecting(item) {
        const programType = item.attributes?.Data?.programType;
        return programType == ProgramType.PROSPECTING;
    }

</script>

<template>
    <div class="order-history-tile d-flex px-1 py-2">
        <div class="flex-fill">
            <div class="title"><span class="order-date">{{Util.date(order.orderDate)}}</span> | Order #{{ order.orderNumber }} | {{totalPrice}} ({{orderItems}}) <span v-if="isCompleted"> | Amount Paid: {{amountPaid}}</span></div>
            <div v-for="item in order.items" :key="item.id" class="sub-title">
                <span class="order-type">{{channelType(item.attributes?.Data?.channelType)}}</span>
                {{item.product.itemNumber}} &nbsp;{{item.product.name}}
                <template v-if="hasListView(item)">
                    <span class="text-danger" v-if="isProspecting(item)">{{getExpiryMessage(item.attributes?.Data?.dropDetails.mailingDetails[item.attributes?.Data?.dropDetails.numberOfMailings - 1]?.mailDate)}}</span>
                    <span class="px-1" v-if="showViewList(item)">
                        <template v-if="isLegacyOrder">
                            <router-link :to="{ path: '/account/order-detail', query: { 'f-order-details-list-order-num': `${order.orderNumber};${item.attributes.Data.pageFlexDocumentId}` } }">View List</router-link>
                        </template>
                        <template v-else>
                            <router-link :to="{ path: '/account/order-detail', query: { 'f-order-details-list-order-num': `${order.orderNumber};${item.attributes.Data.id}` } }">View List</router-link>
                        </template>
                    </span>
                </template>

            </div>
            <div class="ship-to">
                    <span class="statCode">{{formatStatCode(statCode)}}</span>
                    {{ agencyName}}
            </div>
            <div class="status d-flex" :class="cssClass"><i :class="icon"></i>&nbsp; {{ order.orderStatus }}</div>
        </div>
        <div class="text-end action-panel">
            <router-link v-if="isLegacyOrder" :to="`/account/order/${order.id}/?orderNumber=${order.orderNumber}`">View Details</router-link>
            <router-link v-else :to="`/account/order/${order.id}`">View Details</router-link>
        </div>
    </div>

</template>

<style lang="scss">

    .order-history-tile {
        .title {
            font-size: 16px;
        }

        .order-date, .order-type, .statCode {
            font-weight: $bold;
        }

        .order-type, .statCode {
            margin-right: 8px;
        }

        .sub-title, .ship-to {
            font-size: 14px;
        }

        .status {
            margin-top: 0.5rem;
            font-weight: $bold;
            font-size: 14px;
            align-items: center;
        }

        .status i {
            font-size: 1rem;
        }

        .status.pending {
            .bi {
                opacity: 0.5;
                color: $ok;
            }
        }

        .status.pending-approval {
            .bi {
                color: $warning;
            }
        }

        .status.canceled {
            .bi {
                color: $error;
            }
        }

        .status.completed {
            .bi {
                color: $ok;
            }
        }
        // TODO: This is hacked in for now. Look at global link styling.
        .action-panel a, .action-panel a:visited {
            color: $color-2;
            text-decoration: none;
        }

        .action-panel a:hover {
            text-decoration: underline;
        }
    }
</style>