<script setup>

    // UploadListPanel
    // Step 2 for uploading your own list or using saved lists

    // Imports
    import { ref, computed, reactive, defineProps } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    // Components
    import UploadRecipients from '@/site/components/personalization/UploadRecipients';
    import PreviewRecipients from '@/site/components/personalization/PreviewRecipients';
    import SelectRecipients from '@/site/components/personalization/SelectRecipients';
    import SelectMailListOptions from './SelectMailListOptions.vue';
    import ProspectingMailLists from './ProspectingMailLists.vue';
    import PrepareMailList from '@/site/areas/prospecting/components/PrepareMailList.vue';
    import UploadSummary from '@/site/components/personalization/UploadSummary.vue';

    //props
    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    //State
    const uploadedRecipients = ref([]);
    const mailListName = ref("");
    const selectedMailListId = ref({});

    const detail = reactive({ product: props.product });

   
    // Constants ----
    const UploadListSteps = {
        UPLOAD: 'UPLOAD',
        PREVIEW: 'PREVIEW',
        SELECT: 'SELECT',
        SUMMARY:'SUMMARY'
    };

    const mailListOption = ref(null);
    const currentStep = ref(UploadListSteps.UPLOAD);

    // Computed
    const uploadListCurrentStep = computed(() => {
        switch (currentStep.value) {
            case UploadListSteps.UPLOAD:
                return UploadListSteps.UPLOAD;
            case UploadListSteps.PREVIEW:
                return UploadListSteps.PREVIEW;
            case UploadListSteps.SELECT:
                return UploadListSteps.SELECT;
            case UploadListSteps.SUMMARY:
                return UploadListSteps.SUMMARY;
            default:
                return UploadListSteps.UPLOAD;
        }

    });

    const isListNameEntered = computed(() => {
        if (mailListName.value && mailListName.value.length > 0)
            return true;
        else
            return false;
    });

    const hasRecords = computed(() => {
        if (uploadedRecipients.value.totalCount > 0) {
            return true;
        } else {
            return false;
        }
    });

    const failedRecipientsCount = computed(() => {
        return uploadedRecipients.value?.failedAddresses?.length > 0;
    });

    //methods
    function mailListOptionsSelected(option) {
        mailListOption.value = option;
    }

    async function preview(mailListId) {
        if (mailListId && mailListOption.value == 'existing') {
            selectedMailListId.value = mailListId;
            currentStep.value = UploadListSteps.SELECT;
        } else {
            selectedMailListId.value = mailListId;
            currentStep.value = UploadListSteps.SELECT;
        }
    }

    function uploadedList(recipients) {
        uploadedRecipients.value = recipients;
    }

    function back() {
        //router.go(-1);
        if (mailListOption.value == 'new') {
            mailListOption.value = null
        } else {
            router.go(-1);
        }
    }

    function resetProspectingMailLists() {
        mailListOption.value = null
    }

    function next(selectedStep) {
        currentStep.value = selectedStep;
    }

</script>

<template>

    <div class="upload-list-panel">

        <div v-if="uploadListCurrentStep == UploadListSteps.UPLOAD && mailListOption =='new'">


            <div class="row my-5">

                <div class="col border-bottom border-color-1">

                    <div class="d-flex">
                        <div class="text-color-1">
                            <h2 class="mb-0">Upload your mailing list</h2>
                        </div>
                        <div class="ms-auto">
                            {{detail.product.name}} ({{detail.product.itemNumber}})
                        </div>
                    </div>

                </div>

            </div>

            <div class="upload-container row w-75 m-auto">
                <div class="row mb-2 mt-2">
                    <div class="col-sm-4 offset-sm-4" v-if="detail.product.id">
                        <UploadRecipients @uploadedList="uploadedList" :productId="detail.product.id" />
                    </div>
                </div>

                <div class="row mb-5 mt-2">
                    <div class="col-sm-4 offset-sm-4">
                        <label class="form-label">Mailing list name </label>
                        <input type="text" class="form-control" v-model="mailListName" placeholder="Mailing List Name">
                    </div>
                </div>

            </div>

            <div class="row mb-5">

                <div class="text-center mt-4 mb-4">

                    <button @click="back" class="btn btn-outline-primary me-3">Previous</button>
                    <template v-if="failedRecipientsCount">
                        <button class="btn btn-primary" @click="next('SUMMARY')" :disabled="!isListNameEntered || !hasRecords">Next</button>
                    </template>
                    <template v-else>
                        <button class="btn btn-primary" @click="next('SUMMARY')" :disabled="!isListNameEntered || !hasRecords">Next</button>
                    </template>

                </div>

            </div>

            <div class="row my-3">
                <div class="col">
                    <PrepareMailList></PrepareMailList>
                </div>
            </div>

        </div>
        <ProspectingMailLists @reset="resetProspectingMailLists" @mailListSelected="preview" :product="detail.product" v-if="uploadListCurrentStep == UploadListSteps.UPLOAD && mailListOption == 'existing'" />
        <PreviewRecipients :mailListId="selectedMailListId" @next="next" :product="detail.product" v-if="uploadListCurrentStep == UploadListSteps.PREVIEW" />
        <SelectRecipients :mailListId="selectedMailListId" @next="next" :product="detail.product" :order-state="props.orderState" v-if="uploadListCurrentStep == UploadListSteps.SELECT" />
        <SelectMailListOptions v-if="mailListOption == null" :product="detail.product" :order-state="props.orderState" @mailListOption="mailListOptionsSelected" />
        <UploadSummary :recipients="uploadedRecipients" :mailListName="mailListName" :product="detail.product" @mailListSelected="preview" @next="next" v-if="uploadListCurrentStep == UploadListSteps.SUMMARY" />

    </div>

</template>
<style lang="scss">
    .upload-list-panel {
        .excel-instructions {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        }

        .upload-container {
            background: #f2fcfc;
            padding: 1rem;
        }

    }
</style>