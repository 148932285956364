// SearchHandler for product search.

// Imports ----

import { session } from './Session';
import { isNotEmpty } from '@/util';
export default class SearchHandler {

    #productFiltersKey = 'product-filters';

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    getExistingProductFilters() {
        let existingFilters = session.get(this.#productFiltersKey);
        const filters = [];
        if (isNotEmpty(existingFilters)) {
            const keys = Object.keys(existingFilters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]
                const filter = existingFilters[key];
                if (isNotEmpty(filter)) {
                    filters.push(filter);
                }
            }
        }
        return filters;
    }
    storeCurrentProductFilters(filters) {
        session.set(this.#productFiltersKey, filters);
    }
    removeExistingFilters() {
        session.remove(this.#productFiltersKey);
    }
}
