<script>

    // ReportsHomePage
    // Landing page for reports

    // constants
    export const ReportGroupType = {
        ProgramReports: 3,
        MSCActivity: 4,
        AdminReports: 7
    }
</script>
<script setup>

    // Imports ----
    import { computed, reactive, onMounted, ref } from 'vue';
    import { Search, Util } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    const route = useRoute();

    // Components -----
    import PageTemplate from '@/template/PageTemplate';
    import ReportingSidebar from './components/ReportingSidebar';
    import ReportingMainPanel from './components/ReportingMainPanel';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    const search = new Search('report-groups', 'row', 'report-group-name', 1000);

    // Computed ----
    const groups = computed(() => {
        search.results.rows.forEach((item) => {
            const id = item.reportGroupType;
            reportState.assignGroup(id, item)
        });
        reportState.enableReport(ReportGroupType.AdminReports, showAdminReports.value);
        return reportState;
    });

    const showAdminReports = computed(() => {
        
        const adminReportRoles = site.userRoles.getRolesForAdminReport();
        var rec = '';

        if (userRoles.value.length > 0 && userRoles.value.length == 1) {
            rec = userRoles.value.filter(r => adminReportRoles.includes(r.id));
        }
        else {
            rec = userRoles.value.filter(r => !r.forImpersonator && adminReportRoles.includes(r.id));
        }
        return rec?.length > 0;
    });

    const userRoles = computed(() => {
        return site.user?.roles;
    })

    // States -----
    const reportState = reactive({
        [ReportGroupType.MSCActivity]: { label: "MSC Activity", subGroups: [], open: true, show: true },
        [ReportGroupType.AdminReports]: { label: "Admin Reports", subGroups: [], open: true, show: false },
        assignGroup(id, group) {
            if (this[id]) {
                this[id].subGroups.push(group);
            }
        },
        enableReport(id, show) {
            this[id].show = show;
        }
    });


    const favoriteReports = reactive({ reportData: [] })

    const isLoaded = ref(false);
    const selectedSubGroup = reactive({
        id: null,
        name: null,
        assignSubGroup(id, name) {
            this.id = id;
            this.name = name;
        }
    });

    const showFavorites = ref(true);


    // Methods ----
    function changeReports(id, name) {   
        showFavorites.value = false;
        selectedSubGroup.assignSubGroup(id, name);

        // For Adobe analytics
        site.analyticsHandler.push({ "tileCode": name, "lob": "Reports", "productNames": "MSC_" + name });
    }

     async function getFavorites() {
        const response = await site.reporting.getFavoriteReports();
        if(response?.succeeded){
            favoriteReports.reportData = response.reports;
            showFavorites.value = true;
        }
    }

    function changeFavorite(id, name, value) {
        for (let i = 0; i < search.results.rows.length; i++) {
            if (search.results.rows[i].name == name) {
                for (let j = 0; j < search.results.rows[i].reports.length; j++) {
                    if (search.results.rows[i].reports[j].reportId == id) {
                        search.results.rows[i].reports[j].isFavorite = value;
                    }
                }
            }
        }
    }

    // Lifecycle ----
    onMounted(async () => {
        await getFavorites();
        // Added this for deep linking to the report page
        if (Util.isNotEmpty(route.params.id) && Util.isNotEmpty(route.params.name)) {
            changeReports(route.params.id, route.params.name)
        }
        isLoaded.value = true;

        // For Adobe analytics
        await site.analyticsHandler.push({ "tileCode": "Favorite Reports", "lob": "Reports" });
    });
</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.REPORTS_TAB_ID" />

            <div class="root-page-container reporting-home-page" v-if="isLoaded">

                <div class="row">
                    <SearchContainer :search="search" default-view="list" :deep-link="true">
                        <div class="col-4">
                            <ReportingSidebar :show-favorites="showFavorites" :groups="groups" :selected-group="selectedSubGroup" @change-reports="changeReports" @show-favorite-reports="getFavorites"></ReportingSidebar>
                        </div>
                        <div class="col-8">
                            <ReportingMainPanel :show-favorites="showFavorites" :groups="groups" :selected-group="selectedSubGroup" :favorite-reports="favoriteReports.reportData" @change-favorite="changeFavorite"></ReportingMainPanel>
                        </div>
                    </SearchContainer>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>