<script setup>

    //Preview recipients
    //Preview mail contacts upto 20

    // Imports ----
    import { defineProps, computed, onMounted, ref, defineEmits } from 'vue';
    import { site } from '@/Site';

    // Props ----
    const props = defineProps({
        mailListId: {
            type: String,
            required: false,
            defalut: {}
        },
        product: {
            type: Object,
            required: true,
        }
    });


    const emits = defineEmits(['next']);

    const mailListContacts = ref([]);

    const hasContacts = computed(() => {
        if (mailListContacts.value && mailListContacts.value.length > 0)
            return true;
        return false;
    })
    const previewList = computed(() => {
        if (hasContacts.value) {
            if (mailListContacts.value.length <= 20) {
                return mailListContacts.value;
            }
            else {
                return mailListContacts.value.slice(0, 20)
            }
        }
        return [];
    });

    // Handlers ----
    onMounted(async () => {

        //TODO: move if condition to personalizationInfo.js
        const response = await site.personalizationInfo.getMailListContactsById(props.mailListId);
        if (response.succeeded) {
            mailListContacts.value = response.mailListModel.mailContacts;
        }
    });

    function back() {
        emits('next', 'UPLOAD');
    }

    function next() {
        emits('next', 'SELECT');
    }

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0"> Preview the address in your file</h2>
                </div>
                <div class="ms-auto">
                    {{props.product.name}} ({{props.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row mb-5 mt-2">
        <div class="col">

            <div class="address-list  table-responsive" v-if="hasContacts">
                <template v-if="previewList.length > 0">
                    <h3 class="p-4">Compare these first {{ previewList.length }} records in this preview to your data file.</h3>
                    <table class="table table-striped w-auto mw-100">
                        <thead class="title">
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Address Line 1</th>
                                <th>Address Line 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="address in previewList" :key="address">
                                <td class="abbreviation">{{address.firstName}}</td>
                                <td class="abbreviation">{{address.lastName}}</td>
                                <td class="abbreviation">{{address.addressLine1}}</td>
                                <td class="abbreviation">{{address.addressLine2}}</td>
                                <td class="abbreviation">{{address.city}}</td>
                                <td class="abbreviation">{{address.state}}</td>
                                <td class="abbreviation">{{address.postalCode}}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <div v-else>
                    <slot name="empty"></slot>
                </div>
            </div>

            <div class="row my-5">
                <div class="col-sm-6">
                    <!-- TODO: add the correct previous route -->
                    <button class="btn btn-outline-primary" @click="back">Previous</button>

                </div>

                <div class="col-sm-6 text-end">
                    <button class="btn btn-primary" @click="next">Next</button>
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="scss">

    .address-list {
        .title {
            font-size: inherit;
        }

        .table td.abbreviation {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .table td.abbreviation {
            max-width: 100px;
        }

        .mw-100 {
            min-width: 100%;
        }
    }
</style>