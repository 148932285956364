// StoreCart
// Extended to Cart, It has only specific to store cart functions.

// Imports ----

export default class StoreCart {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    async setShipToEmail(email) {
        const cartId = this.store.cart.current.id;
        return await this.store.api.put(`site/cart/${cartId}/ship-to/email`, { email });
    }
}
