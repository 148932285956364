export default class MWSOptOutEmail {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    async trackEmail(type, guid) {        
        return await this.store.api.get(`site/email-tracking/${type}/${guid}/add`);
    }
}