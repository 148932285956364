<script setup>
    
    //MWSEmailOptOutPage
    // Imports
    import { ref, onMounted } from 'vue';
    import { site } from '@/Site';
    import GenericHeader from '@/template/components/GenericHeader';
    import GenericFooter from '@/template/components/GenericFooter';
    import { useRouter, useRoute } from 'vue-router';
    import { Util } from "@dd-nucleus/nucleus-vue";

    // Components
    import { isNotEmpty } from '@/util';
    const router = useRouter();
    const route = useRoute(); 

    // State
    const email = ref("");
    const isValidEmail = ref(true);

    function validateEmail () {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValidEmail.value = regex.test(email.value);
    }

    async function emailOptOut() {
             if (isNotEmpty(email.value)) {
           const response = await site.mwsEmailOptOut.EmailOptOut(email.value); 
          if (response === true) {
            router.push({
                name: 'optoutacknowledged',
                params: {
                        id: email.value
                }
            });
           }
          }
    }

    // Handlers ----
    onMounted(async () => {
        email.value = route.query.e;
    })
        

</script>

<template>
    <GenericHeader>
        <template v-slot:subHeader>
            <div class="optout-head-bar col-8">
                <p class="mws-header">Moving With Safeco</p>
            </div>
        </template>
    </GenericHeader>
    <div class="container root-page">
        <div class="row mt-4">
            <div class="col-sm-8 offset-sm-2 text-center">
                <p class="email-label">Please enter your email address. <span v-if="!Util.isNotEmpty(email)" class="text-danger">(Required)</span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 offset-sm-2 text-center">
                <div>
                    <input type="email" v-model="email" class="input" @input="validateEmail" placeholder="Enter email address">
                    <p v-if="!isValidEmail && email !== ''" style="color:red;">Please enter a valid email address</p>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 text-center">
                <p>If you no longer wish to receive email notices for the Moving with Safeco program, please enter your email information and click the Opt Out button below.</p>
            </div>
        </div>
        <div class="col-sm-8 offset-sm-2 text-center">
            <div class="mb-3">
                <button class="btn btn-color-1" v-bind:disabled="!isValidEmail && email !== ''" @click="emailOptOut(email)">Opt-Out</button>
            </div>
        </div>
    </div>
    <GenericFooter />

</template>

<style lang='scss'>
    .email-label {
        font-weight:700;
        }
    .input {
        width :360px;
    }

    .optout-head-bar {
        text-align: left;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2rem;
    }
    .mws-header {
        color: white;
        font-size: 38px;
        padding-left: 1rem;
        font-family: Calibri light;
    }
</style>