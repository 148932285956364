
// services.js
// Services for all the prospecting pages 

// Imports ----

//import useApi from '@/nucleus/services/api';

//const { GET } = useApi();

export default function () {

    async function getProductDetails() {
        // TODO: These will need come from an API call when it gets built 
        // Review the response structure in the component(s) that use this. 
        const product = {
            "details": {
                "id": "AP-9999",
                "name": "Prospecting Letter, Safeco Package (AP-9999)",
                "frequency": "Multiple Drops (Weekly)",
                "numberOfMailings": 4,
                "dropStartDate": "09/30/2021",
                "orderCount": "1,234",
            },
            "summary": [
                {
                    "dropDate": "10/1/2022",
                    "quantity": 500,
                    "price": "$123.00"
                },
                {
                    "dropDate": "10/8/2022",
                    "quantity": 300,
                    "price": "$98.00"
                },
                {
                    "dropDate": "10/15/2022",
                    "quantity": 500,
                    "price": "$123.00"
                },
                {
                    "dropDate": "10/22/2022",
                    "quantity": 250,
                    "price": "$78.00"
                },
            ],
            "totals": {
                "quantity": 250,
                "price": "$422.00"
            },
            "pdfDetails": {
                "fileUrl": "/downloads/SafecoMSC-Relationship-Marketing-Cards.pdf",
                "totalPages": 10
            },
            "code": 200,
            "isDefaultLanguage": true,
            "messages": [],
            "httpStatusCode": null,
            "succeeded": true,
            "attributes": {}
        };

        return product;
    }

    async function getAgencyDetails() {
        // TODO: These will need come from an API call when it gets built 
        // Review the response structure in the component(s) that use this. 
        const agency = {
            "agencyInformation": {
                "id": "ABC1234",
                "name": "Sample Agency Name",
                "licenseNumber": "Test00123",
                "agentFirstName": "Sam",
                "agentLastName": "Sample",
                "agentEmail": "samsample@testagency.com",
                "agentPhone": "313-555-1212"
            },
            "agencyAddress": {
                "id": "XYZ9876",
                "addressee": "Sam Sample",
                "companyName": "Sample Agency Name",
                "addressLine1": "123 Main",
                "addressLine2": "Suite 123",
                "city": "Royal Oak",
                "state": "MI",
                "province": "",
                "postalCode": "48067",
                "countryCode": "US",
            },
            
            "code": 200,
            "isDefaultLanguage": true,
            "messages": [],
            "httpStatusCode": null,
            "succeeded": true,
            "attributes": {}
        };

        return agency;
    }

    return {
        getProductDetails,
        getAgencyDetails
    }
}