<script setup>
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ToolTip from '@/site/components/ToolTip';

    import { ref, reactive, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { isEmpty, isNotEmpty } from '@/util';
    import { site } from '@/Site'
    import { getProgramTypeForOrderState, getOppIdForOrderState } from '@/constants'
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';

    const loading = ref(true);
    const isProductSaved = ref(false);
    const router = useRouter();
    const route = useRoute();
    const detail = reactive({ product: {} });
    const agency = reactive(site.agencyDetails.defaultAgency);
    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);


    async function next() {
        // resume the previous order state
        await startOrder(true);
    }

    async function cancel() {
        // Start new order and take to step 1
        isProductSaved.value = false;
        await startOrder();
    }

    // Start Order Implementation from products search page
    async function startOrder(isStateSaved = false) {

        const product = detail.product;

        if (isEmpty(route.query.isMultiChannel) || (route.query.isMultiChannel && route.query.isMultiChannel === 'false')) {
            orderFlowDetails.isMultiChannel = false

        } else if (route.query.isMultiChannel && route.query.isMultiChannel === 'true') {
            orderFlowDetails.isMultiChannel = true
        }
        orderFlowDetails.parentId = "";
        if (product.attributes.isExpressOrderingAllowed && agency.isOwnerMaster) {
            const oppId = getOppIdForOrderState(product.attributes.dataListOption);
            const allStatCodes = await site.agencyDetails.getAgencySubCodes(oppId);

            const ownerMasterPolicyCount = allStatCodes.find(a => a.statId == agency.statCode)?.policyHoldersCount;
            const subcodesHasPolicyHolders = allStatCodes.filter(a => a.statId != agency.statCode && a.policyHoldersCount > 0);

            if (ownerMasterPolicyCount && subcodesHasPolicyHolders?.length > 0) {
                router.push({
                    name: 'ExpressOrderConfirmationPage',
                    params: {
                        productId: product.id
                    }
                });
                return;
            }
            return;
        }
        if (!site.errorHandler.canStartOrder()) {
            return false;
        }
        orderFlowDetails.productId = product.id;
        const programType = getProgramTypeForOrderState(product.attributes.dataListType);
        if (programType) {

            
            orderFlowDetails.programType = programType;
            orderFlowDetails.productType = product.attributes.productTypes?.join(";");
            orderFlowDetails.oppId = getOppIdForOrderState(product.attributes.dataListOption);
            let channelType = ChannelTypes.DIRECT_MAIL;
            if (product.attributes.isEmail) {
                channelType = ChannelTypes.EMAIL;
            }

            const response = await site.personalizationInfo.startOrder(channelType, isStateSaved);

            if (site.errorHandler.handleStartOrderError(response)) {
                router.push({
                    name: 'PersonalizeStep1Page',
                    params: {
                        id: response.id,
                        parentId: response.id,
                        productId: orderFlowDetails.productId
                    },
                    query: {
                        channelType: ChannelTypes.DIRECT_MAIL,
                        isSubscription: product.attributes.isSubscription,
                        "active-tab": route.query['active-tab'],
                        "f-product-search-term": route.query['f-product-search-term'],
                        "f-product-type": route.query['f-product-type'],
                        "f-program-type": route.query['f-program-type']
                    } 
                });
            }
        }
        else {
            console.error("Not able to identify the Program type based on the data list type");
        }

    }

    onMounted(async () => {

        if (isNotEmpty(route.params.productId)) {
            // check if there is an existing saved order
            Object.assign(detail.product, await site.products.getProductDetail(route.params.productId));
            const response = await site.personalizationInfo.getOrderStateByProductId(route.params.productId);
            if (response && isNotEmpty(response.id)) {
                isProductSaved.value = true;
            } else {
                await startOrder();
            }
        }
        loading.value = false;

    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page save-for-later-confirmation">
            <GlobalTabBar />

            <div class="loader" v-show="loading">
                <div class="spinner-grow" role="status">
                </div>
                <p> Checking for previously saved order ... </p>
            </div>

            <div class="row mt-5" v-show="!loading && !isProductSaved">
                <div class="col-12 text-center mt-5">
                    <p>Please wait. Taking you to a new order flow ...</p>
                </div>
            </div>

            <div class="row mt-5" v-show="!loading && isProductSaved">

                <div class="col-12 text-center mt-5">
                    <ToolTip placement="bottom">
                        <p class="fw-bolder">You already have this item in My Account > My Saved Items. </p>
                        <p class="fw-bolder">
                            Would you like to use the previously saved item?
                            <span><i class="bi bi-question-circle-fill"></i></span>
                        </p>
                        <template #content>
                            <p class="tip">
                                My Account > My Saved Items: You may save only one of any particular asset in My Saved Items. You must complete the ordering process for the saved item before saving another.
                            </p>
                        </template>
                    </ToolTip>
                </div>
                <div class="col-12 text-center mt-3">
                    <div>
                        <button class="m-2 px-5 btn btn-outline-primary" @click="cancel">No</button>
                        <button class="m-2 px-5 btn btn-primary" @click="next">Yes</button>
                    </div>
                </div>
            </div>
            <div class="row" v-show="!loading && isProductSaved">
                <div class="col-2"></div>
                <div class="col-8 text-center mt-3">
                    <p class="message">
                        If you select NO, your previously saved item will be deleted. You must complete
                        that order before creating a new order for the same asset item.
                        If you select YES, you will resume the ordering process for your previously saved item.
                    </p>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">


    .save-for-later-confirmation {

        .loader {
            display: flex;
            margin-top: 120px;
            justify-content: center;

            p {
                margin: auto 12px;
            }
        }

        .message {
            font-size: 13px;
            color: red
        }

        .popper {
            width: 40%;
        }

        .tip {
            font-size: 12px;
            text-align: left;

            span {
                color: lightseagreen;
                font-weight: bold;
            }
        }
    }
</style>