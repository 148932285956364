<script setup>  
 // Imports ----
    import { defineProps, computed } from 'vue';
    import { ref } from 'vue'

    // Sample data for all three type of files
    const sampleDataExcel = [
        {
            firstName: "Mike",
            lastName: "Johnson",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seattle",
            state: "WA",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seattle",
            state: "WA",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seattle",
            state: "WA",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        }, {
            firstName: "Mike",
            lastName: "Johnson",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seattle",
            state: "WA",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seattle",
            state: "WA",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        }
    ]

    const sampleDataCsv = [
        {
            firstName: "Mike,",
            lastName: "Johnson,",
            addressLine1: "120 Main St,",
            addressLine2: "Apt 2,",
            city: "Seattle,",
            state: "WA,",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike,",
            lastName: "Johnson,",
            addressLine1: "120 Main St,",
            addressLine2: "Apt 2,",
            city: "Seattle,",
            state: "WA,",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike,",
            lastName: "Johnson,",
            addressLine1: "120 Main St,",
            addressLine2: "Apt 2,",
            city: "Seattle,",
            state: "WA,",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        }, {
            firstName: "Mike,",
            lastName: "Johnson,",
            addressLine1: "120 Main St,",
            addressLine2: "Apt 2,",
            city: "Seattle,",
            state: "WA,",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        },
        {
            firstName: "Mike,",
            lastName: "Johnson,",
            addressLine1: "120 Main St,",
            addressLine2: "Apt 2,",
            city: "Seattle,",
            state: "WA,",
            zip: 98103,
            phone: 8888888888,
            email: "test@test.com"
        }
    ]

    const instructionDataExcel = [        
        {
            line: "All of the mailing list data should be located within ONE worksheet, or tab within your Excel file. The process will automatically select the first worksheet within your Excel file for upload."
        },
        {
            line: "The first name and last name should be placed in separate data fields in the Excel file."
        },
        {
            line: "The Street Address, Apartment/Suite Number/Unit Number/PO Box, City, State, ZIP Code, Phone, and Email should also be placed in separate data fields within the Excel file."
        }
    ]


    const instructionDataCsv = [
        {
            line: "This should be a single character. If you do not want to use a comma, you should choose something like a tab or a pipe (|) character."
        },
        {
            line: "Keep each record on a separate line. Each record must start on its own line, but a single record can span multiple lines."
        },
        {
            line: "In the first line of the file, include a header with a list of the column names in the file. This is optional, but strongly recommended; it allows the file to be self-documenting."
        },
        {
            line: "Make sure the header list is delimited in the same way as the rest of the file. This helps guard against the data fields being transposed in the data when it is loaded, which can lead to getting wrong answers when you query the data."
        },
        {
            line: "Remember that the enclosing character (typically double quotes) must be used when required, such as when the delimiter appears in a field."
        }
    ]

    const sampleColumns = [
        {
            column: "First Name"
        },
        {
            column: "Last Name"
        },
        {
            column: "Address 1"
        },
        {
            column: "Address 2"
        },
        {
            column: "City"
        },
        {
            column: "State"
        },
        {
            column: "Zip"
        },
        {
            column: "Phone"
        },
        {
            column: "Email"
        }
    ]

    // Props ----
    const props = defineProps({
        // file format
        fileformat: {
             type: String,
            required: true,
            default: null
        }
    });

    // Computed ----
    const sampleFileUrl = computed(() => {
        if (props.fileformat == '.xlsx')
            return "/downloads/Upload-List-Template.xlsx";
        else if (props.fileformat == '.xls')
            return "/downloads/Upload-List-Template.xls";
        else
            return "/downloads/Upload-List-Template.csv";
    });

    const isExcel = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return true;
        else
            return false;
    });

    const instructionText = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return instructionDataExcel;
        else
            return instructionDataCsv;
    });

    const sampleData = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return sampleDataExcel;
        else
            return sampleDataCsv;
    });

    const tableClass = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return "table-striped m-auto border border-dark p-1";
        else
            return "table-striped m-auto border";
    });

    const headerClass = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return "title bg-dark text-white";
        else
            return "";
    });

    const headerText = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return "Microsoft Excel File Format(" + props.fileformat + ")";
        else
            return "Comma Separated Values File Format (.csv)";
    });

    const pText = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return "Within Excel, all data fields should be separated by cells, or grids. Please note the following requirements for Microsoft Excel file format (" + props.fileformat + "):";
        else
            return "Within Comma Separated Values File Format, separate data fields with a delimiter, usually a comma. Please note the following requirements for Comma Separated Values File Format (.csv):";
    });

    const impText = computed(() => {
        if (props.fileformat == '.xlsx' || props.fileformat == '.xls')
            return "When saving your Excel File for the List Upload process be sure it is saved in " + props.fileformat + " file extension format.";
        else
            return "When saving your csv file for the List Upload process be sure it is saved in " + props.fileformat + " file extension format.";
    });

    const prepareFile = ref(null);
    //Methods
    const printContent = () => {
        // Create a new window to print the content
        const printWindow = window.open('Print for Reference', 'Print', 'height=600,width=800');

        // Create a stylesheet that targets the myDiv element
        const printStylesheet = `
        @media print {
          #prepareFile {
            display: block;
          }
        }`;

        // Inject the stylesheet into the new window
        const printStyles = printWindow.document.createElement('style');
        printStyles.innerHTML = printStylesheet;
        printWindow.document.head.appendChild(printStyles);

        // Clone the myDiv element and add it to the new window
        const clonedDiv = prepareFile.value.cloneNode(true);
        printWindow.document.body.appendChild(clonedDiv);

        // Print the new window
        printWindow.print();
        printWindow.close();
    }
</script>

<template>

    <div class="sub-tab-container" id="prepareFile">
        <div ref="prepareFile">
            <h3>{{headerText}}</h3>
            <hr />
            <div>
                <p>{{pText}}</p>
                <ul class="excel-instructions">
                    <li v-for="data in instructionText" :key="data">
                        {{ data.line }}
                    </li>
                    <li>
                        <strong>IMPORTANT: </strong> {{impText}}
                    </li>
                </ul>
            </div>
            <div class="table-container">
                <p>See example of file below for reference</p>
                <div class="table-responsive">
                    <table v-bind:class="tableClass" >
                        <thead v-bind:class="headerClass">
                            <tr v-if="isExcel">
                                <th v-for="data in sampleColumns" :key="data">{{ data.column }}</th>
                            </tr>
                            <tr v-if="!isExcel">
                                <td v-for="data in sampleColumns" :key="data">{{ data.column }},</td>
                            </tr>
                        </thead>
                        <tr v-for="data in sampleData" :key="data">
                            <td>{{ data.firstName }}</td>
                            <td>{{ data.lastName }}</td>
                            <td>{{ data.addressLine1 }}</td>
                            <td>{{ data.addressLine2 }}</td>
                            <td>{{ data.city }}</td>
                            <td>{{ data.state }}</td>
                            <td>{{ data.zip }}</td>
                            <td>{{ data.phone }}</td>
                            <td>{{ data.email }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            </div>
            <div class="btn-container">
                <button class="btn btn-primary-outline border border-primary" @click="printContent('prepareFile')">Print for Reference</button>
                <a class="btn btn-primary ml-2" :href="sampleFileUrl">Download sample list template</a>
            </div>
        </div>


</template>

<style lang="scss">
    .prepare-mail-list {
        strong {
            color: #1a1446;
        }

        .table-container {
            background: #f5f5f5;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 2.5rem;
            gap: 8px;
        }

        .table-responsive table {
            width: 100%
        }

        .btn-container {
            margin-top: 1rem;
            display: flex;
            gap: 2rem;
        }

        td {
            white-space: normal !important;
            word-wrap: break-word;
        }

        table {
            table-layout: fixed;
        }
    }
</style>