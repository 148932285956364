<script setup>

    // ReviewPage
    // Allows the user to review their order before placing.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import ShipTo from './components/ShipTo';
    import SelectedPaymentMethod from './components/SelectedPaymentMethod';
    import CartItemList from './components/CartItemList';
    import OrderDetails from './components/OrderDetails';    

    // Imports ----
    import { ref, computed, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';
    import { Util } from '@dd-nucleus/nucleus-vue';

    const router = useRouter();

    // State ----
    const isLoaded = ref(false);
    const placingOrder = ref(false);
    //const isDigitalOnly = ref(site.cart.onlyContainsDigitalProducts);
    const isShippingRequired = ref(site.cart.isShippingRequired);
    const email = ref("");
    const orderErrorMessages = ref("");

    // Computed ----
    const canPlaceOrder = computed(() => {
        return (site.cart.current.canPlaceOrder === true && placingOrder.value === false && Util.isNotEmpty(email.value));
    });

    const cart = computed(() => { return site.cart.current; });

    const isExpressOrder = computed(() => {
        if (cart.value.items?.length > 0) {
            return cart.value.items.filter(item => item.product.attributes?.isExpressOrderingAllowed)?.length > 0;
        }
        return false;
    });

    const canPlaceExpressOrder = computed(() => {
        return cart.value.items?.length == 1;
    });

    const isBulkOrder = computed(() => {
        return cart.value.shipToList?.length > 1 && !isExpressOrder.value;
    });

    // Handlers ----
    onMounted(() => {
        update();
    });

    site.cart.onCartLoaded(() => {
        update();
    });

    site.cart.onCartUpdated(() => {
        update();
    });

    // Called once we know we have a cart to work with
    function update() {
        isLoaded.value = true;
    }

    function onChangeShipTo() {
        router.push('/checkout/shipping');
    }

    function onChangeShipMethod() {
        router.push('/checkout/shipping');
    }

    function onChangeItems() {
        router.push('/cart');
    }

    function onChangePayment() {
        router.push('/checkout/payment');
    }



    async function onPlaceOrder() {
        placingOrder.value = true;

        const setEmailResponse = await site.storeCart.setShipToEmail(email.value);
        if (!setEmailResponse || !setEmailResponse.succeeded) {
            site.toast.error('Error while storing email id.', 'Error');
            placingOrder.value = false;
            return;
        }        
        if (isExpressOrder.value) {
            if (canPlaceExpressOrder.value) {
                const response = await site.expressOrderPersonalizationInfo.placeOrder('Cart', false, 'stripe');
                router.push(`/order-confirmed/${response.model.bulkOrderId}`);
            } else {
                site.toast.error("Express Order can be placed with only one item for now.", 'Error');
            }
        } else {
            //TODO. This is workaround to overcome the payment issue. created custom endpoint to place the order.
            //This needs to be replaced the below commented lines once the latest nuget packages are updated.
            //const response = await site.cart.placeOrder('Cart', false, 'stripe');
            const response = await site.api.post('site/orders/place', { cartId: cart.value.id, source: 'Cart', isPaid: false, paymentProcessorId: 'stripe' });

            if (response.succeeded) {

                if (response.model.isMultiShip) {                    
                    router.push('/order-confirmed/' + response.model.bulkOrderId);
                } else {                    
                    router.push('/order-confirmed/' + response.model.id);
                }
            }
            else {
                orderErrorMessages.value = response.messages;
                return;
            }
        }
    }
</script>

<template>
    <PageTemplate>
        <div class="container root-page review-page">
            <div class="row mb-4">
                <div class="col-12 col-sm-8">
                    <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Review Order</span></h1>
                </div>
                <div class="my-2 d-sm-none"></div>
                <div class="col-12 col-sm-4 ms-auto">
                    <router-link to="/cart" class="btn btn-outline-primary me-3">Back to Cart</router-link>
                    <button class="btn btn-primary" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE ORDER</button>
                </div>
            </div>

            <div v-if="site.cart.current.requiresApproval" class="alert alert-warning alert-icon">
                <i class="bi bi-exclamation-triangle-fill"></i> &nbsp;When placed, this order will require approval before it can be fulfilled. See messages below.
            </div>

            <MessageList v-if="site.cart.current.messages" :messages="site.cart.current.messages" :add-box="false" class="mb-3" />
            <MessageList v-if="orderErrorMessages" :messages="orderErrorMessages" :add-box="false" class="mb-3" />

            <div class="email-notification">
                <p class="sub-title text-muted">Enter your email address for updates regarding your order. <span v-if="!Util.isNotEmpty(email)" class="text-danger">(Required)</span> </p>
                <input type="email" v-model="email" class="input" placeholder="Enter email address">
            </div>

            <div class="row" v-if="isShippingRequired">
                <div class="col-12 col-md-9">
                    <CartBodyPanel class="cart-body-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeShipTo" /></div>
                            <h2>Ship To</h2>
                        </template>

                        <ShipTo :editable="false" />

                    </CartBodyPanel>
                </div>

                <div class="col-12 col-md-3" v-if="isLoaded && isShippingRequired">
                    <CartBodyPanel class="cart-body-panel ship-method-panel">

                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeShipMethod" /></div>
                            <h2>Ship Method</h2>
                        </template>
                        <div>
                            <ShipMethod />
                        </div>

                    </CartBodyPanel>
                </div>
            </div>

            <div class="row" v-if="isLoaded && cart.total != 0">
                <div class="col">

                    <CartBodyPanel class="cart-body-panel order-items-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangePayment" /></div>
                            <h2>Payment Information</h2>
                        </template>

                        <SelectedPaymentMethod />

                    </CartBodyPanel>

                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-8">

                    <CartBodyPanel class="cart-body-panel order-items-panel">
                        <template #title>
                            <div class="float-end"><ActionButton class="panel-title-action" icon="bi bi-arrow-left-right" label="Change" @click="onChangeItems" /></div>
                            <h2>Items</h2>
                        </template>

                        <CartItemList :editable="false" :isBulkOrder="isBulkOrder" />

                    </CartBodyPanel>

                </div>
                <div class="col-12 col-sm-4">
                    <OrderDetails :cart="cart" :showActionableItems="false" :isBulkOrder="isBulkOrder" />
                </div>
            </div>

            <div class="row my-3">
                <div class="col">

                    <div v-if="isLoaded" class="bottom-panel">
                        <button class="btn btn-primary" :disabled="!canPlaceOrder" @click="onPlaceOrder">PLACE ORDER</button>
                    </div>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .review-page {
        .ship-method .title {
            font-weight: bold;
        }

        .panel-title-action {
            margin-top: -0.75rem;
        }

        .bottom-panel {
        }

        .n-body-panel {
            .body {
                padding: 0.75rem 1.5rem;
            }

            .title {
                padding: 0.75rem 1.5rem;
                background-color: $gray-1;

                h2 {
                    color: $gray-4;
                    font-size: 1.1rem !important;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .email-notification {
        margin: 22px 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 8px;
        flex-wrap: wrap;

        .title {
            color: #28a3af;
            font-weight: bold;
            margin: 0;
        }

        .sub-title {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
        }

        .input {
            display: block;
            width: 30%;
            padding: 4px 6px;
            background-color: #FAF7F0
        }
    }
</style>
