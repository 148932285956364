<script setup>

    // App.vue
    // Standard app component for Nucleus sites

    // Imports ----
    import { onMounted, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { site } from '@/Site';
    import useEventsBus from '@/site/services/EventBus';
    import { Util, GlobalEvents, EventIDs } from "@dd-nucleus/nucleus-vue";
    const UNAUTHORIZED_RESPONSE_CODE = 401;
    const router = useRouter();
    const { bus } = useEventsBus();

    const route = useRoute();

    
    const { onGlobal } = GlobalEvents(); 



    // Create an options object to pass in
    const options = {
        router: router,
        configUrl: 'api/site/config'
    };

   
    async function init() {
        await site.initialize(options);
    }

    onGlobal(EventIDs.API_CALL_ERROR, function (options) {
        const wasAuthenticated = site.user?.isAuthenticated;
        const isAuthenticated = site.authenticator.isSignedIn();
        if (wasAuthenticated && isAuthenticated && options.status === UNAUTHORIZED_RESPONSE_CODE) {
            site.handleUnauthorizedError(router);
        }
    });
    
    // Lifecycle ----
    onMounted(async () => {
        // Initialize the Nucleus app and supply the router
        await init();

        // Add monitoring code snippet
        Util.addSiteTracking(process.env.VUE_APP_SITE_24X7_API_KEY);

        const userId = route.query.id;
        if (Util.isNotEmpty(userId)) {
            await site.signWithUserId(userId, router, route.query.deeplink);
        }
    });

    watch(() => bus.value.get('loggedIn'), async () => {
        await init();
    });

</script>

<template>
    <SizeDisplay />
    <StartupPanel />
    <router-view :key="$route.fullPath" />
</template>

<style lang="scss">
    // Load main, which allows custom styles to be added after .vue components
    @import '@/scss/main.scss';
</style>

