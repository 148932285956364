import exportFromJSON from "export-from-json";

export const ExportParser = () => {
    function exportXLSFromJSON(data, newFileName, fileExportType, fields, beforeTableEncode) {
        if (!data) return;
        try {
            const fileName = newFileName || "exported-data";
            const exportType = exportFromJSON.types[fileExportType || "xls"];
            exportFromJSON({ data, fileName, exportType, fields, beforeTableEncode });
        } catch (e) {
            throw new Error("Parsing failed!");
        }
    }

    function exportCSVFromJSON(data, newFileName, fileExportType, fields, beforeTableEncode) {
        if (!data) return;
        try {
            const fileName = newFileName || "exported-data";
            const exportType = exportFromJSON.types[fileExportType || "csv"];
            exportFromJSON({ data, fileName, exportType, fields, beforeTableEncode });
        } catch (e) {
            throw new Error("Parsing failed!");
        }
    }

    return {
        exportXLSFromJSON,
        exportCSVFromJSON
    };
};