<script setup>

    // PdfViewer
    // This is a panel that build and embed for PDFs that you can switch pages using pagination. 
    import { computed, defineProps, ref } from 'vue';

    // Props ----
    const props = defineProps({
        pdfDetails: {
            type: Object,
            required: true
        },
        // The height parameter is not required but without it, it is very small
        height: {
            type: Number,
            required: false
        }
    });

    // State 
    let pageNumber = ref(1);

    let showPdf = ref(true);

    // Methods
    function changePage(pageNum) {
        pageNumber.value = pageNum;

        // Not sure if this is the best way to render the PDF after you change the page number but it works
        showPdf.value = false;

        setTimeout(() => {
            showPdf.value = true;
        }, 100);
    }

    function next() {
        if (pageNumber.value < props.pdfDetails.totalPages) {
            changePage(pageNumber.value + 1);
        }
    }

    function previous() {
        if (pageNumber.value > 1) {
            changePage(pageNumber.value - 1);
        }
    }

    // Computed
    const pdfUrl = computed(() => {
        return props.pdfDetails.fileUrl + "#toolbar=0&navpanes=0&scrollbar=0&page=" + pageNumber.value;
    });

</script>

<template>

    <div class="pdf-viewer">

        <nav v-if="pdfDetails.totalPages > 1">
            <ul class="pagination justify-content-center">
                <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous" @click="previous">
                        <i class="bi bi-chevron-left"></i>
                    </a>
                </li>
                <li class="page-item" v-for="i in props.pdfDetails.totalPages" :key="i">
                    <a class="page-link" href="#" @click="changePage(i)">{{ i }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next" @click="next">
                        <i class="bi bi-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>

        <embed v-if="showPdf" :src="pdfUrl" type="application/pdf" class="w-100" :height="height"/>

    </div>

</template>

<style lang="scss">
    .pdf-viewer {
    }
</style>