<script setup>
    // ProspectingStep4ReviewPage
    // Step 4 of mail drops the review and check out or add to cart 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper from '@/site/components/SelectMailListStepper';
    import PdfViewer from '@/site/components/PdfViewer';

    // Imports
    import { reactive, onMounted } from 'vue';

    import useServices from './services';

    const { getProductDetails } = useServices();

    // State 
    let product = reactive({
        "details": {},
        "summary":[],
        "totals": {},
        "pdfDetails": {}
    });

    // Methods
    function addToCart() {
        // TODO: addToCart was pressed call the API to add to cart
    }

    function checkout() {
        // TODO: Not sure the funcationality here, do we need to add to cart than just continue on not sure how this differs from add to cart
    }

    function previewPdf() {
        // Call the API to generate a new PDF Preview 
    }

    // Lifecycle Hooks
    onMounted(async () => {
        let productResponse = await getProductDetails();

        // TODO this is probably not going to be how this is returned from the API 
        product.details = productResponse.details;
        product.summary = productResponse.summary;
        product.totals = productResponse.totals;
        product.pdfDetails = productResponse.pdfDetails;
    });

</script>
<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />
            <div class="root-page-container prospecting-review">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="4" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>

                                <h1>Review & Add to Cart</h1>

                            </div>

                            <div class="ms-auto">
                                {{product.details.name}}
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-sm-7">

                        <div class="mb-3">

                            <div class="fw-bold">Item</div>
                            <div class="text-color-2">
                                {{product.details.name}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Item ID</div>
                            <div class="text-color-2">
                                {{product.details.id}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Personalization <button class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Frequency <button class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2">
                                {{product.details.frequency}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Number of Mailings <button class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2">
                                {{product.details.numberOfMailings}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Drop Start Date <button class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2">
                                {{product.details.dropStartDate}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Total Order Count</div>
                            <div class="text-color-2">
                                {{product.details.orderCount}}
                            </div>

                        </div>

                        <div>
                            Note: Turn off Pop-up Blocker to allow for PDF previewing.
                        </div>

                        <div class="mb-5">
                            <button class="btn btn-primary" @click="previewPdf">Preview PDF</button>
                        </div>

                        <div class="bg-color-2-dk py-2 text-center text-gray-0">
                            Summary
                        </div>

                        <div>

                            <table class="table">

                                <thead>
                                    <tr class="bg-color-3-lt">
                                        <th class="border-end border-color-1">Date Drop</th>
                                        <th class="border-end border-color-1">Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="border-bottom border-gray-2" v-for="drop in product.summary" :key="drop.dropDate">
                                        <td>{{drop.dropDate}}</td>
                                        <td>{{drop.quantity}}</td>
                                        <td>{{drop.price}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td class="fw-bold">{{product.totals.quantity}}</td>
                                        <td class="fw-bold">Total: {{product.totals.price}}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>

                        <div class="mt-2 text-alert-error">
                            Pricing prior to potential applied discount.
                        </div>

                        <div class="mt-3 mb-5">

                            <!-- TODO: Add the route to previous -->
                            <router-link to="" class="btn btn-outline-primary me-3">
                                Previous
                            </router-link>

                            <button @click="addToCart" class="btn btn-outline-primary me-3">
                                Add to Cart
                            </button>

                            <button @click="checkout" class="btn btn-primary">
                                Add to Cart
                            </button>

                        </div>


                    </div>

                    <div class="col-sm-5">
                        <PdfViewer :pdfDetails="product.pdfDetails" :height="700"/>
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .prospecting-review {

        .btn-sm {
            font-size: 0.75rem;
        }
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table 
        .table > :not(:first-child) {
            border-top: unset;
        }
    }
</style>