
// ConsentForm
// Form used to personalize the assets. 

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';

export default class ConsentForm extends Form {

    // Constructor
    constructor(model, id = null) {
        // Pass our model to the base class
        super(Object.assign(model ?? {},
            {
                id: id,
                pdfFinalApproval: false,
                acceptTermsAndConditions: false,
                willNotUseMassachusetts: false
            }));

        this.validator.fields.pdfFinalApproval.isRequired = true;
        this.validator.fields.acceptTermsAndConditions.isRequired = true;
        this.validator.fields.willNotUseMassachusetts.isRequired = true;
        
        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    reset() {
        this.model.pdfFinalApproval = false;
        this.model.acceptTermsAndConditions = false;
        this.model.willNotUseMassachusetts = false;
    }

    /**
     * Call the form to reset for a new payment info.
     */
    create() {
        this.model.id = null;
        this.reset();
    }

    onValidate() {
        super.onValidate();
    }
}