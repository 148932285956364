<script setup>

    // PersonalizeStep3WinBackPage
    // Step 3 Win back Notification

    // Imports ----
    import { site } from '@/Site';
    import { computed, reactive, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { isEmpty } from '@/util';

    const router = useRouter();
    const route = useRoute();

    // Components ----
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import { TabTypes } from './PersonalizeStep1Page';
    import { dropType } from './PersonalizeStep3Page';

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    // Computed
    const tabName = computed(() => {
        switch (orderFlowDetails.programType) {
            case TabTypes.CROSS_SELL:
                return TabIDs.CROSS_SELL_TAB_ID;
            case TabTypes.PROSPECTING:
            case TabTypes.DATA_LIST_UPLOAD:
                return TabIDs.PROSPECTING_TAB_ID;
            case TabTypes.RETENTION:
            case TabTypes.WINBACK:
            case TabTypes.ANNUAL_AUTO:
                return TabIDs.RETENTION_TAB_ID;
            default:
                return '';
        }
    });
    
    // Methods ----
/*    function back() {
        router.go(-1);
    }*/

    async function next() {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        orderFlowDetails.dropDetails.dropType = dropType.NONE;
        //Setting default droptype and frequency and number of mailings to get the winback dropdate.
        await site.personalizationInfo.setDropDetails(currentDate, dropType.NONE, 1)

        router.push({
            name: 'PersonalizeStep4Page',
            params: {id : route.params.id}
        });
    }

    //TODO: May be to move to common place???
    async function saveForLater() {
        if (site.errorHandler.handleSaveForLaterRequest()) {
            router.push({
                name: 'Home',
            });
        }
    }



    // Lifecycle ----
    onMounted(async () => {
        const orderStateId = route.params.id
        if (!isEmpty(orderStateId)) {
            await site.personalizationInfo.getOrderState(orderStateId);
        }
    });


</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container cross-sell-page3">

                <div class="row">

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.THREE" />

                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <p class="fw-bold">
                            Mail Schedule: Safeco mails Win Back Program letters on the 13th day of the month following your order date, or the next business day after the 13th. For example, orders placed in January will be mailed on February 13th.
                        </p>
                    </div>
                </div>

                <div class="row mb-5">

                    <div class="col text-center">

                        <router-link :to="`/personalization/step-2/${orderFlowDetails.id}`" class="btn btn-outline-primary me-3">Previous</router-link>
                        <button @click="saveForLater" class="btn btn-primary me-3">Save For Later</button>
                        <button @click="next" class="btn btn-primary">Next</button>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .cross-sell-page3 {

        label{
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
</style>