<script setup>

    // HowToVideosPage
    // How to video page is for displaying and playing videos 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import HelpCenterSidebar from './components/HelpCenterSidebar';
    import MediaPlayer from '@/site/areas/help-center/components/MediaPlayer';

    const crossSellVideoURL = process.env.VUE_APP_MSC_CROSSSELL_VIDEO_URL;
    const navVideoURL = process.env.VUE_APP_MSC_NAV_VIDEO_URL;
    const overallVideoURL = process.env.VUE_APP_MSC_OVERALL_VIDEO_URL;

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar />

            <div class="how-to-video-page">

                <div class="row">

                    <div class="col-3 mt-5">
                        <HelpCenterSidebar />
                    </div>

                    <div class="col mt-5">

                        <h1>How To Videos</h1>

                        <!-- TODO: We need the real videos once they are created -->

                        <div class="row mt-5">

                            <div class="col-6 text-center">

                                <MediaPlayer title="How to Navigate the Site" :video-width="360" :video-url="navVideoURL" />

                            </div>

                            <div class="col-6 text-center">

                                <MediaPlayer title="How to Place a Cross-sell Order" :video-width="360" :video-url="crossSellVideoURL" />

                            </div>

                        </div>

                        <div class="row mt-5">

                            <div class="col-6 text-center">

                                <MediaPlayer title="Safeco MSC Overview" :video-width="360" :video-url="overallVideoURL" />

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
</style>