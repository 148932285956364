<script setup>

    // RoyaltyLoyaltyInsuranceCheckUpPage
    // Landing page Royalty & Loyalty > Insurance Check-up

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import CallToActionImage from '@/site/components/CallToActionImage';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';

    // Imports
    import { ProductSearchTerm } from '@/constants'
    import { onMounted } from 'vue';
    import { site } from '@/Site'

   // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Insurance Check-up", "lob": "Retention" ,"productNames": "MSC_Insurance Check-up" });
    });

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />

            <div class="root-page-container royalty-loyalty-insurance-check-up">

                <div class="row mb-5">

                    <div class="col-sm-8">

                        <div class="row">
                            
                            <!-- TOOD: Move this headline to the headlineIcon component when merged together -->

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/checklist-icon.png" alt="Insurance Check-Up" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h1>Insurance Check-Up</h1>
                                    <p class="fw-bold">Review a customer's needs, discuss policy changes and explore coverage options.</p>

                                </div>

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="col">

                                <p>
                                    An Insurance Check-Up is a great way to make sure your customers have all the coverage they need. This useful tool can also help drive new business, uncover opportunities to Cross-sell and provide additional coverage to existing customers. Plus, by proactively addressing a customer's changing needs, you can form stronger relationships and increase retention.
                                </p>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <hr />

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="col">

                                <h2>How it works</h2>

                            </div>

                        </div>

                        <div class="row how-it-works">

                            <div class="col text-center">

                                <img src="/images/retention/check-up-step1.png" class="img-fluid" alt="step 1" />

                                <p class="text-start">
                                    <span>1. </span> Select the Insurance Check-Up form.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/check-up-step2.png" class="img-fluid" alt="step 2" />

                                <p class="text-start">
                                    <span>2. </span> Use the form to help uncover gaps in a customer's coverage.
                                </p>

                            </div>

                            <div class="col text-center">

                                <img src="/images/retention/check-up-step3.png" class="img-fluid" alt="step 3" />

                                <p class="text-start">
                                    <span>3. </span> Identify new business opportunities by reaching out to your customers to discuss potential savings.
                                </p>

                            </div>

                        </div>

                    </div>

                    <div class="col-sm">
                        <!-- TODO: Need to add the correct link -->
                        <!-- NOTE from creative: will take user to the 3 DM assets -->
                        <CallToActionImage class="ps-3" imageUrl="/images/retention/insurance-checkup.jpg" :linkUrl="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.INSURANCE_CHECKUP_FORM, 'active-tab': TabIDs.RETENTION_TAB_ID }}" buttonText="Personalize & Download Insurance Check-Up Form" />

                        <!-- TODO: We need to add the correct routes -->
                        <IconPanel class="mt-5" title="Insurance Check-Up resources" icon="/images/icons/search-icon.png">
                            <LinkItem class="mb-3" title="Personalize and Download the Insurance Check-Up Poster" :link="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.INSURANCE_CHECKUP_POASTER, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="Personalize and Download the Insurance Check-Up Form" :link="{ path: '/products', query: { 'f-product-search-term': ProductSearchTerm.INSURANCE_CHECKUP_FORM, 'active-tab': TabIDs.RETENTION_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>

</template>

<style lang="scss">
    .royalty-loyalty-insurance-check-up {

        .how-it-works {

            p {
                font-size: 1rem;
            }

            p span {
                color: $color-2;
                font-size: 1.25rem
            }
        }
    }
</style>