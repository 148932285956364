<script setup>

    // OrderDetails
    // Displays some high level details around the order 

    // Imports ----
    import { computed, reactive, ref, onMounted, defineProps, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { ProductTags } from '@/constants';

    const router = useRouter();

    const props = defineProps({
        // The label to show with the icon on the button.
        cart: {
            type: Object,
            required: true
        },
        showActionableItems: {
            type: Boolean,
            required: false,
            default: true
        },
        isBulkOrder: {
            type: Boolean,
            required: true,
            default: false
        }
    });

    // State ----
    const selectedAgencyIgniteDetails = reactive({});

    // Computed ----
    const cart = computed(() => { return props.cart; });

    const discountLoading = ref(false);

    const totalAddresses = computed(() => { return cart.value.shipToList?.length });

    const subTotal = computed(() => {
        if (props.isBulkOrder) {
            return site.convertNumberToCurrency(cart.value.subTotal * totalAddresses.value)
        }
        else {
            return site.convertNumberToCurrency(cart.value.subTotal)
        }

    });
    
    const shipping = computed(() => {
        if (props.isBulkOrder) {
            return site.convertNumberToCurrency(cart.value.shipping * totalAddresses.value)
        }
        else {
            return site.convertNumberToCurrency(cart.value.shipping);
        }
        
    });

    const total = computed(() => {
        if (props.isBulkOrder) {
            return site.convertNumberToCurrency(cart.value.total * totalAddresses.value)
        }
        else {
            return site.convertNumberToCurrency(cart.value.total)
        }
    });

    const discount = computed(() => {        
        if (props.isBulkOrder) {
            return site.convertNumberToCurrency(cart.value.discountTotal * totalAddresses.value)
        }
        else {
            return site.convertNumberToCurrency(cart.value.discountTotal)
        }
        
    });

    const hasDiscount = computed(() => { return cart.value.discountTotal > 0 })

    const isLoaded = ref(false);

    const discountToApply = ref('');

    const isIgniteEligible = computed(() => {
        let hasIgniteProduct = false;
        cart.value.items.forEach((item) => {
            item.product.tags.forEach((tag) => {
                if (tag.name === ProductTags.IGNITE) {
                    hasIgniteProduct = true;
                    return;
                }
            })
        })

        return hasIgniteProduct;
    })

    // Methods ----
    async function applyDiscount(code) {
        discountLoading.value = true
        if (Util.isNotEmpty(code)
            && props.showActionableItems) {
            const response = await site.cart.applyDiscount(code);
            site.errorHandler.handleApplyDiscountError(response);
        }
        discountLoading.value = false
    }

    async function removeDiscount() {
        discountLoading.value = true
        await site.cart.removeDiscount();
        discountLoading.value = false

    }
    const IsRequireShipping = computed(() => {
        return cart.value && cart.value.items
            && cart.value.items.filter(i => i.product && i.product.attributes && i.product.attributes.isRequireShipping)?.length > 0;
    });

    function onCheckout() {

        if (IsRequireShipping.value) {            
            router.push({ name: 'Checkout', params: { section: 'shipping' } });
        } else if (cart.value.total == 0) {
            router.push({ name: 'Review' });
        } else if (cart.value.total > 0) {
            router.push({ name: 'Checkout', params: { section: 'payment' } });
        } else {            
            router.push({ name: 'Checkout', params: { section: 'shipping' } });
        }
    }


    const disableProceedToCheckout = computed(() => {
        return !(cart.value.canPlaceOrder && isLoaded.value);
    });

    //computed
    const discountCode = computed({
        get() {
            if (Util.isNotEmpty(discountToApply.value)) {
                return discountToApply.value;
            }
            return "";
        },
        set(value) {
            discountToApply.value = value;
        }
    });

    const discountCodeForDisplay = computed(() => {
        const cartAttributes = cart.value.attributes
        if (cartAttributes && cartAttributes.Discounts && cartAttributes.Discounts.length > 0) {
            const discount = cartAttributes.Discounts[0];
            return discount?.code;
        }
        return "";
    });

    onMounted(async () => {
        discountToApply.value = discountCodeForDisplay.value;
        Object.assign(selectedAgencyIgniteDetails, await site.agencyDetails.loadAgencyIgniteDetails());
        if (Util.isNotEmpty(selectedAgencyIgniteDetails.discountCode) && selectedAgencyIgniteDetails.remaining > 0 && isIgniteEligible.value) {
            discountToApply.value = selectedAgencyIgniteDetails.discountCode;
            await applyDiscount(selectedAgencyIgniteDetails.discountCode);
        }
        isLoaded.value = true;
    })

    watch(
        () => cart.value.items,
        (newItems) => {
            if (newItems.length === 0)
                discountToApply.value = '';
        },
        { immediate: true }
    );
  
</script>

<template>

    <div class="order-details p-3" v-if="cart">

        <h2 class="text-center">Your Order</h2>

        <div v-if="discountLoading">
            <div v-for="n in 3" :key="n" class="row placeholder-glow mt-2" style="height: 22px">
                <div class="col-5 placeholder">
                </div>
                <div class="col-2"></div>
                <div class="col-5 placeholder">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr />
                </div>
            </div>
            <div class="row placeholder-glow" style="height: 30px">
                <div class="col-5 placeholder">
                </div>
                <div class="col-2"></div>
                <div class="col-5 placeholder">
                </div>
            </div>

            <div class="row placeholder-glow mt-2" style="height: 30px">
                <div class="col placeholder">
                </div>
            </div>
        </div>

        <div v-else>

            <div class="row">
                <div class="col-6">
                    Subtotal
                </div>
                <div class="col text-end">
                    {{subTotal}}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    Shipping
                </div>
                <div class="col text-end">
                    {{shipping}}
                </div>
            </div>

            <div class="row mt-3 text-danger" v-if="hasDiscount">
                <div class="col-8">
                    Discount ({{discountCodeForDisplay}})
                </div>
                <div class="col text-end">
                    -{{discount}}
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <hr />
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <strong class="fs-2">Total</strong> ({{cart.itemCount}} items)
                </div>
                <div class="col text-end fw-bold fs-2">
                    {{total}}
                </div>
            </div>

            <template v-if="props.showActionableItems">
                <div class="row mt-3">
                    <div class="col">
                        Shipping will be determined during checkout process.
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <strong>Discount</strong> (Optional)
                    </div>
                </div>

                <div class="row">
                    <div class="col-7">
                        <input type="text" class="form-control" v-model="discountToApply" placeholder="Discount Code">
                    </div>
                    <div class="col">
                        <button v-if="!hasDiscount" class="btn btn-outline-primary" @click="applyDiscount(discountCode)">Apply</button>
                        <button v-else class="btn btn-outline-primary" @click="removeDiscount">Remove</button>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col text-center">
                        <button class="btn btn-primary" :disabled="disableProceedToCheckout" @click="onCheckout">Proceed to Checkout</button>
                    </div>
                </div>
            </template>
        </div>

    </div>

</template>

<style lang="scss">
    .order-details {
        border: 1px solid $gray-3;
    }

    .cart-skeleton {
        display: flex;
    }
</style>