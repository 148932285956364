<script setup>

    // TableSkeleton
    // Just a skeleton for while a table is loading

    function random() {
        const column = Math.floor(Math.random() * 10) + 2;
        return 'col-' + column;
    }

</script>

<template>

    <div class="table-skeleton">

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-3"></span>
                        </h5>
                    </th>
                    <th scope="col">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-6"></span>
                        </h5>
                    </th>
                    <th scope="col">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-10"></span>
                        </h5>
                    </th>
                    <th scope="col">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-5"></span>
                        </h5>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                    <td>
                        <p class="placeholder-glow">
                            <span class="placeholder bg-color-2 placeholder-lg" :class="random()"></span>
                        </p>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

</template>

<style lang="scss">
    .table-skeleton {
    }
</style>