<script setup>
    // ProspectingStep1PersonalizationPage
    // Step 1 of personalizing mail drops 

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import PdfViewer from '@/site/components/PdfViewer';

    // Imports
    import { reactive, onMounted } from 'vue';

    import useServices from './services';

    const { getProductDetails, getAgencyDetails } = useServices();

    // State 
    const product = reactive({
        "details": {},
        "pdfDetails": {}
    });

    const agency = reactive({
        "agencyInformation": {},
        "agencyAddress": {}
    });

    const approvals = reactive({
        'finalApproval': false,
        'termsAndConditions': false,
        'noUseInMass': false
    });

    // Methods
    function imageLibrary() {
        // open a modal box for the image library
    }

    function uploadImage() {
        // Call the API to upload and image 
    }

    // Lifecycle Hooks
    onMounted(async () => {
        const productResponse = await getProductDetails();
        const agencyResponse = await getAgencyDetails();

        // TODO this is probably not going to be how this is returned from the API 
        product.details = productResponse.details;
        product.pdfDetails = productResponse.pdfDetails;
        agency.agencyInformation = agencyResponse.agencyInformation;
        agency.agencyAddress = agencyResponse.agencyAddress;
    });

</script>
<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />
            <div class="root-page-container prospecting-personalization">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.ONE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>

                                <h1>Personalize this Item</h1>

                            </div>

                            <div class="ms-auto">
                                {{product.details.name}}
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-sm-4">

                        <h2>Agency and Agent Information</h2>

                        <div class="my-3">
                            <label class="form-label">Agency Name *</label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.name" placeholder="Sample Agency Name">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">License Number <span class="fw-normal">(Required for CA & AR)</span></label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.licenseNumber" placeholder="License Number">
                            <div class="form-text">All other states are optional. </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent First Name*</label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.agentFirstName" placeholder="Agent First Name">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent Last Name*</label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.agentLastName" placeholder="Agent Last Name">
                            <div class="form-text">Enter full name as you would like it to appear in signature block.</div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent Phone Number*</label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.agentPhone" placeholder="123-456-7890">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent Email* <span class="fw-normal">(One email address only)</span></label>
                            <input type="text" class="form-control" v-model="agency.agencyInformation.agentEmail" placeholder="samsample@email.com">
                            <div class="form-text">This email address is used for lead distribution. </div>
                        </div>

                        <h2 class="mt-5">Agency Address</h2>

                        <div class="mb-3">
                            <label class="form-label">Agent Address*</label>
                            <input type="text" class="form-control" v-model="agency.agencyAddress.addressLine1" placeholder="123 Main St">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent Address Line 2 <span class="fw-normal">(Optional)</span></label>
                            <input type="text" class="form-control" v-model="agency.agencyAddress.addressLine2">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent City*</label>
                            <input type="text" class="form-control" v-model="agency.agencyAddress.city" placeholder="Anytown">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent State*</label>
                            <input type="text" class="form-control" v-model="agency.agencyAddress.state" placeholder="dsfgasdfasdf">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Agent Zip Code*</label>
                            <input type="text" class="form-control" v-model="agency.agencyAddress.postalCode" placeholder="12345">
                        </div>

                        <h2 class="mt-5">Agent Image (Optional)</h2>

                        <div class="mb-3">

                            <button class="btn btn-outline-primary me-3" @click="imageLibrary">
                                Image Library
                            </button>

                            <button class="btn btn-outline-primary" @click="uploadImage">
                                Upload Image
                            </button>

                        </div>

                        <h2 class="mt-5">Preview & Approve</h2>

                        <button class="btn btn-primary my-3" @click="uploadImage">
                            Preview PDF
                        </button>

                        <!-- TODO: Probably need the form components for these -->
                        <div class="form-check mt-4">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="approvals.finalApproval">
                                PDF Preview Content <strong class="text-error">Final Approval</strong>.
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="approvals.termsAndConditions">
                                <!-- TODO: link to the correct T&C -->
                                View and Accept <a href="#TODO">Terms and Conditions</a>.
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="approvals.noUseInMass">
                                I Certify that <strong class="text-error">I Will Not</strong> use this Item in the State of Massachusetts.
                            </label>
                        </div>

                        <div class="my-5">
                            <!-- TODO: Not sure what happens when you click cancel -->
                            <button class="btn btn-outline-primary me-3">
                                Cancel
                            </button>
                            <!-- TODO: Using the form validation make this button enabled based on checkboxes above-->
                            <router-link to="#TODO" class="btn btn-primary">
                                Next
                            </router-link>
                        </div>

                    </div>

                    <div class="col-sm-6 offset-sm-2">
                        <PdfViewer :pdfDetails="product.pdfDetails" :height="700"/>
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .prospecting-personalization {
        h2 {
            border-bottom: 1px solid $gray-3;
            padding-bottom: 10px;
        }

        label{
            font-weight: bold;
        }
    }
</style>