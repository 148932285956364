<script setup>

    // CrossSellHomePage
    // Landing page for the cross-sell program

    // Imports ----
    import { onMounted, reactive, ref, computed } from 'vue';
    import { site } from '@/Site';
    import { Search, TabBar } from '@dd-nucleus/nucleus-vue';
    import { ProgramType, ProductType } from '@/constants'
    const search = new Search('cross-sell-programs-for-current-agency', 'detail', 'title', 5000);
    import { PetCrossSellSelfServiceAsset } from '@/constants'

    // Components ----
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import OpportunitiesTile from '@/site/components/OpportunitiesTile';
    import AgencyBestPractices from './components/AgencyBestPractices';
    import IgniteCrossSellPromo from '@/site/components/IgniteCrossSellPromo';


    // State ----
    const crossSellTabs = [
        {
            id: 'cross-sell',
            title: 'Cross-sell'
        },
        {
            id: 'agency-best-practices',
            title: 'Agency best practices'
        }
    ];

    const results = reactive([]);

    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    // Methods  ----

    // Lifecycle Hooks
    onMounted(async () => {
        Object.assign(results, await search.results);
        // For Adobe analytics
        await site.analyticsHandler.push({ "tileCode": "Cross Sell Overview" , "lob": "Cross Sell" });
    });

    // Computed
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="root-page-container cross-sell-home">

                <div class="row">

                    <div class="col-sm-8">

                        <div class="row">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/corporation_icon.png" alt="Corporation" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h1>Cross-sell - Growth Strategy</h1>
                                    <p class="fw-bold">Increase retention and boost revenue by growing your business from within.</p>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <p>Cross-selling additional lines of business to your current customers is the fastest and most effective way to grow your business and improve customer retention. Cross-selling your current policyholders not only helps your agency grow, but also ensures your customers needs are met. And, selling deeper into your existing customer base builds stronger, lasting relationships - keeping you top of mind. Plus, you may be able to provide your customers with potential savings and discounts.</p>

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/growth-opportunities-icon.png" alt="Growth Opportunities" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h2>My growth opportunities</h2>

                                </div>

                            </div>

                        </div>

                        <div class="row" v-if="isEmployee">

                            <div class="col">

                                <AgencyBestPractices />

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col">

                                <TabBar :tabs="crossSellTabs" />

                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="cross-sell" role="tabpanel">
                                        <div class="sub-tab-container">
                                            <OpportunitiesTile filter-id="opportunities-cross-sell" />
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="agency-best-practices" role="tabpanel">
                                        <AgencyBestPractices />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="col">

                                <h3>Cross-sell self-service</h3>

                                <p>In addition to the automated National Cross-sell Program, Safeco provides agents with a self-serve option. This option allows you to self-select which customers you want to Cross-sell. To get started, just click on your preferred line of business and follow the four-step order process. (Minor fees apply)</p>

                            </div>
                        </div>

                        <div class="row my-3">

                            <div class="col-sm-6">
                                <img src="/images/icons/home-icon.png" alt="home icon" />
                                <!-- Sell home to auto <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': DataListOption.HomeCrossSell, 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell home to auto <router-link :to="{ path: '/products', query: { 'f-product-type': ProductType.HOME, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>

                            <div class="col-sm-6">
                                <img src="/images/icons/dog-icon.png" alt="dog icon" />
                                <!-- Sell specialty cross-sell <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': DataListOption.SpecialtyCrossSell, 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell pet cross-sell <router-link :to="{ path: '/products', query: { 'f-product-search-term': PetCrossSellSelfServiceAsset, 'f-program-type': ProgramType.PET_INSURANCE, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>

                        </div>

                        <div class="row my-3">

                            <div class="col-sm-6">
                                <img src="/images/icons/car-icon.png" alt="car icon" />
                                <!-- Sell auto to home <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': DataListOption.AutoCrossSell, 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell auto to home <router-link :to="{ path: '/products', query: { 'f-product-type': ProductType.AUTO, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>

                            <div class="col-sm-6">
                                <img src="/images/icons/motorcycle-icon.png" alt="motorcycle icon" />
                                <!-- Sell specialty cross-sell <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': DataListOption.SpecialtyCrossSell, 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell specialty cross-sell <router-link :to="{ path: '/products', query: { 'f-product-type': ProductType.CROSS_SELL_SPECIALITY, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>
                        </div>

                        <div class="row my-3">

                            <div class="col-sm-6">
                                <img src="/images/icons/umbrella-closed-icon.png" alt="umbrella icon" />
                                <!-- Sell umbrella <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': [DataListOption.UmbrellaCrossSell, DataListOption.Umbrella, DataListOption.FloridaUmbrella, DataListOption.UmbrellaEdcuationCrossSell].join(';'), 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell umbrella <router-link :to="{ path: '/products', query: { 'f-product-type': ProductType.UMBRELLA, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>

                            <div class="col-sm-6">
                                <img src="/images/icons/landlord-icon.png" alt="landlord icon" />
                                <!-- Sell to landlord protection <router-link :to="{ path: '/products', query: { 'f-product-data-list-option': DataListOption.LandLordProtectionCrossSell, 'f-product-data-list-type': [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD].join(';'), 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link> -->
                                Sell landlord protection <router-link :to="{ path: '/products', query: { 'f-product-type': ProductType.LLP, 'f-program-type': ProgramType.CROSS_SELL, 'f-product-state': selectedAgencyDetails.state, 'active-tab': TabIDs.CROSS_SELL_TAB_ID } }" class="no-underline">Get Started ></router-link>
                            </div>
                        </div>

                        <div class="row cross-sell-note">

                            <div class="col text-center">

                                <p>Availability for Cross-sell campaigns may vary by state. Please check back for future updates to availability and reach out to your TM with any questions.</p>

                            </div>
                        </div>

                    </div>

                    <div class="col-sm-4">

                        <div class="row">

                            <div class="col">

                                <IgniteCrossSellPromo />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .cross-sell {
    }
    .cross-sell-note {
        margin-top: 2rem !important;
    }
</style>

