<script setup>
    // Imports
    import { onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    // Components
    import { isNotEmpty } from '@/util';

    // State
    const route = useRoute();    
    
    // Handlers ----
    onMounted(async () => {
        const type = route.query.t;
        const guid = route.query.i;
        const email = route.query.e;

        if (isNotEmpty(type) && isNotEmpty(guid) && isNotEmpty(email)) {
            await site.mwsOptOutEmail.trackEmail(type, guid);
            const MWS_OPT_OUT_REDIRECT_URL = process.env.VUE_APP_MWS_OPT_OUT_REDIRECT_URL;
            window.parent.location.href = MWS_OPT_OUT_REDIRECT_URL + "?tab=remove&id=" + guid + "&e=" + email;
        }
    })

</script>

<template>
    <div><p>Loading..</p></div>
</template>