<script setup>

    // TODO: This might make sense to be moved to the nucleus package

    // ProductImageGallery
    // Shows product images.

    // Imports ----
    import { defineProps, computed } from 'vue';
    import { Util } from '@dd-nucleus/nucleus-vue';

    // Components ----
    import ImageSlider from './ImageSlider';
    import ModalBox from '@/site/components/ModalBox';

    // Props ----
    const props = defineProps({
        // Product object containing the following property:
        // - tags
        product: {
            type: Object,
            required: true
        },
        // URL used if no thumbnail image URL is set
        noImageAvailableUrl: {
            type: String,
            required: false,
            default: '/images/no-image.png'
        }
    });

    const url = computed(() => {
        if (Util.isDefined(props.product.media) && props.product.media.length > 0) {
            const mediaImages = props.product.media.filter(m => m.mediaType == "Image");
            if (mediaImages?.length > 0) {
                return mediaImages[0].url;
            }
            else {
                return props.noImageAvailableUrl;
            }
        }
        else
            return props.noImageAvailableUrl;
    });

    const title = computed(() => {
        if (Util.isDefined(props.product.media) && props.product.media.length > 0) {
            const mediaImages = props.product.media.filter(m => m.mediaType == "Image");
            if (mediaImages?.length > 0) {
                return mediaImages[0].name;
            }
            else {
                return '';
            }
        }
        else
            return '';
    });

    const images = computed(() => {
        if (Util.isDefined(props.product.media) && props.product.media.length > 0) {
            const mediaImages = props.product.media.filter(m => m.mediaType == "Image");
            if (mediaImages?.length > 0) {
                return mediaImages;
            }
            else {
                return props.noImageAvailableUrl;
            }
        }
        else
            return props.noImageAvailableUrl;
    });

    const multipleImages = computed(() => {
        return (Util.isDefined(props.product.media) && props.product.media?.filter(m => m.mediaType == "Image")?.length > 1) ? true : false;
    });

</script>

<template>



    <div class="product-image-gallery">

        <!-- When we only have a mutiple images show the slider -->
        <div v-if="multipleImages">
            <ImageSlider :images="images" />
        </div>

        <!-- When we only have a single image -->
        <div class="text-center" v-else>
            <img class="img-fluid" :src="url" data-bs-toggle="modal" data-bs-target="#largerImage" />

            <ModalBox modalId="largerImage" :title="title" modalSize="modal-xl">
                <template v-slot:body>
                    <div class="text-center">
                        <img class="img-fluid" :src="url" />
                    </div>
                </template>
            </ModalBox>
        </div>

    </div>

</template>

<style lang="scss">

    .product-image-gallery {
    }
</style>