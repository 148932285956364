
// Site
// Defines a class derived from NucleusStore that can customize services provided at the site level.

// Global Components ----
import PageTemplate from '@/template/PageTemplate';
import { EventIDs, GlobalEvents, Announcement } from '@dd-nucleus/nucleus-vue';

const { emitGlobal } = GlobalEvents();

// Pages for Router
import HomePage from '@/site/HomePage.vue';

// Sign In Pages 
import SignInPage from './site/areas/sign-in/SignInPage.vue';
import SSOSignInPage from './site/areas/sign-in/SSOSignInPage.vue';

// Account Pages (panels make up this page)
import AccountPage from './site/areas/account/AccountPage.vue';

// Reporting 
import ReportsHomePage from '@/site/areas/reports/ReportsHomePage.vue';
import ReportDetailsPage from '@/site/areas/reports/ReportDetailsPage.vue';

// Marketing Pages 
import MarketingHomePage from '@/site/areas/marketing/MarketingHomePage.vue';


// Express Order Pages
import ExpressOrderConfirmation from '@/site/areas/expressorder/ExpressOrderConfirmation.vue';
import ExpressOrderPersonalizationConfirmation from '@/site/areas/expressorder/ExpressOrderPersonalizationConfirmation.vue';
import ExpressOrderSubCodes from '@/site/areas/expressorder/ExpressOrderSubCodes.vue';
import ExpressOrderStepOne from '@/site/areas/expressorder/ExpressOrderStepOne.vue';
import ExpressOrderStepTwo from '@/site/areas/expressorder/ExpressOrderStepTwo.vue';
import ExpressOrderStepThree from '@/site/areas/expressorder/ExpressOrderStepThree.vue';
import ExpressOrderStepFour from '@/site/areas/expressorder/ExpressOrderStepFour.vue';
import ExpressOrderPageFlexReturn from '@/site/areas/expressorder/PageFlexReturn.vue';


// Checkout 
import CartPage from '@/site/areas/cart/CartPage.vue';
import CheckoutPage from '@/site/areas/cart/CheckoutPage.vue';
import ReviewPage from '@/site/areas/cart/ReviewPage.vue';
import OrderConfirmedPage from '@/site/areas/cart/OrderConfirmedPage.vue';

// Product Pages 
import ProductSearchPage from './site/areas/products/ProductSearchPage.vue';
import ProductDetailPage from '@/site/areas/products/ProductDetailPage.vue';

// Helper Pages 
import ContactUsPage from '@/site/areas/contact/ContactUsPage.vue';
import FrequentlyAskedQuestionsPage from '@/site/areas/help-center/FrequentlyAskedQuestionsPage.vue';
import HowToVideosPage from '@/site/areas/help-center/HowToVideosPage.vue';


// Cross Sell 
import CrossSellHomePage from '@/site/areas/cross-sell/CrossSellHomePage.vue';
import CrossSellProgamStep1Page from '@/site/areas/cross-sell/CrossSellProgamStep1Page.vue';
import CrossSellProgamStep2Page from '@/site/areas/cross-sell/CrossSellProgamStep2Page.vue';
import CrossSellProgamStep3Page from '@/site/areas/cross-sell/CrossSellProgamStep3Page.vue';
import CrossSellProgamStep4Page from '@/site/areas/cross-sell/CrossSellProgamStep4Page.vue';
import CrossSellExpressOrderingPage from '@/site/areas/cross-sell/CrossSellExpressOrderingPage.vue';
import CrossSellSelectMultipleDropsStep3Page from '@/site/areas/cross-sell/CrossSellSelectMultipleDropsStep3Page.vue';
import CrossSellViewSummaryPage from '@/site/areas/cross-sell/CrossSellViewSummaryPage.vue';
import CrossSellViewDetailPage from '@/site/areas/cross-sell/CrossSellViewDetailPage.vue';
import ProspectingHomePage from '@/site/areas/prospecting/ProspectingHomePage.vue';
import SelectChannelPage from '@/site/areas/personalization/SelectChannelPage.vue';




// Prospecting 
import ProspectingStep2SelectMailListPage from '@/site/areas/prospecting/ProspectingStep2SelectMailListPage.vue';
import ProspectingStep2SelectZipCodesPage from '@/site/areas/prospecting/ProspectingStep2SelectZipCodesPage.vue';
import ProspectingStep2ZipCode from '@/site/areas/prospecting/ProspectingStep2ZipCode.vue';
import ProspectingSelectPromoteProspectsPage from '@/site/areas/prospecting/ProspectingSelectPromoteProspectsPage.vue';
import ProProspectingStep1PersonalizationPage from '@/site/areas/prospecting/ProspectingStep1PersonalizationPage.vue';
import ProspectingStep4ReviewPage from '@/site/areas/prospecting/ProspectingStep4ReviewPage.vue';
import ProspectingSelectMailScheduleStep3Page from '@/site/areas/prospecting/ProspectingSelectMailScheduleStep3Page.vue';
import ProspectingSelectMultipleDropsStep3Page from '@/site/areas/prospecting/ProspectingSelectMultipleDropsStep3Page.vue';
import RelationshipMarketingPage from '@/site/areas/retention/RelationshipMarketingPage.vue';

// Retention 
import RoyaltyLoyaltyMovingPage from '@/site/areas/retention/RoyaltyLoyaltyMovingPage.vue';
import RoyaltyLoyaltyWinBackPage from '@/site/areas/retention/RoyaltyLoyaltyWinBackPage.vue';
import RoyaltyLoyaltyClientReferralPage from '@/site/areas/retention/RoyaltyLoyaltyClientReferralPage.vue';
import RoyaltyLoyaltyInsuranceCheckUpPage from '@/site/areas/retention/RoyaltyLoyaltyInsuranceCheckUpPage.vue';
import MovingWithSafecoListPage from '@/site/areas/retention/moving/MovingWithSafecoListPage.vue';
import RoyaltyLoyaltyAnnualAutoViewListPage from '@/site/areas/retention/RoyaltyLoyaltyAnnualAutoViewListPage.vue';

// Personalize Order Flow 
import SaveForLaterConfirmation from '@/site/areas/personalization/SaveForLaterConfirmation.vue';
import PersonalizeStep1Page from '@/site/areas/personalization/PersonalizeStep1Page.vue';
import PersonalizeStep2Page from '@/site/areas/personalization/PersonalizeStep2Page.vue';
import PersonalizeStep3Page from '@/site/areas/personalization/PersonalizeStep3Page.vue';
import PersonalizeStep3WinBackPage from '@/site/areas/personalization/PersonalizeStep3WinBackPage.vue';
import PersonalizeStep3ReviewPage from '@/site/areas/personalization/PersonalizeStep3ReviewPage.vue';
import PersonalizeStep3DropDatePage from '@/site/areas/personalization/PersonalizeStep3DropDatePage.vue';
import PersonalizeStep4Page from '@/site/areas/personalization/PersonalizeStep4Page.vue';
import PersonalizeStep4MultiPage from '@/site/areas/personalization/PersonalizeStep4MultiPage.vue';
import SelectAssetPage from '@/site/areas/personalization/SelectAssetPage.vue';
import PersonalizeStep2ReviewPage from '@/site/areas/personalization/PersonalizeStep2ReviewPage.vue';
import PageFlexReturnPage from '@/site/areas/personalization/PageFlexReturnPage.vue';
import CustomizeDownloadPage from '@/site/areas/personalization/CustomizeDownloadPage.vue';
import CustomizeDownloadPageFlexReturn from '@/site/areas/personalization/CustomizeDownloadPageFlexReturn.vue';
import DownloadCustomizeAssetPage from '@/site/areas/personalization/DownloadCustomizeAssetPage.vue';


// Print On Demand 
import PrintOnDemandCustomizeStep1Page from '@/site/areas/print-on-demand/PrintOnDemandCustomizeStep1Page.vue';
import PrintOnDemandCustomizeStep2Page from '@/site/areas/print-on-demand/PrintOnDemandCustomizeStep2Page.vue';
import PrintOnDemandCustomizeStep3Page from '@/site/areas/print-on-demand/PrintOnDemandCustomizeStep3Page.vue';
import AnnualAutoPersonalizationSubCodesPage from '@/site/areas/annual-auto/AnnualAutoPersonalizationSubCodesPage.vue';
import AnnualAutoSelectSubCodesPage from '@/site/areas/annual-auto/AnnualAutoSelectSubCodesPage.vue';
import CustomizableDownloadStep1Page from '@/site/areas/downloads/CustomizableDownloadStep1Page.vue';
import CustomizableDownloadStep2Page from '@/site/areas/downloads/CustomizableDownloadStep2Page.vue';
import AnnualAutoExpressOrderingPage from '@/site/areas/annual-auto/AnnualAutoExpressOrderingPage.vue';
import AnnualAutoSelectChannelPage from '@/site/areas/annual-auto/AnnualAutoSelectChannelPage.vue';

// Safeco Misc
import GrowYourBusinessPage from '@/site/areas/grow-your-business/GrowYourBusinessPage.vue';
import SellNewProspectsPage from '@/site/areas/find-new-customers/SellNewProspectsPage.vue';
import PromoteAnEventPage from '@/site/areas/find-new-customers/PromoteAnEventPage.vue';
import PromoteOnlinePresencePage from '@/site/areas/find-new-customers/PromoteOnlinePresencePage.vue';
import OrderMarketingMaterialsPage from '@/site/areas/find-new-customers/OrderMarketingMaterialsPage.vue';
import IncreaseRetentionAddValuePage from '@/site/areas/increase-retention/IncreaseRetentionAddValuePage.vue';
import MyFavoriteMarketingMaterialsPage from '@/site/areas/my-pages/MyFavoriteMarketingMaterialsPage.vue';
import MyFavoriteReportsPage from '@/site/areas/my-pages/MyFavoriteReportsPage.vue';
import MostRecentDownloadsPage from '@/site/areas/my-pages/MostRecentDownloadsPage.vue';
import AutoInsuranceBrochuresPage from '@/site/areas/top-picks/AutoInsuranceBrochuresPage.vue';
import HomeInsuranceBrochuresPage from '@/site/areas/top-picks/HomeInsuranceBrochuresPage.vue';
import AnnualAutoLetterPage from '@/site/areas/top-picks/AnnualAutoLetterPage.vue';
import InsuranceCheckUpPosterPage from '@/site/areas/top-picks/InsuranceCheckUpPosterPage.vue';

// Impersonation
import ImpersonationEnded from '@/site/components/ImpersonationEnded.vue';
import DeepLinkRedirect from '@/site/components/DeepLinkRedirect.vue';

//Terms and Conditions
import TermsAndConditions from '@/site/areas/terms-conditions/TermsAndConditions.vue';

// MWS Opt out
import MWSOptOutEmailPage from '@/site/areas/mws/MWSOptOutEmailPage.vue';
import MWSEmailOptOutPage from '@/site/areas/mws/MWSEmailOptOutPage.vue';
import MWSAcknowledgementPage from '@/site/areas/mws/MWSAcknowledgementPage.vue';

// Imports ----
// Nucelus Base 
import { NucleusStore, MockAuthenticator, Shipping, Util } from '@dd-nucleus/nucleus-vue';
import SSOAuthenticator from '@/site/services/authentication/SSOAuthenticator';

// Custom Services for Safeco
import PaymentInfo from '@/site/services/PaymentInfo';
import PersonalizationInfo from '@/site/services/PersonalizationInfo';
import AgencyDetails from '@/site/services/AgencyDetails';
import ImageLibrary from '@/site/services/ImageLibrary';
import UserFromBridge from '@/site/services/UserFromBridge';
import Opportunities from '@/site/services/Opportunities';
import OrderDetails from '@/site/services/OrderDetails';
import UserRoles from '@/site/services/UserRoles';
import BlobService from '@/site/services/BlobService';
import StoreCart from '@/site/services/StoreCart';
import UserFeedback from '@/site/services/UserFeedback';
import Banner from '@/site/services/Banner';

import AddressList from '@/site/services/AddressList';
import Reporting from '@/site/services/Reporting';

import SSORedirect from './site/areas/sign-in/SSORedirectPage.vue';

import ErrorHandler from '@/site/services/ErrorHandler';
import AnalyticsHandler from '@/site/services/AnalyticsHandler';
import DownloadHandler from '@/site/services/DownloadHandler';


import ExpressOrderPersonalizationInfo from '@/site/services/ExpressOrderPersonalizationInfo';
import SearchHandler from '@/site/services/SearchHandler';

import MWSOptOutEmail from '@/site/services/MWSOptOutEmail';
import MWSEmailOptOut from '@/site/services/MWSEmailOptOut';

const reportMapping = [
    { oldReportId: 51, id: 3, name: "Annual Auto" }
]


export default class Site extends NucleusStore {
    // Constructor
    constructor() {
        super();

        // Set site to require authentication before seeing any pages
        this.requireAuthentication = true;

        // Add ASP.NET authentication
        // Looks a the VUE_APP_MOCK_AUTH_ENABLED var in .env to decide which auth to use 
        if (process.env.VUE_APP_MOCK_AUTH_ENABLED == "false") {
            this.authenticator = new SSOAuthenticator(this);
        } else {
            this.authenticator = new MockAuthenticator(this);
        }

        this.paymentInfo = new PaymentInfo(this);
        this.personalizationInfo = new PersonalizationInfo(this);
        this.agencyDetails = new AgencyDetails(this);
        this.imageLibrary = new ImageLibrary(this);
        this.userFromBridge = new UserFromBridge(this);
        this.opportunities = new Opportunities(this);
        this.shipping = new Shipping(this);
        this.orderDetails = new OrderDetails(this);
        this.userRoles = new UserRoles(this);
        this.banners = new Banner(this);

        this.userFeedback = new UserFeedback(this);

        // add  upload address list
        this.addressList = new AddressList(this);
        this.reporting = new Reporting(this);
        this.blob = new BlobService(this);
        this.storeCart = new StoreCart(this);
        this.errorHandler = new ErrorHandler(this);
        this.downloadHandler = new DownloadHandler(this);
        
        this.announcement = new Announcement(this);


        this.analyticsHandler = new AnalyticsHandler(this);

        this.expressOrderPersonalizationInfo = new ExpressOrderPersonalizationInfo(this);
        this.searchHandler = new SearchHandler(this);

        this.mwsOptOutEmail = new MWSOptOutEmail(this);
        this.mwsEmailOptOut = new MWSEmailOptOut(this);
    }

    /**
     * Initializes the site.
     * @param {any} options
     */
    async initialize(options) {
        await super.initialize(options);
    }

    // #region Services

    paymentInfo;
    personalizationInfo;
    agencyDetails;
    imageLibrary;
    userFromBridge;
    opportunities;
    shipping;
    orderDetails;
    blob;
    storeCart;
    errorHandler;
    downloadHandler;

    expressOrderPersonalizationInfo;

    analyticsHandler;
    searchHandler;


    // #endregion

    // #region Public Methods ----
    /**
    * @deprecated Use function with the same name from util.js.
    */
    convertNumberToCurrency(price) {
        price = price ? price : 0;
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    }

    // Utility Method for finding the last day of the month
    // TODO: May want to consider moving this to the base util.js
    getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    formatDisplayDate(date) {
        if (date) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }


    async mockSignIn(username, statCode1, statCode2) {
        let response = await this.api.post('site/asp-net-auth-mock/login?username=' + username + '&statCode1=' + statCode1 + '&statCode2=' + statCode2);
        site.cart.loadCart();
        site.personalizationInfo.newLogin();
        this.emitSignedIn();
        return response;
    }

    emitSignedIn() {
        emitGlobal(this.authenticator.EventIDs.AUTH_SIGNED_IN);
    }
    // Calendar 
    async getUsersCalendar() {
        return await this.api.get('/site/calendar/events');
    }

    async getLegacyOrderDetails(orderNumber) {
        return await this.api.get(`site/order/${orderNumber}/detail`);
    }

    async postCalendarEvent(payload) {
        console.warn("payload:", payload)
        return 'YEAHHHH'
        //TODO find the real end point 
        //return await this.api.post('/api/user/current/calendar', payload);

    }

    // Safeco SSO Authentication 
    async signWithUserId(userId, router, deepLink) {
        const isSignedIn = await this.authenticator.signIn(userId);
        if (isSignedIn) {
            if (deepLink) {
                this.navigatingTo = this.createDeepLinkRoute(deepLink);
            }
            sessionStorage.setItem('logged-in', 'true');
            this.postSignedIn(router);
        }
    }

    // Safeco SSO Authentication 
    async signOut(router) {
        super.signOut(router);
        sessionStorage.removeItem('logged-in');
    }

    async handleUnauthorizedError(router) {
        sessionStorage.removeItem('logged-in');
        this.authenticator.removeLoginToken();
        router.push(this.authenticator.getSignInRoute());
    }

    createDeepLinkRoute(deepLink) {
        if (deepLink) {
            const url = deepLink.split("?");
            if (url && url.length > 1) {
                const urlParams = new URLSearchParams(url[1]);
                let query = {
                    redirectUrl: url[0]
                };
                for (const [key, value] of urlParams) {
                    query[key] = value;
                }
                return {
                    name: 'DeepLinkRedirect',
                    query: query
                };
            } else {
                return deepLink;
            }
        }
        return null;
    }

    // setupVue()
    // Called to allow this object to modify the Vue app if necessary.
    setupVue(vueApp) {
        super.setupVue(vueApp);

        // Add any statements here to modify the Vue application supplied

        // Register global components
        vueApp.component('PageTemplate', PageTemplate);
    }




    // createVueRouter()
    // Called to allow the site to add any needed routes. Use the helper methods as shown below.
    createVueRouter() {
        // Here, create the list of routes that apply specifically to this site.
        // NOTE: If authentication is required, defining a route to the path '/' will cause an error.
        //       In that case a route with name == 'Home' is also required, which is where users will land after signing in.
        // this.addRoute("/account/:section?", "Account", AccountPage);
        this.addRoute("/account/:section?/:id?", "Account", AccountPage);

        this.addRoute("/", "Home", HomePage);
        this.addRoute("/home", "Home", HomePage);

        if (process.env.VUE_APP_MOCK_AUTH_ENABLED == "false") {
            this.addPublicRoute("/sign-in", "SignIn", SSOSignInPage);
        } else {
            // Sign-in
            this.addPublicRoute("/sign-in", "SignIn", SignInPage);
        }

        //SSO Redirect
        this.addPublicRoute("/sso-redirect", "SSORedirect", SSORedirect);

        // Helper Pages 
        this.addRoute("/contact-us", "ContactUsPage", ContactUsPage);
        this.addRoute("/faq", "FrequentlyAskedQuestionsPage", FrequentlyAskedQuestionsPage);
        this.addRoute("/how-to-videos", "HowToVideosPage", HowToVideosPage);

        // Cart / Checkout 
        this.addRoute("/cart", "CartPage", CartPage);
        this.addRoute("/checkout/:section?", "Checkout", CheckoutPage);
        this.addRoute("/review", "Review", ReviewPage);
        this.addRoute("/order-confirmed/:id?", "OrderConfirmed", OrderConfirmedPage);

        // Products 
        this.addRoute("/products", "ProductSearch", ProductSearchPage);
        this.addRoute("/product/:id", "ProductDetail", ProductDetailPage);

        // Express Order
        this.addRoute("/expressorder/confirmation/:productId", "ExpressOrderConfirmationPage", ExpressOrderConfirmation);
        this.addRoute("/expressorder/subcodes/:bulkOrderStateId", "ExpressOrderSubCodesPage", ExpressOrderSubCodes);
        this.addRoute("/expressorder/personalization/confirmation/:bulkOrderStateId", "ExpressOrderPersonalizationConfirmationPage", ExpressOrderPersonalizationConfirmation);
        //this.addRoute("/expressorder/subcodes/policyholders", "ExpressOrderSelectSubCodesPolicy", ExpressOrderSelectSubCodesPolicy);
        this.addRoute("/expressorder/step-1/:bulkOrderStateId/:orderStateId?", "ExpressOrderStepOne", ExpressOrderStepOne);
        this.addRoute("/expressorder/step-2/:bulkOrderStateId", "ExpressOrderStepTwo", ExpressOrderStepTwo);
        this.addRoute("/expressorder/step-3/:bulkOrderStateId", "ExpressOrderStepThree", ExpressOrderStepThree);
        this.addRoute("/expressorder/step-4/:bulkOrderStateId", "ExpressOrderStepFour", ExpressOrderStepFour);
        this.addRoute("/expressorder/pageflex-return/:bulkOrderStateId/:orderStateId?", "PageFlexReturn", ExpressOrderPageFlexReturn);


        // Cross Sell 
        this.addRoute("/cross-sell", "CrossSellHome", CrossSellHomePage);
        this.addRoute("/cross-sell/express-ordering", "CrossSellExpressOrderingPage", CrossSellExpressOrderingPage);
        this.addRoute("/cross-sell/program-step-1", "CrossSellProgamStep1Page", CrossSellProgamStep1Page);
        this.addRoute("/cross-sell/program-step-2", "CrossSellProgamStep2Page", CrossSellProgamStep2Page);
        this.addRoute("/cross-sell/program-step-3", "CrossSellProgamStep3Page", CrossSellProgamStep3Page);
        this.addRoute("/cross-sell/program-step-4", "CrossSellProgamStep4Page", CrossSellProgamStep4Page);
        this.addRoute("/cross-sell/select-multiple-drops", "CrossSellSelectMultipleDropsStep3Page", CrossSellSelectMultipleDropsStep3Page);
        this.addRoute("/cross-sell/view-summary/:id", "CrossSellViewSummaryPage", CrossSellViewSummaryPage);
        this.addRoute("/cross-sell/view-detail/:id/:subCode", "CrossSellViewDetailPage", CrossSellViewDetailPage);
        // Prospecting 
        this.addRoute("/prospecting", "ProspectingHome", ProspectingHomePage);
        this.addRoute("/prospecting/personalization-step-1", "ProspectingStep1PersonalizationPage", ProProspectingStep1PersonalizationPage);
        this.addRoute("/prospecting/review-step-4", "ProspectingStep4ReviewPage", ProspectingStep4ReviewPage);
        this.addRoute("/prospecting/select-promote-prospect", "ProspectingSelectPromoteProspectsPage", ProspectingSelectPromoteProspectsPage);
        this.addRoute("/prospecting/step2-select-mail-list", "ProspectingStep2SelectMailListPage", ProspectingStep2SelectMailListPage);
        this.addRoute("/prospecting/step2-select-zip-codes", "ProspectingStep2SelectZipCodesPage", ProspectingStep2SelectZipCodesPage);
        this.addRoute("/prospecting/select-select-mail-schedule", "ProspectingSelectMailScheduleStep3Page", ProspectingSelectMailScheduleStep3Page);
        this.addRoute("/prospecting/select-multiple-drops", "ProspectingSelectMultipleDropsStep3Page", ProspectingSelectMultipleDropsStep3Page);

        // TODO: Page object is not imported/defined - this.addRoute("/prospecting/step-2-prospect-phone-numbers", "ProspectingStep2ProspectPhoneNumbers", ProspectingStep2ProspectPhoneNumbers);
        this.addRoute("/prospecting/step-2-zip-codes", "ProspectingStep2ZipCode", ProspectingStep2ZipCode);
        // TODO: Page object is not imported/defined - this.addRoute("/prospecting/program-step-3", "ProspectingStep3SelectMailSchedulePage", ProspectingStep3SelectMailSchedulePage);

        // Retention 
        this.addRoute("/retention", "RetentionHome", RelationshipMarketingPage);
        this.addRoute("/retention/relationship-marketing", "RelationshipMarketingPage", RelationshipMarketingPage);
        this.addRoute("/retention/moving", "RoyaltyLoyaltyMovingPage", RoyaltyLoyaltyMovingPage);
        this.addRoute("/retention/win-back", "RoyaltyLoyaltyWinBackPage", RoyaltyLoyaltyWinBackPage);
        this.addRoute("/retention/client-referral", "RoyaltyLoyaltyClientReferralPage", RoyaltyLoyaltyClientReferralPage);
        this.addRoute("/retention/insurance-check-up", "RoyaltyLoyaltyInsuranceCheckUpPage", RoyaltyLoyaltyInsuranceCheckUpPage);
        this.addRoute("/retention/moving/list", "MovingWithSafecoListPage", MovingWithSafecoListPage);
        this.addRoute("/retention/annual-auto/view-detail/:id", "RoyaltyLoyaltyAnnualAutoViewListPage", RoyaltyLoyaltyAnnualAutoViewListPage);

        this.addPublicRoute("/optoutacknowledge/:id", "optoutacknowledged", MWSAcknowledgementPage);

        // Personalize Order Flow
        this.addRoute("/personalization/save-for-later/confirmation/:productId", "SaveForLaterConfirmationPage", SaveForLaterConfirmation);
        this.addRoute("/personalization/step-1/:id/:parentId?/:productId?/:isMultiChannel?", "PersonalizeStep1Page", PersonalizeStep1Page);
        this.addRoute("/personalization/step-2/:id", "PersonalizeStep2Page", PersonalizeStep2Page);
        this.addRoute("/personalization/step-3/:id", "PersonalizeStep3Page", PersonalizeStep3Page);
        this.addRoute("/personalization/step-3-win-back/:id", "PersonalizeStep3WinBackPage", PersonalizeStep3WinBackPage);
        this.addRoute("/personalization/step-3-drop-date/:id", "PersonalizeStep3DropDatePage", PersonalizeStep3DropDatePage);
        this.addRoute("/personalization/step-4/:id", "PersonalizeStep4Page", PersonalizeStep4Page);
        this.addRoute("/personalization/step-4-multi-channel/", "PersonalizeStep4MultiPage", PersonalizeStep4MultiPage);
        this.addRoute("/personalization/select-asset/:channel?/:searchTerm?", "SelectAssetPage", SelectAssetPage);
        this.addRoute("/personalization/select-channel", "SelectChannelPage", SelectChannelPage);
        this.addRoute("/personalization/step-3-review/:id", "PersonalizeStep3ReviewPage", PersonalizeStep3ReviewPage);
        this.addRoute("/personalization/step-2-review/:id", "PersonalizeStep2ReviewPage", PersonalizeStep2ReviewPage);
        this.addRoute("/personalization/pageflex-return/:id/:parentId?/:productId?/:isMultiChannel?", "PageFlexReturnPage", PageFlexReturnPage);
        this.addRoute("/personalization/customize-download/", "CustomizeDownloadPage", CustomizeDownloadPage);
        this.addRoute("/personalization/customize-download-return/", "CustomizeDownloadPageFlexReturn", CustomizeDownloadPageFlexReturn);
        this.addRoute("/personalization/download-customize-asset/:id?", "DownloadCustomizeAssetPage", DownloadCustomizeAssetPage);


        // Print on Demand
        this.addRoute("/print-on-demand/customize-step-1", "PrintOnDemandCustomizeStep1Page", PrintOnDemandCustomizeStep1Page);
        this.addRoute("/print-on-demand/customize-step-2", "PrintOnDemandCustomizeStep2Page", PrintOnDemandCustomizeStep2Page);
        this.addRoute("/print-on-demand/customize-step-3", "PrintOnDemandCustomizeStep3Page", PrintOnDemandCustomizeStep3Page);

        // Annual Auto
        this.addRoute("/annual-auto/express-ordering", "AnnualAutoExpressOrderingPage", AnnualAutoExpressOrderingPage);
        this.addRoute("/annual-auto/select-channel", "AnnualAutoSelectChannelPage", AnnualAutoSelectChannelPage);
        this.addRoute("/annual-auto/personalization-sub-codes", "AnnualAutoPersonalizationSubCodesPage", AnnualAutoPersonalizationSubCodesPage);
        this.addRoute("/annual-auto/select-sub-codes", "AnnualAutoSelectSubCodesPage", AnnualAutoSelectSubCodesPage);

        // Safeco Misc
        this.addRoute("/marketing", "MarketingHome", MarketingHomePage);

        this.addRoute("/grow-your-business", "GrowYourBusinessPage", GrowYourBusinessPage);
        this.addRoute("/sell-new-prospects", "SellNewProspects", SellNewProspectsPage);
        this.addRoute("/promote-an-event", "PromoteAnEvent", PromoteAnEventPage);
        this.addRoute("/promote-online-presence", "PromoteOnlinePresence", PromoteOnlinePresencePage);
        this.addRoute("/order-marketing-materials", "OrderMarketingMaterials", OrderMarketingMaterialsPage);
        this.addRoute("/increase-retention-add-value", "IncreaseRetentionAddValue", IncreaseRetentionAddValuePage);
        this.addRoute("/my-favorite-marketing-materials", "MyFavoriteMarketingMaterials", MyFavoriteMarketingMaterialsPage);
        this.addRoute("/my-favorite-reports", "MyFavoriteReports", MyFavoriteReportsPage);
        this.addRoute("/most-recent-downloads", "MostRecentDownloads", MostRecentDownloadsPage);
        this.addRoute("/auto-insurance-brochures", "AutoInsuranceBrochures", AutoInsuranceBrochuresPage);
        this.addRoute("/home-insurance-brochures", "HomeInsuranceBrochures", HomeInsuranceBrochuresPage);
        this.addRoute("/annual-auto-letter", "AnnualAutoLetter", AnnualAutoLetterPage);
        this.addRoute("/insurance-check-up-poster", "InsuranceCheckUpPoster", InsuranceCheckUpPosterPage);

        // Customize Pages / These may need to change 
        this.addRoute("/customizable-download-step1", "CustomizableDownloadStep1Page", CustomizableDownloadStep1Page);
        this.addRoute("/customizable-download-step2", "CustomizableDownloadStep2Page", CustomizableDownloadStep2Page);

        //Reporting Pages
        this.addRoute("/reports/:id?/:name?", "ReportsHome", ReportsHomePage);
        this.addRoute("/report/detail/:id/:name?", "ReportDetails", ReportDetailsPage);

        // Impersonation Pages
        this.addRoute("/impersonation-ended", "ImpersonationEnded", ImpersonationEnded);

        //temporary deep link redirect page
        this.addRoute("/deep-link-redirect", "DeepLinkRedirect", DeepLinkRedirect);

        //Terms and Conditions
        this.addRoute("/terms-conditions", "TermsAndConditions", TermsAndConditions);

        // MWS Opt Out Email blank page
        this.addPublicRoute('/OptOutEmail', "OptOutEmail", MWSOptOutEmailPage);

        this.addPublicRoute('/EmailOptOut', "EmailOptOut", MWSEmailOptOutPage);

        // Redirects from old MSC to the new

        // 1 to 1 redirects from the old MSC to the new 
        this.addRedirectRoute('/Account/OrderHistory.aspx', '/account/order-history');
        this.addRedirectRoute('/Brochures', '/products?f-product-format=Brochures');
        this.addRedirectRoute('/CMS/Page/AgentReferral', '/prospecting');
        this.addRedirectRoute('/CMS/Page/Brand_PostersSigns', '/products?f-product-format=Posters');
        this.addRedirectRoute('/CMS/Page/Retention_AnnualAuto', '/retention/annual-auto');
        this.addRedirectRoute('/CMS/Page/Retention_WinBack', '/retention/win-back');
        this.addRedirectRoute('/CMS/Page/TrainingVideos', '/how-to-videos');
        this.addRedirectRoute('/CMS/T1/InsuranceCheckUp', '/retention/insurance-check-up');
        this.addRedirectRoute('/CMS/T1/RelationshipMarketing', '/retention/relationship-marketing');
        this.addRedirectRoute('/CMS/T1/ProspectingAndLeads', '/prospecting');
        this.addRedirectRoute('/Default.aspx', '/home');
        this.addRedirectRoute('/Home/Index', '/home');
        this.addRedirectRoute('/Lists/MovingWithSafecoList.aspx', '/account/agency-lists');
        this.addRedirectRoute('/Lists/MyLists.aspx', '/account/agency-lists');
        this.addRedirectRoute('/pdfs/FAQ_hi-res.pdf', '/products?f-product-search-term=AP-3354');
        this.addRedirectRoute('/Prospecting', '/prospecting'); // Not sure if this is really needed since it is just case change 
        this.addRedirectRoute('/Reporting/', '/reports');
        this.addRedirectRoute('/Retention/AgentReferral.aspx', '/retention/client-referral');
        this.addRedirectRoute('/Retention/AnnualAuto.aspx', '/retention/annual-auto');
        this.addRedirectRoute('/Retention/CrossSell.aspx', '/cross-sell');
        this.addRedirectRoute('/CrossSell/Index', '/cross-sell');
        this.addRedirectRoute('/Retention/MovingWithSafeco.aspx', '/retention/moving');
        this.addRedirectRoute('/Retention/Notecards.aspx', '/retention/relationship-marketing');
        this.addRedirectRoute('/Retention/WinBack.aspx', '/retention/win-back');
        this.addRedirectRoute('/Training/Learn.aspx', '/how-to-videos');
        this.addRedirectRoute('/Brand/BrandedSupplies.aspx', '/marketing');
        this.addRedirectRoute('/Retention/Events.aspx', '/marketing');
        this.addRedirectRoute('/Retention/OnlineContent.aspx', '/marketing');
        this.addRedirectRoute('/CMS/Page/Retention_Newsletters', '/marketing');
        this.addRedirectRoute('/Retention/Newsletters.aspx', '/marketing');
        this.addRedirectRoute('/CMS/T1/AnnualAuto', '/retention/annual-auto');
        this.addRedirectRoute('/CMS/T1/PrintAdvertising', '/marketing');
        this.addRedirectRoute('/CMS/T1/Newsletter', '/marketing');
        this.addRedirectRoute('/CMS/T1/ClientReferralProgram', '/retention/client-referral');
        this.addRedirectRoute('/CMS/T1/WinbackProgram', '/retention/win-back');
        this.addRedirectRoute('/CMS/T1/MovingWithSafeco', '/retention/moving');
        this.addRedirectRoute('/CMS/T1/OnlineContent', '/marketing');
        this.addRedirectRoute('/CMS/Page/Training_Learn', '/how-to-videos');
        this.addRedirectRoute('/CMS/T1/BrandedSupplies', '/marketing');
        this.addRedirectRoute('/CMS/T1/EventsSponsorship', '/marketing');
        this.addRedirectRoute('/CMS/Page/Retention_Notecards', '/marketing');
        this.addRedirectRoute('/CMS/T1/NewCustomerWelcomeKit', '/products?f-product-search-term=AP-5569E');        

        /* After Dave reviewed with safeco these were never filled in but may come down the road.
        this.addRedirectRoute('/Brand/CustomerExperience.aspx', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/Brand/PrintAdvertising.aspx', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/Brand/RadioAdvertising.aspx', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/Brand/WebAdvertising.aspx', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/CMS/Page/Brand_PrintAdvertising', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/CMS/Page/TrackingProgram', '/asdfasdfasdfasdf');
        this.addRedirectRoute('/CMS/T4/QuickStartProgram', '/asdfasdfasdfasdf');
        */

        // This is for deep linking to the report page it looks for a reportId in the query parameter
        // it then matches that based on CONST reportMapping at the top of this file combined with the values in ReportingMainPanel
        // If you want to add any deep links to reports you will need to add it to the CONST reportMapping at the top of this file and match the ID and Name from ReportingMainPanel
        this.addVueRoute({
            path: '/Reporting/Report.aspx',
            beforeEnter: (to, from, next) => {

                const report = reportMapping.find(r => r.oldReportId == to.query.ReportID);

                if (Util.isNotEmpty(report)) {
                    next({
                        name: 'ReportsHome',
                        params: {
                            id: report.id,
                            name: report.name
                        }
                    });
                } else {
                    console.warn("No Route found based on the query parameter.")
                }

            },
        });

        // This should take care of all of the Search / Deeplinks from the old MSC 
        this.addVueRoute({
            path: '/Search.aspx',
            redirect: to => {
                let keywords = to.query.Keywords;
                if (keywords == 'Co-brandedPresentationFolder') {
                    keywords = 'AP-2476;AP-9232'
                }

               
        
                return {
                    name: 'ProductSearch',
                    query: {
                        'f-product-search-term': keywords,
                        'f-program-type': to.query.ProgramTypeCode,
                        'f-product-type': to.query.ProductTypeCode,
                        'f-product-format': to.query.AssetFormatCode,
                        'f-product-category': to.query.CategoryCode
                    }
                }
            }
        });

        // Call the base class, supplying our routes, to actually create and configure the router.
        const router = super.createVueRouter();

        // This one takes care of a majority of the list that all have to do with searches.
        //router.addRoute();

        router.beforeEach(async () => {
            window.scrollTo(0, 0);
        });

        return router;
    }

    /**
    * Refreshes all searches with the specified ID(s).
    * @param {...String} searchIds
    */
    refreshSearches(...searchIds) {
        const index = searchIds.findIndex(item => item === 'products-for-user')
        if (index >= 0) {
            searchIds.splice(index, 1, 'product-list') 
        }
        emitGlobal(EventIDs.SEARCH_REFRESH_ALL, searchIds);
    }
    // #endregion
}
export const DEFAULT_COUNTRY_CODE = "US";
export const site = new Site();
