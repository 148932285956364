<script setup>
    import { onMounted, ref, reactive } from 'vue';
    import { useRoute } from 'vue-router';

    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import ExpressOrderSelectSubCodesPolicy from '@/site/areas/expressorder/ExpressOrderSelectSubCodesPolicy'
    import ExpressOrderSelectPolicy from '@/site/areas/expressorder/ExpressOrderSelectPolicy'

    import { site } from '@/Site';
    import { isNotEmpty } from '@/util';

    const route = useRoute();

    const showPolicy = ref(false);
    const isLoaded = ref(false);
    const bulkOrderState = reactive({});

    async function changeShowPolicy() {
        await loadBulkOrder();
        showPolicy.value = !showPolicy.value;
    }

    async function loadBulkOrder() {
        const bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
        }
    }
    // Lifecycle ----
    onMounted(async () => {
        await loadBulkOrder();
        isLoaded.value = true
    });


</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar />
            <div class="root-page-container " v-if="isLoaded">

                <div class="row">
                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.TWO" />
                    </div>
                </div>

                <div v-if="showPolicy" class="row">
                    <div class="col">
                        <ExpressOrderSelectPolicy @stepTwoSelectAllSelected="changeShowPolicy" :bulkOrderState="bulkOrderState"/>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col">
                        <ExpressOrderSelectSubCodesPolicy @stepTwoSelectAllSelected="changeShowPolicy" :bulkOrderState="bulkOrderState"/>
                    </div>
                </div>
            </div>

        </div>
</PageTemplate>
</template>

<style lang="scss">
    
</style>