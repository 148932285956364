// BlobService
// Services and actions related to blob service.

// Imports ----

import axios from 'axios';

const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
const http = axios.create({ baseURL: BASE_API_URL });
export default class Blob {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
        
    }

    async getBlob(endpoint, annonymous = false) {

        const headers = await this.store.authenticator.getApiHeaders();
        const options = {
            responseType: "blob",
            timeout: 30 * 1000,
            headers: headers
        }
        if (annonymous) {
            delete options.headers.Authorization;
        }

        const response = await http.get(endpoint, options);

        return response;
    }

}
