
<script>

    // ProductGridTile
    // A tile for a single product intended to be displayed in grid mode.

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        tileHeight: '28rem',           // Height of the full tab, fixed for alignment purposes
        productNameLines: 2            // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;

</script>

<script setup>

    // Components ----

    // Imports ----
    import { reactive, defineProps, computed, defineEmits } from 'vue';
    import { productRestrictions } from '@/site/areas/cart/CartPage';
    import { AddToCartForm, Util } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { getUnitOfMeasureFriendlyName } from '@/constants'

    // Props ----
    const props = defineProps({
        // The product whose information to show.
        // API model: DD.Nucleus.Storefront.Api.ProductTileModel
        product: {
            type: Object,
            required: true
        }
    });

    // State ----
    const product = reactive(props.product);
    const form = reactive(new AddToCartForm(props.product, 1));

    // Handlers ----
    const emit = defineEmits(['productSelected', 'customDownloadSelected', 'downloadSelected']);

    // Methods ----
    function productPrice(price) {
        return site.convertNumberToCurrency(price);
    }

    // Computed ----
    const isNotForMA = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORMA)
            return 'NOT FOR USE IN MASSACHUSETTS';
        else
            return '';
    });

    const isNotForCO = computed(() => {
        if (props.product.restriction == productRestrictions.NOTFORCO)
            return 'NOT FOR USE IN COLORADO'
        else
            return ''
    });

    // Handlers ----
    function personalize(productId) {
        emit('productSelected', productId);
    }
    function customizeDownload(productId) {
        emit('customDownloadSelected', productId);
    }
    function download(url,productId) {
        emit('downloadSelected', url, productId );
    }

</script>

<template>

    <div class="product-grid-tile d-flex flex-column h-100" :style="{ 'min-height': setup.tileHeight }">
        <div class="top">
            <ProductThumbnail :product="product" height="200px" no-image-available-url="/images/no-image.png" />
        </div>
        <div class="middle flex-fill">
            <!-- Stretchy middle part-->

            <div class="d-flex flex-row">
                <div class="flex-fill">
                    <ProductTags :product="product" />
                </div>
                <div>
                    <ProductFavoriteIcon :product="product" />
                </div>
            </div>

            <!-- Product name and item number -->
            <router-link class="title-link" :to="`/product/${product.id}`">
                <div class="name" v-snip="{ lines: setup.productNameLines }" :title="product.name">
                    {{ product.name }}
                </div>
            </router-link>
            <p class="description" v-html="product.description" v-snip="{ lines: setup.productNameLines }"></p>
            <div class="item-number"><router-link class="title-link" :to="`/product/${product.id}`">{{ product.itemNumber }}</router-link></div>
            <div class="sale-price mb-2"> {{ productPrice(product.unitPrice) }} {{getUnitOfMeasureFriendlyName(product)}} </div>
            <div class="product-restriction text-danger"> {{ isNotForMA }} </div>
            <div class="product-restriction text-danger"> {{ isNotForCO }} </div>

        </div>
        <div class="bottom">
            <!-- Fixed bottom part -->
            <MessageList v-if="Util.isNotEmpty(product.messages)" :messages="product.messages" :add-box="false" />
            <FormContainer :form="form" v-if="product.canAddToCart">
                <FormMessages field-name="quantity" :add-box="false" class="mb-2" />
                <FieldMessages field-name="quantity" class="mb-2" />
                <ProductAddToCartQty :product="product" />
                <div class="d-grid mt-3">
                    <button class="btn btn-primary" @click="form.submit()" :disabled="form.status.isInvalid">Add To Cart</button>
                </div>
            </FormContainer>

            <div>
                <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                    <button v-if="product.isPersonalizable" @click="personalize(product.id)" class="btn btn-primary w-100 mt-3" :disabled="!product.hasProductDataType"> Personalize </button>
                </ShowOnlyForRoles>
                <button v-if="product.isDownloadble" @click="download(product.downloadableAssetFileUrl, product.downloadableFileName)" class="btn btn-primary w-100 mt-3">Download</button>
                <button v-if="product.isCustomizeDownloadble" @click="customizeDownload(product.id)" class="btn btn-primary w-100 mt-3">Customize</button>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .product-grid-tile {
        .name {
            font-size: 1.0rem;
            font-weight: 600;
            line-height: 1.1rem;
            color: #476791;
        }

        .description {
            font-size: 12px;
            margin-bottom: 0;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .sale-price {
            font-size: 14px;
            font-weight: bold;
        }

        .product-restriction {
            font-size: 14px;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .btn[disabled] {
            opacity: 0.2;
        }

        .n-message.text {
            font-size: 14px;
        }
    }
</style>