<script setup>

    // ReportsDetails
    // Get the report content.

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';

    import { onMounted, reactive, ref } from 'vue';
    import * as pbi from 'powerbi-client';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    //const reportDetail = reactive({});
    const reportToken = reactive({});

    const userInfo = site.user;
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);


    const route = useRoute();
    // Computed
    const showReport = ref(false);

    const errorMessage = reactive([]);
    const bridgeUserName = ref('');

    //functions

    function embedReport(embedData, isDashboard) {
        if (userInfo) {
            const userName = bridgeUserName.value;
            let agencyNumber = ' '
            if (selectedAgencyDetails && selectedAgencyDetails.statId) {
                agencyNumber = selectedAgencyDetails.statId;
            }
            let embedUrl = embedData.embedReport[0].embedUrl;
            embedUrl = `${embedUrl}&rp:UserName=${userName}&rp:AgencyNumber=${agencyNumber}`;
            const config = {
                type: isDashboard ? 'dashboard' : 'report',
                tokenType: pbi.models.TokenType.Embed,
                accessToken: embedData.embedToken.token,
                embedUrl: embedUrl,
                permissions: pbi.models.Permissions.All,
                settings: {
                    background: pbi.models.BackgroundType.Transparent,
                    filterPaneEnabled: true,
                    navContentPaneEnabled: true
                }
            };

            // Get a reference to the embedded report HTML element
            const embedContainer = document.getElementById('container');

            // Embed the report and display it within the div container.
            const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
            const report = powerbi.embed(embedContainer, config);

            // Report.off removes a given event handler if it exists.
            report.off("loaded");

            // Report.on will add an event handler which prints to Log window.
            report.on("loaded", function () {
                //Add any logic if is required
            });

            // Report.off removes a given event handler if it exists.
            report.off("rendered");

            // Report.on will add an event handler which prints to Log window.
            report.on("rendered", function () {
                //Add any logic if is required
            });

            report.on("error", function () {
                report.off("error");
            });
        }
    }

    onMounted(async () => {

        if (userInfo) {
            if (userInfo.userName.indexOf(".Test@test.com")) {
                const bridgeUserAccount = await site.userFromBridge.getBridgeUserAccount();
                if (bridgeUserAccount) {
                    bridgeUserName.value = bridgeUserAccount.username;
                }
                else {
                    bridgeUserName.value = userInfo.userName.replace(".Test@test.com", '');
                }
            }
            else {
                bridgeUserName.value = userInfo.userName.replace(".Test@test.com", '');
            }
        }

        Object.assign(reportToken, await site.reporting.getEmbedToken(route.params.id));

        if (reportToken && reportToken.embeddedParams) {
            showReport.value = true;
            embedReport(reportToken.embeddedParams, false);
        } else {
            if (reportToken && reportToken.messages && reportToken.messages.length) {
                errorMessage.value = reportToken.messages;
            }
        }

        // For Adobe analytics
        await site.analyticsHandler.push({ "tileCode": route.params.name, "lob": "Reports", "productNames": "MSC_" + route.params.name });
    });
</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.REPORTS_TAB_ID" />
            <div class="root-page-container reporting-details-page">
                <div class="row my-2">
                    <div class="col">
                        <router-link to="/reports" class="no-underline"><i class="bi bi-chevron-left"></i> Back to Reports</router-link>
                    </div>
                </div>
                <div class="row">
                    <div id="container" style="width:100%;height:800px;" v-show="showReport"></div>
                    <div v-if="errorMessage && errorMessage.length > 0">
                        <div class="alert alert-danger alert-dismissable fade show mb-0"
                             role="alert">
                            <strong>{{ errorMessage }}</strong>
                            <button type="button"
                                    class="close"
                                    data-dismiss="alert"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>