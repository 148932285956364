<script setup>
    import { onMounted, reactive, ref, defineEmits, defineProps, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { site } from '@/Site';
    import { formatStatCode } from '@/util'

    const route = useRoute();
    const router = useRouter();
    const allSubCodes = reactive({});
    const selectedSubCodes = reactive([]);
    const isLoaded = ref(false);
    const emit = defineEmits(['stepTwoSelectAllSelected']);

    //props
    const props = defineProps({
        bulkOrderState: {
            type: Object,
            required: true
        }
    });
    //computed
    const oppId = computed(() => {
        return props.bulkOrderState?.oppId;
    });
    // Lifecycle ----
    onMounted(async () => {

        // Load All SubCode for an OM
        let subcodes = await site.agencyDetails.getAgencySubCodes(props.bulkOrderState.oppId)
        subcodes.forEach((obj) => {
            allSubCodes[obj.statId] = obj
        })

        // Get all the subcode which OM has selected
        const subCodesByOM = props.bulkOrderState.subCodesDetails?.map(obj => {
            const agencyDetails = allSubCodes[obj.statCode];
            const orderState = props.bulkOrderState.orderStates.find(os => os.statCode == obj.statCode);
            return {
                statId: obj.statCode,
                selectAllPolicyHolders: obj.selectAllPolicyHolders,
                agencyName: agencyDetails.agencyName,
                policyHoldersCount: orderState.targetCount
            }
        })

        Object.assign(selectedSubCodes, subCodesByOM);
        isLoaded.value = true

    });

    function selectAll() {
        selectedSubCodes.forEach(item => {
            item.selectAllPolicyHolders = true;
            item.policyHoldersCount = allSubCodes[item.statId].policyHoldersCount;
        })
    }

    function previous() {
        router.push({
            name: 'ExpressOrderStepOne',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId
            }
        });
    }

    async function next() {
        const isSelfSelect = selectedSubCodes.filter(ssc => !ssc.selectAllPolicyHolders);

        let result = selectedSubCodes.map(item => {
            return {
                statCode: item.statId,
                selectAllPolicyHolders: item.selectAllPolicyHolders
            }
        });

        const payload = {
            id: props.bulkOrderState.id,
            subCodesDetails: result
        }
        await site.expressOrderPersonalizationInfo.updateSubCodesDetails(payload);

        if (isSelfSelect.length > 0) {
            emit("stepTwoSelectAllSelected");
        } else {
            router.push({
                name: 'ExpressOrderStepThree',
                params: {
                    bulkOrderStateId: props.bulkOrderState.id,
                }
            });
        }
    }
</script>

<template>

    <div class="row mt-5" v-if="isLoaded">

        <div class="col-12 text-center">
            <h3>Select policyholder(s)</h3>
        </div>


        <div class="col-1"></div>
        <div class="col-10">
            <div class="select-all">
                <p @click="selectAll">Send to all policyholders for all sub codes</p>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Sub Codes</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">PolicyHolder(s)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in selectedSubCodes" :key="item.statId">
                        <td scope="row">
                            <router-link class="hover-underline" :to="`/cross-sell/view-detail/${oppId}/${item.statId}`">View List</router-link>
                        </td>
                        <td>{{ formatStatCode(item.statId)}} - {{ item.agencyName }}</td>
                        <td>{{ item.policyHoldersCount }}</td>
                        <td class="d-flex">
                            <div class="d-flex mx-5">
                                <input type="radio" v-model="item.selectAllPolicyHolders" :value=true />
                                <label>Select All</label>
                            </div>
                            <div class="d-flex">
                                <input type="radio" v-model="item.selectAllPolicyHolders" :value=false />
                                <label>Self Select</label>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="d-flex mb-3">
                <div class="me-auto">
                    <button class="m-2 btn btn-outline-primary" @click="previous">Previous</button>
                </div>
                <div>
                    <button class="m-2 btn btn-primary" @click="next">Next</button>
                </div>
            </div>
        </div>
        <div class="col-1"></div>



    </div>

</template>

<style lang="scss">
    .select-all {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;

        p {
            font-weight: bold;
            color: blue;
            cursor: pointer;
            font-size: 14px;
        }
    }

    tbody {
        border-top: 0px !important;
    }

    th, td {
        font-size: 14px;
        padding: 0.75rem !important;
    }

    .d-flex label, .d-flex input {
        margin-left: 8px;
    }
</style>