<script setup>
    import { onMounted, ref, reactive, computed } from 'vue';

    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectAgencySubCodes from '@/site/areas/expressorder/SelectAgencySubCodes';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    import { isNotEmpty, isEmpty } from '@/util';
    import { ProductRestrictions, PunchOutActions, SubCodeStatus } from '@/constants';
    import { useRoute } from 'vue-router';
    import { site } from '@/Site';

    //    const router = useRouter();
    const route = useRoute();
    const activeAgency = reactive({
        statCode: '',
        name: '',
        policyHoldersCount:0
    });
    const isAppliedForAll = ref(false)
    const isLoaded = ref(false);
    const bulkOrderState = reactive({});
    const orderStates = reactive([]);
    const allSubCodes = reactive([]);
    const product = reactive({});
    const iframeSrc = ref('');
    const frameHeight = ref(1000);
    const showIframe = ref(false);


    //Computed
    const agencySubCodes = computed(() => {
        const sortedOrderStates = getSortedOrderStates();
        const subcodes = [];
        for (let i = 0; i < sortedOrderStates.length; i++) {
            const agencySubCode = allSubCodes.find(asc => asc.statId == sortedOrderStates[i].statCode);
            subcodes.push({
                statId: sortedOrderStates[i].statCode,
                status: isNotEmpty(sortedOrderStates[i]?.pageFlexDocumentId) ? SubCodeStatus.COMPLETED : SubCodeStatus.NONE,
                agencyName: agencySubCode?.agencyName,
                policyHoldersCount: agencySubCode?.policyHoldersCount
            });
        }
        return subcodes;
        /*const subcodes = [];
        for (let i = 0; i < 20; i++) {
            subcodes.push({
                statId: `STATCODE-${i}`,
                status: SubCodeStatus.NONE,
                agencyName: `AGENCYNAME-${i}`
            });
        }
        return subcodes;
        */
    });

    const activeAgencyIndex = computed(() => {
        return agencySubCodes.value.findIndex(asc => asc.statId == activeAgency.statCode) + 1;
    });

    const totalSubCodes = computed(() => {
        return agencySubCodes.value.length;
    });

    /*const allSubcodesPersonalized = computed(() => {
        if (orderStates) {
            return orderStates.filter(os => isEmpty(os.pageFlexDocumentId)).length == 0;
        }
        return false;
    });*/
    const samePersonalization = computed(() => {
        return bulkOrderState?.isSamePersonalization;
    })

    const disablePersonalization = computed(() => {
        return orderStates?.filter(os => isEmpty(os.pageFlexDocumentId))?.length > 0
    });

    const nextOrderState = computed(() => {
        const orderState = {
            current: {},
            isLastRecord: false
        };

        const sortedOrderStates = getSortedOrderStates();
        if (isNotEmpty(sortedOrderStates)) {
            if (sortedOrderStates.length == 1 || samePersonalization.value) {
                const newOrderStateObj = {
                    current: sortedOrderStates[0],
                    isLastRecord: true
                }
                Object.assign(orderState, newOrderStateObj);
                return orderState;
            }
            const currentOrderStateId = route.params.orderStateId;
            if (isNotEmpty(currentOrderStateId)) {
                let currentStatCodeIndex = sortedOrderStates.findIndex(sc => sc.id == currentOrderStateId);
                if (currentStatCodeIndex >= 0 && currentStatCodeIndex < sortedOrderStates.length - 2) {
                    Object.assign(orderState.current, sortedOrderStates[currentStatCodeIndex + 1]);
                    return orderState;
                }
                else if (currentStatCodeIndex == sortedOrderStates.length - 2) {

                    const newOrderStateObj = {
                        current: sortedOrderStates[currentStatCodeIndex + 1],
                        isLastRecord: true
                    }

                    const isPersonalizedExceptCurrent = sortedOrderStates.filter(os =>
                        (os.id != sortedOrderStates[currentStatCodeIndex + 1].id) && isEmpty(os.pageFlexDocumentId)
                    );
                    
                    if (isPersonalizedExceptCurrent.length > 0) {
                        newOrderStateObj.isLastRecord = false;
                    }

                    Object.assign(orderState, newOrderStateObj);
                    return orderState;
                } 
            }
            Object.assign(orderState.current, sortedOrderStates[0]);
            return orderState;
        }
        return orderState;
    });

    //Methods
    function getSortedOrderStates() {
        const unSortedOrderStates = [];
        Object.assign(unSortedOrderStates, orderStates);
        return unSortedOrderStates.sort((a, b) => a.statCode - b.statCode);
    }

    /*function isLastRecordSelectedByOrderStateId(orderStateId) {
        const sortedOrderStates = getSortedOrderStates();
        let currentStatCodeIndex = sortedOrderStates.findIndex(sc => sc.id == currentOrderStateId);
        return currentStatCodeIndex == sortedOrderStates.length - 2;
    };*/

    function isLastRecordSelectedByStatCode(statCode) {
        const sortedOrderStates = getSortedOrderStates();
        let currentStatCodeIndex = sortedOrderStates.findIndex(sc => sc.statCode == statCode);
        return currentStatCodeIndex == sortedOrderStates.length - 2;
    }

    async function changeActiveSubCode(code) {
        const selectedOrderState = bulkOrderState.orderStates.find(os => os.statCode == code);
        if (isNotEmpty(selectedOrderState)) {
            const agencySubCode = allSubCodes.find(asc => asc.statId == code);
            activeAgency.statCode= selectedOrderState.statCode;
            activeAgency.name = agencySubCode?.agencyName;
            activeAgency.policyHoldersCount = agencySubCode?.policyHoldersCount;
            const currentStep = true;
            const orderState = {
                current: selectedOrderState,
                isLastRecord: isLastRecordSelectedByStatCode(code)
            }
            await getTicket(currentStep, orderState);
        }
    }

    function onLoadIframe() {
        frameHeight.value = window.frames.outerHeight + 100;
    }


    async function getTicket(currentStep, selectedOrderState) {
        showIframe.value = false;
        const BASE_API_URL = process.env.VUE_APP_PAGE_FLEX_RETURN_URL;
        let returnUrl = '';
        let orderState = nextOrderState.value;

        if (isNotEmpty(selectedOrderState)) {
            orderState = selectedOrderState;
        }

        if (isNotEmpty(orderState.current)) {
            if (isNotEmpty(orderState.current.id)) {
                returnUrl = `${BASE_API_URL}expressorder/pageflex-return/${bulkOrderState.id}/${orderState.current.id}?isLastRecord=${orderState.isLastRecord}`;
            } else {
                returnUrl = `${BASE_API_URL}expressorder/pageflex-return/${bulkOrderState.id}?isLastRecord=${orderState.isLastRecord}`;
            }
            //By default, if we are at the end of the last statcode, we will go to the next step
            //If we dont want to do that and stay on the current step. populate this value as true.
            if (isNotEmpty(currentStep)) {
                returnUrl = `${returnUrl}%26currentStep=${currentStep}`;
            }
            if (isNotEmpty(samePersonalization.value)) {
                returnUrl = `${returnUrl}%26isSamePersonalization=${samePersonalization.value}`;
            }
            const userInfo = site.user;
            const userName = userInfo.userName.replace(".Test@test.com", '');
            const agencyDetails = await site.agencyDetails.getAgencyDetails(orderState.current.statCode);

            const agencyName = isNotEmpty(agencyDetails.agencyName) ? encodeURIComponent(agencyDetails.agencyName) : agencyDetails.agencyName;
            const firstName = isNotEmpty(userInfo.firstName) ? encodeURIComponent(userInfo.firstName) : userInfo.firstName;
            const lastName = isNotEmpty(userInfo.lastName) ? encodeURIComponent(userInfo.lastName) : userInfo.lastName;
            const address1 = isNotEmpty(agencyDetails.address1) ? encodeURIComponent(agencyDetails.address1) : agencyDetails.address1;
            const address2 = isNotEmpty(agencyDetails.address2) ? encodeURIComponent(agencyDetails.address2) : agencyDetails.address2;
            const city = isNotEmpty(agencyDetails.city) ? encodeURIComponent(agencyDetails.city) : agencyDetails.city;


            let payload = {
                UserName: userName,
                ReturnUrl: returnUrl,
                AgencyName: agencyName,
                AgentName: firstName,
                AgentLastName: lastName,
                AgentAddress: address1,
                AgentAddress2: address2,
                AgentCity: city,
                AgentZip: agencyDetails.zip,
                AgentState: agencyDetails.state,
                AgentPhone: agencyDetails.phone,
                AgentEmail: agencyDetails.emailAddress,
                DocumentCode: product.itemNumber,
                PHONE: agencyDetails.phone,
                NextStepText: !orderState.isLastRecord ? "Go To Next Sub Code" : "",
            }

            if (samePersonalization.value) {
                Object.assign(payload, { CloneDocuments: (orderStates.length - 1)+"" });
            }

            if (product && product['attributes'] && product.attributes['restriction'] == ProductRestrictions.NOTFORMA) {
                Object.assign(payload, { NotForMA: true });
            }

            if (product && product['attributes'] && product.attributes['restriction'] == ProductRestrictions.NOTFORCO) {
                Object.assign(payload, { NotForCO: true });
            }

            if (!orderState.current.pageFlexDocumentId) {
                Object.assign(payload, { DocumentId: product.itemNumber });
                Object.assign(payload, { Action: PunchOutActions.CREATE });
            } else {
                Object.assign(payload, { Action: PunchOutActions.EDIT });
                Object.assign(payload, { DocumentId: orderState.current.pageFlexDocumentId });
            }

            const response = await site.personalizationInfo.getPunchoutTicket(payload);

            const ticketId = response.ticketId.replace("OK:", "");

            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;

            iframeSrc.value = `${BASE_PUNCHOUT_URL}/UserPMCreateEdit.aspx?ticket=${ticketId}`;

            showIframe.value = true;
        }
        else {

            //TODO.. Need to report an error???
        }
    }
    // Lifecycle ----
    onMounted(async () => {
        const bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
            Object.assign(product, await site.products.getProductDetail(bulkOrderState.productId));
            Object.assign(allSubCodes, await site.agencyDetails.getAgencySubCodes(bulkOrderState.oppId));
            isAppliedForAll.value = bulkOrderState.isSamePersonalization;
            Object.assign(orderStates, bulkOrderState.orderStates);
            const agencySubCode = allSubCodes.find(asc => asc.statId == nextOrderState.value.current.statCode);
            activeAgency.statCode = nextOrderState.value.current.statCode;
            activeAgency.name = agencySubCode.agencyName;
            activeAgency.policyHoldersCount = agencySubCode.policyHoldersCount;
            await getTicket(null, null);
        }
        isLoaded.value = true
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar />
            <div class="root-page-container " v-if="isLoaded">

                <div class="row">
                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.ONE" />
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col select-agency-subcodes">
                        <SelectAgencySubCodes :selectAll="isAppliedForAll"
                                              :subCodes="agencySubCodes"
                                              :activeAgency="activeAgency"
                                              @selected="changeActiveSubCode">
                            <div class="heading-slot">
                                <div>
                                    <p class="">
                                        <span class="subcode-header">Selected agency Sub Codes</span>
                                        <template v-if="isAppliedForAll">
                                            <span class="subcode-page"> ({{totalSubCodes}})</span>
                                        </template>
                                        <template v-else>
                                            <span class="subcode-page"> ({{activeAgencyIndex}} of {{totalSubCodes}})</span>
                                        </template>
                                    </p>
                                </div>
                                <div class="text-right">
                                    <router-link :to="`/expressorder/subcodes/${bulkOrderState.id}`" class="m-2 btn btn-outline-primary change-subcode">Change Sub Codes Selected</router-link>
                                    <router-link :to="`/expressorder/step-2/${bulkOrderState.id}`" class="m-2 btn btn-primary btn-personalization" :class="{ disabled: disablePersonalization}" v-if="!samePersonalization">Done Personalization</router-link>
                                </div>
                            </div>
                        </SelectAgencySubCodes>


                        <p class="agency-info" v-show="isAppliedForAll">The Agency and Agent Information below will be the same for all selected Codes.</p>
                    </div>
                </div>

            </div>
            <div class="row" v-if="showIframe">

                <div class="col">

                    <embed name="punchout" @load="onLoadIframe" :src="iframeSrc" scrolling="auto" width="100%" frameborder="0" :height="frameHeight" />

                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .heading-slot {
        display: flex;
        justify-content: space-between;

        p {
            font-weight: bold;
            font-size: 14px;
        }

        .subcode-header {
            color: $color-1 !important;
        }

        button {
            font-size: 12px;
            border-radius: 6px;
            font-weight: bold;
        }
    }

    .agency-info {
        text-align: center;
        font-weight: bold;
        color: red;
        font-size: 16px;
        margin: 16px 0px;
    }

    .select-agency-subcodes {
        .subcode-page {
            font-size: 12px;
            color: $gray-3;
        }

        .change-subcode {
            font-size: 12px;
        }

        .btn-personalization {
            font-size: 12px;
            background-color: $color-1;
            color: $gray-0;
        }
    }
</style>