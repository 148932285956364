<script setup>
    
    //MWSAcknowledgementPage
    // Imports
    import { ref, onMounted } from 'vue';
    import GenericHeader from '@/template/components/GenericHeader';
    import GenericFooter from '@/template/components/GenericFooter';
    import { useRoute } from 'vue-router';
   
    const route = useRoute();
    const email = ref('');

    // Handlers ----
    onMounted(async () => {
       email.value = route.params.id; 
    })

</script>

<template>
    <GenericHeader>
        <template v-slot:subHeader>
            <div class="optout-head-bar col-8">
                <p class="mws-header">Moving With Safeco</p>
            </div>
        </template>
    </GenericHeader>
    
    <div class="container root-page">
        <div class="row mt-3">
            <div class="col-sm-8 offset-sm-2 text-center">

                <p>We have received and processed your request to opt out the email address: </p>
                <p><b> {{email}}</b></p>
                <p>from:</p>
                <p><b>Moving with Safeco</b></p>
                <p>Offers may have been sent prior to your remove request, you may receive offers up to 10 business day from the day you requested to be removed.</p>

            </div>
        </div>
        
    </div>
    <GenericFooter />

</template>

<style lang='scss'>
    .optout-head-bar {
        text-align: left;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2rem;
    }

    .mws-header {
        color: white;
        font-size: 38px;
        padding-left: 1rem;
        font-family: Calibri light;
    }
</style>