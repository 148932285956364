<script setup>
// OrderDetailsPanel
    // Shows order details in the my account area.

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 50
    }

    const pageSizeOptions = [25, 50, 100, 200];

    const columns = [
        { field: 'mailingDate', title: 'Send Date', isSortable: true, custom: true, formatter: (value) => Util.date(value) },
        { field: 'marketingChannel', title: 'Marketing Channel', custom: true, isSortable: false },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'addressLine1', title: 'Address 1', isSortable: true },
        { field: 'addressLine2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'postalCode', title: 'Zip', isSortable: true },
        { field: 'phone', title: 'Phone', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true },
        { field: 'listExpirationDate', title: 'List Expiration Date', isSortable: true, custom: true, formatter: (value) => Util.date(value, false) },
    ];

    // Import ----
    import { computed, ref } from 'vue';
    import { Search, Util } from '@dd-nucleus/nucleus-vue';
    import { ExportParser } from "@/site/services/ExportParser";
    import { useRoute, useRouter } from 'vue-router'
    import { formatStatCode, getFieldsMapperFromColumns, isNotEmpty, beforeTableEncode } from '@/util';

    // Components ----
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    const route = useRoute()
    const router = useRouter();

    const search = new Search('order-history-details-list', 'detail', 'order-detail-list-contact-mailingDate', constants.DEFAULT_PAGE_SIZE);
    //search.setFilterInitialValue('order-details-list-id', route.params.id);
    const searchDownload = new Search('order-history-details-list', 'detail', 'order-detail-list-contact-mailingDate', null);

    const searchTerm = ref('mailDate-desc');
    const sortPrefix = 'order-detail-list-contact-';

    const fieldsMapper = getFieldsMapperFromColumns(columns);

    // Computed
    const orderHeader = computed(() => {
        if (search.results && search.results.rows && search.results.rows.length > 0) {
            return search.results.rows[0];
        }
        return {};
    });

    const dropNumber = computed(() => {
        if (isNotEmpty(orderHeader.value)) {
            const drop = orderHeader.value.drop;
            return drop ? drop.replace("drop number ", "") : "";
        }
        return ""
    });

    const data = computed(() => {
        if (searchDownload.results && searchDownload.results.rows && searchDownload.results.rows.length > 0) {
            return searchDownload.results.rows;
        }
        return [];
    });

    function getExportedData() {
        return data.value.map(item => ({
            ...item,
            marketingChannel: item.mailingDate ? "Direct Mail" : ""
        }));
    }

    async function exportCSV() {
        await setFilterValues();
        ExportParser().exportCSVFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(columns));
    }

    async function exportXLS() {
        await setFilterValues();
        ExportParser().exportXLSFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(columns));
    }

    async function setFilterValues() {

        const orderDetailsfilterValues = search.getFilterValues("order-details-list-order-num");
        if (!searchDownload.filterValueExists("order-details-list-order-num", orderDetailsfilterValues)) {
            await searchDownload.setFilterValues("order-details-list-order-num", orderDetailsfilterValues);
        }

        const prospectCampaignFilterValues = search.getFilterValues("order-details-list-id");
        if (!searchDownload.filterValueExists("order-details-list-id", prospectCampaignFilterValues)) {
            await searchDownload.setFilterValues("order-details-list-id", prospectCampaignFilterValues);
        }

        await searchDownload.activate(route);
    }

    function back() {
        if (isNotEmpty(route.query['f-order-details-list-id'])) {
            router.push("/home?tab=Prospecting")
        } else {
            router.go(-1);
        }
    }
    function sortSearch(value) {
        value = sortPrefix + value

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }
</script>

<template>
    <div class="account-panel order-details-panel">
        <div class="row mt-2">
            <div class="col">
                <a href="#" @click="back" class="no-underline"><i class="bi bi-chevron-left"></i>Back</a>
            </div>
        </div>
        <div class="row">
            <div class="me-auto">
                <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Order History</span></h1>
            </div>
        </div>
        <div v-if="isNotEmpty(orderHeader.value)" class="order-details-header">
            <div class="row mt-3 p-2">
                <div><strong>{{Util.date(orderHeader.orderDate)}}</strong> <span class="mr-2">&nbsp;|&nbsp;</span><span class="order-number">Order #: {{orderHeader.orderNumber}}</span></div>
            </div>
            <div class="row mb-3 p-2">
                <div class="col-6">
                    <div><strong>Agency Name:</strong> {{orderHeader.agencyName}}</div>
                    <div><strong>Agency #:</strong> {{formatStatCode(orderHeader.statId)}}</div>
                    <div><strong>Drop #:</strong> {{dropNumber}}</div>
                </div>
                <div class="col-6">
                    <div><strong>Asset Name:</strong> {{orderHeader.productName}}</div>
                    <div><strong>Asset Number:</strong> {{orderHeader.productNumber}}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <SearchContainer :search="search" default-view="list" :deep-link="true">
                <div class="d-flex">
                    <div class="me-auto">&nbsp;</div>
                    <div>
                        <i class="bi bi-file-earmark-excel me-3 fs-1 hand" title="Download in Excel format" @click="exportXLS"></i>
                        <i class="bi bi-filetype-csv fs-1 hand" title="Download in CSV format" @click="exportCSV"></i>
                    </div>
                    <div class="px-3">
                        <PageSizeList :options="pageSizeOptions" />
                    </div>
                    <div>
                        <div>
                            <span v-if="search.results.loaded && search.results.rows.length > 0">Showing {{ search.results.startIndex }} of {{ search.results.totalCount }}</span>
                        </div>
                        <PageSelector />
                    </div>
                </div>
                <div class="data-table">
                    <SearchTable :columns="columns" @sort-by="sortSearch">
                        <template v-slot:mailingDate="row">
                            <template v-if="row.mailingDate">
                                {{Util.date(row.mailingDate, false)}}
                            </template>
                        </template>

                        <template v-slot:listExpirationDate="row">
                            <template v-if="row.listExpirationDate">
                                {{Util.date(row.listExpirationDate, false)}}
                            </template>
                        </template>

                        <template v-slot:marketingChannel="row">
                            <div v-if="row.mailingDate">Direct Mail</div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            No
                            <span v-html="listHeadline"></span> Lists were found that match your criteria.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTable>
                </div>
                <div class="d-flex">
                    <div class="flex-fill text-end"><PageSelector /></div>
                </div>
            </SearchContainer>
        </div>
    </div>
</template>

<style lang="scss">
    .order-details-header {
        background: $gray-10;
    }

    .order-number {
        color: $color-1
    }

    .hand {
        cursor: pointer
    }
</style>