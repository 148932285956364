<script setup>

    // CalendarPanel
    // Shows the agency lists panel of the my account area.

    // Imports ----
    import { ref, computed } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import ModalBox from '@/site/components/ModalBox';
    import { site } from '@/Site';
    import { ExportParser } from "@/site/services/ExportParser";
    import { getFieldsMapperFromColumns, beforeTableEncode } from '@/util';


    // Components ----

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 50,
    }

    const columns = [
        { field: 'actions', title: 'Actions', isSortable: false, custom: true },
        { field: 'name', title: 'List Name', isSortable: true },
        { field: 'mailListContactsCount', title: 'Record Count', isSortable: false },
        { field: 'created', title: 'Creation Date', custom: true, isSortable: true },
    ];

    const agencyRecipientsColumns = [
        { field: "firstName", title: "First Name" },
        { field: "lastName", title: "Last Name" },
        { field: "addressLine1", title: "Address Line 1" },
        { field: "addressLine2", title: "Address Line 2" },
        { field: "city", title: "City" },
        { field: "state", title: "State" },
        { field: "postalCode", title: "Zip" },
    ]

    const searchTerm = ref('name');
    const mailListContacts = ref([]);

    const hasContacts = computed(() => {
        if (mailListContacts.value.length > 0)
            return true;
        return false;
    })

    const previewList = computed(() => {
        return mailListContacts.value;
    });


    // Components ----


    // State ----
    let sortPrefix = 'mail-list-';


    // Handlers ----
    const search = new Search('mail-list', 'row', 'mail-list-name', constants.DEFAULT_PAGE_SIZE);

    // Methods ----
    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = sortPrefix + value;
            } else {
                searchTerm.value = sortPrefix + value + '-desc';
            }

        } else {
            searchTerm.value = sortPrefix + value;
        }

        search.setSortId(searchTerm.value);
    }

    async function loadMailRecipients(mailId) {
        const response = await site.personalizationInfo.getMailListContactsById(mailId);
        if (response.succeeded) {
            mailListContacts.value = response.mailListModel.mailContacts;
            this.viewRecipients();
        }
    }

    async function downloadMailRecipients(mailId, filename) {
        const response = await site.personalizationInfo.getMailListContactsById(mailId);
        if (response.succeeded) {
            const recipients = response.mailListModel.mailContacts;
            const fieldsMapper = getFieldsMapperFromColumns(agencyRecipientsColumns);
            ExportParser().exportCSVFromJSON(recipients, filename, null, fieldsMapper, beforeTableEncode(agencyRecipientsColumns));
        }
    }

    async function deleteMailRecipients(mailId, filename) {
        let text = `Are you sure you want to delete ${filename} list ?`
        if (confirm(text)) {
            await site.personalizationInfo.deleteMailListContactsById(mailId);
            await search.refresh();
        }
    }

</script>

<template>
    <ModalBox modalId="viewRecipients" title="Agency Recipients" modalSize="modal-xl">
        <template v-slot:body>
            <div class="row mb-5 mt-2">
                <div class="col">

                    <div class="address-list  table-responsive" v-if="hasContacts">
                        <template v-if="previewList.length > 0">
                            <table class="table table-striped w-auto mw-100">
                                <thead class="title">
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Address Line 1</th>
                                        <th>Address Line 2</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Zip</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="address in previewList" :key="address">
                                        <td class="abbreviation">{{address.firstName}}</td>
                                        <td class="abbreviation">{{address.lastName}}</td>
                                        <td class="abbreviation">{{address.addressLine1}}</td>
                                        <td class="abbreviation">{{address.addressLine2}}</td>
                                        <td class="abbreviation">{{address.city}}</td>
                                        <td class="abbreviation">{{address.state}}</td>
                                        <td class="abbreviation">{{address.postalCode}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                        <div v-else>
                            <slot name="empty"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>

    <div class="account-panel agency-lists-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Uploaded Agency Lists</span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="false">
            <div class="data-table">
                <SearchTable :columns="columns" @sort-by="sortSearch">
                    <template v-slot:actions="row">
                        <div class="d-flex flex-row">
                            <div class="px-2 action-item" @click="loadMailRecipients(row.mailListId)" data-bs-toggle="modal" data-bs-target="#viewRecipients">View</div>
                            <div class="px-2 action-item" @click="downloadMailRecipients(row.mailListId, row.name)">Download</div>
                            <div class="px-2 action-item" @click="deleteMailRecipients(row.mailListId, row.name)">Delete</div>
                        </div>
                    </template>
                    <template v-slot:created="row">
                        {{formatDate(row.created)}}
                    </template>
                </SearchTable>
            </div>
            <SearchGrid>
                
                <template #empty>
                    <div>
                        You do not currently have any uploaded lists.
                    </div>
                </template>
     
            </SearchGrid>
        </SearchContainer>
    </div>
</template>

<style lang="scss">
    .action-item {
        cursor: pointer;
        color: $color-2;
    }
</style>