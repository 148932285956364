<script setup>

    // ProspectingSelectMailScheduleStep3Page
    // Determines the mail schedule for sending mailings

    // Imports 
    import { ref } from 'vue';

    // Constants 
    const mailingType = {
        ONE_TIME: 'one time',
        SEVERAL_DATES: 'several dates'
    }

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // State 
    const howToSendMailing = ref("");

    // Methods
    function sendMailing() {
        // Add the API call for how to send the mailing 
    }

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />

            <div class="root-page-container prospecting-select-select-mail-schedule">

                <div class="row my-5">

                    <div class="col">

                        <SelectMailListStepper :currentStep="stepCounter.THREE" />

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col text-center mt-5">

                        <h1>How would you like your mailing to be sent?</h1>

                    </div>

                </div>

                <div class="row">

                    <div class="col-sm-4 offset-sm-4">

                        <div class="form-check mt-3">
                            <input class="form-check-input" v-model="howToSendMailing" :value="mailingType.ONE_TIME" type="radio" name="howToSendMailing" id="oneTime">
                            <label class="form-check-label" for="oneTime">
                                Send to prospects at one time
                            </label>
                        </div>
                        <div class="form-check my-3">
                            <input class="form-check-input" v-model="howToSendMailing" :value="mailingType.SEVERAL_DATES" type="radio" name="howToSendMailing" id="severalDates">
                            <label class="form-check-label" for="severalDates">
                                Send to prospects over several dates
                            </label>
                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col text-center">

                        <button type="submit" class="btn btn-primary" @click="sendMailing">Next</button>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <!-- TODO: Route to the previous page -->
                        <button type="submit" class="btn btn-outline-primary">Previous</button>

                    </div>

                </div>

            </div>

        </div>

</PageTemplate>

</template>

<style lang="scss">
    .prospecting-select-select-mail-schedule {
    }
</style>