<script setup>

    // ProspectingStep2ZipCode
    // Step 2 on selecting by zipcode

    // Imports
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import ModalBox from '@/site/components/ModalBox';
    //import ZipCodeLookup from '@/site/components/ZipCodeLookup';

    // Constants 
    const zipRadius = {
        ZERO: '0 Miles',
        FIVE: '5 Miles',
        TEN: '10 Miles',
        FIFTEEN: '15 Miles',
        TWENTY_FIVE: '25 Miles'

    }

    const router = useRouter();

    //State 
    const postalCodes = ref("");
    const postalRadius = ref(zipRadius.ZERO);
    const crossState = ref(false);
    const showPricing = ref(false);

    // Methods
    function submit() {
        // TODO: Submit this data to an API 
        showPricing.value = true;
    }
    /*
    function setSelectedZipCodes(zipCodes) {
        // TODO: call the API with the selected Zipcodes 
        console.log("zipCodes from modal: ", zipCodes)
    }
    */

    function saveForLater() {
        // TODO: call the API to save this for later 
    }

    function next() {
        // TODO: call the API to capture data from this page and move to the next
        router.push({ name: 'ProspectingStep3SelectMailSchedulePage' });
    }


</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <ModalBox modalId="zipCodeLookUp" title="ZIP Code Look Up" modalSize="modal-lg">

                <template v-slot:body>

                    <!--<ZipCodeLookup @selectedZips="setSelectedZipCodes"/>-->

                </template>

            </ModalBox>

            <div class="root-page-container prospecting-step2-zip-code">

                <div class="row">

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.TWO" />

                    </div>

                </div>

                <div class="row my-5">
                    <div class="col-sm-6 offset-sm-3 text-center bg-color-3-xlt py-3 zip-code-lookup">
                        <button class="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#zipCodeLookUp">
                            Zip Code Lookup
                        </button>
                        <div class="py-4 d-flex align-items-center">
                            <hr class="flex-grow-1" />
                            <div class="px-5">OR</div>
                            <hr class="flex-grow-1" />
                        </div>
                        <div class="mb-5 text-start">
                            <label class="form-label"><strong>ZIP Code(s)</strong> (Separate by commas)</label>
                            <input type="text" v-model="postalCodes" class="form-control">
                        </div>
                        <div class="mb-5 text-start">
                            <label class="form-label fw-bold">ZIP Code Radius <a href="/downloads/Zip-Code-Radius.pdf" class="no-underline fw-normal fs-6" target="_blank">Information on Zip Radius</a></label>
                            <select class="form-select" v-model="postalRadius">
                                <option :value="zipRadius.ZERO">{{zipRadius.ZERO}}</option>
                                <option :value="zipRadius.FIVE">{{zipRadius.FIVE}}</option>
                                <option :value="zipRadius.TEN">{{zipRadius.TEN}}</option>
                                <option :value="zipRadius.FIFTEEN">{{zipRadius.FIFTEEN}}</option>
                                <option :value="zipRadius.TWENTY_FIVE">{{zipRadius.TWENTY_FIVE}}</option>
                            </select>
                        </div>
                        <div class="mb-3 form-check text-start">
                            <label class="form-check-label">
                                <input type="checkbox" v-model="crossState" class="form-check-input">
                                Okay to cross state boundaries
                            </label>
                        </div>
                        <button type="submit" class="btn btn-primary mb-3" @click="submit">Check Availability</button>
                    </div>
                </div>

                <div class="row mt-3" v-if="showPricing">
                    <div class="col-sm-6 offset-sm-3 text-center bg-color-2-dk p-2 fw-bold text-white fs-4">
                        Estimated Available Prospects & Pricing
                    </div>
                </div>

                <div class="row mt-3" v-if="showPricing">
                    <div class="col-sm-6 offset-sm-3">
                        <div class="row">
                            <div class="col p-0 fw-bold">
                                Safeco recommends including phone numbers when available.
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-5">
                                <h4 class="fw-normal">Prospects with Phone Numbers</h4>
                                <p class="fst-italic fs-6">(Additional charges apply)</p>
                            </div>
                            <div class="col-sm-6 offset-1">
                                <div class="form-check">
                                    <label class="form-check-label text-error fw-bold">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault">

                                        <!-- TODO: get the values from the API-->
                                        <div>
                                            3,492
                                            <span class="fw-normal fs-6 text-gray-4">($.76 Each)</span>
                                        </div>
                                        <div class="fs-6 text-gray-4">
                                            <span class="fw-normal">Total:</span>
                                            $2,653.92
                                        </div>
                                        <div class="fs-6">
                                            Pricing prior to potential applied discount.
                                        </div>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="selectQuantity" checked>
                                    <label class="form-check-label" for="selectQuantity">
                                        Select Quantity
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col">
                                <hr/>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-5">
                                <h4 class="fw-normal">Prospects without Phone Numbers</h4>
                            </div>
                            <div class="col-sm-6 offset-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="allAvailableQuantity">
                                    <label class="form-check-label text-error fw-bold" for="allAvailableQuantity">
                                        <!-- TODO: get the values from the API-->
                                        3,492
                                        <span class="fw-normal fs-6 text-gray-4">($.76 Each)</span>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="selectQuantity" checked>
                                    <label class="form-check-label" for="selectQuantity">
                                        Select Quantity
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-5">

                    <div class="col-sm-6">
                        <!-- TODO: add the correct previous route -->
                        <router-link to="#todo" class="btn btn-outline-primary">Previous</router-link>

                    </div>

                    <div class="col-sm-6 text-end">

                        <button class="btn btn-outline-primary me-3" @click="saveForLater">Save for Later</button>
                        <button class="btn btn-primary" @click="next">Next</button>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .prospecting-step2-zip-code{
        .zip-code-lookup {
            padding: 0 150px
        }

        label{
            font-size: 1.125rem;
        }
    }
</style>