<script setup>

    // RoyaltyLoyaltyAnnulaAutoViewListPage
    // View the lists for Annual Auto opportunites

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 50
    }

    const pageSizeOptions = [25, 50, 100, 200];

    const annualAutoColumns = [
        { field: 'agencyNumber', title: 'Sub Code', isSortable: true, custom: true, formatter: (value) => formatStatCode(value) },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true, formatter: (value) => formatDate(value) },
        { field: 'policyType', title: 'Current Policy Type', isSortable: false },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'middleName', title: 'Middle Name', isSortable: false },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'recommendedIndicator', title: 'Recommended Indicator', isSortable: true },
        { field: 'youngDriverIndicator', title: 'Young Driver Indicator', isSortable: false },
        { field: 'meritIndicator', title: 'Merit Indicator', isSortable: false },
    ];

    const customersUpRenewalColumns = [
        { field: 'statCode', title: 'Sub Code', isSortable: true, custom: true, formatter: (value) => formatStatCode(value) },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true, formatter: (value) => formatDate(value) },
        { field: 'policyType', title: 'Current Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'middleName', title: 'Middle Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
    ]

    // Import ----
    import { computed, ref } from 'vue';
    import { Indicators } from '@/constants'
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router';
    import { ExportParser } from "@/site/services/ExportParser";
    import { retentionTypes } from '@/site/components/OpportunitiesTile';
    import { site } from '@/Site';
    import { formatStatCode, getFieldsMapperFromColumns, beforeTableEncode } from '@/util';

    const route = useRoute();
    const router = useRouter();

    // Components ----
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    const sortId = () => {
        switch (route.params.id) {
            case retentionTypes.AnnualAuto:
                return "auto-renewalDate";
            case retentionTypes.CustomersUpRenewal:
                return "renewalDate";
            default:
                return "";
        }
    }

    // State ----
    const sortPrefix = route.params.id === retentionTypes.AnnualAuto ? "auto-" : "";
    const searchTerm = ref(sortPrefix + 'renewalDate');
    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    const search = new Search(route.params.id, 'row', sortId(), constants.DEFAULT_PAGE_SIZE);
    const searchDownload = new Search(route.params.id, 'row', sortId(), null);

    // Computed
    const listHeadline = computed(() => {
        switch (route.params.id) {
            case retentionTypes.AnnualAuto:
                return "Annual Auto-Convert 6-month To Annual Auto";
            case retentionTypes.CustomersUpRenewal:
                return "Customers Up For Renewal In The Next 3 Months";
            default:
                return "";
        }
    });

    const getColumns = computed(() => {
        switch (route.params.id) {
            case retentionTypes.AnnualAuto: return annualAutoColumns;
            case retentionTypes.CustomersUpRenewal: return customersUpRenewalColumns;
            default: return [];
        }
    })

    const isAnnualAuto = computed(() => {
        if (route.params.id === retentionTypes.AnnualAuto)
            return true
        return false
    })

    if (isAnnualAuto.value == true) {
        search.setFilterValues('recommended-indicator', ['true'])
    }

    const indicatorOptions = [
        { id: 'true', text: 'Yes', values: ['true'] },
        { id: 'false', text: 'No', values: ['false'] }
    ]

    const indicatorData = [
        {
            id: 'recommended-indicator',
            name: Indicators.RECOMMENDED
        },
        {
            id: 'young-driver-indicator',
            name: Indicators.YOUNG_DRIVER
        },
        {
            id: 'merit-indicator',
            name: Indicators.MERIT
        }
    ]

    const fieldsMapper = getFieldsMapperFromColumns(getColumns.value);

    const opps = computed(() => {
        return searchDownload.results.rows;
    });

    const isOwnerMaster = computed(() => {
        return agencyDetails.value.isOwnerMaster;
    })

    const statCodeField = computed(() => {
        switch (route.params.id) {
            case retentionTypes.AnnualAuto:
                return "agencyNumber";
            case retentionTypes.CustomersUpRenewal:
                return "statCode";
            default:
                return "";
        }
    })


    // Methods ----
    function back() {
        router.go(-1);
    }

    async function exportCSV() {
        await searchDownload.activate(route);
        ExportParser().exportCSVFromJSON(opps.value, null, null, fieldsMapper, beforeTableEncode(getColumns.value));
    }

     async function exportXLS() {
         await searchDownload.activate(route);
        ExportParser().exportXLSFromJSON(opps.value, null, null, fieldsMapper, beforeTableEncode(getColumns.value));
    }

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }


    function sortSearch(value) {
        value = sortPrefix + value

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }


        search.setSortId(searchTerm.value);
    }

</script>

<template>
    <PageTemplate>

        <div class="container annual-auto-view-detail">

            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />
            <div class="row mt-4">
                <div class="col">
                    <h1 class="text-capitalize" v-html="listHeadline"></h1>
                    <div v-if="isOwnerMaster">Owner Master ID: {{formatStatCode(agencyDetails.statId)}}</div>
                    <div v-else>Sub-Code Agency ID: {{formatStatCode(agencyDetails.statId)}}</div>
                </div>

            </div>



            <SearchContainer :search="search" default-view="list" :deep-link="false">
                <div class="row mt-3" v-if="isAnnualAuto">

                    <div class="col-4" v-for="indicatorItem in indicatorData" :key="indicatorItem.id">
                        <label class="fw-bold">{{ indicatorItem.name }}</label>
                        <div class="row">
                            <div class="col-3" v-for="opt of indicatorOptions" :key="opt.id">
                                <AddFilterCheckBox :filter-id="indicatorItem.id" :values="opt.values" :label="opt.text" />
                            </div>
                        </div>
                    </div>                                 
                </div>

                    <div class="row mt-3">
                        <div class="d-flex">
                            <div class="me-auto fw-bold">
                                Total: <strong>{{ search.results.totalCount }} </strong> <a class="ms-3 no-underline" @click="back()">Back</a>
                            </div>
                            <div>
                                <i class="bi bi-file-earmark-excel me-3 fs-1" title="Download in Excel format" @click="exportXLS"></i>
                                <i class="bi bi-filetype-csv fs-1" title="Download in CSV format" @click="exportCSV"></i>
                            </div>
                            <div class="px-3">
                                <PageSizeList :options="pageSizeOptions" />
                            </div>
                            <div>
                                <div>
                                    <span v-if="search.results.loaded && search.results.rows.length > 0">Showing {{ search.results.startIndex }} of {{ search.results.totalCount }}</span>
                                </div>
                                <PageSelector />
                            </div>
                        </div>
                    </div>

                    <div class="data-table">
                        <SearchTable :columns="getColumns" @sort-by="sortSearch">

                            <template v-slot:[statCodeField]="row">
                                {{formatStatCode(row[statCodeField])}}
                            </template>

                            <template v-slot:renewalDate="row">
                                {{formatDate(row.renewalDate)}}
                            </template>

                            <!-- Template for no results -->
                            <template #empty>
                                No
                                <span v-html="listHeadline"></span> Lists were found that match your criteria.
                            </template>

                            <!-- Template while results are initially loading -->
                            <template #loading>
                                <div class="row">
                                    <div class="col text-center">
                                        <div class="spinner-border me-3" role="status"></div>
                                        <div class="spinner-border me-3" role="status"></div>
                                        <span class="fw-bold">Performing a search for the data</span>
                                        <div class="spinner-border mx-3" role="status"></div>
                                        <div class="spinner-border" role="status"></div>
                                    </div>
                                </div>
                                <TableSkeleton />
                            </template>
                        </SearchTable>
                    </div>

                    <div class="row">
                        <div class="col text-end fit-content"><PageSelector /></div>
                    </div>

</SearchContainer>

        </div> <!-- End Container-->

    </PageTemplate>
</template>

<style lang="scss">
    .annual-auto-view-detail {
        .btn-sm {
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
        }

        .bi {
            color: $color-2;
            cursor: pointer;
        }

        h1 span {
            color: $success;
        }

        table td, table th {
            white-space: nowrap;
        }
    }
</style>