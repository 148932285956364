<script setup>

    // CheckoutPage
    // Allows the user to view and change the shipping information for the cart.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import ShipTo from './components/ShipTo';
    import PaymentInfoPanel from '@/site/areas/account/components/PaymentInfoPanelStripe';
    import {defaultShipMethodCode} from '@/constants';

    // Imports ----
    import { ref, watch, onMounted, computed } from 'vue';
    import { site } from '@/Site';
    import { useRoute, useRouter } from 'vue-router';

    const route = useRoute();
    const router = useRouter();
    const disableButton = ref(false);
    const disableContinueShippingButton = ref(false);

    // State ----
    let section = ref(route.params.section);

    //const isDigitalOnly = ref(site.cart.onlyContainsDigitalProducts);
    const isShippingRequired = ref(site.cart.isShippingRequired);
    const isLoaded = ref(false);
    // Update section when route (URL) changes
    watch(() => route.params, () => { section.value = route.params.section });

    async function next() {
        router.push({ path: '/review' });
    }
    function paymentDetailsValue(value) {
        disableButton.value = value;
    }
    function shippingDetailsValue(value) {
        disableContinueShippingButton.value = value;
    }

    async function nextNavigation (){
        if(site.cart.current.total == 0 && currentShipMethod.value == defaultShipMethodCode){
            router.push({ path: '/review' })
        } else {
            router.push({ path: '/checkout/payment' })
        }
    }

    const currentShipMethod = computed({
        get() {
            return site.cart.current.shipMethodCode;
        },
        set(value) {
            isLoaded.value = false;
            site.cart.current.shipMethodCode = value;
            isLoaded.value = true;
        }

    });

    onMounted(async () => {
        await site.cart.loadCart();
        if (!site.cart.isShippingRequired) {
            nextNavigation();
        }
        isLoaded.value = true;

    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page checkout-page">
            <template v-if="isLoaded">
                <!-- Shipping Section -->
                <div v-if="section === 'shipping'">
                    <div class="row mb-4">
                        <div class="col-12 col-sm-8">
                            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Shipping</span></h1>
                        </div>
                        <div class="my-2 d-sm-none"></div>
                        <div class="col-12 col-sm-4 ms-auto">
                            <router-link to="/cart" class="btn btn-outline-primary me-3">Back to Cart</router-link>
                            <button v-if="!site.cart.isMultiShip()" class="btn btn-primary" :disabled="disableContinueShippingButton" @click="nextNavigation">Continue</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-9">
                            <CartBodyPanel class="cart-body-panel">
                                <template #title>
                                    <h2>Ship To</h2>
                                </template>

                                <ShipTo :editable="true" :editing="true" @hasShippingDetails="shippingDetailsValue" />

                            </CartBodyPanel>
                        </div>

                        <div class="col-12 col-md-3">
                            <CartBodyPanel class="cart-body-panel ship-method-panel">
                                <template #title>
                                    <h2>Ship Method</h2>
                                </template>

                                <ShipMethodSelector v-model="currentShipMethod" :showPrice="true" />
                            </CartBodyPanel>
                        </div>
                    </div>

                </div>
                <!--/ Shipping Section -->
                <!-- Payment Section-->
                <div v-if="section === 'payment'">
                    <div class="row mb-4">
                        <div class="col-12 col-sm-8">
                            <h1 class="cart-page-header mb-3">Checkout <span class="text-muted">/ Payment</span></h1>
                        </div>
                        <div class="my-2 d-sm-none"></div>
                        <div class="col-12 col-sm-4 ms-auto text-right">
                            <router-link to="/checkout/shipping" class="btn btn-outline-primary me-3" v-if="isShippingRequired">Back to Shipping</router-link>
                            <router-link to="/cart" class="btn btn-outline-primary me-3" v-if="!isShippingRequired">Back to Cart</router-link>
                            <button @click="next" class="btn btn-primary" :disabled="disableButton">Continue</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                            <CartBodyPanel class="cart-body-panel">
                                <template #title>
                                    <h2>Payment Information</h2>
                                </template>

                                <PaymentInfoPanel :large-create-button="true" :show-header="false" :show-select-button="true" :cart-id="site.cart.current.id" @hasPaymentDetails="paymentDetailsValue" />

                            </CartBodyPanel>

                        </div>
                    </div>

                </div>
                <!--/ Payment Section -->
            </template>
        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .checkout-page {
        .n-body-panel {
            .body {
                padding: 0.75rem 0rem;
            }

            .title {
                padding: 0.75rem 1.5rem;
                background-color: $gray-1;

                h2 {
                    color: $gray-4;
                    font-size: 1.1rem !important;
                    margin-bottom: 0px;
                }
            }
        }

        .ship-method-panel {
            .body {
                padding-left: 1.5rem;
            }
        }

        .payment-info-panel .n-tile-select-button {
            position: absolute;
            margin-top: -0.5rem;
            margin-left: -0.25rem;
        }
    }
</style>