<script setup>

    // OrderConfirmedPage
    // Shows the user a confirmation that their order has been placed.

    // Components ----
    import CartBodyPanel from './components/CartBodyPanel';
    import OrderShipTo from './components/OrderShipTo';
    import OrderItemList from './components/OrderItemList';
    import SelectedPaymentMethod from './components/SelectedPaymentMethod';
    import OrderShipToMultiple from './components/OrderShipToMultiple';
    import UserFeedback from '@/site/components/UserFeedback'
    import ModalBox from '@/site/components/ModalBox';
    import { FeedbackType } from '../../../constants';


    // Imports ----
    import { computed, ref, reactive, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import { site } from '@/Site';
    import { Util } from '@dd-nucleus/nucleus-vue';

    const route = useRoute();
    let feedbackTimer = null;

    // State ----
    const order = reactive({ isLoaded: false, detail: {} });
    const bulkOrder = reactive({ isLoaded: false, detail: {} });
    //const isDigitalOnly = ref(site.cart.onlyContainsDigitalProducts);
    const feedbackModal = ref();


    const isShippingRequired = ref(site.cart.isShippingRequired);

    const orderNumbers = ref([]);

    // Computed ----
    const shipMethod = computed(() => {
        return order.detail.shipMethod;
    });


    const shipListCount = computed(() => {
        return bulkOrder.detail?.orders?.length;
    });

    const isExpressOrder = computed(() => {
        return order.detail?.items?.filter(i => i.attributes?.Data?.bulkOrderStateId)?.length > 0;
    });

    const isBulkOrder = computed(() => {
        return shipListCount.value > 0 && !isExpressOrder.value;
    });

    const orderNumbersText = computed(() => {
        return orderNumbers.value.join(",");
    });

    const showPayment = computed(() => {
        return !isBulkOrder.value && order.detail.total > 0;
    })

    function closeModal() {
        feedbackModal.value.close();
    }

    // Handlers ----
    onMounted(async () => {
        // If an ID was provided, get that order. If none was provided, use cart's order property, which is set when the
        // order is placed.

        if (Util.isEmpty(route.params.id))
            Object.assign(order.detail, site.cart.order);
        else {
            const response = await site.orders.getOrder(route.params.id);

            if (response?.orders?.length > 1) {
                bulkOrder.detail = response;
                const orders = response?.orders;
                orderNumbers.value = orders.map(o => o.orderNumber).sort();                
                if (!isBulkOrder.value) {
                    const orderDetail = orders[0];
                    for (let i = 0; i < orderDetail.items.length; i++) {
                        let itemQuantity = 0;
                        for (let j = 0; j < orders.length; j++) {
                            let quantity = orders[j].items.find(item => item.product.id == orderDetail.items[i].product.id)?.quantity;
                            if (!isNaN(quantity)) {
                                itemQuantity += quantity
                            }
                        }
                        orderDetail.items[i].quantity = itemQuantity;
                    }
                }
                else {
                    // Set AddressLine3 as blank. Because this is containing BulkOrder number. 
                    for (let i = 0; i < bulkOrder.detail.orders.length; i++) {
                        bulkOrder.detail.orders[i].shipTo.addressLine3 = "";
                    }
                }
                Object.assign(order.detail, response?.orders[0]);
            } else {
                Object.assign(order.detail, response);
            }
        }

        console.warn('order.detail', order.detail);

        // Clear the cart
        await site.cart.resetCart();
        // Reload the cart
        await site.cart.loadCart();
        //reseting personalizationInfo
        await site.personalizationInfo.resetOrderState();
        //refresh the ignite numbers
        await site.agencyDetails.loadAgencyIgniteDetails();

        update();

        initiateFeedback()

    });

    function initiateFeedback() {
        feedbackTimer = setInterval(() => { showFeedback() }, 3000)
    }

    function showFeedback() {
        feedbackModal.value.show();
        clearInterval(feedbackTimer);
    }

    // Called once we know we have an order to work with
    function update() {
        order.isLoaded = true;
    }

</script>

<template>
    <ModalBox modalId="orderFeedbackModal" title="Please rate your experience." ref="feedbackModal">
        <template v-slot:body>
            <div class="row">
                <div class="col">
                    <UserFeedback @close-modal="closeModal" :feedback-type="FeedbackType.ORDER_COMPLETE" />
                </div>
            </div>
        </template>
    </ModalBox>
    <PageTemplate>
        <div class="container root-page order-confirmation-page" v-if="order.isLoaded">

            <div class="text-end">
                <router-link class="btn btn-primary me-2" to="/">BACK TO HOME</router-link>
            </div>
            <div class="text-center mb-4 order-message" v-if="isExpressOrder">
                <h2>We've received your order</h2>
                <div class="order-number mb-1">
                    Order # {{orderNumbersText}}
                </div>
                An email confirmation containing order details has been sent to <strong>{{ order.detail.shipTo.shipToEmail }}</strong>
            </div>

            <div class="text-center mb-4 order-message" v-else>
                <h2>We've received your order</h2>
                <template v-if="!isBulkOrder">
                    <div class="order-number mb-1">Order #{{ order.detail.orderNumber }}</div>
                </template>
                An email confirmation containing order details has been sent to <strong>{{ order.detail.shipTo.shipToEmail }}</strong>
            </div>

            <div class="row">
                <div class="col">
                    <template v-if="isShippingRequired">
                        <CartBodyPanel class="cart-body-panel" v-if="isBulkOrder">

                            <template #title>
                                <h2>Shipping Details <span class="fs-3">({{shipListCount}} recipients)</span></h2>
                            </template>

                            <OrderShipToMultiple :order="bulkOrder.detail.orders" :removable="false" />

                        </CartBodyPanel>

                        <CartBodyPanel class="cart-body-panel" v-else-if="!isExpressOrder">

                            <template #title>
                                <h2>Ship To</h2>
                            </template>

                            <OrderShipTo :order="order.detail" />

                        </CartBodyPanel>

                        <CartBodyPanel class="cart-body-panel ship-method-panel" v-if="!isBulkOrder && !isExpressOrder">
                            <template #title>
                                <h2>Ship Method</h2>
                            </template>

                            <ShipMethod :ship-method="shipMethod" />

                        </CartBodyPanel>
                    </template>

                    <CartBodyPanel class="cart-body-panel payment-method-panel" v-if="showPayment">
                        <template #title>
                            <h2>Payment Information</h2>
                        </template>

                        <SelectedPaymentMethod :payment-method-id="order.detail.paymentMethodId" />

                    </CartBodyPanel>

                    <CartBodyPanel class="cart-body-panel order-items-panel" v-if="!isBulkOrder">
                        <template #title>
                            <h2>Items</h2>
                        </template>

                        <OrderItemList :order="order.detail" />

                    </CartBodyPanel>
                </div>
            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .root-page {
        min-height: 30rem;
    }

    .order-confirmation-page {
        .order-message h2 {
            font-weight: $normal;
        }

        .order-number {
            font-size: 1.2rem;
            font-weight: $bold;
        }

        .ship-method .title {
            font-weight: bold;
        }

        .panel-title-action {
            margin-top: -0.75rem;
        }

        .n-body-panel {
            .body {
                padding: 0.75rem 1.5rem;
            }

            .title {
                padding: 0.75rem 1.5rem;
                background-color: $gray-1;

                h2 {
                    color: $gray-4;
                    font-size: 1.1rem !important;
                    margin-bottom: 0px;
                }
            }
        }

        .email-notification {
            margin: 22px 0px;

            .title {
                color: #28A3AF;
                font-weight: bold;
                margin: 0;
            }

            .sub-title {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
            }

            .label {
                font-weight: bold;
                margin-top: 8px;
            }

            .input {
                display: block;
                width: 30%;
                padding: 4px 6px;
                background-color: #FAF7F0
            }
        }
    }
</style>