<script>

    // SelectChannelPage
    // First step in selecting how the order flow happens where you select your channel

    const constants = {
        channelTypes: {
            DIRECT_MAIL: 1,
            EMAIL: 2,
            MULTIPLE_CHANNEL: 3
        },
        productFormat: {
            EMAIL: 'Email',
            DIRECT_MAIL: 'LetterTemplates,Letter,NoteCards'
        }
    };

    // Exports
    export const ChannelTypes = constants.channelTypes;
    export const ProductFormat = constants.productFormat;

    export const getProductFormat = (id) => {
        switch (id) {
            case ChannelTypes.DIRECT_MAIL:
                return ProductFormat.DIRECT_MAIL;
            case ChannelTypes.EMAIL:
                return ProductFormat.EMAIL;
            case ChannelTypes.MULTIPLE_CHANNEL:
                return ProductFormat.DIRECT_MAIL;
            default:
                return '';
        }
    }

</script>
<script setup>


    // Imports
    import { computed, ref, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    const router = useRouter();
    const route = useRoute();
    import { ProgramType,DataListOptionToOppId } from '@/constants'
    import { site } from '@/Site'


    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import ToolTip from '@/site/components/ToolTip';

    

    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    //computed
    const isOwnerMaster = computed(() => {
        return agencyDetails.value.isOwnerMaster;
    });

    const showExpressOrderMessage = computed(() => {
        return isOwnerMaster.value && route.query.oppId == DataListOptionToOppId.SpecialtyCrossSell
    });


    // Methods ----
    function back() {
        
        const back_url = router.options.history.state.back
        if (back_url.includes('home') || (route.query["programType"] == "CrossSell")) {
            router.push({ name: 'Home', query: { tab: ProgramType.CROSS_SELL } })
        }
        else {
            router.back()
        }
    }

    async function onStartOrderClicked(channel) {

        let channelType, isMultiChannel;


        if (channel == ChannelTypes.EMAIL || channel == ChannelTypes.DIRECT_MAIL) {
            isMultiChannel = false;
            channelType = channel;
        }
        else {
            isMultiChannel = true;
            channelType = ChannelTypes.DIRECT_MAIL;
        }

        let queryParams = route.query
        queryParams['channelType'] = channelType
        queryParams['isMultiChannel'] = isMultiChannel


        if (channel == ChannelTypes.EMAIL) {

            router.push({
                name: 'SelectAssetPage',
                params: {
                    channel: ChannelTypes.EMAIL
                },
                query: queryParams
            });

        } else {

            router.push({
                name: 'SelectAssetPage',
                query: queryParams
            });

        }
    }

    // For Adobe analytics
    onMounted(async () => {
        let queryParams = route.query;
        let tileCode;
        if (queryParams['oppType'] == 'Specialty') {
            tileCode = 'Specialty';
        }
        else {
            tileCode = queryParams['productType'];
        }

        switch (tileCode) {
            case "Home":
                tileCode = "Home to Auto";
                break;
            case "Auto":
                tileCode = "Auto to Home";
                break;
        }

        await site.analyticsHandler.push({ "tileCode": tileCode, "lob": queryParams['programType'], "productNames": "MSC_" + tileCode });
    });

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="root-page-container select-channel">


                <div class="row mt-5">

                    <div class="col-sm-8 offset-sm-2 text-center">

                        <h1>
                            Which channel(s) would you like to use for your Cross-sell effort?
                        </h1>

                    </div>

                </div>

                <div class="row">

                    <div class="col text-center">

                        <div class="pro-tip">
                            <ToolTip>
                                <p class="mb-3 tip-text">
                                    Pro tip on how these different marketing channels work together
                                    <i class="bi bi-chevron-right"></i>
                                </p>
                                <template #content>
                                    <div class="text-start">
                                        <h4 class="fw-bold">Pro Tip</h4>
                                        <div class="d-flex gap-2">
                                            <img src="/images/icons/boost-conversions.png" alt="Boost conversions" class="img-fluid" />
                                            <p class="fs-5 mb-0">
                                                <span class="fw-bold">Boost conversions: </span>
                                                Studies have shown that using email and direct mail together can increase your conversion rate by up to 35% from using either method individually.
                                            </p>
                                        </div>
                                        <p class="text-end fs-6 mb-0">SOURCE: Marketing Insider Group</p>
                                    </div>
                                </template>
                            </ToolTip>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-2 text-center">
                            </div>
                            <div class="col-sm-8 text-center">

                                <p>Availability for Cross-sell campaigns may vary by state. Please check back for future updates to availability and reach out to your TM with any questions.</p>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" v-if="showExpressOrderMessage">
                    <div class="col text-center text-danger express-order">
                        Express Ordering is available for <span style="text-decoration:underline">Direct Mail only</span>.
                    </div>
                </div>

                <div class="row my-5">

                    <div class="col">

                        <div class="d-flex justify-content-center">

                            <div class="text-center me-5">

                                <img src="/images/select-channel-direct-mail.png" class="img-fluid" alt="direct mail" />

                                <div>
                                    <button class="btn btn-primary" @click="onStartOrderClicked(ChannelTypes.DIRECT_MAIL)">Direct Mail</button>
                                </div>

                            </div>

                            <div class="text-center me-5">

                                <img src="/images/select-channel-email.png" class="img-fluid" alt="email" />

                                <div>
                                    <button class="btn btn-primary" @click="onStartOrderClicked(ChannelTypes.EMAIL)">Email</button>
                                </div>

                            </div>

                            <div class="text-center">

                                <img src="/images/select-channel-mutli-channel-campaign.png" class="img-fluid" alt="multi-channel campaign" />

                                <div>
                                    <button class="btn btn-primary" @click="onStartOrderClicked(ChannelTypes.MULTIPLE_CHANNEL)">Multi-Channel (Direct Mail & Email)</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <!-- TODO: make this go to the previous page-->
                        <button @click="back" class="btn btn-outline-primary">Previous</button>

                    </div>

                </div>


            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss" scoped>
    .select-channel {
        .pro-tip > div {
            position: relative;
        }

        .pro-tip {
            img {
                width: 95px;
                height: 95px;
            }

            .tip-text {
                color: $color-2-dk;
                cursor: pointer;
                line-height: 27px;

                i {
                    font-size: 12px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .express-order {
            font-weight: bold
        }
    }
</style>
