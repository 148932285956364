// AgencyDetails
// Services and actions related to agencies.

// Imports ----
import { reactive } from 'vue';

export default class Reporting {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
        this.detail = reactive({});
    }


    // #region Getters & Setters ----

    // #endregion



    // #region Public Methods ----
    /**
     * Gets the report details by report id.
     */

    async getReportDetail(id) {
        const response = await this.store.api.get(`/site/report/${id}`);
        Object.assign(this.reportDetail, response);
    }

    async getEmbedToken(id) {
        return await this.store.api.get(`/site/report/token/${id}`);
    }

    async addFavoriteReport(id) {
        await this.store.api.put(`/site/report/favorites/${id}`);
    }

    async deleteFavoriteReport(id) {
        await this.store.api.delete(`/site/report/favorites/${id}`);
    }

    async getFavoriteReports() {
        return await this.store.api.get('/site/report/favorites');
    }

    async getUserReportDetail() {
        return await this.store.api.get(`/site/reports/`);
    }

    // #region Private Methods ----

    // #endregion
}
