<script setup>

    // HeadlineWithIcon
    // Headline component that accepts a title as a prop and builds the h2 container

    // Imports
    import { defineProps } from 'vue';

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: false
        },
        iconPath: {
            type: String,
            required: true
        }
    })

</script>

<template>
    <div class="d-flex align-items-center headline-with-icon">

        <div class="flex-shrink-0">

            <img :src="props.iconPath" :alt="props.title" class="img-fluid" />

        </div>

        <div class="flex-grow-1 ms-3">

            <h1>{{props.title}}</h1>
            <p class="fw-bold" v-if="props.subTitle">{{props.subTitle}}</p>

        </div>

    </div>
</template>

<style lang="scss">
    .headline-with-icon {
    }
</style>