export const UnitOfMeasure = {
    EA: 'EA',
    KIT: 'KIT',
    BOX: 'BOX',
    PK: 'PK',
    PAD: 'PAD',
    CAR: 'CAR'
}

export const getUnitOfMeasureFriendlyName = (product) => {
    if (product.unitOfMeasure) {
        switch (product.unitOfMeasure) {
            case UnitOfMeasure.EA:
                return product.quantityPerUnit > 1 ? 'For Pieces of ' + product.quantityPerUnit : 'Per Piece';
            case UnitOfMeasure.KIT:
                return product.quantityPerUnit > 1 ? 'For Kits of ' + product.quantityPerUnit : 'Per Kit';
            case UnitOfMeasure.PK:
                return product.quantityPerUnit > 1 ? 'For Packs of ' + product.quantityPerUnit : 'Per Pack';
            case UnitOfMeasure.PAD:
                return product.quantityPerUnit > 1 ? 'For Pads of ' + product.quantityPerUnit : 'Per Pad';
            case UnitOfMeasure.BOX:
                return product.quantityPerUnit > 1 ? 'For Boxes of ' + product.quantityPerUnit : 'Per Box';
            case UnitOfMeasure.CAR:
                return product.quantityPerUnit > 1 ? 'For Cartons of ' + product.quantityPerUnit : 'Per Carton';
        }
    }

    return '';
}

export const ProgramType = {
    CROSS_SELL: 'CrossSell',
    PROSPECTING: 'Prospecting',
    ANNUAL_AUTO: 'AnnualAuto',
    RETENTION: 'Retention',
    DATA_LIST_UPLOAD: 'DataListUpload',
    WINBACK: 'Winback',
    AGENCY_REFERRAL: 'AgencyReferral',
    CROSS_SELL_EMAIL: 'CrossSellEmail',
    PET_INSURANCE: 'PetInsurance'
}

export const ProductType = {
    HOME: 'Home',
    AUTO: 'Auto',
    CROSS_SELL_SPECIALITY: 'RV;Watercraft;Motorcycle',
    LLP: 'LandlordProtection',
    UMBRELLA: 'Umbrella',
    OTHER: 'Other',
    RENTERS: 'Renters',
    PACKAGE: 'Package',
    SAFECO_PACKAGE: 'SafecoPackage',
    PET_INSURANCE: 'Pet Insurance',
    EXPRESS_ORDER: 'AP-8083;AP-8083LU;AP-8084;AP-8085'
}

export const ProductFormat = {
    CALL_SCRIPTS: 'CallScripts',
    LETTER: 'Letter',
    LETTER_TEMPLATES: 'LetterTemplates',
    WEBSITE_CONTENT: 'WebsiteContent'
}

export const ProductSearchTerm = {
    PROSPECTING_OVERVIEW: 'AP-3661E',
    INSURANCE_CHECKUP_FORM: 'AP-9266E',
    INSURANCE_CHECKUP_POASTER: 'AP-4327E',
    MOVING_WITH_SAFECO: 'AP-4045E',
    WINBACK_OVERVIEW: 'AP-3647E',
    REFERRAL_PROGRAM_PROMOTIONAL_FLYER: 'AP-5584E',
    PROGRAM_POSTCARD: 'AP-5586LU',
    SOURCE_CODE_TRACKER_TOOL: 'AP-5397E',
    REFERRAL_TRACKING_SHEET: 'AP-4968E',
    Client_Referral: 'Referral',
    SPANISH: 'spanish'
}

export const ProductTatic = {
    WINBACK: 'WinBack',
    PROSPECTING: 'Prospecting'
}

export const TabIds = {
    RETENTION: 'growth-retention',
    PROSPECTING: 'growth-prospecting',
    CROSS_SELL: 'growth-cross-sell'
}

export const ProductTags = {
    IGNITE: 'Ignite',
    SPANISH_ASSETS: 'SpanishLanguageAssets'
}

export const ProductRestrictions = {
    NOTFORMA: 'NotForMA',
    NOTFORCO: 'NotForCO'
}

export const DataListType = {
    NCS: 'NCS',
    NCS_EMAIL: 'NCSEmail',
    PROSPECT: 'Prospect',
    ANNUAL_AUTO_EXPRESS: 'AnnualAutoExpress',
    ANNUAL_AUTO: 'AnnualAuto',
    WINBACK: 'Winback',
    DATA_LIST_UPLOAD: 'DatalistUpload',
    CAUMB: 'CAUMB',
    LOYALTY_CARDS: 'LoyaltyCards',
    NCS_LANDLORD: 'NCSLandlord',
    POD: 'POD',
    AGENCY_REFERRAL: 'AgencyReferral',
    PET_INSURANCE: 'PetInsurance'
}

export const DataListTypeToProgramType = {
    NCS: 'CrossSell',
    NCS_EMAIL: 'CrossSellEmail',
    PROSPECT: 'Prospecting',
    ANNUAL_AUTO: 'AnnualAuto',
    WINBACK: 'Winback',
    DATA_LIST_UPLOAD: 'DataListUpload',
    CAUMB: 'CrossSell',
    LOYALTY_CARDS: 'Retention',
    NCS_LANDLORD: 'CrossSell',
    POD: 'Retention',
    AGENCY_REFERRAL: 'AgencyReferral',
    PET_INSURANCE: 'PetInsurance'
}

export const getProgramTypeForOrderState = (id) => {
    switch (id) {
        case DataListType.NCS:
            return DataListTypeToProgramType.NCS;
        case DataListType.NCS_EMAIL:
            return DataListTypeToProgramType.NCS;
        case DataListType.PROSPECT:
            return DataListTypeToProgramType.PROSPECT;
        case DataListType.ANNUAL_AUTO_EXPRESS:
        case DataListType.ANNUAL_AUTO:
            return DataListTypeToProgramType.ANNUAL_AUTO;
        case DataListType.WINBACK:
            return DataListTypeToProgramType.WINBACK;
        case DataListType.CAUMB:
            return DataListTypeToProgramType.CAUMB;
        case DataListType.LOYALTY_CARDS:
            return DataListTypeToProgramType.LOYALTY_CARDS;
        case DataListType.NCS_LANDLORD:
            return DataListTypeToProgramType.NCS_LANDLORD;
        case DataListType.DATA_LIST_UPLOAD:
            return DataListTypeToProgramType.DATA_LIST_UPLOAD;
        case DataListType.POD:
            return DataListTypeToProgramType.POD;
        case DataListType.AGENCY_REFERRAL:
            return DataListTypeToProgramType.AGENCY_REFERRAL;
        case DataListType.PET_INSURANCE:
            return DataListTypeToProgramType.PET_INSURANCE;
        default:
            return '';
    }
}

export const DataListOption = {
    AutoCrossSell: "H",
    HomeCrossSell: "A",
    UmbrellaCrossSell: "UR",
    SpecialtyCrossSell: "SP",
    PetCrossSell: "PI",
    LandLordProtectionCrossSell: "NWL",
    UmbrellaEdcuationCrossSell: "UE",
    UmbrellaRatingsCrossSell: "UR",
    Umbrella: "U",
    UmbrellaLandlord: "UL",
    FloridaUmbrella: "FL",
    AnnualAuto: "AA",
    AnnualAutoCustomersRenewal: "AAC",// Made up own data list option.
    AgencyReferral: "N"

}

export const DataListOptionToOppId = {
    AutoCrossSell: "H",
    HomeCrossSell: "A",
    UmbrellaCrossSell: "UR",
    SpecialtyCrossSell: "SP",
    LandLordProtectionCrossSell: "LPP",
    PetCrossSell:"PI",
    AnnualAutoConvert6months: "AA",
    AnnualAutoCustomersRenewal: "AAC", // Made up own data list option.
    AgencyReferral: 'N'
}

export const getOppIdForOrderState = (id) => {
    switch (id) {
        case DataListOption.AutoCrossSell:
            return DataListOptionToOppId.AutoCrossSell;
        case DataListOption.HomeCrossSell:
            return DataListOptionToOppId.HomeCrossSell;
        case DataListOption.UmbrellaCrossSell:
        case DataListOption.UmbrellaEdcuationCrossSell:
        case DataListOption.UmbrellaRatingsCrossSell:
        case DataListOption.Umbrella:
        case DataListOption.UmbrellaLandlord:
        case DataListOption.FloridaUmbrella:
            return DataListOptionToOppId.UmbrellaCrossSell;
        case DataListOption.SpecialtyCrossSell:
            return DataListOptionToOppId.SpecialtyCrossSell;
        case DataListOption.LandLordProtectionCrossSell:
            return DataListOptionToOppId.LandLordProtectionCrossSell;
        case DataListOption.AnnualAuto:
            return DataListOptionToOppId.AnnualAutoConvert6months;
        case DataListOption.AnnualAutoCustomersRenewal:
            return DataListOptionToOppId.AnnualAutoCustomersRenewal;
        case DataListOption.AgencyReferral:
            return DataListOptionToOppId.AgencyReferral;
        default:
            return '';
    }
}

export const OppIdFromSystem = {
    CROSS_SELL_SPECIALITY: "specialty-cross-sell",
    LPP: "lpp",
    CROSS_SELL_PET : "pet-cross-sell",
    CROSS_SELL_UMBRELLA_TO_AUTO: "cross-sell-umbrella-to-auto",
    CROSS_SELL_HOME_TO_AUTO: "cross-sell-home-to-auto",
    CROSS_SELL_AUTO_TO_HOME: "cross-sell-auto-to-home",
    ANNUAL_6_MONTH: "annual-auto-convert-6-month-to-annual-auto",
    CUSTOMER_RENEWAL: "customers-up-for-renewal-in-the-next-3-months",
}

export const EmailMultiChannelIds = ["specialty-cross-sell", "cross-sell-home-to-auto", "cross-sell-auto-to-home"];

export const getOppIdFromOppId = (id) => {
    switch (id) {
        case OppIdFromSystem.CROSS_SELL_SPECIALITY:
            return DataListOptionToOppId.SpecialtyCrossSell;
        case OppIdFromSystem.LPP:
            return DataListOptionToOppId.LandLordProtectionCrossSell;
        case OppIdFromSystem.CROSS_SELL_PET:
            return DataListOptionToOppId.PetCrossSell;
        case OppIdFromSystem.CROSS_SELL_UMBRELLA_TO_AUTO:
            return DataListOptionToOppId.UmbrellaCrossSell;
        case OppIdFromSystem.CROSS_SELL_HOME_TO_AUTO:
            return DataListOptionToOppId.HomeCrossSell;
        case OppIdFromSystem.CROSS_SELL_AUTO_TO_HOME:
            return DataListOptionToOppId.AutoCrossSell;
        case OppIdFromSystem.ANNUAL_6_MONTH:
            return DataListOptionToOppId.AnnualAutoConvert6months;
        case OppIdFromSystem.CUSTOMER_RENEWAL:
            return DataListOptionToOppId.AnnualAutoCustomersRenewal;
        default:
            return id;
    }
}

export const getProgramTypeFromOppId = (id) => {
    switch (id) {
        case DataListOptionToOppId.AutoCrossSell:
            return ProgramType.CROSS_SELL;
        case DataListOptionToOppId.HomeCrossSell:
            return ProgramType.CROSS_SELL;
        case DataListOptionToOppId.UmbrellaCrossSell:
            return ProgramType.CROSS_SELL;
        case DataListOptionToOppId.SpecialtyCrossSell:
            return ProgramType.CROSS_SELL;
        case DataListOptionToOppId.LandLordProtectionCrossSell:
            return ProgramType.CROSS_SELL;
        case DataListOptionToOppId.PetCrossSell:
            return ProgramType.PET_INSURANCE;
        case DataListOptionToOppId.AnnualAutoConvert6months:
            return ProgramType.ANNUAL_AUTO;
        case DataListOptionToOppId.AnnualAutoCustomersRenewal:
            return ProgramType.ANNUAL_AUTO
        default:
            return id;
    }
}

export const getProductTypeFromOppId = (id) => {
    switch (id) {
        case DataListOptionToOppId.AutoCrossSell:
            return ProductType.AUTO;
        case DataListOptionToOppId.HomeCrossSell:
            return ProductType.HOME;
        case DataListOptionToOppId.UmbrellaCrossSell:
            return ProductType.UMBRELLA;
        case DataListOptionToOppId.SpecialtyCrossSell:
            return ProductType.CROSS_SELL_SPECIALITY;
        case DataListOptionToOppId.LandLordProtectionCrossSell:
            return ProductType.LLP;
        case DataListOptionToOppId.PetCrossSell:
            return ProductType.PET_INSURANCE;
        case DataListOptionToOppId.AnnualAutoConvert6months:
            return "";
        case DataListOptionToOppId.AnnualAutoCustomersRenewal:
            return "";
        default:
            return "";
    }
}

export const getDataListTypeFromProgramType = (id) => {
    switch (id) {
        case ProgramType.CROSS_SELL:
            return [DataListType.NCS, DataListType.CAUMB, DataListType.NCS_LANDLORD];
        case ProgramType.CROSS_SELL_EMAIL:
            return [DataListType.NCS_EMAIL];
        case ProgramType.PROSPECTING:
            return [DataListType.PROSPECT, DataListType.DATA_LIST_UPLOAD];
        case ProgramType.ANNUAL_AUTO:
            return [DataListType.ANNUAL_AUTO, DataListType.ANNUAL_AUTO_EXPRESS];
        case ProgramType.RETENTION:
            return [DataListType.POD]; //DataListType.LOYALTY_CARDS, 
        case ProgramType.WINBACK:
            return [DataListType.WINBACK];
        case ProgramType.PET_INSURANCE:
            return [DataListType.PET_INSURANCE];
        default:
            return id;
    }
}

export const getDataListOptionFromOppId = (id) => {
    switch (id) {
        case DataListOptionToOppId.AutoCrossSell:
            return [DataListOption.AutoCrossSell];
        case DataListOptionToOppId.HomeCrossSell:
            return [DataListOption.HomeCrossSell];
        case DataListOptionToOppId.UmbrellaCrossSell:
            return [DataListOption.UmbrellaCrossSell,
            DataListOption.UmbrellaEdcuationCrossSell,
            DataListOption.UmbrellaRatingsCrossSell,
            DataListOption.Umbrella,
            DataListOption.UmbrellaLandlord,
            DataListOption.FloridaUmbrella];
        case DataListOptionToOppId.SpecialtyCrossSell:
            return [DataListOption.SpecialtyCrossSell];
        case DataListOptionToOppId.LandLordProtectionCrossSell:
            return [DataListOption.LandLordProtectionCrossSell];
        case DataListOptionToOppId.PetCrossSell:
            return [DataListOption.PetCrossSell];
        case DataListOptionToOppId.AnnualAutoConvert6months:
            return [DataListOption.AnnualAuto];
        case DataListOptionToOppId.AnnualAutoCustomersRenewal:
            return [DataListOption.AnnualAuto];
        default:
            return [id];
    }
}
export const ReportName = {
    WIN_BACK_AUTO_CUSTOMER_LIST: 'Win Back Auto Customer List',
}
export const MarketingMaterial = {
    Events_Sponsorship: 'EventsSponsorship',
    Online_Content: 'OnlineContent'
}

export const OperationType = {
    CROSS_SELL: 'CrossSell',
    CROSS_SELL_SPECIALITY: 'Specialty'
}

export const getOppTypeFromProductType = (type) => {
    switch (type) {
        case ProductType.HOME:
        case ProductType.AUTO:
            return OperationType.CROSS_SELL
        case ProductType.CROSS_SELL_SPECIALITY:
            return OperationType.CROSS_SELL_SPECIALITY
        default:
            return type
    }
}

export const getOppIdFromProductType = (type) => {
    switch (type) {
        case ProductType.HOME:
            return DataListOption.HomeCrossSell
        case ProductType.AUTO:
            return DataListOption.AutoCrossSell
        case ProductType.CROSS_SELL_SPECIALITY:
            return DataListOptionToOppId.SpecialtyCrossSell
        default:
            return type
    }
}

export const defaultShipMethodCode = "U11";

export const ChannelTypes = {
    DIRECT_MAIL: 1,
    EMAIL: 2,
    MULTIPLE_CHANNEL: 3
}

export const ReportGroupType = {
    TopReports: 1,
    MailPerformanceReports: 2,
    ProgramReports: 3,
    MSCActivity: 4,
    MSCUserDetails: 5,
    MarketingSuccessTrackingPrograms: 6
}


export const Indicators = {
    RECOMMENDED: 'Recommeded Indicators',
    YOUNG_DRIVER: 'Young Driver Indicators',
    MERIT: 'Merit Indicators'
}
export const MinMailingCount = 25;

export const PunchOutActions = {
    CREATE: 'create',
    EDIT: 'edit',
    COMMIT: 'commit'
}

export const SubCodeStatus = {
    NONE: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
}
export const DropType = {
    ONE: { value: 1, text: 'ONE' },
    MULTIPLE: { value: 2, text: 'MULTIPLE' },
    NONE: { value: 0, text: 'NONE' }
}
export const Frequency = {
    BI_WEEKLY: { value: 4, text: 'bi-weekly' }, 
    WEEKLY: { value: 1, text: 'weekly' }
}
export const TabTypes =  {
    CROSS_SELL: 'CrossSell',
    PROSPECTING: 'Prospecting',
    RETENTION: 'Retention',
    ANNUAL_AUTO: 'AnnualAuto',
    WINBACK: 'Winback',
    DATA_LIST_UPLOAD: 'DataListUpload',
    AGENT_REFERRAL: 'AgencyReferral',
    PET_INSURANCE: 'PetInsurance'
}
export const TabIDs = {
    HOME_TAB_ID: 'home',
    CROSS_SELL_TAB_ID: 'cross-sell',
    PROSPECTING_TAB_ID: 'prospecting',
    RETENTION_TAB_ID: 'retention',
    MARKETING_TAB_ID: 'marketing',
    REPORTS_TAB_ID: 'reports',
    AGENT_REFERRAL_TAB_ID: 'agency-referral'
}

export const getTabName = (tabId) => {
    switch (tabId) {
        case TabTypes.CROSS_SELL:
        case TabTypes.PET_INSURANCE:
            return TabIDs.CROSS_SELL_TAB_ID;
        case TabTypes.PROSPECTING:
        case TabTypes.DATA_LIST_UPLOAD:
            return TabIDs.PROSPECTING_TAB_ID;
        case TabTypes.RETENTION:
        case TabTypes.WINBACK:
        case TabTypes.ANNUAL_AUTO:
            return TabIDs.RETENTION_TAB_ID;
        case TabTypes.AGENT_REFERRAL:
            return TabIDs.AGENT_REFERRAL_TAB_ID;
        default:
            return '';
    }
}

export const FeedbackType = {
    USER: 1,
    ORDER_COMPLETE: 2
}


export const StoreMediaCategory = {
    BANNER: '0'
}

export const StoreMediaStatus = {
    ACTIVE: '2',
    INACTIVE: '3'
}

export const DeliveryType = {
    NONE: 0,
    PHYSICAL: 1,
    DIGITAL: 2
}

export const PetCrossSellSelfServiceAsset = "AP-9614";