export function isEmpty(obj) {
    if (obj === null || obj === undefined || obj === '') {
        return true;
    }
    if (typeof obj === 'number' && !isFinite(obj)) {
        return true;
    }
    if (typeof obj === 'string' && obj.trim().length === 0) {
        return true;
    }
    if (typeof obj === 'object' && Object.keys(obj).length === 0) {
        return true;
    }
    return false;
}

export function isNotEmpty(obj) {
    return !isEmpty(obj);
}

export const formatStatCode = (statcode) => {
    if (statcode && statcode.length >= 4) {
        return statcode.substring(0, 2) + "-" + statcode.substring(2, 4) + "-" + statcode.substring(4, statcode.length);
    }
    return statcode;
}

export const formatDate = (date) => {
    if (date) {
        const dt = new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return dt;
    }
    return '';
}

export const formatDateTime = (date, locale = true) => {
    if (date) {
        let dt = new Date(date);
        if (locale) {
            const offsetHours = dt.getTimezoneOffset() / 60 * (-1)
            dt.setHours(dt.getHours() + offsetHours);
        }
        dt = dt.toLocaleDateString('en-US', {
            year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return dt;
    }
    return '';
}

// getFieldsMapperFromColumns return fields filter or a field name mapper type Record<string, string> e.g. { 'bar': 'baz' }
export const getFieldsMapperFromColumns = (columns) => {
    const fieldsMapper = columns.reduce((fields, item) => {
        fields[item.field] = item.title;
        return fields;
    }, {});
    return fieldsMapper;
}

// beforeTableEncode allows to alter table entries, only works for CSV and XLS file
export const beforeTableEncode = (columns) => {
    return (entries) => {
        const finalEntries = [];
        const keyToValues = {};
        entries.forEach(({ fieldName, fieldValues }) => {
            keyToValues[fieldName] = fieldValues;
        });
        columns.forEach(item => {
            const { title, formatter } = item;
            const fieldValues = keyToValues[title];
            if (!fieldValues) return;
            finalEntries.push({
                fieldName: title,
                fieldValues: fieldValues.map((value) => {
                    if (!value || value === 'null') return "";
                    return formatter ? formatter(value) : value;
                })
            });
        });
        return finalEntries;
    }
}

export const formatPhone = (number) => {
    var x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    number = !x[2] ? x[1] : (x[1]  + '-' + x[2] + (x[3] ? '-' + x[3] : ''));
    return number;
};