<script setup>

    // CrossSellViewDetailPage
    // View the lists for cross sell opportunites

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 50
    }

    const pageSizeOptions = [25, 50, 100, 200];

    const columns = [
        { field: 'statCode', title: 'Sub Code', custom: true, isSortable: true, formatter: (value) => formatStatCode(value) },
        { field: 'mailingDate', title: 'Mailing Date', custom: true, isSortable: true, formatter: (value) => formatDate(value) },
        { field: 'marketingChannel', title: 'Marketing Channel', custom: true, isSortable: true },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true, formatter: (value) => formatDate(value) },
        { field: 'policyType', title: 'Current Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true },
        { field: 'phone', title: 'Phone', isSortable: true },
    ];

    const spColumns = [
        { field: 'motorCycle', title: 'Motorcycle', custom: true, isSortable: true, formatter: (value) => formatFlag(value) },
        { field: 'waterCraft', title: 'Watercraft', custom: true, isSortable: true, formatter: (value) => formatFlag(value) },
        { field: 'rv', title: 'RV', custom: true, isSortable: true, formatter: (value) => formatFlag(value) },
    ];

    const specialtyColumns = columns.concat(spColumns);

    const sortIds = [
        { field: 'statCode', sortName: 'statCode'},
        { field: 'mailingDate', sortName: 'policyholder-mailingDate'},
        { field: 'marketingChannel', sortName: 'marketingChannel'},
        { field: 'policyNumber', sortName: 'policyNumber'},
        { field: 'renewalDate', sortName: 'renewalDate'},
        { field: 'policyType', sortName: 'policyType'},
        { field: 'firstName', sortName: 'firstName'},
        { field: 'lastName', sortName: 'lastName'},
        { field: 'address1', sortName: 'address1'},
        { field: 'address2', sortName: 'address2'},
        { field: 'city', sortName: 'city'},
        { field: 'state', sortName: 'state'},
        { field: 'zip', sortName: 'zip'},
        { field: 'email', sortName: 'policyholder-email'},
        { field: 'phone', sortName: 'policyholder-phone' },
        { field: 'motorCycle', sortName: 'motorCycle' },
        { field: 'waterCraft', sortName: 'waterCraft' },
        { field: 'rv', sortName: 'rv' },
    ];

    // Import ----
    import { computed, ref } from 'vue';
    import { Search, Util } from '@dd-nucleus/nucleus-vue';
    import { useRoute, useRouter } from 'vue-router';
    import { ExportParser } from "@/site/services/ExportParser";
    import { crossSellTypes } from '@/site/components/OpportunitiesTile';
    import { site } from '@/Site';
    import { formatStatCode, getFieldsMapperFromColumns, beforeTableEncode } from '@/util';

    const route = useRoute();
    const router = useRouter();

    // Components ---- 
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    
    // State ----
    const searchTerm = ref('statCode');
    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    const search = new Search('bridge-cross-sell-detail', 'row', 'statCode', constants.DEFAULT_PAGE_SIZE);
    const searchDownload = new Search('bridge-cross-sell-detail', 'row', 'statCode', null);

    // Computed
    const listHeadline = computed(() => {
        switch (route.params.id) {
            case crossSellTypes.AutoCrossSell:
                return "Cross-sell <b>auto to home: </b><span>" + isEnrolled.value + '</span>';
            case crossSellTypes.HomeCrossSell:
                return "Cross-sell <b>home to auto: </b><span>" + isEnrolled.value + '</span>';
            case crossSellTypes.UmbrellaCrossSell:
                return "Cross-sell <b>umbrella to auto: </b><span>" + isEnrolled.value + '</span>';
            case crossSellTypes.SpecialtyCrossSell:
                return "Specialty cross-sell";
            case crossSellTypes.LandLordProtectionCrossSell:
                return "Landlord protection (LPP)";
            case crossSellTypes.PetCrossSell:
                return "Pet cross-sell";
            default:
                return "*"+route.params.id+"*"  + "--" + "*" + crossSellTypes.SpecialtyCrossSell+"*";
        }
    });

    // Computed
    const headline = computed(() => {
        switch (route.params.id) {
            case crossSellTypes.AutoCrossSell:
                return "Cross-sell <b>auto to home</b>";
            case crossSellTypes.HomeCrossSell:
                return "Cross-sell <b>home to auto</b>";
            case crossSellTypes.UmbrellaCrossSell:
                return "Cross-sell <b>umbrella to auto</b>";
            case crossSellTypes.SpecialtyCrossSell:
                return "Specialty cross-sell";
            case crossSellTypes.LandLordProtectionCrossSell:
                return "Landlord protection (LPP)";
            case crossSellTypes.PetCrossSell:
                return "Pet Cross-sell";
            default:
                return "";
        }
    });

    searchDownload.onResults(() => {
        searchDownload.results.rows.forEach((items) => {
            items.motorCycle = items.motorCycle ? "Y" : "N";
            items.rv = items.rv ? "Y" : "N";
            items.waterCraft = items.waterCraft ? "Y" : "N";
        });
    });

    // Computed
    const opps = computed(() => {
        return searchDownload.results.rows;
    });

    const exportColumns = computed(() => {
        switch (route.params.id) {
            case crossSellTypes.SpecialtyCrossSell: return specialtyColumns;
            default: return columns;
        }
    })

    const fieldsMapper = getFieldsMapperFromColumns(exportColumns.value);

    const isEnrolled = computed(() => {
        const enrolledText = route.query.enrollText;
        
        if (enrolledText) {
            return enrolledText;
        }
        if (search.results && search.results.rows && search.results.rows.length > 0) {
            return search.results.rows.some(r => r.mailingDate!=null) ? 'Enrolled' : 'Not Enrolled';
        }
        return '';

    });

    const isOwnerMaster = computed(() => {
        return (agencyDetails.value.statId === route.params.subCode && agencyDetails.value.isOwnerMaster);
    })

    // Methods ----
    function back() {
        router.go(-1);
    }

    function getExportedData() {
        return opps.value.map(item => ({
            ...item,
            marketingChannel: item.mailingDate ? "Direct Mail" : ""
        }));
    }

    async function exportCSV() {
        await setFilterValues();
        ExportParser().exportCSVFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(exportColumns.value));
    }

    async function exportXLS() {
        await setFilterValues();
        ExportParser().exportXLSFromJSON(getExportedData(), null, null, fieldsMapper, beforeTableEncode(exportColumns.value));        
    }

    async function setFilterValues() {
        const filterValues = search.getFilterValues("cross-sell-type-code-sub-code");
        await searchDownload.setFilterValues("cross-sell-type-code-sub-code", filterValues);
        await searchDownload.activate(route);
    }

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    function formatFlag(flag) {
        if (flag == 'Y' || flag == 'N') {
            return flag;
        }
        else {
            if (flag) {
                return 'Y';
            }
            else {
                return 'N';
            }
        }    
    }

    function sortSearch(value) {
        const sortField = sortIds.find(s => s.field == value);
     
        if (!Util.isEmpty(sortField)) {
            if (searchTerm.value.includes(sortField.sortName)) {

                if (searchTerm.value.includes('-desc')) {
                    searchTerm.value = sortField.sortName;
                } else {
                    searchTerm.value = sortField.sortName + '-desc';
                }

            } else {
                searchTerm.value = sortField.sortName;
            }
        } else {
            searchTerm.value = value;
        }


        search.setSortId(searchTerm.value);
    }

</script>

<template>
    <PageTemplate>

        <div class="container cross-sell-view-detail">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />
            <div class="row mt-4">
                <div class="col">
                    <h1 class="text-capitalize" v-html="listHeadline"></h1>
                    <div v-if="isOwnerMaster">Owner Master ID: {{formatStatCode(route.params.subCode)}}</div>
                    <div v-else>Sub-Code Agency ID: {{formatStatCode(route.params.subCode)}}</div>
                </div>

            </div>

            <SearchContainer :search="search" default-view="list" :deep-link="false">

                <!-- Hidden but used for filtering on filterInitiallValue-->
                <DateRangeFilter v-show="false" filter-id="cross-sell-type-code-sub-code" :initial-start-date="route.params.id" :initial-end-date="route.params.subCode" />

                <div class="row">
                    <div class="d-flex">
                        <div class="me-auto">
                            Total Opportunities: <strong>{{ search.results.totalCount }} </strong> <a class="ms-3 no-underline" @click="back()">Back to <span v-html="headline"></span> <i class="bi bi-chevron-right"></i></a>
                        </div>
                        <div>
                            <i class="bi bi-file-earmark-excel me-3 fs-1" title="Download in Excel format" @click="exportXLS"></i>
                            <i class="bi bi-filetype-csv fs-1"  title="Download in CSV format" @click="exportCSV"></i>
                        </div>
                        <div class="px-3">
                            <PageSizeList :options="pageSizeOptions" />
                        </div>
                        <div>
                            <div>
                                <span v-if="search.results.loaded && search.results.rows.length > 0">Showing {{ search.results.startIndex }} of {{ search.results.totalCount }}</span>
                            </div>
                            <PageSelector />
                        </div>
                    </div>
                </div>

                <div class="data-table">
                    <SearchTable :columns="exportColumns" @sort-by="sortSearch">
                        <template v-slot:statCode="row">
                            {{formatStatCode(row.statCode)}}
                        </template>
                        <template v-slot:mailingDate="row">
                            {{formatDate(row.mailingDate)}}
                        </template>

                        <template v-slot:renewalDate="row">
                            {{formatDate(row.renewalDate)}}
                        </template>

                        <template v-slot:motorCycle="row">
                            {{formatFlag(row.motorCycle)}}
                        </template>

                        <template v-slot:waterCraft="row">
                            {{formatFlag(row.waterCraft)}}
                        </template>

                        <template v-slot:rv="row">
                            {{formatFlag(row.rv)}}
                        </template>

                        <template v-slot:marketingChannel="row">
                            <div v-if="row.mailingDate">Direct Mail</div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            No
                            <span v-html="listHeadline"></span> Lists were found that match your criteria.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTable>
                </div>

                <div class="row">
                    <div class="col text-end fit-content"><PageSelector /></div>
                </div>

            </SearchContainer>

        </div> <!-- End Container-->

    </PageTemplate> 
</template>

<style lang="scss">
    .cross-sell-view-detail {
        .btn-sm {
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
        }

        .bi {
            color: $color-2;
            cursor: pointer;
        }

        h1 span {
            color: $success;
        }

        table td, table th {
            white-space: nowrap;
        }
    }
</style>