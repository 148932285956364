<script setup>

    // Links
    // Links is a collection of links that pass the values down to link
    import { ref, defineProps } from 'vue';

    // State ----
    let show = ref(false);

    // Props ----
    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        },
        isExternal: {
            type: Boolean,
            required: false,
            default: false
        },
        openNewWindow: {
            type: Boolean,
            required: true
        }
    });

    // Handlers ----
    function showHide() {
        show.value = !show.value;
    }


</script>

<template>

    <div class="expandable-link"
         :class="show ? 'active' : ''">

            <a @click="showHide"
               class="no-underline"
               :class="show ? 'fw-bold' : 'fw-normal'">
                {{props.title}}
                <i v-if="!show" class="bi bi-chevron-right"></i>
                <i v-if="show" class="bi bi-chevron-down"></i>
            </a>

            <div v-if="show">
                <slot></slot>
            </div>

    </div>

</template>

<style lang="scss">
    .expandable-link {
        margin: 1rem 0;

        .bi {
            font-size: 12px;
        }

        &.active {
            background: $color-2-xlt;
            margin: 0 -1rem;
            padding: 0.5rem 1rem;
        }

        .links-children {
            margin: 1rem 0;
        }
    }
</style>