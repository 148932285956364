
// PersonalizationForm
// Form used to personalize the assets. 

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { site } from '@/Site';

export default class PersonalizationForm extends Form {

    // Constructor
    constructor(model, id = null) {
        // Pass our model to the base class
        super(Object.assign(model ?? {},
            {
                id: id,
                agencyName: '',
                licenseNumber: '',
                agentFirstName: '',
                agentLastName: '',
                agentPhone: '',
                agentEmail: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                province: '',
                postalCode: '',
                imageId: ''
            }));

        // Set required fields
        /*
        this.validator.fields.agencyName.isRequired = true;
        this.validator.fields.agentFirstName.isRequired = true;
        this.validator.fields.agentLastName.isRequired = true;
        this.validator.fields.agentPhone.isRequired = true;
        this.validator.fields.agentEmail.isRequired = true;
        this.validator.fields.addressLine1.isRequired = true;
        this.validator.fields.city.isRequired = true;
        this.validator.fields.state.isRequired = true;
        this.validator.fields.postalCode.isRequired = true;
        */

        // Set maximum lengths
        this.validator.fields.agencyName.maxLength = 100;
        this.validator.fields.licenseNumber.maxLength = 20;
        this.validator.fields.agentFirstName.maxLength = 50;
        this.validator.fields.agentLastName.maxLength = 50;
        this.validator.fields.agentPhone.maxLength = 12;
        this.validator.fields.agentEmail.maxLength = 50;
        this.validator.fields.addressLine1.maxLength = 50;
        this.validator.fields.city.maxLength = 50;
        this.validator.fields.state.maxLength = 2;
        this.validator.fields.postalCode.maxLength = 10;

        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    reset() {
        this.model.name = '';
        this.model.licenseNumber = '';
        this.model.agentFirstName = '';
        this.model.agentLastName = '';
        this.model.agentPhone = '';
        this.model.agentEmail = '';
        this.model.addressLine1 = '';
        this.model.addressLine2 = '';
        this.model.city = '';
        this.model.state = '';
        this.model.postalCode = '';
        this.model.imageId = ''
    }

    /**
     * Call the form to reset for a new payment info.
     */
    create() {
        this.model.id = null;
        this.reset();
    }

    setRequired(field) {
        
        for (let i = 0; i < field.length; i++) {
            let fieldName = field[i].attributeName;
            if (field[i].isRequired) {
                this.validator.fields[fieldName].isRequired = true;
            }
            
        }
        
    }

    onValidate() {
        super.onValidate();


        if (this.model.state == 'CA' || this.model.state == 'AR') {
            this.validator.fields.licenseNumber.isRequired = true;
        } else {
            this.validator.fields.licenseNumber.isRequired = false;
        }
    }

    async onSubmit() {
        if (await site.personalization.generatePdfPreview(this.model, this.validator)) {
            super.reset();
        }
    }
}