<script setup>

    // CurrentAgency
    // Shows current agency, and provides option to change if available.

    // Imports
    import { computed, onMounted, ref, watch, reactive } from 'vue';
    import { site } from '@/Site';
    import useEventsBus from '@/site/services/EventBus';
    import debounce from "lodash/debounce"
    import { isEmpty, isNotEmpty, formatStatCode } from '@/util';
    import { useRoute, useRouter } from 'vue-router';

    const { emit } = useEventsBus();

    // Components
    import ModalBox from '@/site/components/ModalBox';

    // State ----
    let search = reactive({ results: [] });

    const selectedAgency = ref('');
    const agencyName = ref('');
    const statCode = ref('');

    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    const currentDefaultAgency = reactive(site.agencyDetails.defaultAgency);
    const isAgencySelectedOnce = ref(site.agencyDetails.isAgencySelectedOnce);

    const modalRef = ref(null);
    const isLoaded = ref(false);
    const showChangeAgency = ref(false);
    const initialAgencyId = ref('');


    const route = useRoute();
    const router = useRouter();
    // Methods
    async function changeAgency() {


        storeInitialAgencyId();

        await site.agencyDetails.setDefaultAgency(selectedAgency.value);
        //Needs to reload the cart when the agency is changed, old Cart items might have been removed, hence need to reload.
        await site.cart.loadCart();
        await site.personalizationInfo.newLogin();

        modalRef.value.close();

        // Redirect to home except redirect from oldMSC
        if (!route.redirectedFrom)
            router.push({ path: '/home' });
    }

    async function returnToDefault() {
        // reset state
        await site.personalizationInfo.newLogin();
        
        
        if (isAgentRole.value) {
            const payload = { id: initialAgencyId.value }
            await site.agencyDetails.returnToDefault(payload);
        }
        else {
            const payload = { id: 0, isDefault: true }
            await site.agencyDetails.returnToDefault(payload);
        }
        router.push({ path: '/home' });
    }

    function storeInitialAgencyId() {
        if (isEmpty(initialAgencyId.value)) {
            if (isAgentRole.value && selectedAgency.value > 0) {
                initialAgencyId.value = selectedAgency.value;
                site.agencyDetails.storeInitialAgencyId(selectedAgency.value);
            }
        }
    }

    function exitAgencyChange() {
        modalRef.value.close();
    }

    function selectAgency(id) {
        selectedAgency.value = id;
    }

    const isAgentRole = computed(() => {
        const roles = site.userRoles.showAgencyModalRoles();
        return currentRoleId.value && roles.includes(currentRoleId.value);
    });

    // Computed
    const isDefaultAgency = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

    const isAgencyNull = computed(() => {
        return currentAgencyId.value == null;
    });

 
    const hasMoreThanOneAgencyToSelect = computed(() => {
        if (search && search.results && search.results.length > 1) {
            return true
        }
        return false
    });

    const hasResults = computed(() => {
        return search && search.results && search.results.length > 0;
    });

    const results = computed(() => {
        if (hasResults.value) {
            return search.results
        }
        return [];
    });

    const currentRoleId = computed(() => {
        if (site && site.user && site.user.primaryRoleId) {
            return site.user.primaryRoleId
        }
        return null;
    });

    const showAgencyModal = computed(() => {
        var roles = site.userRoles.showAgencyModalRoles();
        if (currentRoleId.value) {
            return roles.includes(currentRoleId.value)
                && isDefaultAgency.value
                && showChangeAgency.value
                && modalRef
                && !isAgencySelectedOnce.value;
        }
        return false;
    });

    const showReturnToProfile = computed(() => {
        if (isAgentRole.value) {
            return isNotEmpty(initialAgencyId.value) 
                && (initialAgencyId.value != selectedAgency.value && initialAgencyId.value != currentAgencyId.value);
        } else {
            return Number(selectedAgency.value) > 0 || Number(currentAgencyId.value) > 0;
        }

    });

    const currentAgencyInfo = computed(() => {
        if (currentAgencyId.value > 0) {
            return currentDefaultAgency;
        }
        return {};
    });

    const ownerMasterPrefix = computed(() => {
        if (currentAgencyInfo.value.isOwnerMaster) {
            return "OM - ";
        }
        return "";
    })

    watch(currentAgencyId, async () => {
        emit('changeAgency');
    })

    watch(() => [currentRoleId.value, showChangeAgency.value], async () => {
        if (showAgencyModal.value) {
            modalRef.value.show()
            site.agencyDetails.isAgencySelectedOnce.value = true
            site.agencyDetails.removeInitialAgencyId()
            initialAgencyId.value = '';
        }
    }, { deep: true, immediate: true })

    const debouncedFilter = debounce(getAgencies, 500);

    async function getAgencies() {
        var payload = {
            agencyName: agencyName.value,
            statCode: statCode.value
        };
        var response = await site.agencyDetails.getAgencies(payload);
        search.results = response.results;
        isLoaded.value = true;
    }

    onMounted(async () => {
        await getAgencies();
        if (hasMoreThanOneAgencyToSelect.value) {
            showChangeAgency.value = true;
        }
        if (isAgentRole.value && site.agencyDetails.getInitialAgencyId()) {
            initialAgencyId.value = site.agencyDetails.getInitialAgencyId();
        } else {
            site.agencyDetails.removeInitialAgencyId();
            initialAgencyId.value = '';
        }

    });

</script>
<template>
    <div>
        <div class="change-agency">
            <div class="container-fluid agency-outer-panel">
                <div class="agency-panel container">
                    <span v-if="isLoaded && (isDefaultAgency || isAgencyNull)">Welcome  {{site.user.firstName}} {{ site.user.lastName}}</span>
                    <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">

                        <template v-if="!isEmpty(currentAgencyInfo)">
                            <span>{{formatStatCode(currentAgencyInfo.statCode)}}, {{ownerMasterPrefix}}{{currentAgencyInfo.name}}</span>
                        </template>
                        <button @click="getAllAgencies" v-if="showChangeAgency" class="btn-change-agency btn btn-outline-neutral-dk" data-bs-toggle="modal" data-bs-target="#changeAgency">Change Agency</button>
                        <button @click="returnToDefault()" v-if="showChangeAgency && showReturnToProfile" class="btn-change-agency btn btn-outline-neutral-dk">Return To My Profile</button>
                    </ShowOnlyForRoles>
                </div>
            </div>
        </div>

        <ModalBox modalId="changeAgency" ref="modalRef" title="Change Agency" modal-size="modal-lg">
            <template v-slot:body>
                <div class="row mb-3">
                    <label>Agency Name</label>
                    <div class="col">
                        <input type="text" v-model="agencyName" class="form-control n-text-box-filter" placeholder="Search Agency Name" @input="debouncedFilter" />
                    </div>
                </div>

                <div class="row mb-4">
                    <label>Agency Statcode</label>
                    <div class="col">
                        <input type="text" v-model="statCode" class="form-control n-text-box-filter" placeholder="Search Agency Statcode" @input="debouncedFilter" />
                    </div>
                </div>

                <div class="agency-container">
                    <template v-if="isLoaded">
                        <div v-if="hasResults">
                            <div class="n-search-grid row">
                                <div v-for="result in results" class="tile col col-12" :key="result.id">
                                    <div class="ps-3" :class="{selected: result.id == selectedAgency }" @click="selectAgency(result.id)">
                                        <strong> {{result.name}} </strong> {{result.statCode}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Template for no results -->
                        <div v-else>
                            No agencies were found that match your criteria.
                        </div>
                    </template>
                </div>

                <div class="row mt-4">
                    <div class="col text-end">
                        <button class="btn btn-outline-primary me-3" @click="exitAgencyChange">Exit Change Agency</button>
                        <button class="btn btn-primary" @click="changeAgency">Select</button>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>
<style lang="scss">
    .change-agency {

        label {
            font-weight: bold
        }

        .agency-outer-panel {
            background-color: $gray-1;
        }

        .agency-panel {
            padding: 1rem;
            padding-left: 0.5rem;
            background-color: $gray-1;
            color: $neutral-dk;
            font-weight: $bold;
        }

        .btn-change-agency {
            font-size: 0.75rem;
            margin-left: 1.5rem;
        }
    }

    .agency-container {
        border: 1px solid $gray-3;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        .selected {
            background: $highlight-4;
        }

        .n-search-grid .tile {
            cursor: pointer;
            margin-bottom: 0;
        }

        .n-search-grid .tile:hover {
            background: $highlight-4;
        }
    }
</style>