<script setup>
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ToolTip from '@/site/components/ToolTip';

    import { onMounted, reactive, ref /*computed, watch */ } from 'vue';
    import { site } from '@/Site'
    import { useRouter, useRoute } from 'vue-router';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { getProgramTypeForOrderState, getOppIdForOrderState } from '@/constants'
    import { isEmpty, isNotEmpty } from '@/util';

    const router = useRouter();
    const route = useRoute();

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const product = reactive({});
    const isLoaded = ref(false);

    async function selectOption(selectedOption) {
        const programType = getProgramTypeForOrderState(product.attributes.dataListType);
        if (isEmpty(programType)) {
            console.error("Not able to identify the Program type based on the data list type");
            return;
        }
        if (selectedOption == 'Yes') {

            if (!site.errorHandler.canStartExpressOrder()) { return false; }
            
            const bulkOrderStatePayload = {
                channelType: ChannelTypes.DIRECT_MAIL,
                productId: product.id,
                programType: programType,
                productType: product.attributes.productTypes?.join(";"),
                statCode: site.agencyDetails.selectedAgencyDetails.statId,
                oppId: getOppIdForOrderState(product.attributes.dataListOption)
            };
            const response = await site.expressOrderPersonalizationInfo.startOrder(bulkOrderStatePayload);
            
            if (site.errorHandler.handleStartOrderError(response)) {
                router.push({
                    name: 'ExpressOrderSubCodesPage',
                    params: {
                        bulkOrderStateId: response.id,
                    }
                });
            }
        }
        else {
            if (!site.errorHandler.canStartOrder()) { return false; }
            orderFlowDetails.productId = product.id;
            orderFlowDetails.programType = programType;
            orderFlowDetails.productType = product.attributes.productTypes?.join(";");
            orderFlowDetails.oppId = getOppIdForOrderState(product.attributes.dataListOption);
            const response = await site.personalizationInfo.startOrder(ChannelTypes.DIRECT_MAIL);
            if (site.errorHandler.handleStartOrderError(response)) {
                router.push({
                    name: 'PersonalizeStep1Page',
                    params: {
                        id: response.id,
                        parentId: response.id,
                        productId: orderFlowDetails.productId
                    },
                    query: {
                        channelType: ChannelTypes.DIRECT_MAIL,
                        isSubscription: product.attributes.isSubscription
                    }
                });
            }
        }
    }

    onMounted(async () => {
        const productId = route.params.productId
        if (isNotEmpty(productId)) {
            Object.assign(product, await site.products.getProductDetail(productId));
        }
        isLoaded.value = true;
    });


</script>

<template>
    <PageTemplate>
        <div class="container root-page express-confirmation">
            <GlobalTabBar />

            <div class="row mt-5" v-if="isLoaded">

                <div class="col-12 text-center mt-5">
                    <h1>Would you like to use Express Ordering to place</h1>
                    <ToolTip placement="bottom">
                        <h1>one order for yourself and the Sub Codes to which you have access <span><i class="bi bi-question-circle-fill"></i></span></h1>
                        <template #content>
                            <p class="tip">
                                <span>Express Ordering</span>: This functionality allows you to place one order for the substats that you have authorization/access to.
                                Important to note the custom personalization information ( Agent Name, Contact Information, Phone Number and Signature, Logo) will be
                                identical for all the SubCodes selected. If you to chose to have custom personalization for some or all your Sub Codes, you'll need to
                                personlaize SubCode for each separately. The available Sub Codes will be driven by state(s) in which the asset usage is permitted.
                            </p>
                        </template>
                    </ToolTip>
                </div>
                <div class="col-4"></div>
                <div class="col-4 text-center ">
                    <div class="mt-4">
                        <button class="m-2 px-5 btn btn-outline-primary" @click="selectOption('No')">No</button>
                        <button class="m-2 px-5 btn btn-primary" @click="selectOption('Yes')">Yes</button>
                    </div>
                </div>

            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .express-confirmation {

        .popper {
            width: 40%;
        }

        .tip {
            font-size: 12px;
            text-align: left;

            span {
                color: lightseagreen;
                font-weight: bold;
            }
        }
    }
    
</style>