<script setup>

    // ShipToContact
    // Allows the user to select a single contact as the ship-to destination.

    // Components ----
    import MarketingContacts from '@/site/components/marketing/MarketingContacts';

    // Imports ----
    import { ref, onMounted,defineEmits, defineProps } from 'vue';
    import { site } from '@/Site';

    // State ----
    const selection = ref([]);

    const props = defineProps({
        // If true, this will be styled for selecting multiple items.
        multiSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        // Indicates whether the selection can be toggled on/off
        toggle: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // Events ----
    const emit = defineEmits(['selected', 'deselected', 'hasShippingDetails']);

    // Computed ----

    // Handlers ----
    onMounted(() => {
        update();
    });

   

    function update() {
        if (typeof site.cart.current.shipToList === 'undefined') return;

        if (site.cart.current.shipToMode === site.cart.ShipToMode.ShipToContact) {
            selection.value.push(site.cart.current.shipToList[0]?.marketingContactId);
        }
        else {
            selection.value = [];
            for (let contact of site.cart.current.shipToList) {
                if (contact.marketingContactId !== null && contact.marketingContactId !== undefined)
                    selection.value.push(contact.marketingContactId);
            }
        }
    }

    function onContactSelected(contact) {

        if (site.cart.current.shipToMode === site.cart.ShipToMode.ShipToContacts) {
            selection.value.push(contact.id);
            emit('selected', contact);
        }
        else {
            selection.value = []
            selection.value.push(contact.id);
            site.cart.setShipToMarketingContact(contact.id);
        }
        emit('hasShippingDetails', false);
    }

    function onContactDeselected(contact) {

        if (site.cart.current.shipToMode === site.cart.ShipToMode.ShipToContacts) {
            selection.value = selection.value.filter(x => x !== contact.id);
            emit('deselected', contact);
        }
    }

</script>

<template>

    <div class="ship-to-contact">
        <template v-if="site.cart.current.shipToMode === site.cart.ShipToMode.ShipToContacts">            
            <MarketingContacts :allow-create="false" :can-select="true" :selection="selection" @selected="onContactSelected" @deselected="onContactDeselected" :multiSelect="props.multiSelect" :toggle="props.toggle" />
        </template>
        <template v-else>            
            <MarketingContacts :can-select="true" :selection="selection" @selected="onContactSelected" />
        </template>

    </div>

</template>

<style lang="scss">

    .ship-to-contact {
    }

</style>