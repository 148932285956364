<script setup>

    // AnnulaAutoSelectPolicyholders
    // Step 2 SelectPolicyholders

    // Constants ----

    const annualAutoColumns = [
        { field: 'agencyNumber', title: 'Sub Code', isSortable: true, custom: true },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Current Policy Type', isSortable: false },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'middleName', title: 'Middle Name', isSortable: false },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'recommendedIndicator', title: 'Recommended Indicator', isSortable: true },
        { field: 'youngDriverIndicator', title: 'Young Driver Indicator', isSortable: false },
        { field: 'meritIndicator', title: 'Merit Indicator', isSortable: false },
    ];

    const customersUpRenewalColumns = [
        { field: 'statCode', title: 'Sub Code', isSortable: true, custom: true },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Current Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'middleName', title: 'Middle Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
    ]

    const indicatorOptions = [
        { id: 'true', text: 'Yes', values: ['true'] },
        { id: 'false', text: 'No', values: ['false'] }
    ]

    const indicatorData = [
        {
            id: 'recommended-indicator',
            name: Indicators.RECOMMENDED
        },
        {
            id: 'young-driver-indicator',
            name: Indicators.YOUNG_DRIVER
        },
        {
            id: 'merit-indicator',
            name: Indicators.MERIT
        }
    ]

    // Imports
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { ref, reactive, computed, defineProps, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { DataListOptionToOppId, ChannelTypes } from '@/constants';
    import { retentionTypes } from '@/site/components/OpportunitiesTile';
    import { formatStatCode } from '@/util';
    import { Indicators } from '@/constants'
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';


    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    //props
    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    // State
    const router = useRouter();
    const emit = defineEmits(['saveForLater']);

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const sortPrefix = orderFlowDetails.oppId === DataListOptionToOppId.AnnualAutoConvert6months ? "auto-" : "";

    const search = new Search(searchId(), 'row', sortId());
    
    const searchTerm = ref('statCode');

    let selectedList = reactive([]);

    const detail = reactive({ product: props.product });


    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    const getColumns = computed(() => {
        switch (orderFlowDetails.oppId) {
            case DataListOptionToOppId.AnnualAutoConvert6months:
                return annualAutoColumns;
            case DataListOptionToOppId.AnnualAutoCustomersRenewal:
                return customersUpRenewalColumns;
            default:
                return [];
        }
    })

    const isAnnualAuto = computed(() => {
        if (orderFlowDetails.oppId === DataListOptionToOppId.AnnualAutoConvert6months)
            return true
        return false
    })

    if (isAnnualAuto.value  == true) {
            search.setFilterValues('recommended-indicator', ['true'])
    }

    const statCodeField = computed(() => {
        switch (orderFlowDetails.oppId) {
            case DataListOptionToOppId.AnnualAutoConvert6months:
                return "agencyNumber";
            case DataListOptionToOppId.AnnualAutoCustomersRenewal:
                return "statCode";
            default:
                return "";
        }
    })

    // Methods
    function searchId() {
        switch (orderFlowDetails.oppId) {
            case DataListOptionToOppId.AnnualAutoConvert6months:
                return retentionTypes.AnnualAuto;
            case DataListOptionToOppId.AnnualAutoCustomersRenewal:
                return retentionTypes.CustomersUpRenewal;
            default:
                return "";
        }
    }

    function sortId() {
        switch (orderFlowDetails.oppId) {
            case DataListOptionToOppId.AnnualAutoConvert6months:
                return "auto-renewalDate";
            case DataListOptionToOppId.AnnualAutoCustomersRenewal:
                return "renewalDate";
            default:
                return "";
        }
    }

    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        await UpdateRecipients();
        emit('saveForLater');
    }

    async function next() {
        UpdateRecipients();
        router.push({
            name: 'PersonalizeStep3ReviewPage',
            params: {id: orderFlowDetails.id}
        });
    }

    async function UpdateRecipients() {
        const allRows = search.results.rows;
        const selected = allRows.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {
            return {
                "externalId": sl.externalId,
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2,
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "renewalDate": sl.renewalDate
            };
        });
        await site.personalizationInfo.addMailContacts(selected);
    }

    function sortSearch(value) {
        value = sortPrefix + value

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    //methods
    function selectAll(isSelectAll, list) {
        selectedList.length = 0;
        Object.assign(selectedList, list.map(l => l.policyNumber));
    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
    }

    search.onResults(() => {
        if (props.orderState?.mailContacts?.length == 0) {
            selectedList.length = 0;
            Object.assign(selectedList, search.results?.rows?.map(mc => mc.policyNumber));
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const currentSelectedList = props.orderState?.mailContacts.map(mc => mc.policyNumber);
            const filteredSelectedList = search?.results?.rows?.filter(mc => currentSelectedList.includes(mc.policyNumber));
            if (filteredSelectedList.length > 0) {
                Object.assign(selectedList, filteredSelectedList.map(mc => mc.policyNumber));
            }
            else {
                selectedList.length = 0;
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.policyNumber));
            }
        }
    });


</script>

<template>

    <div class="row my-5">

        <div class="row">
            <div class="col">

            </div>
        </div>

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col">
            <SearchContainer v-if="orderFlowDetails.oppId && orderFlowDetails.statCode" :search="search" default-view="list" :deep-link="false">
                <div class="row" v-if="isAnnualAuto">

                    <div class="col-4" v-for="indicatorItem in indicatorData" :key="indicatorItem.id">
                        <label class="fw-bold">{{ indicatorItem.name }}</label>
                        <div class="row">
                            <div class="col-3" v-for="opt of indicatorOptions" :key="opt.id">
                                <AddFilterCheckBox :filter-id="indicatorItem.id" :values="opt.values" :label="opt.text" />
                            </div>
                        </div>
                    </div>

                </div>


                <div class="row mb-2 mt-3">
                    <div class="d-flex">
                        <div class="me-auto">
                            <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                        </div>
                    </div>
                </div>
                <div class="data-table">

                    <SearchTableV1 :columns="getColumns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">

                            <template v-slot:[statCodeField]="row">
                                {{formatStatCode(row[statCodeField])}}
                            </template>

                            <template v-slot:mailingDate="row">
                                {{formatDate(row.mailingDate)}}
                            </template>

                            <template v-slot:renewalDate="row">
                                {{formatDate(row.renewalDate)}}
                            </template>

                            <template v-slot:marketingChannel="row">
                                <div v-if="row.mailingDate">Direct Mail</div>
                            </template>

                            <!-- Template for no results -->
                            <template #empty>
                                None of your policyholders currently meet the criteria for this type of campaign.
                            </template>

                            <!-- Template while results are initially loading -->
                            <template #loading>
                                <div class="row">
                                    <div class="col text-center">
                                        <div class="spinner-border me-3" role="status"></div>
                                        <div class="spinner-border me-3" role="status"></div>
                                        <span class="fw-bold">Performing a search for the data</span>
                                        <div class="spinner-border mx-3" role="status"></div>
                                        <div class="spinner-border" role="status"></div>
                                    </div>
                                </div>
                                <TableSkeleton />
                            </template>
                        </SearchTableV1>
</div>

            </SearchContainer>
        </div>
    </div>

    <div class="row mt-3">

        <div class="d-flex mb-3">
            <div class="me-auto">
                <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>
            </div>
            <div>
                <button v-if="listLength > 0" @click="saveForLater" class="btn btn-primary me-3">Save For Later</button>
                <button v-if="listLength > 0" @click="next" class="btn btn-primary">Next</button>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
</style>
