
// SignInForm
// Implements the form that signs a user in.

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';
import { site } from '@/Site';

export default class SignInForm extends Form {

    // Constructor
    constructor(router) {
        // Pass our model to the base class
        super(
          {
                username: '',
                statCode1: '',
                statCode2: '',
                keepSignedIn: false
          });

        this.router = router;

        this.validator.fields.username.isRequired = true;

        // If we want the form to validate immediately based on automatic validations (e.g. to disable a button), do so here
        this.validate();
    }

    onValidate() {
        super.onValidate();
    }

    async onValidateAsync() {
        super.onValidateAsync();
    }

    async onSubmit() {
        if (!await site.mockSignIn(this.model.username, this.router)) {
            // Show messages
            this.validator.addError('The user name or password provided is not valid.');
        }
    }
}