<script>
    // SearchTable
    // Displays rows in a tabular format.
    // This component is created for temporary purpose to move the express ordering changes for testing.
    // When we start working on the fix the address the issue in the SearchTable component
    // which is in dd - nucleus - vue package or lib /../../ util folder
    // This component changes may be moved to SearchTable component and this can be deleted

    export const SelectionType = {
        NONE: 'NONE',               // No selection
        SINGLE: 'SINGLE',           // Single selection
        MULTIPLE: 'MULTIPLE'        // Multiple selection
    }

    export const constants = {
        Keys: {
            SEARCH: 'search',
            RESULTS: 'results'
        }
    }
</script>

<script setup>

    // Imports ----
    import { defineProps, defineEmits, inject, reactive, ref, computed } from 'vue';
    import { Util } from "@dd-nucleus/nucleus-vue";

    // Props ----
    const props = defineProps({
        // An array representing the ancestry path, with the selected item first, its parent second, etc. Each column contains:
        // - id - Unique ID of the column
        // - title - Title of the column shown in the header
        columns: {
            type: Array,
            required: true,
            default: () => []
        },
        // Text to show in style of selected item if no items exist.
        rows: {
            type: String,
            required: false,
            default: ''
        },
        striped: {
            type: Boolean,
            required: false,
            default: true
        },
        // Indicates the type of selection to provide, if any
        selectionType: {
            type: String,
            required: false,
            default: SelectionType.NONE
        },
        // Identifies the field used as the unique ID for the results
        idField: {
            type: String,
            required: false,
            default: 'id'
        },
        // Sets the loaded value of select all to true 
        selectAll: {
            type: Boolean,
            required: false,
            default: false
        },
        // this prop is set to true only if you are returning the isValid column in search results data.
        showInvalid: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedRecords: {
            type: Array,
            required: false
        }
    });

    // State ----    
    const search = inject(constants.Keys.SEARCH);
    const results = reactive(inject(constants.Keys.RESULTS));
    const isLoaded = ref(false);

    // Computed ----
    const tableCssClasses = computed(() => {
        const css = {
            'table': true,
            //'table-sm': true,
            'table-striped': props.striped
        };

        return css;
    });

    const selectedRecords = computed({
        get() {
            return props.selectedRecords;
        },
        set(value) {
            emit('selected', value);
        }
    });

    const hasResults = computed(() => {
        return (results.count > 0);
    });

    const selectAll = computed({
        get() {
            return props.selectAll;
        },
        set(value) {
            onSelectAll(value);
        }
    });

    function isValid(row) {
        if (props.showInvalid) {
            if (row.isValid) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    // Events ----
    const emit = defineEmits(['selected', 'sortBy', 'selectAll']);

    // Methods ----
    function getColumnUrl(column, result) {
        return column.url.replace('{id}', result.id);
    }

    // Handlers ----
    search.onResults(() => {
        isLoaded.value = true;
    });


    function onSelectAll(value) {
        emit('selectAll', value, results.rows);
    }

    function emitSortBy(value) {
        emit('sortBy', value);
    }

</script>

<template>

    <div v-if="isLoaded && hasResults" class="table-responsive">
        <table class="n-search-table" :class="tableCssClasses">

            <thead>
                <tr>
                    <th v-if="props.selectionType === SelectionType.SINGLE || props.selectionType === SelectionType.MULTIPLE" class="col-head selection-head">
                        <input v-model="selectAll" v-if="props.selectionType === SelectionType.MULTIPLE" class="checkbox form-check-input" type="checkbox" title="Select all" >
                    </th>
                    <th v-for="column in props.columns" :key="column.field" class="col-head" scope="col">
                        {{ column.title }} 
                        <i @click="emitSortBy(column.field)" v-if="column.isSortable" class="ms-3 bi bi-chevron-expand"></i>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(result) in results.rows" :key="result[props.idField]" :class="isValid(result)? '': 'invalid'">
                    <td v-if="props.selectionType === SelectionType.SINGLE || props.selectionType === SelectionType.MULTIPLE">
                        <input class="checkbox form-check-input" type="checkbox" :value="result[props.idField]" 
                               v-model="selectedRecords" :id="result[props.idField]" 
                               v-show="isValid(result)">
                    </td>
                    <td v-for="column in props.columns" :key="column.field + result[props.idField]">
                        <template v-if="Util.isUndefined(column.custom) || column.custom === false">
                            <template v-if="Util.isNotEmpty(column.url)">
                                <router-link :to="getColumnUrl(column, result)">{{ result[column.field] }}</router-link>
                            </template>
                            <template v-else>
                                {{ result[column.field] }}
                            </template>
                        </template>
                        <template v-else>
                            <slot :name="column.field" v-bind="result">
                            </slot>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <slot v-if="isLoaded && !hasResults" name="empty"></slot>

    <slot v-if="!isLoaded" name="loading"></slot>


</template>

<style lang="scss">

    .n-search-table {
        .selection-head {
            width: 1.5rem;
        }

        .checkbox {
            width: 1rem;
            height: 1rem;
        }

        .form-check-input {
            margin-top: 0.125rem;
        }

        .invalid {
            border: 1px solid $error;
            background: #f4cccc;
            border-color: $error; // update with right color code?
        }
    }

</style>