<script setup>

    //UploadErrors 

    // Imports ----
    import { defineProps, defineEmits, computed, ref } from 'vue';
    import { site } from '@/Site';


    // Props ----
    const props = defineProps({

        uploadedListSummary: {
            type:Object,
            required: true
        },
        editingMode: {
            type: Boolean,
            required: true,
            default:false
        }

    });

    const updatedAddresses = ref([]);

    const errorAddresses = computed(() => {
        return props.uploadedListSummary.uploadedListItems?.filter(addr => addr.validationErrors.length > 0);
    })

    const isEditing = computed(() => {
        return props.editingMode;
    })

    async function  goToNext() {
       
        const updatedAddresses = props.uploadedListSummary.uploadedListItems?.map((addr) => { return addr.address });
        const response = await site.addressList.validateAddresses(updatedAddresses);
        
        if(response.succeeded){
            emits('update', response.uploadedListSummary);
        }
    }

    function updateAddress(address, index){
        updatedAddresses.value.splice(index, 1, address)
    }

    function hasError(validationErrors, field) {

        const errors = validationErrors.filter(item => item.field == field);
        if (errors.length > 0)
            return true;

        return false;
    }

    function getErrorMessage(validationErrors, field) {
        const errors = validationErrors.filter(item => item.field == field);
        if(errors.length > 0)
            return errors[0].errorMessage;
        return '';
    }

    function cancelEdit(){
     emits('cancel');
    }

    // Emits ----
    const emits = defineEmits(['update', 'cancel']);

</script>

<template>

        <div class="row mb-5 mt-2">
            <div class="col">

                <div class="error-records table-responsive">
                    <table class="table table-striped w-100 mw-100">
                        <thead class="title">
                            <tr>
                                <th></th>
                                <th>Addressee</th>
                                <th>Address Line 1</th>
                                <th>Address Line 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Country</th>
                                <th>PhoneNumber</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(addr, index) in errorAddresses" :key="addr">
                                <td><i class="bi bi-x-circle-fill btn-danger" style="color:red" /></td>

                                <td v-if="hasError(addr.validationErrors, 'Addressee')">
                                    <template v-if="isEditing">
                                        <input v-model="addr.address.addressee" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'Addressee')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'Addressee')">
                                            {{ addr.address.addressee }}
                                        </div>
                                    </template>
                                </td>
                                <td v-else>{{addr.address?.addressee}}</td>

                                <td v-if="hasError(addr.validationErrors, 'AddressLine1')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.addressLine1" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'AddressLine1')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'AddressLine1')">
                                            {{ addr.address.addressLine1 }}
                                        </div>
                                    </template>

                                </td>
                                <td v-else>{{addr.address?.addressLine1}}</td>

                                <td>{{addr.address?.addressLine2}}</td>

                                <td v-if="hasError(addr.validationErrors, 'City')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.city" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'City')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'City')">
                                            {{ addr.address.city }}
                                        </div>
                                    </template>

                                </td>
                                <td v-else>{{addr.address?.city}}</td>

                                <td v-if="hasError(addr.validationErrors, 'State')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.state" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'State')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'State')">
                                            {{ addr.address.state }}
                                        </div>

                                    </template>

                                </td>
                                <td v-else>{{addr.address?.state}}</td>

                                <td v-if="hasError(addr.validationErrors, 'PostalCode')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.postalCode" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'PostalCode')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'PostalCode')">
                                            {{ addr.address.postalCode }}
                                        </div>
                                    </template>

                                </td>
                                <td v-else>{{addr.address?.postalCode}}</td>

                                <td v-if="hasError(addr.validationErrors, 'Country Code')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.countryCode" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'Country Code')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'Country Code')">
                                            {{ addr.address.countryCode }}
                                        </div>
                                    </template>

                                </td>
                                <td v-else>{{addr.address?.countryCode}}</td>

                                <td v-if="hasError(addr.validationErrors, 'ShipToPhoneNumber')">

                                    <template v-if="isEditing">
                                        <input v-model="addr.address.shipToPhoneNumber" type="text" style="border: 1px solid red;" @change="updateAddress(addr.address,index)" :title="getErrorMessage(addr.validationErrors, 'ShipToPhoneNumber')" />
                                    </template>
                                    <template v-else>
                                        <div class="w-100" style="border: 1px solid red; height: 1.1rem" :title="getErrorMessage(addr.validationErrors, 'ShipToPhoneNumber')">
                                            {{ addr.address.shipToPhoneNumber }}
                                        </div>
                                    </template>

                                </td>
                                <td v-else>{{addr.address?.shipToPhoneNumber}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="row my-5" v-if="props.editingMode">
                    <div class="col text-center">
                        <button class="btn btn-outline-primary  me-3" @click="cancelEdit()">Cancel</button>
                        <button class="btn btn-primary" @click="goToNext()">Next</button>
                    </div>
                </div>

            </div>
        </div>
    
</template>

<style lang="scss">

</style>