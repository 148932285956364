
// ZipSearch
// Form used to find zip codes for prospecting

// Imports ----
import { Form } from '@dd-nucleus/nucleus-vue';

export default class ZipSearch extends Form {

    // Constructor
    constructor(model, id = null) {
        // Pass our model to the base class
        super(Object.assign(model ?? {},
            {
                id: id,
                city: '',
                state: ''
            }));

        this.validator.fields.city.isRequired = true;
        this.validator.fields.state.isRequired = true;

        this.validator.fields.city.maxLength = 50;
        this.validator.fields.state.maxLength = 2;
        
        // Force an immediate validation (e.g. for buttons disabled until valid)
        this.validate();
    }

    reset() {
        this.model.city = '';
        this.model.state = '';
    }

    /**
     * Call the form to reset for a new payment info.
     */
    create() {
        this.model.id = null;
        this.reset();
    }

    onValidate() {
        super.onValidate();
    }
}