<script>

    // PaymentInfoPanelCyberSource
    // Shows the payment info panel of the my account area.

    export const getMaskPen = (data) => {

        const json = JSON.parse(data);

        return '**** **** **** ' + json.card.last4;
    }

    export const getCardType = (data) => {

        const json = JSON.parse(data);

        return json.card.brand;
    }

    export const getCardTypeName = (data) => {

        const json = JSON.parse(data);

        switch (json.card.brand) {
            case 'amex':
                return 'American Express';
            case 'visa':
                return 'Visa';
            case 'mastercard':
                return 'MasterCard';
            case 'discover':
                return 'Discover';
            default:
                return '';
        }
    }

    export const getCardDate = (d) => {

        let date = new Date(d)
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (mm < 10) { mm = '0' + mm }

        return d = mm + '/' + yyyy;

    }

</script>

<script setup>

    // PaymentInfoPanelStripe
    // Shows the payment info panel of the my account area.

    // Constants ----
    const constants = {
        DEFAULT_PAGE_SIZE: 12,
        DEFAULT_SORT_OPTION: 'newest-to-oldest'
    }

    // Components ----
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';
    import PaymentMethodTile from '@/site/components/payment-info/PaymentMethodTile';
    import AddPaymentMethod from '@/site/components/payment-info/AddPaymentMethodStripe';

    // Imports ----
    import { defineProps, defineEmits, reactive, ref, onBeforeMount, watch } from 'vue';
    import { site } from '@/Site';
    import { store, Search, Util } from '@dd-nucleus/nucleus-vue';
    import PaymentMethodForm from '@/site/forms/PaymentMethodFormStripe';

    // Props ----
    const props = defineProps({
        // Allow creation of a payment?
        allowCreate: {
            type: Boolean,
            required: false,
            default: true
        },
        // Whether to use a large, primary color button rather than an ActionButton for creating.
        largeCreateButton: {
            type: Boolean,
            required: false,
            default: false
        },
        showSelectButton: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    // State ----
    const form = reactive(new PaymentMethodForm());

    const editing = ref(false);

    const paymentMethodIdToDelete = ref('');

    const search = new Search('user-payment-methods-for-user', 'tile', constants.DEFAULT_SORT_OPTION, constants.DEFAULT_PAGE_SIZE);

    const sortOptions = [
        { id: constants.DEFAULT_SORT_OPTION, text: 'Newest to Oldest' },
        { id: 'oldest-to-newest', text: 'Oldest to Newest' }
    ];

    const pageSizeOptions = [12, 24, 36, 48, 60];

    const showOverlay = ref(false);

    const selectedPaymentId = ref('')

    // Events ----
    const emit = defineEmits(['created', 'hasPaymentDetails']);

    // Handlers ----
    function onCreate() {
        form.create();
        editing.value = true;
    }

    function onCancel() {
        editing.value = false;
    }

    async function onSetDefault(id) {
        await site.paymentInfo.setUserPaymentMethodAsDefault(id);
        site.refreshSearches(search.searchType);
    }

    async function onDelete(id) {
        paymentMethodIdToDelete.value = id;
        showOverlay.value = true;
    }

    async function onDeleteConfirmed() {
        await site.paymentInfo.deleteUserPaymentMethod(paymentMethodIdToDelete.value);
        site.refreshSearches(search.searchType);
        if (selectedPaymentId.value === paymentMethodIdToDelete.value)
            emit('hasPaymentDetails', true);
        // Reset the variables
        paymentMethodIdToDelete.value = '';
        showOverlay.value = false;
    }

    function onDeleteCanceled() {
        paymentMethodIdToDelete.value = '';
        showOverlay.value = false;
    }

    async function savePaymentToken(method) {

        editing.value = false;
        site.refreshSearches(search.searchType);
        site.toast.success('Payment Method has been saved.', 'Success');
        emit('created');
        onCreditCardSelected({ id: method.id });

    }

    async function onCreditCardSelected(item) {
        selectedPaymentId.value = item.id;
        await store.cart.setPaymentMethod(item.id);

    }

    // Watch ----
    watch(selectedPaymentId, async (newValue, oldValue) => {
        if (newValue)
            emit('hasPaymentDetails', false);
        else
            emit('hasPaymentDetails', true);
    })

    // Lifecycle ----
    onBeforeMount(async () => {
        await site.paymentInfo.getPaymentMethods();
        selectedPaymentId.value = site.paymentInfo.defaultPaymentMethodId.value
        if (!Util.isEmpty(selectedPaymentId.value))
            onCreditCardSelected({ id: selectedPaymentId.value });
        else
            emit('hasPaymentDetails', true);
    });

</script>

<template>
    <div class="account-panel payment-info-panel">

        <div class="row mb-3">
            <div class="col-12 col-sm-8 me-auto">
                <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Payment & Billing Info</span></h1>
            </div>
            <div class="my-2 d-sm-none"></div>
            <div class="col-12 col-sm-4">
                <button v-if="props.allowCreate && props.largeCreateButton && !editing" class="btn btn-primary" @click="onCreate()">ADD PAYMENT METHOD</button>
            </div>
        </div>

        <template v-if="editing">
            <div class="row">
                <div class="col-12 col-md-6">
                    <AddPaymentMethod :form="form" @generateToken="savePaymentToken" @cancel="onCancel()" />
                </div>
            </div>
        </template>

        <div v-else class="subform">
            <SearchContainer :search="search" :deep-link="false">
                <div v-if="search.results.count > 0" class="row mb-3">
                    <div class="col"><SortOptionsList :options="sortOptions" /></div>
                    <div class="col"><PageSizeList :options="pageSizeOptions" /></div>
                    <div class="col-4 text-end"><PageSelector /></div>
                </div>

                <SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3" :columns-xxl="3">
                    <!-- Template for each item in a grid view -->
                    <template v-slot:grid="item">
                        <TileSelectButton v-if="showSelectButton" :item="item" :multi-select="false" :is-selected="item.id === selectedPaymentId" @selected="onCreditCardSelected(item)" />
                        <PaymentMethodTile :model="item" height="14rem" :can-set-default="true" @default="onSetDefault(item.id)" @delete="onDelete(item.id)">

                            <template v-slot:method>
                                <div class="fw-bold fs-3">Credit Card</div>
                                <div class="fw-bold">{{item.name}}</div>
                                <div><i class="bi bi-credit-card me-2" :class="getCardType(item.paymentData)"></i>{{getCardTypeName(item.paymentData)}}</div>
                                <div>{{getMaskPen(item.paymentData)}}</div>
                                <div>{{getCardDate(item.expirationDate)}}</div>
                            </template>

                            <!-- TODO: build more templates for other options paypal, ach, etc -->
                        </PaymentMethodTile>
                    </template>

                    <!-- Template for no results -->
                    <template #empty>
                        You have no credit cards saved to your account.
                    </template>

                    <!-- Template while results are initially loading -->
                    <template #loading-grid>
                        <MockSearchGrid>
                            <!-- TODO: Add mock user payment method tile -->
                        </MockSearchGrid>
                    </template>
                </SearchGrid>

                <div class="row mb-3">
                    <div class="col-4 text-end"><PageSelector /></div>
                </div>

            </SearchContainer>
        </div>

        <ConfirmationOverlay v-if="showOverlay">
            <template v-slot:headline>
                <h2 class="confirm">Are you sure you want to delete this payment method?</h2>
            </template>
            <template v-slot:buttons>
                <button class="btn btn-outline-primary me-5" @click="onDeleteCanceled()">Cancel</button>
                <button class="btn btn-outline-primary ms-5" @click="onDeleteConfirmed()">Delete</button>
            </template>
        </ConfirmationOverlay>
    </div>
</template>

<style lang="scss">
    .payment-info-panel {
    }
</style>