// PaymentInfo
// Services and actions related to payment info.

// Imports ----
import { ref, reactive } from 'vue';
import { Util } from '@dd-nucleus/nucleus-vue';


export default class PaymentInfo {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;

        this.defaultPaymentMethodId = ref('');

        this.stripeCustomerId = ref('');

        this.paymentMethods = reactive([]);

        this.months = reactive([]);
        this.years = reactive([]);

        this.#loadMonths();
        this.#loadYears();

    }

    /**
     * Creates a new user payment method for the current user.
     */
    async createUserPaymentMethod(userPaymentMethodModel, validator = null) {

        const response = await this.store.api.post(`/site/user/current/user-payment-method`, userPaymentMethodModel );

        
        if (response.succeeded) {  
            this.getPaymentMethods();
            return response;
        }
        else {
            // TODO: More error handling here?
            if (validator !== null) validator.addMessages(response.messages);
            return false;
        }
        
    }

    /**
     * Deletes a user payment method from the current user's account.
     */
    async deleteUserPaymentMethod(id) {
        return await this.store.api.delete(`/site/user/current/user-payment-method/${id}`, {});
    }

    /**
     * Calls our own service that calls Cybersource to get back a JWK token which we will use to tokenize payment methods for the user 
     */
    async getJwkToken() {
        return await this.store.api.get('site/user/current/user-payment-method');
    }

    /**
     * Get the default Payment Method ID Method
     */
    async getPaymentMethods() {

        const filter = this.#getFilter();

        const results = await this.store.api.post(`/api/search/user-payment-methods-for-user/tile`, filter);

        Object.assign(this.paymentMethods, results.results);

        this.#setDefaultPaymentId(results.results);

        this.#setStripeCustomerId(results.results);
    }

    /**
     * Sets the given payment method to the default payment method for the user.
     */
    async setUserPaymentMethodAsDefault(id) {
        const response = await this.store.api.post(`/site/user/current/user-payment-method/${id}/default`, {});
        this.defaultPaymentMethodId.value = id;
        return response;
    }

    /**
     * Create a customer at stripe 
     * @param {object} data - the meta data of userId and name of the user 
     */
    async createStripeCustomer(data) {

        // Stripe create customer endpoint 
        const url = `/site/stripe/create-customer`;
        const response = await this.store.api.post(url, data);
        this.stripeCustomerId = response.id;    
        return response;

    }

    /**
     * Attach a customer to a payment method 
     */
    async attachStripeCustomerPaymentMethod(paymentMethodId, customerId, isDefault) {

        // Stripe create customer endpoint
        const url = `/site/stripe/payment-methods/${paymentMethodId}/attach`;
        const data = { customer: customerId, isDefault: isDefault, province:'' };
        const response = await this.store.api.post(url, data).catch((error) => {
            return error;
        });
        this.getPaymentMethods();
        return response;
    }

    /**
     * Gets a list of calendar months, normally used to identify the month of when a credit card expires.
     */
    #loadMonths() {
        this.months.push({ text: 'Jan', id: '01' });
        this.months.push({ text: 'Feb', id: '02' });
        this.months.push({ text: 'Mar', id: '03' });
        this.months.push({ text: 'Apr', id: '04' });
        this.months.push({ text: 'May', id: '05' });
        this.months.push({ text: 'Jun', id: '06' });
        this.months.push({ text: 'Jul', id: '07' });
        this.months.push({ text: 'Aug', id: '08' });
        this.months.push({ text: 'Sep', id: '09' });
        this.months.push({ text: 'Oct', id: '10' });
        this.months.push({ text: 'Nov', id: '11' });
        this.months.push({ text: 'Dec', id: '12' });
    }

    /**
     * Gets a list of calendar years, normally used to identify the year of when a credit card expires.
     */
    #loadYears() {
        let now = new Date();

        for (let i = 0; i < 8; i++) {
            const year = now.getFullYear();

            this.years.push({ text: year.toString(), id: year.toString() });
            now = new Date(year +1, now.getMonth(), now.getDay());
        }
    }

    #getFilter() {
        return { "filterValues": [], "paging": { "pageNumber": 0, "perPage": 500, "includeTotalCount": true, "includePageCount": true }, "sortId": "newest-to-oldest" }
    }

    #setDefaultPaymentId(methods) {

        methods.forEach((x) => {
            if (x.isDefault == true) {
                this.defaultPaymentMethodId.value = x.id;
            }
        });

    }

    #setStripeCustomerId(methods) {
        if (Util.isNotEmpty(methods)) {
            const paymentData = JSON.parse(methods[0].paymentData)
            this.stripeCustomerId = paymentData.customer;
        }
    }

}

// Exports ----
export const PaymentTypeCode = {
    /**
     * Type code to assign a payment method as a type of credit card
     * TODO: Currently only used for develop/display purposes.
     */
    CREDIT_CARD: 'CREDIT_CARD',

    /**
     * Type code to assign a payment method as a type of stripe payment method.
     * TODO: Not an actual implementation requirement at this time. Just stubbing out for visual/understanding purposes. Might be removed at a later date.
     */
    PAYPAL: 'PAYPAL',

    /**
     * Type code to assign a payment method as a type of paypal payment method.
     * TODO: Not an actual implementation requirement at this time. Just stubbing out for visual/understanding purposes. Might be removed at a later date.
     */
    STRIPE: 'STRIPE'
}