<script setup>

    // CrossSellAutoToHomePage
    // Determines if productin being ordered is going to be sent express

    // CREATIVE NOTES: This screen is only visible if OM has opportunities and their Sub Codes have opportunities.
    // CREATIVE NOTES: The system would recognize if there are no sub Sub Codes for this Agency and this step would be skipped. User would then be taken directly to the Personalization Screen.

    // TODO: It might make sense to make this into a component instead of a page.
    // TODO: This is also duplicated but for time and not knowing the exact requirements.
    // TODO:  Two pages were created for this also at /areas/annual-auto/AnnualAutoExpressOrderingPage.vue

    //Imports
    import { onMounted } from 'vue';
    import { site } from '@/Site'

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';

    // Methods
    function expressOrdering(isExpress) {
        // TODO: add the functionality to include Express Ordering
        console.log("button clicked was: ", isExpress);
    }

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "ExpressOrdering", "lob": "Cross Sell" });
    }); 
</script>

<template>
    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container cross-sell-express-ordering">

                <div class="row mt-5">

                    <div class="col-sm-8 offset-sm-2 text-center">

                        <h1>
                            Would you like to use Express Ordering to place one order for yourself and the Sub Codes to which you have access?
                        </h1>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col text-center">

                        <button class="btn btn-outline-primary me-3" @click="expressOrdering(false)">No</button>
                        <button class="btn btn-primary" @click="expressOrdering(true)">Yes</button>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>
</template>

<style lang="scss">
    .annual-auto-express-ordering{

    }
</style>