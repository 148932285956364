<script setup>
    // AddPersonalization
    // Form for personalizing assets

    // Imports ----
    import { defineProps, defineEmits, reactive } from 'vue';
    //import { site } from '@/Site';
    import { store } from '@dd-nucleus/nucleus-vue';

    // Props ----
    const props = defineProps({
        // The payment method form object.
        form: {
            type: Object,
            required: true
        }
    });

    // State ----
    const form = reactive(props.form);

    // Handlers ----
    const emit = defineEmits(['change']);

    function onChanged() {
        emit('change');
    }
</script>

<template>
    <div class="add-personalization">
        <FormContainer :form="form" optional-text="(Optional)">
            <FormMessages />
            <slot name="top"></slot>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="agencyName" label="Agency Name">
                        <TextBoxField v-model="form.model.agencyName" placeholder="Sample Agency Name" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="licenseNumber" label="License Number" help-text="Will print on mail piece for CA & AR. Other states are optional. ">
                        (Required for CA & AR)
                        <TextBoxField v-model="form.model.licenseNumber" placeholder="License Number" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="agentFirstName" label="Agent First Name">
                        <TextBoxField v-model="form.model.agentFirstName" placeholder="Agent First Name" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="agentLastName" label="Agent Last Name">
                        <TextBoxField v-model="form.model.agentLastName" placeholder="Agent Last Name" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="agentPhone" label="Agent Phone Number">
                        <TextBoxField v-model="form.model.agentPhone" placeholder="Agent Phone Number" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="agentEmail" help-text="This email address is used for lead distribution" label="Agent Email">
                        (One email address only)
                        <TextBoxField v-model="form.model.agentEmail" placeholder="samsample@email.com" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <h2 class="mt-5">Agency Address</h2>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="addressLine1" label="Agent Address">
                        <TextBoxField v-model="form.model.addressLine1" placeholder="123 Main St" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="addressLine2" label="Agent Address Line 2">
                        <TextBoxField v-model="form.model.addressLine2" placeholder="" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="city" label="Agent City">
                        <TextBoxField v-model="form.model.city" placeholder="Anytown" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="state" label="Agent State">
                        <DropDownListField v-model="form.model.state" :options="store.shipping.states" null-option-text="Select State" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="postalCode" label="Agent Zip Code">
                        <TextBoxField v-model="form.model.postalCode" placeholder="12345" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

        </FormContainer>
    </div>
</template>

<style lang="scss">
    .add-personalization{
        h2 {
            border-bottom: 1px solid $gray-3;
            padding-bottom: 10px;
        }
    }
</style>