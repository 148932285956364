<script setup>

    // AdvantagePlusPanel
    // AdvantagePlusPanel is a common panel used in a few places with different text / offers and buttons?
    /* 
     * The button will emit an event to the parent 
     * add @click="{parent function name}" when you include this component to know when the button is clicked
    */

    import { defineProps, defineEmits } from 'vue';

    // Emits 
    const emit = defineEmits(['click']);

    function buttonClick() {
        emit('click')
    }

    // Props ----
    const props = defineProps({
        tierName: {
            type: String,
            required: false,
        },
        buttonText: {
            type: String,
            required: false
        },
        discountCode: {
            type: String,
            required: false,
        },

    });

</script>

<template>

    <div class="advantage-plus bg-gray-1 text-center p-3">


        <div class="headline fw-bold">
            <img src="/images/icons/ignite-rocket-icon.png" alt="ignite rocket icon" />{{props.tierName}}
        </div>

        <slot name="aboveDiscount"></slot>

        <div v-if="props.discountCode" class="bg-gray-0 p-2 my-3 bg-white">
            DISCOUNT CODE: {{props.discountCode}}
        </div>

        <slot name="belowDiscount"></slot>

        <div v-if="props.buttonText" class="mt-2">
            <button class="btn btn-primary" @click="buttonClick">
                {{buttonText}}
            </button>
        </div>

    </div>

</template>

<style lang="scss">
    .advantage-plus {
        background: $gray-1;

        .headline{
            font-size: 1.875rem;
        }
    }
</style>