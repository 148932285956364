<script setup>

    // OrderContactTile
    // Customizes the standard AddressTile component with options/markup specific to an order contact (i.e. shipping address OR marketing contact).

    // Components ----

    // Imports ----
    import { defineProps, computed } from 'vue';
    import { site } from '@/Site';

    // Props ----
    const props = defineProps({
        // The order contact to show
        contact: {
            type: Object,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----

    // Computed ----
    const isDefault = computed(() => {
        if (typeof props.contact === 'undefined')
            return false;
        else
            return (site.shipping.addresses.length > 0 && props.contact.contactAddressId === site.shipping.addresses[0].id);
    });

</script>

<template>

    <div class="order-contact-tile">
        <AddressTile :address="props.contact" :height="props.height" :border="props.border">
            <template #top>
                <div v-if="props.contact.marketingContactId !== null" class="name-block">
                    <div v-if="props.contact.shipToEmail.length > 0" class="email">
                        <a :href="'mailto:' + props.contact.shipToEmail">{{ props.contact.shipToEmail }}</a>
                    </div>
                    <div v-if="props.contact.shipToPhoneNumber.length > 0" class="phone">{{ props.contact.shipToPhoneNumber}}</div>
                </div>
            </template>

            <div class="mt-2">
                <div class="tags">
                    <span v-if="isDefault" class="badge default-shipping"><i class="bi bi-star-fill"></i> Default Shipping</span>
                </div>
            </div>
        </AddressTile>
    </div>

</template>

<style lang="scss">

    .order-contact-tile {
        overflow-x: clip;

        .name-block {
            margin-bottom: 0.25rem;
        }

        .name {
            font-weight: $bold;
            font-size: 0.9rem;
            line-height: 1rem;
        }

        .default-shipping {
            background-color: $highlight-1; // TODO: SCSS variable
        }
    }

</style>