<script setup>

    // MarketingHomePage
    // Landing page for marketing materials
    import { useRouter } from 'vue-router';
    import { onMounted } from 'vue';
    import { site } from '@/Site';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';

    //State
    const router = useRouter();

    function searchProductBrochures() {
        router.push({ path: '/products', query: { 'f-product-format': 'Brochures','active-tab': TabIDs.MARKETING_TAB_ID } });
    }

    function searchAllMarketingMaterials() {
        router.push({ path: '/products', query: { 'active-tab': TabIDs.MARKETING_TAB_ID } });
    }

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Marketing Materials", "lob": "Marketing" });
    }); 
</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.MARKETING_TAB_ID" />

            <div class="root-page-container marketing-materials">

                <div class="row">

                    <div class="col-sm-6">

                        <!-- TODO: swap this HTML for the HeadlineWithImage component when it gets merged into main-->
                        <div class="d-flex align-items-center">

                            <div class="flex-shrink-0">

                                <img src="/images/icons/online-newsletter-icon.png" alt="Marketing Materials" class="img-fluid" />

                            </div>

                            <div class="flex-grow-1 ms-3">
                                <h1>Marketing Materials</h1>
                                <p class="fw-bold">Professional and Customizable Marketing Materials at your fingertips</p>

                            </div>

                        </div>

                        <p class="mt-3">Safeco provides a vast array of product marketing and collateral materials to help you grow.   These profesional marketing materials provide Agents with useful and detailed information about Safeco products and services.  Plus most are completely customizable, allowing you to include your name, contact information and logo/headshot.   The materials come in a variety of formats - digital options that allow you to download to email to your customers or print locally.  Or, a more traditional method of customizing the brochure and the vendor will print and ship to you directly.   There are even some off-the-shelf materials that can ship directly to you.   Start browsing today by Product, Program, Category, or Keyword. </p>

                    </div>

                    <div class="col-sm">

                        <div class="text-center">
                            <img src="/images/marketing/marketing-materials.png" alt="marketing materials" class="img-fluid" />
                        </div>

                        <div class="d-flex justify-content-between mt-5">

                            <button class="btn btn-primary me-2" @click="searchProductBrochures()">
                                Search Product Brochures & Collateral
                            </button>

                            <button class="btn btn-primary ms-2" @click="searchAllMarketingMaterials()">
                                Search All Marketing Materials
                            </button>

                        </div>

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-sm-6">

                        <IconPanel title="Content library" icon="images/icons/online-training-icon.png">
                            <p class="my-3">Optimize your web and social media presence with your potential and current customers. Showing up first in search engine results is critical to attracting new customers and keeping current customers engaged. Safeco has provided you with tools to coordinate your website, blog, social media, and newsletter efforts so your agency can maintain a strong online presence.</p>
                            <!--TODO: uncomment this and add link when its ready-->
                            <!--<LinkItem class="mb-3" title="View tips for using online content" link="#TODO" :isExternal="false" :openNewWindow="false" />-->
                            <LinkItem class="mb-3" title="View online content" :link="{path: '/products', query: { 'f-product-format': 'WebsiteContent' }}" :isExternal="false" :openNewWindow="false" />
                            <LinkItem class="mb-3" title="View newsletter/blog articles" :link="{path: '/products', query: { 'f-product-format': 'Newsletters' }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                    <div class="col-sm-6" id="Branded Supplies">

                        <IconPanel title="Branded supplies" icon="images/icons/right-track-icon.png">
                            <p class="my-3">Support your sales efforts with branded supplies. Many Safeco supplies are now available through Regal Press! Click on the icons below to browse available items. <strong>Questions on a Regal Press item?</strong> Email <a href="mailto:service@regalpress.com" class="no-underline">service@regalpress.com</a></p>
                            <LinkItem class="mb-3" title="View personalized stationary, business forms and envelopes" link="https://www.eprocurelink2.com/safeco/Default.aspx" :isExternal="true" :openNewWindow="true" />
                            <LinkItem class="mb-3" title="View quick reference guide" link="/downloads/marketing-material-quick-reference-guide.pdf" :isExternal="true" :openNewWindow="true" />
                            <LinkItem class="mb-3" title="View payment envelopes" :link="{path: '/products', query: { 'f-product-search-term': 'V-5170' }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col-sm-6">

                        <IconPanel title="Events/sponsorship" icon="images/icons/mentoring-icon.png">
                            <p class="my-3">Partner with Safeco to support your activities at community events and trade shows. Sponsoring local events and participating in trade shows are great ways to boost customer retention and drive new business growth. </p>
                            <!--TODO: uncomment this and add link when its ready-->
                            <!--<LinkItem class="mb-3" title="View event best practices & tips" link="#TODO" :isExternal="false" :openNewWindow="false" />-->
                            <LinkItem class="mb-3" title="Shop promotional merchandise collection" link="https://libertymutualpromo.com/" :isExternal="true" :openNewWindow="true" />
                            <LinkItem class="mb-3" title="View Safeco product posters" :link="{path: '/products', query: { 'f-product-format': 'Posters' }}" :isExternal="false" :openNewWindow="false" />
                        </IconPanel>

                    </div>

                    <div class="col-sm-6">

                        <IconPanel title="Promotional merchandise" icon="images/icons/gifts-icon.png">
                            <p class="my-3">Safeco provides you with a full selection of promotional merchandise. From apparel to business accessories to bags. There are items that also allow you to personalize them with your Agency logo and contact information. Perfect fit for in-office promotions, hand-outs at your next event or just to say "thank you" to your clients.</p>
                            <LinkItem class="mb-3" title="Shop promotional merchandise collection" link="https://libertymutualpromo.com/" :isExternal="true" :openNewWindow="true" />
                        </IconPanel>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>