// EventBus
// An event bus to emit events

// TODO this should probably be a nucleus base feature 

/*
 * Useage is as easy as... 
 * 

    import useEventsBus from '@/site/services/EventBus';
 
 *
 * Emit your event 
 * 
    const { emit } = useEventsBus();
    emit('sampleEvent');

 *
 * Then watch for it 
 * 
    const { bus } = useEventsBus()
    watch(() => bus.value.get('sampleEvent'), () => {});
 *
*/


import { ref } from "vue";
const bus = ref(new Map());

export default function useEventsBus() {

    function emit(event, ...args) {
        bus.value.set(event, args);
    }

    return {
        emit,
        bus
    }
}