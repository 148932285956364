<script setup>
    import { site } from '@/Site';
    import { ref, defineEmits, defineProps } from 'vue';

    const props = defineProps({
        feedbackType: {
            type: Number,
            required: true
        }
    });

    const feedbackQuestions = ref([]);
    const feedbackResponse = ref([]);
    const feedbackComment = ref("");
    const emit = defineEmits(['close-modal']);

    function updateRating(questionId, rating) {
        const response = feedbackResponse.value;
        response.forEach(item => {
            if (item.feedbackQuestionsId === questionId) {
                item.feedbackValue = rating;
            }
        })
        feedbackResponse.value = response;
    }

    function closeModal() {
        resetData();
        emit('close-modal');
    }

    function resetData() {
        const feedbackData = feedbackQuestions.value.map(item => {
            return {
                "feedbackQuestionsId": item.feedbackQuestionsId,
                "feedbackValue": 0,
                "description": item.description
            }
        })
        feedbackResponse.value = feedbackData;
        feedbackComment.value = "";
    }

    async function sendFeedback() {
        // eslint-disable-next-line no-unused-vars
        const feedbackSurveyModel = feedbackResponse.value.map(({ description,  ...rest }) => {
            return rest;
        });

        const payload = {
            "feedbackSurveyModel": feedbackSurveyModel,
             "comments": feedbackComment.value,
             "feedbackType": props.feedbackType
        }
        
        
        const response = await site.userFeedback.saveFeedBack(payload);
        if (response.succeeded) {
            resetData();
            emit('close-modal');
        }
    } 

    site.onConfigLoaded(async () => {
        const questions = site.config.site.feedbackQuestions.filter(item => item.type == props.feedbackType)
        if (questions.length > 0) {
            const feedbackData = questions.map(item => {
                return {
                    "feedbackQuestionsId": item.id,
                    "feedbackValue": 0,
                    "description": item.description,
                }
            })
            feedbackResponse.value = feedbackData;
            feedbackQuestions.value = feedbackData;
        }
    })


</script>

<template>
    <div class="feedback">
        <ul>
            <li>
                <div class="item" v-for="item in feedbackResponse" :key="item.feedbackQuestionsId">
                    <p>{{ item.description }}</p>
                    <div>
                        <ul>
                            <li v-for="index in item.feedbackValue" :key="index" @click="item.feedbackValue = index"><i class="bi bi-star-fill inactive"></i></li>
                            <li v-for="index in (5 - item.feedbackValue)" @click="updateRating(item.feedbackQuestionsId, item.feedbackValue + index)" :key="index"><i class="bi bi-star active"></i></li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
        <label>What suggestions do you have as we continue to develop the platform to deliver more value?</label>
        <br />
        <textarea v-model="feedbackComment" placeholder="Comments and Feedback" rows="6"  />
        <br />
        <div class="buttons">
            <button class="btn btn-outline-primary" style="margin-right: 6px;" @click="closeModal">Close</button>
            <button class="btn btn-primary" @click="sendFeedback">Send Feedback</button>
        </div>
    </div>
</template>

<style lang="scss">
    
    .feedback {
        ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .item {
            display: flex;
            justify-content: space-between;
            ul {
                display: flex;
            }
        }

        p, label, textarea {
            font-size: 14px;
        }

        textarea {
            width: 100%;
            border-color: dimgrey;
            border-radius: 4px;
            background-color: lightyellow;
            padding: 6px;
        }

        .active, .inactive {
            color: orange;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
        }
    }
    
</style>