// Adobe Analytics Services
// Services and actions related to Adobe analytics

    export const withUtag = async () => {
        if (window.utag) return window.utag;
    let i = 0;
    while (i < 50) {
        await new Promise(resolve => setTimeout(resolve, 200));
        if (window.utag) {
            console.warn("utag i:", i);
            return window.utag;
        }
    i = i + 1;
    }
    return {view: () => { } };
    }


export default class AnalyticsHandler {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    async push(payload) {
        if (!payload.lob) {
            payload.lob = "";
        }

        if (!payload.productNames) {
            payload.productNames = "";
        }

        if (!payload.tileCode) {
            payload.tileCode = "";
        }

        withUtag().then(utag => utag.view({
            "tileCode": payload.tileCode,
            "state": "--",
            "application": "MSC",
            "lob": payload.lob,
            "productNames": payload.productNames,
            "taskName": "--",
            "stepName": "--"
        }));       

    }

}
