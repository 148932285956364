<script setup>

    // ProspectingMailList
    // Step 2 Select Existing Mail List
    // TODO: Pass the selected maillistId to preview page

    // Imports ----
    import { defineProps, defineEmits, ref, computed } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';

    const props = defineProps({
        product: {
            type: Object,
            required: true,
        }
    });

    const constants = {
        DEFAULT_PAGE_SIZE: 50,
    }

    const columns = [
        { field: 'name', title: 'List Name', isSortable: true },
        { field: 'created', title: 'Creation Date', custom: true, isSortable: true },
        { field: 'mailListContactsCount', title: 'Record Count', isSortable: false },
    ];

    const searchTerm = ref('name');
    let sortPrefix = 'mail-list-';

    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = sortPrefix+ value;
            } else {
                searchTerm.value = sortPrefix+ value + '-desc';
            }

        } else {
            searchTerm.value = sortPrefix+ value;
        }

        search.setSortId(searchTerm.value);
    }


    const mailListSelected = ref(null);
    // Handlers ----
    const emit = defineEmits(['mailListSelected', 'reset']);

    const search = new Search('mail-list', 'row', 'mail-list-name', constants.DEFAULT_PAGE_SIZE);

    const hasMailLists = computed(() => {
        return search && search.results && search.results.count > 0
    })

    // Methods ----
    function back() {
        emit('reset');
    }

    function next() {
        emit('mailListSelected', mailListSelected.value);
    }

    function selected(row) {
        mailListSelected.value = row.mailListId;
    }

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

</script>

<template>

    <div class="row my-5 prospecting-mail-lists">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0"> Select from your existing mail list</h2>
                </div>
                <div class="ms-auto">
                    {{props.product.name}} ({{props.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row mb-5 mt-2">
        <div class="col-sm-8 offset-sm-2">
            <h3>Select a mail list</h3>
            <SearchContainer :search="search" default-view="list" :deep-link="false" >
                <div class="data-table">
                    <SearchTable :columns="columns" @selected="selected" @sort-by="sortSearch" selection-type="SINGLE">
                        <template v-slot:created="row">
                            {{formatDate(row.created)}}
                        </template>
                        <template #empty>
                           There are no lists uploaded to use. Please go to the previous screen and upload a new list.
                        </template>
                    </SearchTable>
                </div>
            </SearchContainer>

        </div>
    </div>

    <div class="row mb-5">

        <div class="col text-center">

            <button @click="back" class="btn btn-outline-primary me-3">Previous</button>
            <button @click="next" class="btn btn-primary" :disabled="!hasMailLists">Preview</button>

        </div>
    </div>

</template>
<style lang="scss">
    .prospecting-mail-lists {
    }
</style>