<script setup>
    /* eslint-disable */
    // PersonalizeStep4MultiPage
    // Step 4 of mail drops the review and check out or add to cart

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import ReviewTile from './components/ReviewTile';

    // Imports
    import { reactive, ref, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRouter, useRoute } from 'vue-router';
    import { TabTypes } from './PersonalizeStep1Page';
    import { ChannelTypes } from './SelectChannelPage';
    import { Util } from '@dd-nucleus/nucleus-vue';

    const router = useRouter();
    const route = useRoute();

    // State

    const directMail = reactive({});

    const email = reactive({});

    const directMailProductDetails = reactive({});

    const emailProductDetails = reactive({});

    const isLoaded = ref(false);
    const isEmailLoaded = ref(false);

    const addingToCart = ref(false);

    // Methods
    async function addToCart() {
        addingToCart.value = true;
        await site.personalizationInfo.setSaveForLater(false, email.id, email.parentId);

        let response = await site.personalizationInfo.addToCart(directMail.productId, directMail.targetCount);

        if (response.succeeded) {
            site.toast.success(directMailProductDetails.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }

        response = await site.personalizationInfo.addToCart(email.productId, directMail.targetCount);

        if (response.succeeded) {
            site.toast.success(emailProductDetails.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }
        addingToCart.value = false;
    }


    async function checkout() {
        if (!site.errorHandler.canStartOrder(directMail.productId) ||
            !site.errorHandler.canStartOrder(email.productId)) {
            return false;
        }
        await addToCart();

        router.push({
            name: 'CartPage'
        });
    }

    async function getOrderStates() {
        if (Util.isNotEmpty(route.query.parentId)) {
            isLoaded.value = false;
            isEmailLoaded.value = false;
            const response = await site.personalizationInfo.getOrderStates(route.query.parentId);

            Object.assign(directMail, response.orderStates.find(o => o.channelType === ChannelTypes.DIRECT_MAIL));
            Object.assign(directMailProductDetails, await site.products.getProductDetail(directMail.productId));
            isLoaded.value = true;
            Object.assign(email, response.orderStates.find(o => o.channelType === ChannelTypes.EMAIL));
            Object.assign(emailProductDetails, await site.products.getProductDetail(email.productId));
            isEmailLoaded.value = true;

        }
    }

    const tabName = computed(() => {
        switch (directMail.programType) {
            case TabTypes.CROSS_SELL:
                return TabIDs.CROSS_SELL_TAB_ID;
            case TabTypes.PROSPECTING:
            case TabTypes.DATA_LIST_UPLOAD:
                return TabIDs.PROSPECTING_TAB_ID;
            case TabTypes.RETENTION:
            case TabTypes.WINBACK:
            case TabTypes.ANNUAL_AUTO:
                return TabIDs.RETENTION_TAB_ID;
            default:
                return '';
        }
    });

    const orderTotal = computed(() => {
        let total = 0;

        if (directMail && directMail.dropDetails && directMail.dropDetails.mailingDetails && directMail.dropDetails.mailingDetails.length > 0) {
            total += directMail.dropDetails.mailingDetails.reduce((a, b) => ((isNaN(a) ? 0 : a) + (isNaN(b.price) ? 0 : b.price)), 0);
        }

        if (email && email.dropDetails && email.dropDetails.mailingDetails && email.dropDetails.mailingDetails.length > 0) {
            total += email.dropDetails.mailingDetails.reduce((a, b) => ((isNaN(a) ? 0 : a) + (isNaN(b.price) ? 0 : b.price)), 0);
        }
        return total;
    });

    const canAddToCart = computed(() => {
        return !addingToCart.value
    });

    const toEmailPersonalizationPage = computed(() => {
        const parentId = email.parentId ? email.parentId : email.id;
        const channelType = emailProductDetails.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${email.id}/${parentId}/${email.productId}/?edit=true&review=true&isSubscription=${emailProductDetails?.attributes?.isSubscription}&channelType=${channelType}`;
    });

    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    //TODO: May be to move to common place???
    async function saveForLater() {
        const response = await site.personalizationInfo.enableSaveForLater(email.id, email.parentId);
        if (response.succeeded) {
            site.toast.success("Your item has been saved in My Account - My Saved Items.");
            router.push({
                name: 'Home',
            });
        } else {
            site.toast.error("Error while saving items for later use. Please contact administrator");
        };
    }



    // Lifecycle ----
    onMounted(async () => {
        await getOrderStates();
    });

</script>
<template>
    <PageTemplate>

        <div class="container root-page" v-if="isLoaded">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />
            <div class="root-page-container personalize-step-4">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.FOUR" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>
                                <h1>Review & Add to Cart</h1>
                            </div>

                            <div class="ms-auto">
                                <div>
                                    {{directMailProductDetails.name}} ({{directMailProductDetails.itemNumber}})
                                </div>
                                <div>
                                    {{emailProductDetails.name}} ({{emailProductDetails.itemNumber}})
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <template v-if="isLoaded">
                    <div class="row mt-5">
                        <div class="col">
                            <h2>Multi-Channel Cross-sell (Direct Mail & Email)</h2>
                            <h3 class="text-color-2">Direct Mail</h3>
                        </div>
                    </div>

                    <ReviewTile :product="directMailProductDetails" :personalization="directMail" />
                </template>
                <template v-if="isEmailLoaded">
                    <div class="row mt-5 mb-3">
                        <div class="col">
                            <hr />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <h3 class="text-color-2">Email</h3>
                        </div>
                    </div>

                    <ReviewTile :product="emailProductDetails" :personalization="email" :editable="false" />

                    <div class="row mt-5">
                        <div class="col fw-bold fs-3">
                            <div>Multi-Channel (Direct Mail & Email) Total</div>
                            <div class="text-color-2">{{site.convertNumberToCurrency(orderTotal)}}</div>
                        </div>
                    </div>

                    <div class="mt-3 mb-5">

                        <router-link :to="toEmailPersonalizationPage" class="btn btn-outline-primary me-3">
                            Previous
                        </router-link>

                        <button @click="saveForLater" v-if="isAgencySelected"  class="btn btn-primary me-3">Save For Later</button>

                        <button @click="checkout" class="btn btn-primary" :disabled="!canAddToCart">
                            Add to Cart
                        </button>

                    </div>

                </template>
            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .personalize-step-4 {

        .btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }
        // Seems like this is coming from some auto generated bootstrap styling it is outputting...
        //  .table > :not(caption) > * > * {
        //      padding: 0.5rem 0.5rem;
        //      background-color: var(--bs-table-bg);
        //      border-bottom-width: 1px;
        //      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        //  }
        // I am overwritting it here to remove the bottom border for the table on this page
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead {
            background: $color-2;
            color: $gray-4;
            border-color: $gray-0;
        }

        .price-message {
            color: $error;
            font-size: .75rem;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 33%;
            }
        }

        .text-color-2 {
            color: $color-2;
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }
    }
</style>