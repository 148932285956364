<script setup>

    import { TabBar } from '@dd-nucleus/nucleus-vue';

    import { ref } from 'vue'

    const mailListTabs = [
        {
            id: 'prepareFile',
            title: 'Prepare shipping address list'
        },
        {
            id: 'faqs',
            title: 'FAQs'
        }
    ];

    const sampleData = [
        {
            firstName: "Mike",
            lastName: "Johnson",
            company: "Johnson Insurance",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seatle",
            state: "WA",
            zip: 98103,
            phoneNumber: "123-456-7890"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            company: "Johnson Insurance",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seatle",
            state: "WA",
            zip: 98103,
            phoneNumber: "123-456-7890"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            company: "Johnson Insurance",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seatle",
            state: "WA",
            zip: 98103,
            phoneNumber: "123-456-7890"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            company: "Johnson Insurance",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seatle",
            state: "WA",
            zip: 98103,
            phoneNumber: "123-456-7890"
        },
        {
            firstName: "Mike",
            lastName: "Johnson",
            company: "Johnson Insurance",
            addressLine1: "120 Main St",
            addressLine2: "Apt 2",
            city: "Seatle",
            state: "WA",
            zip: 98103,
            phoneNumber: "123-456-7890"
        }
    ]
    const prepareFile = ref(null);

    //Methods
    const printContent = () => {
        // Create a new window to print the content
        const printWindow = window.open('Print for Reference', 'Print', 'height=600,width=800');

        // Create a stylesheet that targets the myDiv element
        const printStylesheet = `
        @media print {
          #prepareFile {
            display: block;
          }
        }`;

        // Inject the stylesheet into the new window
        const printStyles = printWindow.document.createElement('style');
        printStyles.innerHTML = printStylesheet;
        printWindow.document.head.appendChild(printStyles);

        // Clone the myDiv element and add it to the new window
        const clonedDiv = prepareFile.value.cloneNode(true);
        printWindow.document.body.appendChild(clonedDiv);

        // Print the new window
        printWindow.print();
        printWindow.close();
    }

</script>

<template>
    <div class="prepare-mail-list">
        <TabBar :tabs="mailListTabs" />

        <div class="tab-content mt-3">
            <div class="tab-pane fade show active" id="prepareFile" role="tabpanel">
                <div ref="prepareFile">
                    <div class="sub-tab-container">
                        <h3>Microsoft Excel File Format (.xlsx)</h3>
                        <hr />
                        <div>
                            <p>Within Excel, all data fields should be separated by cells, or grids. Please note the following requirements for Microsoft Excel file format (.xlsx):</p>
                            <ul class="excel-instructions">
                                <li>
                                    This first name and last name should be placed in separate data fields in the Excel file.
                                </li>
                                <li>
                                    All of the shipping address list data should be located within ONE worksheet, or tab within your Excel file. The process will automatically select the first worksheet within your Excel file for upload.
                                </li>
                                <li>
                                    A Street Address, Apartment, Suite or Unit number, PO Box, City and State, Zip Code and Phone Number should also be placed in separate data fields within the Excel file.
                                </li>
                                <li>
                                    <strong>IMPORTANT: </strong> When saving your Excel File for the List Uplaod process be sure it is saved in .xlsx file extension format.
                                </li>

                            </ul>
                        </div>
                        <div class="table-container">
                            <p>See example of file below for reference</p>
                            <div class="table-responsive">
                                <table class="table-striped m-auto border border-dark p-1">
                                    <thead class="title bg-dark text-white">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Company Name</th>
                                            <th>Address 1</th>
                                            <th>Address 2</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Zip</th>
                                            <th>Phone Number</th>
                                        </tr>
                                    </thead>
                                    <tr v-for="data in sampleData" :key="data">
                                        <td>{{ data.firstName }}</td>
                                        <td>{{ data.lastName }}</td>
                                        <td>{{ data.company }}</td>
                                        <td>{{ data.addressLine1 }}</td>
                                        <td>{{ data.addressLine2 }}</td>
                                        <td>{{ data.city }}</td>
                                        <td>{{ data.state }}</td>
                                        <td>{{ data.zip }}</td>
                                        <td>{{ data.phoneNumber }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="btn btn-primary-outline border border-primary" @click="printContent('prepareFile')">Print for Reference</button>
                    <a class="btn btn-primary ml-2" href="/downloads/Shipping_Address_Upload_Template_Sample.xlsx">Download sample list template</a>
                </div>
            </div>

            <div class="tab-pane fade" id="faqs" role="tabpanel">
                <div class="sub-tab-container">
                    <h3>FAQs</h3>
                    <div class="table-responsive">
                        <table class="table table-striped mw-100">
                            <tbody>
                                <tr>
                                    <td>
                                        <h3>Who do I contact for help?</h3>
                                        <p>Please contact the Safeco Marketing Solutions Center Technical Support Line at (800) 845-9860.</p>
                                        <p>Office Hours: Monday - Friday, 9:00 am to 8:00 pm ET.</p>
                                        <h3>Does Safeco have access to the list that I upload?</h3>
                                        <p>No. The uploaded list is not accessible to Safeco. Only the 3rd party vendor that ships your item(s) has access to your list.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .prepare-mail-list {
        strong {
            color: #1a1446;
        }

        .table-container {
            background: #f5f5f5;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 2.5rem;
            gap: 8px;
        }

        .table-responsive table {
            width: 100%
        }

        .btn-container {
            margin-top: 1rem;
            display: flex;
            gap: 2rem;
        }

        td {
            white-space: normal !important;
            word-wrap: break-word;
        }

        table {
            table-layout: fixed;
        }
    }
</style>
