<script setup>

    // CrossSellProgamStep1Page
    // Step 2 of the Cross Sell Program

    // Imports
    import { ref, reactive, onMounted } from 'vue';
    import { site } from '@/Site';
    import PersonalizationForm from '@/site/forms/PersonalizationForm';
    import ConsentForm from '@/site/forms/ConsentForm';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, {TabIDs} from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, {stepCounter} from '@/site/components/SelectMailListStepper';
    import PdfViewer from '@/site/components/PdfViewer';
    import AddPersonalization from '@/site/components/personalization/AddPersonalization';
    import AddConsent from '@/site/components/personalization/AddConsent';
    import ModalBox from '@/site/components/ModalBox';
    import UserImageLibrary from '@/site/areas/image-library/UserImageLibrary';

    // State
    const personalizationForm = reactive(new PersonalizationForm());

    const consentForm = reactive(new ConsentForm());

    const selectedImageId = ref('');

    const product = reactive({
        "details": { },
        "pdfDetails": { }
    });

    /*
     * COMMENTED OUT TO CLEAN UP DUMMY APIS CALLS
     * 
    const agency = reactive({
        "agencyInformation": { },
        "agencyAddress": { }
    });
    */

    // Methods
    function imageLibrary() {
        // open a modal box for the image library
    }

    async function next() {
        const payload = {...personalizationForm.model, ...consentForm.model};
        const response = await site.personalizationInfo.submitPesonlization(payload);
        console.warn("response: ", response);
    }

    async function previewPdf() {
        // TODO: Call the API to Preview PDF
        const response = await site.personalizationInfo.generatePdfPreview(personalizationForm.model);
        console.warn("response: ", response);
    }

    function setSelectedImage(id) {
        selectedImageId.value = id;
        personalizationForm.model.imageId = id;

        // TODO: Add the modal close here when we merge together 
    }

    // Lifecycle Hooks
    onMounted(async () => {
        /*
         * COMMENTED OUT TO CLEAN UP DUMMY APIS CALLS The model will no doubt change 
         * 
        const productResponse = await site.getProductDetails();
        const agencyResponse = await site.getAgencyDetails();

        // TODO this is probably not going to be how this is returned from the API
        product.details = productResponse.details;
        product.pdfDetails = productResponse.pdfDetails;
        agency.agencyInformation = agencyResponse.agencyInformation;
        agency.agencyAddress = agencyResponse.agencyAddress;
        */

        personalizationForm.create();
        consentForm.create();
    });

</script>
<template>
    <PageTemplate>

        <ModalBox modalId="imageLibrary" title="Select from Image Library" modalSize="modal-xl">
            <template v-slot:body>

                <UserImageLibrary @selectImage="setSelectedImage" />

            </template>
        </ModalBox>

        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />
            <div class="root-page-container prospecting-personalization">

                <div class="row">

                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.ONE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>

                                <h1>Personalize this Item</h1>

                            </div>

                            <div class="ms-auto">
                                {{product.details.name}}
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row mt-5">

                    <div class="col-sm-4">

                        <h2>Agency and Agent Information</h2>

                        <AddPersonalization :form="personalizationForm" />

                        <h2 class="mt-5">Agent Image (Optional)</h2>

                        <div class="mb-3">

                            <button class="btn btn-outline-primary me-3" @click="imageLibrary" data-bs-toggle="modal" data-bs-target="#imageLibrary">
                                Image Library
                            </button>

                        </div>

                        <h2 class="mt-5">Preview & Approve</h2>

                        <button class="btn btn-primary my-3" @click="previewPdf" :disabled="personalizationForm.status.isInvalid">
                            Preview PDF
                        </button>

                        <AddConsent :form="consentForm" />

                        <div class="my-5">
                            <!-- TODO: Not sure what happens when you click cancel -->
                            <button class="btn btn-outline-primary me-3">
                                Cancel
                            </button>
                            <!-- TODO: Using the form validation make this button enabled based on checkboxes above-->
                            <button @click="next" class="btn btn-primary" :disabled="personalizationForm.status.isInvalid || !consentForm.model.pdfFinalApproval || !consentForm.model.acceptTermsAndConditions || !consentForm.model.willNotUseMassachusetts">
                                Next
                            </button>
                        </div>

                    </div>

                    <div class="col-sm-6 offset-sm-2">
                        <PdfViewer :pdfDetails="product.pdfDetails" :height="700" />
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .prospecting-personalization {
        h2 {
            border-bottom: 1px solid $gray-3;
            padding-bottom: 10px;
        }

        label {
            font-weight: bold;
        }
    }
</style>