<script setup>
    // AgencyBestPractices
    // A table of Agency Best Practices
    import { TabBar } from '@dd-nucleus/nucleus-vue';
    import FileFormats from '@/site/areas/prospecting/components/FileFormats';

   

    const mailListTabs = [
        {
            id: 'prepareFile',
            title: 'Prepare your mailing list'
        },
        {
            id: 'faqs',
            title: 'FAQs'
        }
    ];

   
</script>

<template>
    <div class="prepare-mail-list">
        <TabBar :tabs="mailListTabs" />
        <div class="tab-content">
            <div class="tab-pane fade show active" id="prepareFile" role="tabpanel">
                <div >
                    <FileFormats fileformat=".xlsx" />
                    <FileFormats fileformat=".xls" />
                    <FileFormats fileformat=".csv" />
                </div>
            </div>

            <div class="tab-pane fade" id="faqs" role="tabpanel">
                <div class="sub-tab-container">
                    <h3>FAQs</h3>
                    <div class="table-responsive">
                        <table class="table table-striped mw-100">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong class=""> Who do I contact for help?</strong> <br />
                                        <span>Please contact the <strong>Safeco Marketing Solutions Center Technical Support Line at (800) 845-9860.</strong></span><br />
                                        <span> Office Hours: Monday - Friday, 9:00 am to 8:00 pm ET.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span><strong> Why does the final quantity differ from the quantity I selected?</strong></span> <br />
                                        <span>Your list must go through Address Standardization, NCOA and Safeco suppression process. These required processes may decrease the records mailed due to:</span><br />
                                        <ul>
                                            <li> Undeliverable addresses (person moved and left no forward address).</li>
                                            <li> Multiple names within a household (records that have the same Last Name and Address will receive only one mail piece).</li>
                                            <li> Suppressions, such as National Do Not Mail List (records that appear on the National Do Not Contact List are suppressed from your final mail file), Safeco Do Not Contact, and Current Safeco Customers (for prospect mailings).</li>
                                        </ul>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span><strong> If not all my records are mailed, will I be charged for these records?</strong></span> <br />
                                        <span>Yes. You will be charged for all records processed and sent. <strong>NOTE:</strong> Some of your records may be removed from your total mail quantity due to standard suppressions applied.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span><strong> Will I know which records mailed?</strong></span> <br />
                                        <span>Yes. As with other MSC Prospect mailings, you can view the final mailing list one day after the mail was sent. This is available on the MSC under My Account > My List section.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span><strong> Does Safeco have access to the list that I upload?</strong></span> <br />
                                        <span>No. The uploaded list is not accessible to Safeco. Only the 3rd party vendor that executes your mailing has access to your list.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span><strong> If Safeco doesn't have access to my list, how are the reports produced on the MSC?</strong></span> <br />
                                        <span>
                                            The 3rd party direct marketing vendor that executes your list upload mailing will automatically
                                            conduct a post-campaign analysis based on identifying the number of Safeco Insurence policy sales,
                                            policyholder retention/or other relevant program results. Safeco will have access to individual-specific data for
                                            Safeco policyholders only. For non-Safeco policyholders, Safeco will only have access to high-level aggregate data.
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span><strong>For more information about Safeco's Private Policy:</strong></span> <br />
                                        <span><a href="https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy">https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy</a></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .prepare-mail-list {
        strong {
            color: #1a1446;
        }

        .table-container {
            background: #f5f5f5;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 2.5rem;
            gap: 8px;
        }

        .table-responsive table {
            width: 100%
        }

        .btn-container {
            margin-top: 1rem;
            display: flex;
            gap: 2rem;
        }

        td {
            white-space: normal !important;
            word-wrap: break-word;
        }

        table {
            table-layout: fixed;
        }
    }
</style>
