<script>

    // CartPage
    // Shows the current contents of the cart.

    const constants = {
        deliveryType: {
            /// The none delivery type could be used for subscriptions or services (ie. hair appt, or other types of bookings)
            NONE: 0,
            /// The physical delivery type means it is something that will be shipped out by us or a fulfillment vendor.
            PHYSICAL: 1,
            /// A digital delivery type could be something that a user downloads after checkout (paid or not) or
            /// in the case of safeco a mailing which doesnt require shipping address at checkout.
            DIGITAL: 2
        },
        productRestrictions: {
            NOTFORMA: 'NotForMA',
            NOTFORCO: 'NotForCO'
        }
    }

    export const deliveryType = constants.deliveryType;
    export const productRestrictions = constants.productRestrictions;

</script>
<script setup>

    // Components----
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import OrderDetails from './components/OrderDetails';
    import CartItemList from './components/CartItemList';
    import MockProductListTile from '../products/components/MockProductListTile';


    // Imports ----
    import { computed, onMounted, ref, watch } from 'vue';
    import { site } from '@/Site';
    import { defaultShipMethodCode } from '@/constants';

    const isLoaded = ref(false);
    const shipMethodUpdated = ref(false);

    // Computed ----
    const cart = computed(() => {
        return site.cart.current;
    });

    const cartItemsCount = computed(() => {
        return cart.value.unitCount
    });

    const showMessage = computed(() => {
        return isLoaded.value && site.cart.current.messages && site.cart.current.messages.length > 0;
    });

    onMounted(async () => {
        await site.cart.loadCart();
        isLoaded.value = true;
    })

    watch(cartItemsCount, async () => {
        if (cartItemsCount.value == 0 && cart.value.shipMethodCode != defaultShipMethodCode && !shipMethodUpdated.value) {
            shipMethodUpdated.value = true;
            await site.cart.setShipMethod(defaultShipMethodCode);
        }
    }, { deep: true }
    );


</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar />
            <template v-if="isLoaded">
                <div class="row mt-3">
                    <div class="col">
                        <h1>Shopping Cart <span class="fs-3">({{cart.itemCount}} items)</span></h1>
                    </div>
                </div>
                <MessageList v-if="showMessage" :messages="site.cart.current.messages" :add-box="false" class="mb-3" />
                <div class="row">
                    <div class="col-sm-8 col-12">
                        <CartItemList class="mt-5" :editable="true">
                            <template #empty>
                                <div class="text-center">
                                    Your cart is currently empty.<br /><br />
                                    <router-link class="btn btn-outline-color-1 me-2" to="/">CONTINUE SHOPPING</router-link>
                                </div>
                            </template>
                        </CartItemList>
                    </div>
                    <div class="col-sm-4 col-12">
                        <OrderDetails :cart="cart" />

                        <div class="text-center mt-4">
                            <router-link to="/" class="btn btn-outline-primary">Continue Shopping</router-link>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-for="n in 3" :key="n" class="row placeholder-glow mt-2">
                    <MockProductListTile />
                </div>
            </template>
        </div>
    </PageTemplate>
</template>