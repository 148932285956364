<script setup>

    // OrderShipTo
    // Displays ship-to information about an order. Typically used when viewing order detail.

    // Imports ----
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        // The order whose ship-to information to show
        order: {
            type: Object,
            required: true
        }
    });

    // State ----


</script>

<template>

    <div class="order-ship-to">
        <AddressTile :address="props.order.shipTo" :border="false" />
    </div>

</template>

<style lang="scss">

    .order-ship-to {
        .ship-type-title {
            font-size: 0.9rem;
            font-weight: $bold;
        }
    }

</style>