
<script setup>
// PreviousButton
//For previous button w.r to personalization

// Imports ----
import { defineEmits } from 'vue';

// Props ----

// State ----

// Handlers ----
const emit = defineEmits(['back']);

function onBack() {
        emit('back');
}
</script>
<template>
        <div class="row mt-2">
        <div class="col">
            <button @click="onBack()" class="btn btn-outline-primary">Previous</button>
        </div>
       </div>
</template>
