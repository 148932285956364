<script setup>

    // CrossSellProgamStep2Page
    // Step 2 of the Cross Sell Program

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // Methods 
    function sendToAll(sendToAll) {
        console.log(sendToAll);
        // TODO add the functaionality of what to do based on the button clicked
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="root-page-container">

                <div class="row">

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.TWO" />

                    </div>

                </div>

                <div class="row">

                    <div class="col text-center">

                        <h1>Would you like to send to all Policyholders?</h1>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col text-center">

                        <button class="btn btn-outline-primary me-3" @click="sendToAll(false)">No</button>
                        <button class="btn btn-primary" @click="sendToAll(true)">Yes</button>

                    </div>

                </div>

                <div class="row mb-5">

                    <div class="col">

                        <router-link to="#todo-go-back-to-step1" class="btn btn-outline-primary me-3">Previous</router-link>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>