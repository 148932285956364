<script setup>
    // OneTimeDrop
    // Form finding the start date of the drop

    // Imports ----
    import Datepicker from '@vuepic/vue-datepicker';
    import { addDays, addMonths, lastDayOfMonth } from 'date-fns';
    import { onMounted, ref, defineEmits, computed, defineProps } from 'vue';


    const props = defineProps({
        selectedDate: {
            type: String,
            required: false
        }
    });

    // State ----
    const date = ref();
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }
    const disabledDates = ref([]);

    const minDate = computed(() => addDays(new Date(),2));
    const maxDate = computed(() => lastDayOfMonth(addMonths(new Date(),4)));

    // Handlers ----
    const emit = defineEmits(['change']);

    function removePastDates() {
        // Remove the last 500 days from the calendar 
        for (let i = 0; i < 500; i++) {
            disabledDates.value.push(addDays(new Date(), -i))
        }
    }

    function change() {
        emit('change', date.value);
    }

    onMounted(async () => {
        removePastDates();
        if (props.selectedDate) {
            date.value = new Date(props.selectedDate);
            change();
        }
    });

</script>

<template>
    <div class="one-time-drop text-center">
        <h2 class="text-center">Select Drop Start Date</h2>

        <Datepicker class="w-50 m-auto"
                    v-model="date"
                    :highlight="disabledDates"
                    :disabledDates="disabledDates"
                    :format="format"
                    highlightDisabledDays
                    @update:modelValue="change"
                    placeholder="Select Drop Date"
                    :enable-time-picker="false"
                    :auto-apply="true"
                    :min-date="minDate"
                    :max-date="maxDate"
                    prevent-min-max-navigation
                    week-start="0"
                    :disabled-week-days="[0, 6]" />
    </div>

</template>

<style lang="scss">
    .one-time-drop {

    }
</style>