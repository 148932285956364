<script setup>

    // ImageSlider
    // An image slider that take an Array of Objects for the images which is required.

    // Props
    // - images              (Required)  This is the default media coming from the product API
    // - showThumb           (Required)  This will show or hide the thumbs under the main image, when clicked it changes the images
    // - showControls        (Required)  These are the 2 chevrons on the main image for cycling through the images 

    // Imports ----
    import { computed, ref, defineProps } from 'vue';

    // Components ----
    import ModalBox from '@/site/components/ModalBox';

    // Props ----
    const props = defineProps({
        images: {
            type: Object,
            required: true
        },
        showThumbs: {
            type: Boolean,
            required: false,
            default: true
        },
        showControls: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----
    const currentIndex = ref(0);

    // Handlers ----
    function changeImage(i) {
        currentIndex.value = i;
    }

    function next() {
        currentIndex.value += 1;
    }

    function previous() {
        currentIndex.value -= 1;
    }

    const currentImage = computed(() => {
        return props.images[Math.abs(currentIndex.value) % props.images.length].url;
    });

    const title = computed(() => {
        return props.images[Math.abs(currentIndex.value) % props.images.length].name;
    });

</script>

<template>

    <div class="image-slider">

        <div class="image-container text-center" v-for="i in [currentIndex]" :key='i'>

            <div class="controls previous" v-if="props.showControls">
                <i class="bi bi-chevron-left" @click="previous"></i>
            </div>

            <img class="img-fluid" :src="currentImage" data-bs-toggle="modal" data-bs-target="#largerImage" />

            <div class="controls next" v-if="props.showControls">
                <i class="bi bi-chevron-right" @click="next"></i>
            </div>

        </div>

        <div class="mt-5" v-if="showThumbs">

            <img class="thumbnail me-5" :class="{ active: index == currentIndex }" @click="changeImage(index)" :src="image.thumbnailImageUrl" v-for="(image, index) in props.images" :key="image.id" />

        </div>
    </div>

    <ModalBox modalId="largerImage" :title="title" modalSize="modal-xl">
        <template v-slot:body>
            <div class="text-center">
                <img class="img-fluid" :src="currentImage" />
            </div>
        </template>
    </ModalBox>

</template>

<style lang="scss">

    .image-slider {

        .image-container{ 
            position: relative;
        }

        .thumbnail {
            width: 150px;
            height: 150px;
        }

        .active {
            border: 2px solid $color-1;
        }

        .controls {
            position: absolute;
            top: 50%;
            background: rgba($gray-1, .75);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            font-size: 2rem;
        }

        .controls.next {
            right: 15px;
        }

        .controls.previous {
            left: 15px;
        }
    }
</style>