<script>

    const constants = {
        TabTypes: {
            CROSS_SELL: 'CrossSell',
            PROSPECTING: 'Prospecting',
            RETENTION: 'Retention'
        },
        punchOutActions: {
            CREATE: 'create',
            EDIT: 'edit',
            COMMIT: 'commit',
            GETPROOF: 'download'
        }
    };

    // Exports
    export const TabTypes = constants.TabTypes;
    export const punchOutActions = constants.punchOutActions;

</script>
<script setup>

    // Imports
    import { computed, ref } from 'vue';
    import { site } from '@/Site';
    import { useRoute } from 'vue-router';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import { stepCounter } from '@/site/components/SelectMailListStepper';
    import { Util } from "@dd-nucleus/nucleus-vue";
    import TwoStepStepper from '@/site/components/TwoStepStepper';

    const displayStepper = computed(() => {
        let displayState = {
            showTwoStepper: true,
        };
        return displayState;
    })

    // State
    const route = useRoute();

    const isWelcomeKit = route.query.isWelcomeKit === 'true';
    const orderGenerated = route.query.completed === 'true';
    const enableWelcomeKitButton = ref(true);

    async function downloadPageFlexDocument() {
        const pageFlexDocId = route.query.docId;
        if (Util.isNotEmpty(pageFlexDocId)) {
            const userInfo = site.user;
            const userName = userInfo.userName.replace(".Test@test.com", '');

            var url = `/site/punch-out/get-pdf-proof?userName=${userName}&documentId=${pageFlexDocId}`;

            await site.downloadHandler.handleDownload(url, `${pageFlexDocId}.pdf`);
        }
    }

    async function sendPageFlexDocumentForReview() {
        enableWelcomeKitButton.value = false;
        await site.personalizationInfo.sendPageFlexDocument(route.params.id);
    }

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container print-on-demand-customize-step1">

                <div class="row">

                    <div class="col">
                        <TwoStepStepper v-if="displayStepper.showTwoStepper" :currentStep="stepCounter.TWO" stepTitle="Download" />
                    </div>

                </div>

                <div class="row my-5">
                    <div class="col">
                        <div class="d-flex justify-content-evenly">
                            <div v-if="isWelcomeKit">
                                <span>Your personalized New Client Welcome Kit is available from My Custom Downloads under My Account at the top of the page. If you have partially completed your Kit, you may finish it at any time by going to My Account > My Custom Downloads. If it's complete, you may download it here.</span>
                            </div>
                            <div v-else>
                                <span>Your Customizable Download is ready. You may also access it from My Custom Downloads under My Account.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-7">
                        <div class="d-flex justify-content-evenly" v-if="orderGenerated">
                            <button v-if="isWelcomeKit" @click="sendPageFlexDocumentForReview" class="btn btn-primary me-3" :disabled="!enableWelcomeKitButton">Send Email to Marketing Coach for Review</button>
                            <button @click="downloadPageFlexDocument" class="btn btn-primary w-full">Download</button>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .print-on-demand-customize-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }

        .multi-channel-headline {
            background: $color-3-dk;
        }

        .multi-channel-detail {
            background: $gray-1;
        }

        .txt-smaller {
            font-size: 14px;
        }
    }
</style>
