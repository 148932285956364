<script setup>

    // ProspectingOpportunitiesTile
    // Tile for displaying prospecting opportunities

    // Imports ----
    import { watch, ref, defineProps } from 'vue';
    import { Search, Util } from '@dd-nucleus/nucleus-vue';
    import useEventsBus from '@/site/services/EventBus';
    import { useRouter } from 'vue-router';

    const { bus } = useEventsBus();

    const router = useRouter();

    // Components ----
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    // Props ----
    const props = defineProps({
        filterId: {
            type: String,
            required: true
        }
    });

    watch(() => bus.value.get('changeAgency'), () => {
        showSearch.value = false;
        setTimeout(() => {
            showSearch.value = true;
        }, 200);
    });

    // State ----
    const search = new Search('prospecting-search', 'detail', 'prospect-title-desc', 5000);

    const showSearch = ref(true);

    // Handlers ----
    async function startOrder() {

        router.push({
            name: 'ProspectingSelectPromoteProspectsPage'
        });
        
    }


</script>

<template>

    <SearchContainer :search="search" default-view="list" :deep-link="true" v-if="showSearch">

        <TextBoxFilter v-show="false" :filter-id="props.filterId" />

        <SearchGrid>
            <template v-slot:list="opps">
                <div v-if="opps.id == 'prospecting-opportunities'">
                    <template v-if="opps.opps && opps.opps.length > 0">
                        <h2 class="sub-tab">{{ opps.title }}</h2>
                        <table class="table table-striped sub-tab">
                            <thead>
                                <tr>
                                    <th scope="col">Program</th>
                                    <th scope="col">Opportunities</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <template v-for="opp in opps.opps" :key="opp.titleHtml">
                                    <tr>
                                        <td width="45%">
                                            Prospecting discount code: <strong>{{opp.titleHtml}}</strong>
                                        </td>

                                        <td width="25%">
                                            <strong>{{opp.oppCount}}</strong>
                                        </td>

                                        <td width="30%">
                                            <button @click="startOrder(opps.id, opps.opps[0].agencyCode)" class="btn btn-primary-sm btn-primary">Start Order</button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="prospect-opps p-2">
                            <strong>Grow your business through a targeted lead generation program today.</strong>
                            &nbsp;<button @click="startOrder(opps.id)" class="btn btn-primary-sm btn-primary">Start Order</button>
                        </div>
                    </template>
                </div>
                <div v-if="opps.id == 'prospecting-campaigns'">
                    <h2 class="sub-tab">{{ opps.title }}</h2>
                    <table class="table table-striped sub-tab">
                        <thead>
                            <tr>
                                <th scope="col">Program</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Date sent</th>
                                <th scope="col">Prospecting list expires</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-if="opps.opps && opps.opps.length > 0">
                                <template v-for="opp in opps.opps" :key="opp.titleHtml">
                                    <tr>
                                        <td width="35%">
                                            Prospecting Order: {{opp.titleHtml}}
                                        </td>

                                        <td width="15%">
                                            <strong>{{opp.oppCount}}</strong>
                                        </td>

                                        <td width="15%">
                                            {{Util.date(opp.dateSent, false)}}
                                        </td>

                                        <td width="20%" :class="{ urgent: opp.listExpires < 10 }">
                                            {{opp.listExpires}} Days
                                        </td>

                                        <td>
                                            <router-link :to="`/account/order-detail?f-order-details-list-id=${opp.id}`" class="no-underline">View List</router-link>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <div>No campaigns were found.</div>
                            </template>
                        </tbody>
                    </table>

                </div>
            </template>

            <!-- Template for no results -->
            <template #empty>
                No opportunities were found that match your criteria.
            </template>

            <template #loading>
                <TableSkeleton />
            </template>

        </SearchGrid>

    </SearchContainer>

</template>

<style lang="scss" scoped>

    .enrolled {
        font-weight: $bold;
    }

    .btn-primary-sm {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .multi-row-div {
        height: 2rem;
        vertical-align: middle;
    }

    .urgent {
        color: $error !important;
    }

    .prospect-opps {
        background: $color-2-xlt;
    }
</style>