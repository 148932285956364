<script setup>

    // MultiShipDetails
    // A body panel to show the multiship details 

    // Imports ----
    import { defineProps } from 'vue';

    // Components ----
    import CartItemTile from './CartItemTile';

    // Props ----
    const props = defineProps({
        // ID of the sort set to use
        order: {
            type: Object,
            required: false
        },
        // Is Cart Item Removable
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----

</script>

<template>

    <div class="multi-ship-detail">

        <div class="row mb-3">
            <div class="col">
                <strong class="ship-method">Ship Method:</strong> {{props.order.shipMethod?.name}}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <strong class="ship-method">Tracking: </strong> {{ `${props.order.shipments?.[0]?.trackingNumber || 'Not Available'}` }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="item-details" v-for="item in props.order.items" :key="item.id">

                    <CartItemTile :item="item" :editable="false" :removable="props.removable" />

                </div>
            </div>
        </div>

    </div>

</template>

<style lang="scss">

    .multi-ship-detail {
        .ship-method {
            color: $color-1;
        }

        .item-details {
            padding: 1rem 0;
            border-bottom: 1px solid $neutral-dk;

            &:last-child {
                border: unset;
            }

            .cart-item-tile .inner-row {
                border: unset;
            }
        }
    }
</style>