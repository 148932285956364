<script setup>

    // CartBodyPanel
    // A body panel styled for the cart pages (cart, checkout, review order).

    // Imports ----
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        // ID of the sort set to use
        title: {
            type: String,
            required: false
        },
        open: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----

</script>

<template>

    <BodyPanel class="cart-body-panel" :title="props.title" :open="props.open">
        <template #title>
            <slot name="title">{{ props.title }}</slot>
        </template>

        <slot></slot>
    </BodyPanel>

</template>

<style lang="scss">

    .cart-body-panel .n-body-panel {
        .title {
        }

        .body {
        }
    }

</style>