<script setup>

    // ProductDetailPage
    // Shows detail for a product.

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ProductDetail from './components/ProductDetail';

    // Imports ----
    import { useRoute } from 'vue-router';

    const route = useRoute();

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar />

            <ProductDetail :product-id="route.params.id" />
        </div>
    </PageTemplate>
</template>