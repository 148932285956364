<script setup>
    // PaymentMethodTile
    // A payment method block with a styleable border.

    // Components ----

    // Imports ----
    import { reactive, defineProps, defineEmits } from 'vue';

    // Props ----
    const props = defineProps({
        // The payment method to show
        model: {
            type: Object,
            required: true
        },
        // An optional height to enforce.
        height: {
            type: String,
            required: false,
            default: null
        },
        // Whether to add a "set default" action button
        canSetDefault: {
            type: Boolean,
            required: false,
            default: false
        },
        // Whether to show a border around the tile.
        border: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----
    const paymentMethod = reactive(props.model);

    // Computed ----

    // Events ----
    const emit = defineEmits(['default', 'delete']);

    // Handlers ----
    function onDelete() {
        emit('delete');
    }
    async function onSetDefault() {
        emit('default');
        paymentMethod.isDefault = !paymentMethod.isDefault;  
    }

</script>

<template>
    <div class="payment-method-tile d-flex flex-column" :class="{ 'has-border': props.border, default: props.model.isDefault, expired: props.model.isExpired }" :style="{ height: props.height }">
        <div class="flex-fill">
            <slot name="method"></slot>
            <div class="text-danger fs-5 fw-bold" v-if="props.model.isExpired">This card has expired</div>
        </div>

        <div class="mt-2">
            <div v-if="props.model.isDefault">
                <ActionButton icon="bi bi-star-fill" class="disabled default" label="Default Method" />
                <ActionButton icon="bi bi-trash-fill" @click="onDelete()" label="" />
            </div>
            <div v-else>
                <ActionButton v-if="canSetDefault && !props.model.isExpired" icon="bi bi-star" @click="onSetDefault()" label="Set As Default" />
                <ActionButton icon="bi bi-trash-fill" @click="onDelete()" label="" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .n-action-button {
        margin-right: 0.5rem;
        background: transparent !important;
    }

    .n-action-button .bi-star-fill {
        color: #ff8100 !important;
    }

    .n-action-button.default {
        color: #ff8100 !important;
    }


    // Updated from border to has-border because border is a default bootstrap with an !important tag
    .payment-method-tile.has-border {
        padding: 1rem;
        border: 1px solid $gray-2;
        border-radius: 0.25rem
    }

    .payment-method-tile.has-border.default {
        border-color: $color-1;
    }

    .payment-method-tile.has-border.expired {
        border-color: $error;
    }
</style>