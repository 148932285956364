<script setup>

    // ProspectingSelectMultipleDropsStep3Page
    // Set up for multiple drops of mailings

    // Imports 
    import { ref } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // Constants
    const frequencyOptions = {
        BI_WEEKLY: 'bi-weekly',
        WEEKLY: 'weekly'
    }

    const numberOfMailingsOptions = {
        TWO: 2,
        THREE: 3,
        FOUR: 4
    }

    // State 
    const numberOfMailings = ref("");

    const frequency = ref("");

    // Methods 
    function switchToOneTime() {
        // Call API to switch to One Time Mailing 
    }

    function saveForLater() {
        // TODO make the API call to save this product for later
    }

</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />

            <div class="root-page-container prospecting-select-multiple-drops">
                
                <div class="row">
                    
                    <div class="col">
                        <SelectMailListStepper :currentStep="stepCounter.THREE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">
                            <div>
                                <h1>Multiple Drops</h1>
                            </div>
                            <div class="ms-auto pt-2">
                                <!-- TODO: output the selected product -->
                                Prospecting Letter, Safeco Package (AP-9999)
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div class="row">

                    <div class="col fs-5 mt-3">
                        <a href="#" class="no-underline" @click="switchToOneTime">Switch to One-Time Drop</a>
                    </div>

                </div>

                <div class="row my-5">

                    <div class="col-sm-4 offset-sm-4 bg-color-3-xlt text-center px-5 py-3">

                        <div class="mb-5">
                            <label class="form-label fw-bold">Choose Frequency</label>
                            <select class="form-select" v-model="frequency">
                                <option value="">Choose Frequency</option>
                                <option :value="frequencyOptions.WEEKLY">Weekly</option>
                                <option :value="frequencyOptions.BI_WEEKLY">Bi-Weekly</option>
                            </select>
                        </div>

                        <div class="mb-5">
                            <label class="form-label fw-bold">Choose Number of Mailings</label>
                            <select class="form-select" v-model="numberOfMailings">
                                <option value="">Choose Number of Mailings</option>
                                <option :value="numberOfMailingsOptions.TWO">2</option>
                                <option :value="numberOfMailingsOptions.THREE">3</option>
                                <option :value="numberOfMailingsOptions.FOUR">4</option>
                            </select>
                        </div>

                        <div class="mb-5">
                            <label class="form-label fw-bold">Select Drop Start Date</label>
                            <!-- TODO: Add the calendar input when forms are merged in -->
                        </div>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">

                        <div class="d-flex">
                            <div class="p-2">

                                <!-- TODO: Add the router back link-->
                                <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>
                            </div>
                            <div class="ms-auto p-2">
                                <a href="#" class="btn btn-outline-primary me-3" @click="saveForLater">Save for Later</a>
                                <!-- TODO: Add the router link to the next step-->
                                <router-link to="#TODO" class="btn btn-primary">Next</router-link>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

</PageTemplate>

</template>