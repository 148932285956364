<script setup>

    // AnnualAutoSelectSubCodesPage
    // Page for selecting sub codes

    import { ref, reactive } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';

    // State 
    // TODO these should be retrieved from an API 
    const subCodes = [
        { "id": 33, "name": "21-02-8212 - Agency Name LLC (33)" },
        { "id": 7, "name": "21-02-2073 - Agency Name LLC (7)" },
        { "id": 1, "name": "21-02-1141 - Agency Name LLC (1)" },
        { "id": 9, "name": "21-02-2518 - Agency Name LLC (9)" },
        { "id": 50, "name": "21-02-1234  - Agency Name LLC (50)" },
        { "id": 10, "name": "21-02-1234  - Agency Name LLC (10)" },
        { "id": 5, "name": "21-02-1234  - Agency Name LLC (5)" },
        { "id": 25, "name": "21-02-1234  - Agency Name LLC (25)" },
    ];

    const allSelected = ref(false);

    const selected = reactive({ value: [] });

    // Methods
    function clearAll() {
        selected.value = [];
        allSelected.value = false;
    }

    function select() {
        if (selected.value.length == subCodes.length) {
            allSelected.value = true;
        } else {
            allSelected.value = false;
        }
    }

    function selectAll() {
        if (allSelected.value) {
            selected.value = subCodes.map((u) => u.id);
        } else {
            selected.value = [];
        }
    }

</script>

<template>
    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container annual-auto-select-sub-codes">

                <div class="row">

                    <div class="col text-center">

                        <h1>The Sub Codes below will be included.</h1>
                        
                        <h3>Unselect any Sub Codes you would like to exclude.</h3>

                    </div>

                </div>

                <div class="row">

                    <div class="col-sm-4 offset-sm-4">

                        <div class="mb-3">
                            <a href="#" @click="clearAll" class="no-underline">Clear All</a>
                        </div>

                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" v-model="allSelected" @change="selectAll" id="selectAll">
                            <label class="form-check-label fw-bold" for="selectAll">All Sub Codes</label>
                        </div>

                        <template v-for="code in subCodes" :key="code.id">
                            <div class="mb-3 form-check">
                                <input type="checkbox" v-model="selected.value" @click="select($event)" :value="code.id" class="form-check-input" :id="'subCode' + code.id">
                                <label class="form-check-label" :for="'subCode' + code.id">{{code.name}}</label>
                            </div>
                        </template>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col text-center">

                        <button class="btn btn-outline-primary me-3">Previous</button>
                        <button class="btn btn-primary">Next</button>

                    </div>

                </div>

            </div>

        </div>

    </PageTemplate>
</template>

<style lang="scss">
    .annual-auto-select-sub-codes {
    }
</style>