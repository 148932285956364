<script setup>
    // PersonalizeStep3ReviewPage
    // Step 3 of mail drops the review and check out or add to cart

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import ThreeStepStepper, { stepCounter } from '@/site/components/ThreeStepStepper';

    // Imports
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRouter, useRoute } from 'vue-router';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import { TabTypes } from './PersonalizeStep1Page';
    import { ProgramType } from '../../../constants';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';

    const route = useRoute();

    const router = useRouter();

    // State
    const detail = reactive({ product: {} });

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const summaryDetails = reactive([]);

    const selectedPageFlexDocumentId = ref('');

    const addingToCart = ref(false);
    // Methods
    async function addToCart() {
        
        addingToCart.value = true;
        await site.personalizationInfo.setSaveForLater(false);
        const response = await site.personalizationInfo.addToCart(orderDetails.value.productId, orderDetails.value.targetCount);
        if(Util.isNotEmpty(orderFlowDetails.childOrderStates)) {
            for(let i = 0 ; i< orderFlowDetails.childOrderStates.length; i++) {
                const response = await site.personalizationInfo.addToCart(orderFlowDetails.childOrderStates[i].productId, orderFlowDetails.childOrderStates[i].targetCount);
                if (!response.succeeded) {
                    site.toast.error(response.error.responseStatus.message, 'Error');
                    return;
                }
            }
        }

        if (response.succeeded) {
            site.toast.success(detail.product.name + ' was successfully added to the cart!', 'Added To Cart');
        } else {
            site.toast.error(response.error.responseStatus.message, 'Error');
        }
        addingToCart.value = false;
    }

    const toPersonalizationPage = computed(() => {
        const parentId = orderDetails.value.parentId ? orderDetails.value.parentId : orderDetails.value.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderDetails.value.id}/${parentId}/${orderDetails.value.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });


    function back() {
        router.push({
            name: 'PersonalizeStep2Page',
            params: orderFlowDetails.id
        });
    }

    async function checkout() {
        if (!site.errorHandler.canStartOrder(orderDetails.value.productId)) {
            return false;
        }
        await addToCart();

        router.push({
            name: 'CartPage'
        });
    }

    function previewPdf(pageFlexDocumentId) {
        site.personalizationInfo.openPdfPreview(pageFlexDocumentId);
    }

    
    async function saveForLater() {
        if (site.errorHandler.handleSaveForLaterRequest()) {
            router.push({
                name: 'Home',
            });
        }
    }


    // Computed
    const orderDetails = computed(() => {
        return site.personalizationInfo.orderFlowDetails;
    });

    const userInfo = computed(() => {
        return site.user;
    });

    const childPageFlexDocumentIds = computed(() => {

        if (Util.isNotEmpty(orderFlowDetails.childOrderStates)) {
            return orderFlowDetails.childOrderStates.map(pd => {
                return {
                    value: pd.pageFlexDocumentId,
                    text:  pd.itemName
                };
            });
        }
        return [];
    });
    
    const pdfPreviewUrl = ((pageFlexDocId) => {

        if (Util.isNotEmpty(pageFlexDocId) && Util.isNotEmpty(userInfo.value.userName)) {
            const userName = userInfo.value.userName.replace(".Test@test.com", '');
            const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;
            return `${BASE_PUNCHOUT_URL}/PMGetPdfProof.aspx?DocID=${pageFlexDocId}&UserName=${userName}`;
        }
        else
            return '';

    });


    const tabName = computed(() => {
        switch (orderFlowDetails.programType) {
            case TabTypes.CROSS_SELL:
            case TabTypes.PET_INSURANCE:
                return TabIDs.CROSS_SELL_TAB_ID;
            case TabTypes.PROSPECTING:
            case TabTypes.DATA_LIST_UPLOAD:
                return TabIDs.PROSPECTING_TAB_ID;
            case TabTypes.RETENTION:
            case TabTypes.WINBACK:
            case TabTypes.ANNUAL_AUTO:
                return TabIDs.RETENTION_TAB_ID;
            default:
                return '';
        }
    });

    const isLoaded = computed(() => {
        return Util.isNotEmpty(detail.product);
    });

    const canAddToCart = computed(() => {
        return !addingToCart.value
    });

    // Methods
    async function getSummaryData() {

        const response = await site.personalizationInfo.getRelationMarketingSummary(orderFlowDetails.subscriptionPeriod);

        summaryDetails.length = 0;

        for (let i = 0; i < response.summary.length; i++) {
            summaryDetails.push(response.summary[i]);
        }

    }

    const displayElements = computed(() => {
        let displayState = {
            showSubscription: true,
            showPriceSummary: true,
            showRenewalText: false,
            showPetInsuranceDropText: false,
        };
        switch (orderDetails.value.programType) {
            case ProgramType.ANNUAL_AUTO:
                displayState.showSubscription = false;
                displayState.showPriceSummary = false;
                displayState.showRenewalText = true;
                break;
            case ProgramType.PET_INSURANCE:
                displayState.showSubscription = false;
                displayState.showPriceSummary = false;
                displayState.showPetInsuranceDropText = true;
                break;
            default:
                break;
        }
        return displayState;
    })

    // Lifecycle ----
    onMounted(async () => {

        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            await site.personalizationInfo.getOrderState(orderStateId);
            if (orderFlowDetails.childOrderStates && orderFlowDetails.childOrderStates.length > 0) {
                selectedPageFlexDocumentId.value = orderFlowDetails.childOrderStates[0].pageFlexDocumentId;
            }
        }

        if (orderFlowDetails && orderFlowDetails.productId && orderFlowDetails.productId.length > 0) {
            Object.assign(detail.product, await site.products.getProductDetail(orderFlowDetails.productId));
        }

        getSummaryData();
    });
    
</script>
<template>
    <PageTemplate>
        <div class="container root-page personalize-step-3-review">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />
            <div class="root-page-container" v-if="isLoaded">

                <div class="row">

                    <div class="col">
                        <ThreeStepStepper :currentStep="stepCounter.THREE" />
                    </div>

                </div>

                <div class="row border-bottom border-gray-2 mt-5">

                    <div class="col">

                        <div class="d-flex">

                            <div>
                                <h1>Review & Add to Cart</h1>
                            </div>

                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>

                        </div>

                    </div>

                </div>

                <p v-if="displayElements.showRenewalText" class="text-center fw-bold text-danger py-2">Your customer will be mailed a letter 60-90 days prior to their respective renewal date.</p>
                <p v-if="displayElements.showPetInsuranceDropText" class="text-center fw-bold text-danger py-2">Emails are processed and deployed weekly.</p>

                <div class="row mt-5">

                    <div class="col-sm-6">

                        <div class="mb-3">

                            <div class="fw-bold">Item</div>
                            <div class="text-color-2">
                                {{detail.product.name}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Item ID</div>
                            <div class="text-color-2">
                                {{detail.product.itemNumber}}
                            </div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Personalization <router-link :to="toPersonalizationPage" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</router-link></div>

                        </div>

                        <div class="mb-3">

                            <div class="fw-bold">Total Order Count <button @click="back" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2">
                                {{orderDetails.targetCount}}  x (<UomLabel :item="detail.product" />)
                            </div>

                        </div>

                        <div v-if="displayElements.showSubscription" class="mb-3">

                            <div class="fw-bold">Subscription Period <button @click="back" class="btn btn-outline-primary btn-sm"><i class="bi bi-pencil-fill"></i> Edit</button></div>
                            <div class="text-color-2 text-capitalize">
                                {{orderDetails.subscriptionPeriod}} months
                            </div>

                        </div>
                        <template v-if="!displayElements.showSubscription">
                            <div>
                                Note: Turn off Pop-up Blocker to allow for PDF previewing.
                            </div>

                            <div class="mb-5">
                                <button class="btn btn-primary" @click="previewPdf(orderFlowDetails.pageFlexDocumentId)">Preview PDF</button>
                            </div>
                        </template>

                        <div v-if="displayElements.showPriceSummary" class="frequency-headline py-2 fs-4 text-center mb-1 fw-bold">
                            Summary
                        </div>

                        <div v-if="displayElements.showPriceSummary">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Selected</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="detail in orderDetails.seasonalSummary" :key="detail.assetName" :class="{ 'fw-bold': detail.assetName == 'Total' }">
                                        <td>{{detail.assetName}}</td>
                                        <td>
                                            {{detail.selectedCount}}
                                        </td>
                                        <td v-if="detail.price">{{site.convertNumberToCurrency(detail.price)}}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>

                        <div class="mt-3 mb-5">

                            <button @click="back" class="btn btn-outline-primary me-3">
                                Previous
                            </button>

                            <button @click="saveForLater" class="btn btn-primary me-3">Save For Later</button>

                            <button @click="checkout" class="btn btn-primary" :disabled="!canAddToCart">
                                Add to Cart
                            </button>

                        </div>

                    </div>

                    <div class="col-sm-5 offset-1" v-if="!displayElements.showSubscription">
                        <!-- TODO get the actual PDF -->
                        <iframe if="pdfPreviewUrl(orderFlowDetails.pageFlexDocumentId).length > 0 > 0" :src="pdfPreviewUrl(orderFlowDetails.pageFlexDocumentId)" scrolling="yes" width="100%" height="800px" />
                        <!--<PdfViewer :pdfDetails="pdfDetails" :height="700" />-->
                    </div>

                    <div class="col-sm-5 offset-1" v-if="displayElements.showSubscription">
                        <!-- TODO get the actual PDF -->
                        <div class="row">
                            <div class="col-sm-8">
                                <select v-model="selectedPageFlexDocumentId" class="n-page-size-list form-select">
                                    <option v-for="pageFlexInfo in childPageFlexDocumentIds" :value="pageFlexInfo.value" :key="pageFlexInfo.value">{{ pageFlexInfo.text}}</option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <button class="btn btn-primary" @click="previewPdf(selectedPageFlexDocumentId)">Preview</button>
                            </div>
                        </div>
                        <div class="mt-2">
                            Note: Turn off Pop-up Blocker to allow for PDF previewing.
                        </div>
                        <div class="mt-2">
                            <iframe if="pdfPreviewUrl(selectedPageFlexDocumentId).length > 0" :src="pdfPreviewUrl(selectedPageFlexDocumentId)" scrolling="yes" width="100%" height="800px" />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .personalize-step-3-review {

        .btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        .summary {
            background: $color-2-dk;
            color: $gray-0;
        }

        thead tr {
            background: $color-2;
            color: $gray-0;
        }

        thead tr th {
            font-weight: 400;
            border-right: 1px solid #fff;
        }

        thead tr th:last-child {
            border-right: none;
        }

        tbody tr {
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }

        .text-color-2 {
            color: $color-2;
        }

        .frequency-headline {
            background: $color-2-dk;
            color: $gray-0;
        }
    }
</style>
