<script setup>

    // ProspectingStep2SelectMailListPage
    // Prospecting Step 2 Select Mail List

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';

    // Methods 
    function includeProspectPhone(includePhone) {
        // TODO add the functaionality of what to do based on the button clicked
        console.log(includePhone)
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar/>

            <div class="root-page-container prospecting-step2-select-mail-list">

                <div class="row">

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.TWO" />

                    </div>

                </div>

                <div class="row">

                    <div class="col text-center">

                        <h1>Would you like to include prospect phone numbers when available?</h1>
                        <p class="fst-italic">By including phone numbers, additional charges will apply if not using Ignite Rewards.</p>

                    </div>

                </div>

                <div class="row mb-5">

                    <div class="col text-center">

                        <button class="btn btn-outline-primary me-3" @click="includeProspectPhone(false)">No</button>
                        <button class="btn btn-primary" @click="includeProspectPhone(true)">Yes</button>

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">
                        <!-- TODO: Add the route for back -->
                        <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .prospecting-step2-select-mail-list{

    }
</style>