<script>

    const constants = {
        dropType: {
            ONE: 'ONE',
            MULTIPLE: 'MULTIPLE',
            NONE: 'NONE'
        }
    }

    export const dropType = constants.dropType;

</script>
<script setup>

    // PersonalizeStep3Page
    // Step 3 selecting how you would like the mail sent

    // Constants ----
    const mailSchedule = reactive([]);

    // Imports ----
    import { site } from '@/Site';
    import { computed, ref, onMounted, reactive } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { MinMailingCount } from '@/constants'

    // Components ----
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar  from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import { isNotEmpty } from '@/util';
    import { ProgramType, getTabName } from '@/constants'

    const router = useRouter();
    const route = useRoute();


    // State ----
    const dropTypeSelected = ref(dropType.ONE);
    const orderFlowDetails = reactive({});
    const isLoaded = ref(false);

    async function next() {

        //If previously selected drop type is different from what we have it now
        if (dropTypeSelected.value != orderFlowDetails.dropDetails?.dropType) {
            console.warn("dropTypeSelected", dropTypeSelected.value);
            await site.personalizationInfo.setDropType(dropTypeSelected.value);
        }
        router.push({
            name: 'PersonalizeStep3DropDatePage',
            params: { id: orderFlowDetails.id },
            query: route.query
        });
    }

    async function setMailScheduleTypes() {
        let hasProductDataType = await site.personalizationInfo.getProductDataType(orderFlowDetails.productId);
        const programType = orderFlowDetails.programType;
        mailSchedule.push({
            value: dropType.ONE,
            label: programType == ProgramType.PROSPECTING ? 'Send to all prospects at one time' : 'Send to all policyholders at one time'
        });

        if (isNotEmpty(hasProductDataType) && hasProductDataType == true && orderFlowDetails.targetCount > 25) {
            mailSchedule.push({
                value: dropType.MULTIPLE,
                label: programType == ProgramType.PROSPECTING ? 'Send to all prospects over several dates' : 'Send to policyholders over several dates'
            });
        }
    }



    // Computed
    const tabName = computed(() => {
         return getTabName(orderFlowDetails.programType);
    });


    const hasLessMailingList = computed(() => {
        return orderFlowDetails.targetCount <= MinMailingCount
    });

    //OnMounted
    onMounted(async () => {

        const orderStateId = route.params.id
        if (isNotEmpty(orderStateId)) {
            const response = await site.personalizationInfo.getOrderState(orderStateId);
            Object.assign(orderFlowDetails, response.model);
            if (orderFlowDetails.dropDetails?.dropType) {
                dropTypeSelected.value = orderFlowDetails.dropDetails?.dropType;
            }
            if (hasLessMailingList.value) {
                dropTypeSelected.value = dropType.ONE;
                await next();
                return;
            }
        }

        if (isNotEmpty(orderFlowDetails.productId)) {
            await setMailScheduleTypes();
        }
        isLoaded.value = true;
    });


</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />

            <div class="root-page-container cross-sell-page3" v-show="!hasLessMailingList">

                <div class="row">

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.THREE" />

                    </div>

                </div>
                <template v-if="isLoaded">
                    <div class="row">

                        <div class="col text-center">

                            <h1>How would you like your mailing to be sent?</h1>

                        </div>

                    </div>

                    <div class="row mb-5 mt-2">

                        <div class="col-sm-4 offset-sm-4">

                            <div class="form-check mb-3" v-for="schedule in mailSchedule" :key="schedule.value">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="dropTypeSelected" :value="schedule.value" name="howToSend">
                                    {{schedule.label}}
                                </label>
                            </div>

                        </div>

                    </div>

                    <div class="row mb-5">

                        <div class="col text-center">

                            <router-link :to="`/personalization/step-2/${orderFlowDetails.id}`" class="btn btn-outline-primary me-3">Previous</router-link>
                            <button @click="next" class="btn btn-primary">Next</button>

                        </div>

                    </div>
                </template>
            </div>

        </div>
    </PageTemplate>
</template>
<style lang="scss">
    .cross-sell-page3 {

        label {
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
</style>
