<script setup>
    import { onMounted, ref, reactive, computed, defineEmits } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    import { site } from '@/Site';
    import SelectAgencySubCodes from '@/site/areas/expressorder/SelectAgencySubCodes';
    import ExpressOrderSelectPolicyHolders from '@/site/areas/expressorder/ExpressOrderSelectPolicyHolders';
    import { isNotEmpty } from '@/util';
    import { SubCodeStatus } from '@/constants';

    const emit = defineEmits(['stepTwoSelectAllSelected']);
    const route = useRoute();
    const router = useRouter();
    const bulkOrderState = reactive({});
    const currentOrderState = reactive({});
    const allSubCodes = reactive({});

    const isLoaded = ref(false);
    const selectedSubCodes = ref([]);

    const activeOrderStateId = ref("");
    const activeAgency = reactive({
        statCode: '',
        name: '',
        policyHoldersCount: 0
    });
    const isAppliedForAll = ref(false)

    // computed
    const showPolicyHolders = computed(() => {
        return activeAgency?.statCode?.length > 0;
    });

    const activeAgencyIndex = computed(() => {
        return selectedSubCodes.value.findIndex(asc => asc.statId == activeAgency.statCode) + 1;
    });

    const totalSubCodes = computed(() => {
        return selectedSubCodes.value.length;
    });


    //Methods
    async function changeActiveSubCode(code) {
        if (isNotEmpty(code)) {
            var orderState = bulkOrderState.orderStates.find(os => os.statCode == code);
            activeOrderStateId.value = orderState?.id;
            activeAgency.statCode = code;
            activeAgency.name = allSubCodes[code].agencyName;
            activeAgency.policyHoldersCount = orderState.targetCount;
            const response = await site.personalizationInfo.getOrderState(orderState?.id);
            if (response.succeeded) {
                Object.assign(currentOrderState, response.model);
            }
        }
    }
    async function updatedMailContacts(request) {
        //get latest ordertstae and updated subcodes in the common component
        isLoaded.value = false;
        var bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
        }

        await nextSubCode(request.statCode);
        isLoaded.value = true;
    }

    function goToNextStep() {
        router.push({
            name: 'ExpressOrderStepThree',
            params: {
                bulkOrderStateId: route.params.bulkOrderStateId,
            }
        });
    }

    async function nextSubCode(currentStatCode) {
        // Get all the subcode which OM has selected

        if (selectedSubCodes.value && selectedSubCodes.value.length > 0) {
            let statCode = selectedSubCodes.value[0].statId;
            if (isNotEmpty(currentStatCode)) {
                if (selectedSubCodes.value.length == 1) {
                    goToNextStep();
                }
                const currentSelectedIndex = selectedSubCodes.value.findIndex(a => a.statId == currentStatCode);
                if (currentSelectedIndex >=0 && currentSelectedIndex <= selectedSubCodes.value.length - 2) {
                    statCode = selectedSubCodes.value[currentSelectedIndex + 1]?.statId;
                } else if (currentSelectedIndex == selectedSubCodes.value.length-1) {
                    //goToNextStep();
                    statCode = selectedSubCodes.value[0]?.statId;
                }

            }
            await changeActiveSubCode(statCode);
        }
    }

    const populateSelectedSubCodes = (() => {
        const subCodesToUpdatePolicyHolders = bulkOrderState?.subCodesDetails?.filter(sd => !sd.selectAllPolicyHolders);
        if (isNotEmpty(subCodesToUpdatePolicyHolders)) {
            const orderStates = bulkOrderState.orderStates;
            const subCodesByOM = subCodesToUpdatePolicyHolders.map(obj => {
                const orderState = orderStates.find(os => os.statCode == obj.statCode);
                const subcode = allSubCodes[obj.statCode];
                return {
                    statId: obj.statCode,
                    name: subcode.agencyName,
                    policyHoldersCount: orderState.targetCount,
                    status: orderState.targetCount > 0 /*&& orderState.targetCount != subcode.policyHoldersCount*/
                        ? SubCodeStatus.COMPLETED : SubCodeStatus.IN_PROGRESS
                }
            });
            selectedSubCodes.value = subCodesByOM;
        } else {
            selectedSubCodes.value = [];
        }
    });

    function changeShowPolicy() {
        emit('stepTwoSelectAllSelected')
    }

    // Lifecycle ----
    onMounted(async () => {
        // Load Bulk Order State
        var bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId))
        }

        // Load All SubCode for an OM
        let subcodes = await site.agencyDetails.getAgencySubCodes(bulkOrderState.oppId)
        subcodes.forEach((obj) => {
            allSubCodes[obj.statId] = obj
        })
        Object.assign(allSubCodes, subcodes);
        populateSelectedSubCodes();
        await nextSubCode(null);
        isLoaded.value = true
    });


</script>

<template>
    <div class="root-page-container ">

        <div class="row mt-5">
            <div class="col select-agency-subcodes">
                <SelectAgencySubCodes :selectAll="isAppliedForAll"
                                      :subCodes="selectedSubCodes"
                                      :activeAgency="activeAgency"
                                      @selected="changeActiveSubCode">
                    <div class="heading-slot">
                        <p class="">
                            <span class="subcode-header">Selected agency Sub Codes</span>
                            <template v-if="isAppliedForAll">
                                <span class="subcode-page"> ({{totalSubCodes}})</span>
                            </template>
                            <template v-else>
                                <span class="subcode-page"> ({{activeAgencyIndex}} of {{totalSubCodes}})</span>
                            </template>
                        </p>
                        <button class="m-2 btn btn-outline-primary change-subcode" @click="changeShowPolicy">Change Policy Holder(s)</button>
                    </div>
                </SelectAgencySubCodes>


                <p class="agency-info" v-show="isAppliedForAll">The Agency and Agent Information below will be the same for all selected Codes.</p>
            </div>
        </div>

        <div class="row mt-5" v-if="showPolicyHolders">
            <div class="col">
                <ExpressOrderSelectPolicyHolders :bulkOrderState="bulkOrderState"
                                                 :statCode="activeAgency.statCode"
                                                 @updatedMailContacts="updatedMailContacts"
                                                 :orderStateId="activeOrderStateId"
                                                 @stepTwoSelectAllSelected="changeShowPolicy"
                                                 :showNextSubCode = "totalSubCodes > 1"
                                                 />
            </div>
        </div>


    </div>
</template>

<style lang="scss">

    .heading-slot {
        display: flex;
        justify-content: space-between;

        p {
            color: blue;
            font-weight: bold;
            font-size: 14px;
        }

        button {
            font-size: 12px;
            border-radius: 6px;
            font-weight: bold;
        }
    }

    .agency-info {
        text-align: center;
        font-weight: bold;
        color: red;
        font-size: 16px;
        margin: 16px 0px;
    }
</style>