// UserFromBridge
// Queries the bridge for user info 

export default class UserFromBridge {
    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }


    /**
     * Returns all of the user data including agency emails, agency details and addresses 
     */
    async getUserInfo() {
        return await this.store.api.get(`/site/user/current/profile`);
    }

    async getBridgeUserAccount() {
        return await this.store.api.get(`/site/user/current/bridge-user`);
    }


}