<script setup>
    // Ignite Promo For Cross sell 

    import { reactive, ref, computed } from 'vue';
    import { site } from '@/Site';

    // Components
    import AdvantagePlusPanel from "./AdvantagePlusPanel"

    // State
    const eliteAndPlusTiers = ["Elite", "Advantage Plus", "Launch Plus", "Partner Plus"];
    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);
    const selectedAgencyIgniteDetails = reactive(site.agencyDetails.selectedAgencyIgniteDetails);

    // Methods  ----
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

    // computed
    const igniteMessage = computed(() => {
        if (eliteAndPlusTiers.includes(selectedAgencyIgniteDetails.igniteStatus))
            return "Free Cross-sell for Auto, Home, Umbrella and Specialty."; //Elite and other plus tiers

        return "Free Cross-sell for Auto, Home, and Umbrella."; //non plus tiers
    });

    
</script>

<template>
    <!-- OWNER MASTER -->
    <AdvantagePlusPanel v-if="selectedAgencyDetails.isOwnerMaster && !isEmployee" :tierName="selectedAgencyIgniteDetails.igniteStatus">

        <template v-slot:aboveDiscount>

            <p class="pb-0 mt-2 mb-0">
                you qualify for
            </p>
            <p class="fw-bold">
                {{igniteMessage}}
            </p>
        </template>
    </AdvantagePlusPanel>

    <!-- SUB CODE AGENT -->
    <AdvantagePlusPanel v-if="selectedAgencyDetails.isOwnerMaster === false && !isEmployee" :tierName="selectedAgencyDetails.igniteStatus">
        <template v-slot:aboveDiscount>
            <p class="pb-0 mt-2 mb-0">
                you qualify for
            </p>
            <p class="fw-bold mb-1">
                {{igniteMessage}}
            </p>
        </template>
        <template v-slot:belowDiscount>
            Increase issue rates by following up with your customers.
        </template>
    </AdvantagePlusPanel>

    <!-- ALL USERS -->
    <AdvantagePlusPanel v-if="isEmployee">
        <template v-slot:aboveDiscount>
            <p class="fw-bold">
                Safeco is committed to helping
                agents grow their business.
            </p>
        </template>
        <template v-slot:belowDiscount>
            That's why the MSC provides a
            wide array of tools and resources.
        </template>
    </AdvantagePlusPanel>
</template>