<script setup>
    //Place holder page. This page will be redirected to SSO Logout to MSC
    import { onMounted } from 'vue';
    import { useRoute } from 'vue-router'
    import { site } from '@/Site';
    import { Util } from '@dd-nucleus/nucleus-vue';

    var route = useRoute();

    onMounted(() => {
        const signOut = route.query.signout === 'true';
        const homePage = Util.isEmpty(route.params.path);

        if (signOut && Util.isNotEmpty(process.env.VUE_APP_SAFECO_LIBERTY_SSO_LOGOUT_URL)) {
            window.location.href = process.env.VUE_APP_SAFECO_LIBERTY_SSO_LOGOUT_URL;
        }
        else if (Util.isNotEmpty(process.env.VUE_APP_SAFECO_LIBERTY_SSO_REDIRECT_URL)) {
            if (!homePage) {
                site.toast.info("Your Safeco Marketing Solutions Center session has expired and you have been redirected to the home page to begin a new session.");
                setTimeout(() => {
                    window.location.href = process.env.VUE_APP_SAFECO_LIBERTY_SSO_REDIRECT_URL;
                }, 5000)
            } else {
                window.location.href = process.env.VUE_APP_SAFECO_LIBERTY_SSO_REDIRECT_URL;
            }
        }
    })
</script>

<template>
    <div>
    </div>
</template>

<style lang="scss">
</style>