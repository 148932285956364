<script setup>

    // AccountTitle
    // Displayes different account information and messaging.

    // Imports ----
    import { defineProps } from 'vue';

    // Props ----
    const props = defineProps({
        isLocked: {
            type: Boolean,
            required: false,
            default: false
        }
    });

</script>

<template>

    <div class="account-tile mb-3">

        <div class="p-3">
            <i class="bi bi-lock-fill" v-if="props.isLocked"></i>
            <div>
                <slot name="accountInfo"></slot>
            </div>
            <div>
                <slot name="message"></slot>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
    .account-tile {
        position: relative;
        border: 1px solid $color-1;
        border-radius: $card-border-radius;

        .bi-lock-fill {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $gray-2;
        }
    }
</style>