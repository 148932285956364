<script setup>

    // ProspectingStep2SelectZipCodesPage
    // Prospecting Step 2 Select Zip Codes

    // Imports
    import { reactive, ref } from 'vue';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import ModalBox from '@/site/components/ModalBox';

    //State 
    const postalCodes = ref("");

    const postalRadius = ref(0);

    const crossState = ref(false);

    const city = ref("");

    const state = ref("");

    const showZipResults = ref(false);

    const returnedZipCodes = reactive([
        { "city": "Detroit", "zipCode": "48201" },
        { "city": "Detroit", "zipCode": "48202" },
        { "city": "Detroit", "zipCode": "48203" },
        { "city": "Detroit", "zipCode": "48204" },
        { "city": "Detroit", "zipCode": "48205" }
    ]);

    const selectedZipCodes = reactive([
        { "city": "Detroit", "zipCode": "48201" },
        { "city": "Detroit", "zipCode": "48202" }
    ]);

    // Methods
    function submit() {
        // TODO: Submit this data to an API 
    }

    function lookupZip() {
        // TODO: Call API to get list of Zips based on search 
        showZipResults.value = true;
    }

    function continueWitSelectedZips() {
        // TODO: Continue to the next page with the selected Zip Codes
        console.log("continueWitSelectedZips pressed")
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar />

            <div class="root-page-container prospecting-step2-select-zip-codes">

                <div class="row">

                    <ModalBox modalId="zipCodeLookUp" title="ZIP Code Look Up" modalSize="modal-lg">
                        <template v-slot:body>

                            <!-- TODO: Remove the code below and replace it with the component

                                <ZipCodeLookup @selectedZips="setSelectedZipCodes"/>

                                Remember to create a method for setSelectedZipCodes to accept the zipcodes emitted from the component 
                            -->

                            <div class="row">

                                <div class="col">

                                    <p class="fw-bold">Lookup ZIP Code by City and/or State</p>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label class="form-label">City</label>
                                        <input type="text" class="form-control" v-model="city" placeholder="Detroit">
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <label class="form-label">State</label>
                                    <select class="form-select" v-model="state">
                                        <option value="">Select State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>

                                </div>

                            </div>

                            <div class="row mb-5">

                                <div class="col text-end">

                                    <button @click="lookupZip" class="btn btn-primary">
                                        Lookup
                                    </button>

                                </div>
                            </div>

                            <div class="row" v-if="showZipResults">

                                <div class="col-5 bg-color-3-xlt p-3">
                                    <div class="fs-4">Select ZIP Code(s)</div>
                                    <table class="table table-striped">
                                        <thead class="bg-color-2-dk">
                                            <tr class="text-gray-0">
                                                <td class="border-end border-gray-0">
                                                    City
                                                </td>
                                                <td>
                                                    Zip Code
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="zip in returnedZipCodes" :key="zip.zipCode">
                                                <td>{{zip.city}}</td>
                                                <td>{{zip.zipCode}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <div class="col-2 text-center mt-5">

                                    <div class="mt-5">
                                        <button class="btn btn-outline-primary">
                                            Add
                                        </button>
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn btn-outline-primary">
                                            Remove
                                        </button>
                                    </div>

                                </div>

                                <div class="col-5 bg-color-3-xlt p-3">
                                    <div class="fs-4">Selected ZIP Code(s)</div>
                                    <table class="table table-striped">
                                        <thead class="bg-color-2-dk">
                                            <tr class="text-gray-0">
                                                <td class="border-end border-gray-0">
                                                    City
                                                </td>
                                                <td>
                                                    Zip Code
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="zip in selectedZipCodes" :key="zip.zipCode">
                                                <td>{{zip.city}}</td>
                                                <td>{{zip.zipCode}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div class="row mt-5" v-if="showZipResults">

                                <div class="col text-end">

                                    <button @click="continueWitSelectedZips" class="btn btn-primary">
                                        Continue
                                    </button>

                                </div>
                            </div>

                        </template>
                    </ModalBox>

                    <div class="col my-5">

                        <SelectMailListStepper :currentStep="stepCounter.TWO" />

                    </div>

                </div>

                <div class="row my-5">

                    <div class="col border-bottom">

                        <div class="d-flex">
                            <div>
                                <h1>Select ZIP Code(s) to Create Prospecting List</h1>
                            </div>
                            <div class="ms-auto pt-2">
                                <!-- TODO: Output the product that this is for -->
                                Prospecting Letter, Safeco Package (AP-9999)
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row mb-5">

                    <div class="col-sm-4 offset-sm-4 text-center bg-color-3-xlt py-3 px-5">

                        <button class="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#zipCodeLookUp">
                            Zip Code Lookup
                        </button>

                        <div class="py-4 d-flex align-items-center">
                            <hr class="flex-grow-1" />
                            <div class="px-5">OR</div>
                            <hr class="flex-grow-1" />
                        </div>

                        <div class="mb-5 text-start">
                            <label class="form-label"><strong>ZIP Code(s)</strong> (Separate by commas)</label>
                            <input type="text" v-model="postalCodes" class="form-control">
                        </div>

                        <div class="mb-5 text-start">
                            <label class="form-label fw-bold">ZIP Code Radius</label>
                            <select class="form-select" v-model="postalRadius">
                                <option value="0">0 Miles</option>
                                <option value="5">5 Miles</option>
                                <option value="10">10 Miles</option>
                                <option value="15">15 Miles</option>
                                <option value="25">25 Miles</option>
                            </select>
                        </div>

                        <div class="mb-3 form-check text-start">
                            <input type="checkbox" v-model="crossState" class="form-check-input" id="cross-state">
                            <label class="form-check-label" for="cross-state">Okay to cross state boundaries</label>
                        </div>
                        <button type="submit" class="btn btn-primary mb-3" @click="submit">Check Availability</button>
                    </div>

                </div>

                <div class="row my-5">

                    <div class="col">
                        <!-- TODO: Add the route for back -->
                        <router-link to="#TODO" class="btn btn-outline-primary">Previous</router-link>

                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .prospecting-step2-select-zip-codes {
    }
</style>