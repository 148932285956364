<script setup>
    
    // Ignite Promo For Prospecting 

    import { reactive, ref, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { site } from '@/Site';

    // Components 
    import AdvantagePlusPanel from "./AdvantagePlusPanel"

    //State
    const router = useRouter();
    const advantageAndPlusTiers = ["Advantage", "Advantage Plus", "Launch Plus", "Partner Plus"];
    const launchAndPartner = ["Launch", "Partner"];
    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);
    const selectedAgencyIgniteDetails = reactive(site.agencyDetails.selectedAgencyIgniteDetails);

    // Methods  ----
    function enroll() {

        router.push({
            name: 'ProspectingSelectPromoteProspectsPage'
        });
    }

    function viewLists() {
        router.push({ path: '/account/order-history' });
    }

    // computed
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

    const qualifyTextOM = computed(() => {
        if (launchAndPartner.includes(selectedAgencyIgniteDetails.igniteStatus)) {
            return "you qualify for "; //Launch and Partner tiers
        }
        return "you qualify for up to";
    });

    const discountValueOM = computed(() => {
        var percentage = (selectedAgencyIgniteDetails.reductionRate ?? 0) * 100;
        if (launchAndPartner.includes(selectedAgencyIgniteDetails.igniteStatus) && percentage>0) {
            return percentage + "%" + " off " + selectedAgencyIgniteDetails.discountValue  + " prospects annually";
        }
        return selectedAgencyIgniteDetails.discountValue + " Free prospects annually";
    });

    const igniteMessage = computed(() => {
        if (advantageAndPlusTiers.includes(selectedAgencyIgniteDetails.igniteStatus)) {
            return "Free mailings."; //Advantage and other plus tiers
        } else if (launchAndPartner.includes(selectedAgencyIgniteDetails.igniteStatus)) {
            return "Discounted mailings."; //Launch and Partner tiers
        }

        return "Free Prospects."; //Elite tier
    });

</script>

<template>
    <!-- OWNER MASTER -->
     <AdvantagePlusPanel v-if="selectedAgencyDetails.isOwnerMaster && !isEmployee && selectedAgencyIgniteDetails.discountValue > selectedAgencyIgniteDetails.usage" :tierName="selectedAgencyIgniteDetails.igniteStatus" :discountCode="selectedAgencyIgniteDetails.discountCode" buttonText="Order Mailing" @click="enroll">

        <template v-slot:aboveDiscount>

            <p class="pb-0 mt-2 mb-0">
                {{qualifyTextOM}}
            </p>
            <p class="fw-bold">
                {{discountValueOM}}
            </p>

        </template>

        <template v-slot:belowDiscount>
            <p class="fw-bold">
                Total used: {{selectedAgencyIgniteDetails.usage}} | Remaining: {{selectedAgencyIgniteDetails.remaining}}
            </p>
        </template>

    </AdvantagePlusPanel>

    <!-- OWNER MASTER Reward has been used -->
    <AdvantagePlusPanel v-if="selectedAgencyDetails.isOwnerMaster && !isEmployee && selectedAgencyIgniteDetails.discountValue === selectedAgencyIgniteDetails.usage" :tierName="selectedAgencyIgniteDetails.igniteStatus" buttonText="View Lists" @click="viewLists">

        <template v-slot:aboveDiscount>

            <p class="fw-bold mt-2 mb-0">
                Thank you for redeeming your Ignite rewards.
            </p>

        </template>

        <template v-slot:belowDiscount>
            <p class="fw-bold">
                To get started, download your list to view your new prospects.
            </p>
        </template>

    </AdvantagePlusPanel>

    <!-- SUB CODE AGENT -->
    <AdvantagePlusPanel v-if="selectedAgencyDetails.isOwnerMaster === false && !isEmployee" :tierName="selectedAgencyIgniteDetails.igniteStatus" buttonText="Order Mailing" @click="enroll">

        <template v-slot:aboveDiscount>

            <p class="pb-0">
                you may qualify for
            </p>
            <p class="fw-bold">
                {{igniteMessage}}
            </p>
        </template>
        <template v-slot:belowDiscount>
            Contact your TM to discuss or you can order Prospects at cost.
        </template>
    </AdvantagePlusPanel>

    <!-- ALL USERS -->
    <AdvantagePlusPanel v-if="isEmployee">
        <template v-slot:aboveDiscount>
            <p class="fw-bold">
                Safeco is committed to helping
                agents grow their business.
            </p>
        </template>
        <template v-slot:belowDiscount>
            That's why the MSC provides a
            wide array of tools and resources.
        </template>
    </AdvantagePlusPanel>
</template>