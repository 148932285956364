export default class MWSEmailOptOut {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    async EmailOptOut(email) {
        return await this.store.api.post(`site/mws/email-optout?email=${email}`);
    }
}