<script setup>

    //UploadSummary
    //Data validation of uploaded recipients for required

    // Imports ----
    import { defineProps, ref, defineEmits} from 'vue';
    import { site } from '@/Site';

    // Props ----
    const props = defineProps({
        recipients: {
            type: Object,
            required: true
        },
        mailListName: {
            type: String,
            required: true
        },
        product: {
            type: Object,
            required:true
        }
    });

    const recipientsSelectTypeSelected = ref("proceed");
    const recipientsSelectType = [
        { value: 'proceed', label: 'Proceed with only records loaded successfully' },
        { value: 'reupload', label: 'Cancel and Upload Again' }
    ];

    const emits = defineEmits(['next', 'mailListSelected']);

    
    function back() {
        emits('next', 'UPLOAD');
    }

    async function next() {

        if (recipientsSelectTypeSelected.value == 'proceed') {
            const response = await site.personalizationInfo.addNewProspectingMailList(props.recipients.passedAddresses, props.mailListName)
            if (response?.succeeded) {
                emits('mailListSelected', response.id);
            } else {
                site.toast.error(response.messages[0].debugMessage, 'Card Error');
            }
        }
        else {
            emits('next', 'UPLOAD');
        }
    }

</script>

<template>
    <div class="upload-summary">

        <div class="row my-5">

            <div class="col border-bottom border-color-1">

                <div class="d-flex">
                    <div class="text-color-1">
                        <h2 class="mb-0"> Upload summary</h2>
                    </div>
                    <div class="ms-auto">
                        {{props.product.name}} ({{props.product.itemNumber}})
                    </div>
                </div>

            </div>

        </div>

        <div class="row mb-5 mt-2">
            <div class="col">

                <div class="row records-summary">
                    <div class="col-sm-4">
                        <h4><span>{{ props.recipients.totalCount }} </span> Total Records </h4>
                    </div>
                    <div class="col-sm-4">
                        <h4><span class="text-danger"> {{ props.recipients.failedAddresses.length }}</span> Records Failed </h4>
                    </div>
                    <div class="col-sm-4">
                        <h4><span class="text-success"> {{ props.recipients.passedAddresses.length }}</span> Records Loaded Successfully </h4>
                    </div>
                </div>

                <div class="row mb-5 my-5">
                    <div class="mb-5">
                        <h1 class="text-center">Preview the records that failed below. How would you like to proceed?</h1>
                    </div>

                    <div class="col-sm-4 offset-sm-4">

                        <div class="form-check mb-3" v-for="type in recipientsSelectType" :key="type.value">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" v-model="recipientsSelectTypeSelected" :value="type.value">
                                {{type.label}}
                            </label>
                        </div>

                    </div>

                </div>

                <div class="row my-5">
                    <div class="col text-center">
                        <button class="btn btn-outline-primary  me-3" @click="back">Previous</button>
                        <button class="btn btn-primary" @click="next">Next</button>
                    </div>

                </div>

                <div class="failed-records table-responsive">
                    <table class="table table-striped w-100 mw-100">
                        <thead class="title">
                            <tr>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Address Line 1</th>
                                <th>Address Line 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Phone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="props.recipients.failedAddresses.length > 0">
                                <tr v-for="address in props.recipients.failedAddresses" :key="address">

                                <td><i class="bi bi-x-lg btn-danger" /></td>
                                    <td>{{address.firstName}}</td>
                                    <td>{{address.lastName}}</td>
                                    <td>{{address.addressLine1}}</td>
                                    <td>{{address.addressLine2}}</td>
                                    <td>{{address.city}}</td>
                                    <td>{{address.state}}</td>
                                    <td>{{address.postalCode}}</td>
                                    <td>{{address.phone}}</td>
                                    <td>{{address.email}}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="7">No failed records.</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">

    .upload-summary {

        .records-summary {
            background: #f2fcfc;
            padding: 10px;
        }

        .n-action-button {
            .bi {
                color:#ffffff;
            }
        }
    }
</style>