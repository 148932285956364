<script setup>
    import { onMounted, ref, reactive, computed } from 'vue';

    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import { site } from '@/Site';

    import { useRouter, useRoute } from 'vue-router';
    import { isNotEmpty, isEmpty } from '@/util';

    const router = useRouter();
    const route = useRoute();

    const subCodesSelected = ref([]);
    const allSubCodes = reactive([]);
    const isSelectAll = ref(false);
    const bulkOrderState = reactive({});
    const isLoaded = ref(false);
    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);
    

    //Computed
    const disableNext = computed(() => {
        return subCodesSelected.value.length == 0
    });

    const ownerMaster = computed(() => {
        return agencyDetails.value?.statId;
    });

    const filteredSubCodes = computed(() => {
        return allSubCodes.filter(sc => sc.statId != ownerMaster.value);
    });

    // Lifecycle ----
    onMounted(async () => {
        const bulkOrderStateId = route.params.bulkOrderStateId;
        if (isNotEmpty(bulkOrderStateId)) {
            Object.assign(bulkOrderState, await site.expressOrderPersonalizationInfo.getBulkOrderState(bulkOrderStateId));
            Object.assign(allSubCodes, await site.agencyDetails.getAgencySubCodes(bulkOrderState.oppId));
            subCodesSelected.value = bulkOrderState.subCodesDetails?.map(sc => sc.statCode) || [];
            if (isEmpty(subCodesSelected.value)) {
                isSelectAll.value = true;
                handleSelectAllChange();
            }
            selected();
        }
        isLoaded.value = true
    });

    function clearAll() {
        isSelectAll.value = false
        subCodesSelected.value = []
    }

    function handleSelectAllChange() {
        if (isSelectAll.value) {
            const statCodes = filteredSubCodes.value.map(item => { return item.statId })
            subCodesSelected.value = statCodes
        } else {
            subCodesSelected.value = []
        }
    }

    function selected() {
        if (subCodesSelected.value.length >= filteredSubCodes.value.length) {
            isSelectAll.value = true;
        } else {
            isSelectAll.value = false;
        }
    }

    async function next() {

        const newSelectedSubcodes = new Set(subCodesSelected.value).add(ownerMaster.value);
        const payload = {
            id: bulkOrderState.id,
            subCodesDetails: [...newSelectedSubcodes].map(sc => ({
                statCode: sc,
                selectAllPolicyHolders: true
            }))
        }

        const response = await site.expressOrderPersonalizationInfo.updateBulkOrderStateSubCodes(payload);

        if (site.errorHandler.handleStartOrderError(response)) {
            router.push({
                name: 'ExpressOrderPersonalizationConfirmationPage',
                params: {
                    bulkOrderStateId: bulkOrderState.id,
                }
            });
        }
    }

</script>

<template>
    <PageTemplate>
        <div class="container root-page expressordersubcodes">
            <GlobalTabBar />
            <template v-if="isLoaded">
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <h1>The Sub Codes below will be included.</h1>
                        <h4>Unselect any Sub Codes you would like to exclude.</h4>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-4"></div>
                    <div class="col-6">
                        <div class="p-3">
                            <p class="clear-all" @click="clearAll">Clear All</p>
                            <label class="select-all p-2" key="0">
                                <input type="checkbox" v-model="isSelectAll" @change="handleSelectAllChange" />
                                <span>All Sub Codes</span>
                            </label>
                            <label class="select-all p-2" v-for="item in filteredSubCodes" :key="item.statId">
                                <input type="checkbox" v-model="subCodesSelected" :value="item.statId" @change="selected" />
                                <span class="agency-name">{{ item.statId }} - {{ item.agencyName }} ({{item.policyHoldersCount}})
                                    - <router-link class="hover-underline" :to="`/cross-sell/view-detail/${bulkOrderState.oppId}/${item.statId}`">View List</router-link>
                                </span>
                            </label>
                            <br />
                            <router-link class="m-2 btn btn-outline-primary" :to="`/expressorder/confirmation/${bulkOrderState.productId}`">Previous</router-link>
                            <button class="m-2 btn btn-primary" @click="next" :disabled="disableNext">Next</button>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
            </template>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .expressordersubcodes {
        .clear-all {
            font-size: 12px;
            color: blue;
            font-weight: bold;
            margin: 0px;
            margin-bottom: 4px;
            cursor: pointer;
        }

        .select-all {
            display: flex;
            justify-content: flex-start;
            margin: 0px;

            input {
                width: 18px;
                height: 18px;
            }

            span {
                margin-left: 14px;
                font-size: 16px;
                font-weight: bold;
            }

            .agency-name {
                color: dimgrey;
                font-weight: 500;
            }
        }
    }
</style>