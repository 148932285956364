<script setup>

    // ResetPasswordForm

    // Imports
    import { defineProps } from 'vue';

    // Components ----
    import Popper from 'vue3-popper';

    // Props ----
    const props = defineProps({
        // Tooltip placement
        placement: {
            type: String,
            default: "bottom"
        }
    });

</script>

<template>
    <Popper arrow :placement="props.placement">
        <slot />
        <template #content>
            <slot name="content">

            </slot>
        </template>
    </Popper>
                   
</template>

<style lang="scss">
    .bi {
    cursor: pointer;
    }
</style>
