<script setup>

    // CollapsiblePanel
    // Collapsible Panel for FAQs clicking the title or chevron will open the panel. 

    // Imports
    import { ref, defineProps, watch, computed } from 'vue';

    // Contants ----
    var showState = {
        UNSET: 0,
        OPEN: 1,
        CLOSED: 2
    };

    // Props ----
    const props = defineProps({
        open: {
            type: Boolean,
            required: false,
            default: false
        },
        showAnimation: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // State ----
    let open = ref(showState.UNSET);

    // Methods ----
    function toggleOpenClose() {
        if (open.value == showState.UNSET && !props.open || open.value == showState.CLOSED) {
            open.value = showState.OPEN
        } else {
            open.value = showState.CLOSED
        }
    }

    // Computed ----
    const show = computed(() => {
        if (open.value == showState.UNSET) {
            return props.open;
        } else if (open.value == showState.OPEN) {
            return true;
        } else {
            return false;
        }
    });

    // Watcher ----
    watch(
        () => props.open, () => {
            open.value = showState.UNSET;
        }
    );

</script>

<template>

    <div class="collapsible-panel">

        <div class="panel" :class="{ active: show }">
            <div class="panel-title py-3" :class="{ 'fw-bold': show }" @click="toggleOpenClose">
                <slot name="title"></slot>
            </div>

            <template v-if="showAnimation">
                <transition>
                    <div class="panel-content py-3" v-if="show">
                        <slot name="content"></slot>
                    </div>
                </transition>
            </template>

            <template v-else>
                <div class="panel-content py-3" v-if="show">
                    <slot name="content"></slot>
                </div>
            </template>
        </div>

    </div>
</template>

<style lang="scss">

    .panel-title {
        position: relative;
        cursor: pointer;
    }

    .panel-title:before {
        content: " ";
        position: absolute;
        top: calc(50% - 8px);
        right: 14px;
        border-top: 2px solid;
        border-right: 2px solid;
        width: 8px;
        height: 8px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform .3s,top .3s;
        transform: rotate(135deg) translate3d(0,-50%,0);
        transform-origin: 35%;
    }

    .panel.active > .panel-title:before {
        top: calc(50% + 1px);
        transform: rotate(-45deg);
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>