<script setup>
    import { defineProps, ref, defineEmits, /*watch,*/ computed } from 'vue';
    import { SubCodeStatus } from '@/constants';
    import { formatStatCode } from '@/util'

    // Props ----
    const props = defineProps({
        subCodes: {
            type: Array,
            required: true
        },
        activeAgency: {
            type: Object,
            required: true
        },
        // SelectAll is set to true if all the sub codes are perosnalized or selected at once
        selectAll: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    //const subCodes = ref([]);
    const emit = defineEmits(['selected']);
    const numberOfStatCodesToShow = 10;

    //const totalItems = ref(props.subCodes.length);
    let selectedIndex = ref(0);
    //const subCodesSubSet = ref([])


    function loadPrevious() {
        currentIndex.value -= 1
        if (currentIndex.value < 0) {
            currentIndex.value = 0;
        }
    }

    function loadNext() {
        currentIndex.value += 1;
        if ((currentIndex.value + numberOfStatCodesToShow) > props.subCodes.length - 1) {
            currentIndex.value = (props.subCodes.length) - numberOfStatCodesToShow;
        }
        /*        if (currentIndex.value + 10 < props.subCodes.length) {
                    currentIndex.value += 1
                    //subCodesSubSet.value = subCodes.value.slice(currentIndex, currentIndex + 10)
                }
        */
    }

    function subCodeStatus(statId, status) {
        if (props.selectAll) {
            return 'no-action'
        }
        if (statId === props.activeAgency.statCode) {
            return 'active'
        } else {
            if (status === SubCodeStatus.COMPLETED) {
                return 'done'
            } else {
                return 'todo'
            }
        }
    }

    function onStatIdClick(itemId) {
        if (!props.selectAll) {
            emit('selected', itemId)
        }
    }

    //computed
    const showScroll = computed(() => {
        return props.subCodes && props.subCodes.length > numberOfStatCodesToShow;
    });

    const subCodesSubSet = computed(() => {
        return props.subCodes.slice(currentIndex.value, currentIndex.value + numberOfStatCodesToShow);
    });

    const currentIndex = computed({
        get() {
            return Number(selectedIndex.value);
        },
        set(value) {
            selectedIndex.value = value;
        }
    });

</script>
<template>
    <div class="row">
        <div class="col">
            <div class="subcode-container" :class="{'not-active': props.selectAll}">
                <slot></slot>
                <p class="heading"></p>
                <div class="row">
                    <div class="col-1 text-center">
                        <template v-if="showScroll">
                            <p @click="loadPrevious"><i class="bi bi-arrow-left-circle-fill"></i></p>
                        </template>

                    </div>
                    <div class="col-1" v-for="item in subCodesSubSet" :key="item.statId">
                        <template v-if="props.selectAll">
                            <div class="box">
                                <p :class="subCodeStatus(item.statId, item.status)">
                                    {{ formatStatCode(item.statId) }}
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <div class="box cursor" @click="onStatIdClick(item.statId)">
                                <p :class="subCodeStatus(item.statId, item.status)">
                                    {{ formatStatCode(item.statId) }}
                                </p>
                            </div>
                        </template>
                    </div>
                    <div class="col-1 text-center">
                        <template v-if="showScroll">
                            <p @click="loadNext"><i class="bi bi-arrow-right-circle-fill"></i></p>
                        </template>
                    </div>
                </div>
                <div v-show="!props.selectAll" class="row">
                    <div class="col-1"></div>
                    <div class="col">
                        <p class="agency-name">{{ props.activeAgency?.name }} ({{props.activeAgency?.policyHoldersCount}} Recipients)</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .heading {
        color: blue;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid $gray-3;
        font-weight: bold;
    }

    .subcode-container {
        background-color: #FFEFD6;
        padding: 12px;

        .box {
            p {
                font-size: 0.85rem;
                padding: 6px 4px;
                text-align: center;
                border-radius: 6px;
                color: $color-1;
                font-weight: 700
            }

            .active {
                //background-color: $color-2;
                background-color: blue;
                color: $gray-0;
            }

            .done {
                background-color: green;
                color: $gray-0;
            }

            .todo {
                background-color: grey;
                color: $gray-0;
            }

            .no-action {
                background-color: $color-3-dk;
            }
        }

        .cursor {
            cursor: pointer;
        }

        .agency-name {
            margin: 0px;
            font-weight: bold;
            font-size: 12px;
        }
    }

    .not-active {
        background-color: $gray-1 !important;
    }
</style>