<script setup>

    // PageTemplate
    // This template is for pages that use PageHeader.vue and PageFooter.vue in /src/site/components

     // Components
    import { onMounted } from 'vue';
    import { useRouter} from 'vue-router';
    import { site } from '@/Site';

    // Components
    import PageHeader from './components/PageHeader';
    import PageFooter from './components/PageFooter';
    import CurrentAgency from './components/CurrentAgency';

    const router = useRouter();

    // Lifecycle ----
    onMounted(async () => {
        //Check the session storage has a sso logged in value or not. If not redirect to home page.
        await clearStorage();
    });

    async function clearStorage() {
        const session = sessionStorage.getItem('logged-in');
        if (session == null) {
            await signOut();
        }
    }

    async function signOut() {
        if (process.env.VUE_APP_MOCK_AUTH_ENABLED == "true") {
            router.push({ name: 'SignIn' });
        } else {
            await site.signOut(router);
        }
    }

</script>

<template>
    <PageHeader />
    <CurrentAgency />
    <slot></slot>
    <PageFooter />
</template>
