<script setup>

    // PetInsuranceSelectPolicyholders
    // Step 2 SelectPolicyholders

    // Constants ----

    const columns = [
        { field: 'statCode', title: 'Sub Code', isSortable: true, custom: true },
        { field: 'mailingDate', title: 'Mailing Date', custom: true, isSortable: true },
        { field: 'marketingChannel', title: 'Marketing Channel', custom: true, isSortable: true },
        { field: 'policyNumber', title: 'Policy #', isSortable: true },
        { field: 'renewalDate', title: 'Renewal Date', custom: true, isSortable: true },
        { field: 'policyType', title: 'Policy Type', isSortable: true },
        { field: 'firstName', title: 'First Name', isSortable: true },
        { field: 'lastName', title: 'Last Name', isSortable: true },
        { field: 'address1', title: 'Address 1', isSortable: true },
        { field: 'address2', title: 'Address 2', isSortable: true },
        { field: 'city', title: 'City', isSortable: true },
        { field: 'state', title: 'State', isSortable: true },
        { field: 'zip', title: 'Zip', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true },
        { field: 'phone', title: 'Phone', isSortable: true },
    ];

    // Imports
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { ref, reactive, computed, defineProps, defineEmits } from 'vue';
    import { site } from '@/Site';
    import { useRouter, useRoute } from 'vue-router';
    import { formatStatCode } from '@/util';
    import { ChannelTypes, DropType, Frequency } from '@/constants'
    import SearchTableV1 from '@/site/components/searches/SearchTableV1';
    // Components
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';

    //props

    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    // State
    const search = new Search('pet-insurance-list-search', 'row', 'policyNumber');

    const router = useRouter();
    const route = useRoute();

    const searchTerm = ref('statCode');

    let selectedList = reactive([]);


    const detail = reactive({ product: props.product });

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);
    const emit = defineEmits(['saveForLater']);

    // Computed
    const listLength = computed(() => {
        return selectedList.length;
    });

    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = detail.product?.attributes?.isEmail ? ChannelTypes.EMAIL : ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=${detail.product?.attributes?.isSubscription}&channelType=${channelType}`;
    });


    // Methods

    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        }
        return '';
    }

    async function next() {
        await UpdateRecipients();

         const currentDate = new Date();

        //since we do not want to show mailing/drop details on cart page, making drop type as none.
        orderFlowDetails.dropDetails.dropType = DropType.NONE.text;
        //Setting default droptype and frequency and number of mailings to get the Pet insurance dropdate.
        await site.personalizationInfo.setDropDetails(currentDate, Frequency.WEEKLY.text, DropType.ONE.value)

        router.push({
            name: 'PersonalizeStep3ReviewPage',
            params: { id: orderFlowDetails.id },
            query: route.query
        });
    }

    async function saveForLater() {
        site.personalizationInfo.saveForLaterInLocal();
        await UpdateRecipients();
        emit('saveForLater');
    }

    async function UpdateRecipients() {
        const allRecords = search.results.rows;
        const selected = allRecords.filter(sl => selectedList.includes(sl.policyNumber)).map(sl => {
            return {
                "firstName": sl.firstName,
                "lastName": sl.lastName,
                "addressLine1": sl.address1,
                "addressLine2": sl.address2,
                "city": sl.city,
                "state": sl.state,
                "postalCode": sl.zip ? sl.zip.trim() : "",
                "policyNumber": sl.policyNumber,
                "policyType": sl.policyType,
                "renewalDate": sl.renewalDate,
                "email": sl.email,
                "phone": sl.phone
            };
        });
        await site.personalizationInfo.addMailContacts(selected);
    }

    function sortSearch(value) {

        if (searchTerm.value.includes(value)) {

            if (searchTerm.value.includes('-desc')) {
                searchTerm.value = value;
            } else {
                searchTerm.value = value + '-desc';
            }

        } else {
            searchTerm.value = value;
        }

        search.setSortId(searchTerm.value);
    }

    //SearchTable Changes
    //computed
    const allRecordsSelected = computed(() => {
        return search?.results?.rows?.length == listLength.value;
    });

    //methods
    function selectAll(isSelectAll, list) {
        selectedList.length = 0;
        if (isSelectAll)
            for (var i = 0; i < list.length; i++) {
                selectedList.push(list[i].policyNumber)
            }
    }

    function selected(value) {
        selectedList.length = 0;
        Object.assign(selectedList, value);
    }

    search.onResults(() => {
        if (props.orderState?.mailContacts?.length == 0) {
            selectedList.length = 0;
            Object.assign(selectedList, search.results?.rows?.map(mc => mc.policyNumber));
        }
        else if (props.orderState?.mailContacts?.length > 0) {
            const currentSelectedList = props.orderState?.mailContacts.map(mc => mc.policyNumber);
            const filteredSelectedList = search?.results?.rows?.filter(mc => currentSelectedList.includes(mc.policyNumber));
            if (filteredSelectedList.length > 0) {
                Object.assign(selectedList, filteredSelectedList.map(mc => mc.policyNumber));
            }
            else {
                selectedList.length = 0;
                Object.assign(selectedList, search?.results?.rows?.map(mc => mc.policyNumber));
            }
        }
    });

</script>

<template>

    <div class="row my-5">

        <div class="row">
            <div class="col">

            </div>
        </div>

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0">Select policyholders to be included in this campaign.</h2>
                </div>
                <div class="ms-auto">
                    {{detail.product.name}} ({{detail.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col">
            <SearchContainer :search="search" default-view="list" :deep-link="false">
                <div class="row mb-2">
                    <div class="d-flex">
                        <div class="me-auto">
                            <span class="text-success fw-bold">{{listLength}}</span> Selected <span class="ms-3 text-success fw-bold">{{ search.results.totalCount }}</span> List Count
                        </div>
                    </div>
                </div>
                <div class="data-table">
                    <SearchTableV1 :columns="columns" @sort-by="sortSearch" selection-type="MULTIPLE" id-field="policyNumber" :select-all="allRecordsSelected" @selected="selected" @selectAll="selectAll" :selected-records="selectedList">
                        <template v-slot:statCode="row">
                            {{ formatStatCode(row.statCode)}}
                        </template>
                        <template v-slot:mailingDate="row">
                            {{formatDate(row.mailingDate)}}
                        </template>

                        <template v-slot:renewalDate="row">
                            {{formatDate(row.renewalDate)}}
                        </template>

                        <template v-slot:marketingChannel="row">
                            <div v-if="row.mailingDate">Direct Mail</div>
                        </template>

                        <!-- Template for no results -->
                        <template #empty>
                            None of your policyholders currently meet the criteria for this type of campaign.
                        </template>

                        <!-- Template while results are initially loading -->
                        <template #loading>
                            <div class="row">
                                <div class="col text-center">
                                    <div class="spinner-border me-3" role="status"></div>
                                    <div class="spinner-border me-3" role="status"></div>
                                    <span class="fw-bold">Performing a search for the data</span>
                                    <div class="spinner-border mx-3" role="status"></div>
                                    <div class="spinner-border" role="status"></div>
                                </div>
                            </div>
                            <TableSkeleton />
                        </template>
                    </SearchTableV1>
                </div>

            </SearchContainer>
        </div>
    </div>

    <div class="row mt-3">

        <div class="d-flex mb-3">
            <div class="me-auto">
                <router-link :to="toPersonalizationPage" class="btn btn-outline-primary">Previous</router-link>
            </div>
            <div class="d-flex">
                <div class="mx-5"><button v-if="listLength > 0" @click="saveForLater" class="btn btn-primary">Save For Later</button></div>
                <button v-if="listLength > 0" @click="next" class="btn btn-primary">Next</button>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
</style>
