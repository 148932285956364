<script setup>

    // CartItemList
    // A list of cart items in either edit or read-only mode.

    // Imports ----
    import { defineProps } from 'vue';
    import { site } from '@/Site';

    // Components ----
    import CartItemTile from './CartItemTile';

    // Props ----
    const props = defineProps({
        // Indicates whether this list is considered editable
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        isBulkOrder: {
            type: Boolean,
            required: false,
            default: false
        }
    });

</script>

<template>

    <div class="cart-item-list">

        <template v-if="site.cart.current.items.length > 0">
            <div v-for="item in site.cart.current.items" :key="item.id" class="cart-item-container">
                <CartItemTile :item="item" :editable="props.editable" :isBulkOrder="isBulkOrder" />
            </div>
        </template>
        <div v-else>
            <slot name="empty"></slot>
        </div>
    </div>

</template>

<style lang="scss">

    .cart-item-list {

        .cart-item-container {
            border-bottom: 1px solid $gray-20;
        }

        .cart-item-container:last-of-type {
            border-bottom: 0;
        }
    }
</style>