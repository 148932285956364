<script setup>

    // SelectMailListOptions

    // Constants ----
    const mailListOptions = [
        { value: 'new', label: 'Upload a new mail list  ' },
        { value: 'existing', label: 'Use previously uploaded agency list' }
    ];

    // Imports ----
    import { defineProps, defineEmits, ref, reactive, computed } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/constants';

    // Props ----
    //props
    const props = defineProps({
        orderState: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        }
    });

    // State ----
    const mailListOptionsSelected = ref('new');

    const orderFlowDetails = reactive(props.orderState);

    const router = useRouter();

    // Computed
    const isAgencySelected = computed(() => {
        return site.agencyDetails.defaultAgency.id > 0;
    });

    // Handlers ----
    const emit = defineEmits(['mailListOption']);


    const toPersonalizationPage = computed(() => {
        const parentId = orderFlowDetails.parentId ? orderFlowDetails.parentId : orderFlowDetails.id;
        const channelType = ChannelTypes.DIRECT_MAIL;
        return `/personalization/step-1/${orderFlowDetails.id}/${parentId}/${orderFlowDetails.productId}/?edit=true&isSubscription=false&channelType=${channelType}`;
    });


    // Methods ----
    function next() {
        emit('mailListOption', mailListOptionsSelected.value);
    }

    async function saveForLater() {
        
        if (site.errorHandler.handleSaveForLaterRequest()) { 
            router.push({
                name: 'Home',
            });
        }
    }

</script>

<template>

    <div class="row my-5">

        <div class="col border-bottom border-color-1">

            <div class="d-flex">
                <div class="text-color-1">
                    <h2 class="mb-0"> Select or upload your mailing list</h2>
                </div>
                <div class="ms-auto">
                    {{props.product.name}} ({{props.product.itemNumber}})
                </div>
            </div>

        </div>

    </div>

    <div class="row">

        <div class="col text-center">

            <h1>
                What type of mail list  would you like to use?
            </h1>

        </div>

    </div>

    <div class="row mb-5 mt-2">

        <div class="col-sm-4 offset-sm-4">

            <div class="form-check mb-3" v-for="type in mailListOptions" :key="type.value">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" v-model="mailListOptionsSelected" :value="type.value" name="howToSend">
                    {{type.label}}
                </label>
            </div>

        </div>

    </div>

    <div class="row mb-5">

        <div class="col text-center">

            <router-link :to="toPersonalizationPage" class="btn btn-outline-primary me-3">Previous</router-link>
            <button v-if="isAgencySelected" @click="saveForLater" class="btn btn-primary me-3">Save For Later</button>
            <button @click="next" class="btn btn-primary">Next</button>
        </div>

    </div>
</template>
<style lang="scss">
    .cross-sell-page3 {

        label {
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
</style>