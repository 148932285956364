<script setup>
    // AddEvent
    // Form for adding events to the user calendar 

    // Imports ----
    import { defineProps, defineEmits, reactive, computed } from 'vue';
    import { eventTypes, eventCategories, eventClass } from '././CalendarPanel';

    // Props ----
    const props = defineProps({
        // The payment method form object.
        form: {
            type: Object,
            required: true
        }
    });

    // State ----
    const form = reactive(props.form);

    // Handlers ----
    const emit = defineEmits(['change']);

    function onChanged() {
        emit('change');
    }

    // Computed
    const category = computed(() => {
        return eventCategories.filter(data => data.id != 'ALL');
    });

    const type = computed(() => {
        return eventTypes.filter(data => data.id != 'ALL');
    });

</script>

<template>
    <div class="add-personalization">
        <FormContainer :form="form" optional-text="(Optional)">
            <FormMessages />
            <slot name="top"></slot>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="title" label="Event Title">
                        <TextBoxField v-model="form.model.title" placeholder="Event Title" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="content" label="Event Content">
                        <TextBoxField v-model="form.model.content" placeholder="Event Content" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-6">
                    <FieldLabel field-name="startDate" label="Start Date and Time">
                        <DateField v-model="form.model.startDate" :addTime="true" placeholder="Start Date and Time" @change="onChanged" />
                    </FieldLabel>
                </div>

                <div class="col-6">
                    <FieldLabel field-name="endDate" label="End Date and Time">
                        <DateField v-model="form.model.endDate" :addTime="true" placeholder="End Date and Time" @change="onChanged" />
                    </FieldLabel>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <FieldLabel field-name="class" label="Class">
                        <DropDownListField v-model="form.model.class" :options="eventClass" null-option-text="Event Class" />
                    </FieldLabel>
                </div>

                <div class="col-4">
                    <FieldLabel field-name="type" label="Event Type">
                        <DropDownListField v-model="form.model.type" :options="type" null-option-text="Event Types" />
                    </FieldLabel>
                </div>

                <div class="col-4">
                    <FieldLabel field-name="category" label="Event Category">
                        <DropDownListField v-model="form.model.category" :options="category" null-option-text="Event Category" />
                    </FieldLabel>
                </div>
            </div>

        </FormContainer>
    </div>
</template>

<style lang="scss">
    .add-personalization{
        h2 {
            border-bottom: 1px solid $gray-3;
            padding-bottom: 10px;
        }
    }
</style>