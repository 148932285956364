<script setup>
    // AddConsent
    // Form for adding the consent items to the bottom of the form

    // Imports ----
    import { defineProps, defineEmits, reactive } from 'vue';

    // Props ----
    const props = defineProps({
        // The payment method form object.
        form: {
            type: Object,
            required: true
        }
    });

    // State ----
    const form = reactive(props.form);

    // Handlers ----
    const emit = defineEmits(['change']);

    function onChanged() {
        emit('change');
    }
</script>

<template>
    <div class="add-consent">
        <FormContainer :form="form" optional-text="(Optional)">
            <FormMessages />
            <slot name="top"></slot>

            <div class="row mb-4">
                <div class="col">
                    <CheckBoxField v-model="form.model.pdfFinalApproval" @change="onChanged">
                        PDF Preview Content <span class="text-danger">Final Approval</span>.
                    </CheckBoxField>
                    <FieldMessages field-name="pdfFinalApproval" />
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <CheckBoxField v-model="form.model.acceptTermsAndConditions" @change="onChanged">
                        <!-- TODO add route to the T&Cs -->
                        View and Accept Terms and Conditions.
                    </CheckBoxField>
                    <FieldMessages field-name="acceptTermsAndConditions" />
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <CheckBoxField v-model="form.model.willNotUseMassachusetts" @change="onChanged">
                        I certify that <span class="text-danger">I Will Not</span> use this item in the state of Massachusetts.
                    </CheckBoxField>
                    <FieldMessages field-name="willNotUseMassachusetts" />
                </div>
            </div>

        </FormContainer>
    </div>
</template>

<style lang="scss">
    .add-consent { }
</style>