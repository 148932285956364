<script setup>
    //ImpersonateAlert

    // Components

    // Imports
    import { computed } from 'vue';
    import { site } from '@/Site';
    import { useRouter } from 'vue-router';

    // State
    const router = useRouter();

    // Computed
    const isImpersonating = computed(() => {
        return site.user.isImpersonating;
    })

    const name = computed(() => {
        return site.user.firstName + site.user.lastName;
    })

    // Handlers
    function endEmulation() {
        const endImpersonateSession = confirm("Are you sure to end Impersonating user session ?");
        if (endImpersonateSession) {
            site.authenticator.deleteImpersonateSession();
            router.push({ name: 'ImpersonationEnded' });
        }
    }

</script>


<template>
    <div class="alert alert-warning" v-if="isImpersonating">
        <div class="d-flex emulation">
            <div>
                <i class="bi bi-people-fill fs-2" />
            </div>
            <div>
                <p class="mb-0">
                    You are in an Impersonation Session for
                    <span class="fw-bold">{{name}}</span>
                </p>
            </div>
            <div>
                <button class="btn btn-primary btn-sm" @click="endEmulation">End Impersonation</button>
            </div>
        </div>
    </div>
</template>


<style lang='scss'>
    .emulation {
        align-items: center;
        gap: 1.5rem;
    }
</style>