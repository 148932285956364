export default class Banners {
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

   
    async getBanners() {
        return await this.store.api.get('/site/media/banner-list');
    }
}